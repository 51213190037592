import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { upperFirst } from 'lodash'
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';


const useStyles = makeStyles({
  root: {
    height: 20,
    borderRadius: 6,
    float: props => props?.alignLeft ? 'left' : 'right',

    background: props => (props?.status === 'succeeded' || props?.status === 'active')
      ? '#cbf4c9'
      : props?.status === 'pending'
        ? "#ffcc80"
        : '#e57373',
  },
});

const getIcon = (row) => (row?.status === "succeeded" || row?.status === 'active')
  ? <DoneIcon />
  : row?.status === 'failed'
    ? <CloseIcon />
    : <PauseIcon />

const CustomChip = ({ row, alignLeft }) => {
  const classes = useStyles({ ...row, alignLeft });
  return (
    < Chip
      size="small"
      icon={getIcon(row)}
      label={upperFirst(row?.status)}
      clickable
      className={classes.root}
    />)
}
export default CustomChip