import { buildQueryParam } from '../utils/queryParams';
import http from './httpService';
const postEndpoint = '/admin/posts';

class PostService {
  create = (data) => http.post(`${postEndpoint}`, data);

  getItem = (id) => http.get(`${postEndpoint}/get/${id}`);

  update = (data) => http.put(`${postEndpoint}/update/${data._id}`, data);

  getPostById = async (id) => {
    const { data } = await http.get(
      `${postEndpoint}/get-post-detail-by-id/${id}`
    );
    return data?.data;
  };

  updateStatus = (data) =>
    http.put(`${postEndpoint}/update-status/${data._id}`, {
      status: data.status,
      action_by: 'admin',
    });

  quickUpdate = (data, id) =>
    http.put(`${postEndpoint}/quick-update/${id}`, data);

  delete = (id) => http.delete(`${postEndpoint}/delete${id}`);

  getPostDetail = async (slug) => {
    const { data } = await http.get(`${postEndpoint}/get-post-detail/${slug}`);
    return data?.data;
  };

  paginate = (options) => {
    return http.get(`${postEndpoint + '/paginate' + buildQueryParam(options)}`);
  };

  sendPromotionMail = (data) => {
    return http.post(`${postEndpoint}/send-promotion-mail/${data?._id}`);
  };

  async updatePost(data) {
    return http.put(`/posts/admin/update/${data._id}`, data);
  }
}

export default new PostService();
