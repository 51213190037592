import http from './httpService';
import { buildQueryParam } from '../utils/queryParams';

const paymentEndpoint = '/admin/payments/stripe';
const payments = '/admin/payments';
class PaymentService {
  create = (data) => http.post(`${paymentEndpoint}/refund`, data);

  getTransactions = () => http.get(`${paymentEndpoint}/charges`);

  getPayments = (options) =>
    http.get(`/admin/payments/paginate${buildQueryParam(options)}`);

  getPayment = (id) => http.get(`/admin/payments/get/${id}`);

  createBannerPayment = (data) =>
    http.post(`${payments}/create-banner-payment/paypal`, data);

  captureBannerPayment = (orderId) =>
    http.post(`${payments}/capture-banner-payment/paypal`, { orderId });
}

export default new PaymentService();
