
import 'date-fns';
import Typography from '@material-ui/core/Typography'
import React from 'react';
import Button from '@material-ui/core/Button'
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { upperFirst } from 'lodash';
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';
import { ReactComponent as ScheduleIcon } from '../../../assets/images/schedule.svg'

const useStyles = makeStyles((theme) => ({
    scheduleIcon: {
        width: 40, height: 40,
        margin: '5px 7px 0px 0px',
        cursor: 'pointer'
    },
    btn: {
        height: '40px',
        paddingLeft: 7,
        width: 115
    }
}));

function CustomDatePicker({ value, setValue, label, name,  }) {
    const classes = useStyles()
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <Typography variant="h6">{label}</Typography>
             
                        <DatePicker
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            name={name}
                            format="yyyy-MM-dd"
                            value={value}
                            onChange={(date => {
                                setValue(`${name}`, date)
                            })}
                            disableFuture={true}
                            style={{ width: '0px' }}
                        />
                    
                 
                <Tooltip title={upperFirst(name)}>
                    <ScheduleIcon className={classes.scheduleIcon} onClick={() => setIsOpen(true)} />
                </Tooltip>
                <Button variant="contained" disabled className={classes.btn} onClick={() => setIsOpen(true)}>
                    {value ? moment(value).format('ll') : ""}
                </Button>
            </div>
        </MuiPickersUtilsProvider>

    );
}

export default CustomDatePicker;