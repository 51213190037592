import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { alpha, lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Helmet } from 'react-helmet';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useForm } from 'react-hook-form';
import ReplayIcon from '@material-ui/icons/Replay';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 700,
    marginLeft: theme.spacing(1.5),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#6d6d6d',
  },
  inputRoot: {
    color: 'inherit',
    borderRadius: '5px',
    // boxShadow: '1.5px 1.5px 2px 2px #bfbaba'
    border: '1px solid #edeef2',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
  popper: {
    zIndex: 1300,
    marginRight: 23,
    filter: 'drop-shadow(-1px -1px 2px #8e939c)',
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '5em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '4em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popperPaper: {
    minWidth: 260,
    marginTop: -10,
    // padding: '0px 2px',
  },
  clearBtn: {
    padding: '0px 5px',
    marginTop: 5,
    color: '#fff',
    background: '#455b80 !important',
    float: 'right',
    borderRadius: '2px !important',
    textTransform: 'none',
    marginRight: 5,
  },
  applyBtn: {
    padding: '0px 5px',
    marginTop: 5,
    // marginRight: 5,
    color: '#fff',
    background: '#0f414a !important',
    borderRadius: '2px !important',
    float: 'right',
    textTransform: 'none',
  },
  clearIcon: {
    color: 'red',
    cursor: 'pointer !important',
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const {
    numSelected,
    customLabel = 'Refund',
    onClick,
    custom = false,
    query,
    setQuery,
    children,
    title,
    onAddNew,
    open,
    setOpen,
    addDisable = false,
    filterable,
    allowNavigator = true,
    setNavigatorValue,
    navigatorValue,
    navigators = [],
  } = props;
  let disableAddButton = addDisable;

  const { handleSubmit, register, reset, watch } = useForm({
    defaultValues: { field: '' },
  });

  const anchorRef = React.useRef(null);
  const [arrowRef, setArrowRef] = React.useState(null);

  const handleClickButton = () => {
    if (!filterable) return;
    setOpen((prevOpen) => !prevOpen);
  };

  const onSubmit = (formData) => {
    // history
    setQuery({ ...query, page: 1, search: formData.field });
  };

  React.useEffect(() => {
    if (query?.search) {
      reset({ field: query?.search });
    }
  }, [!!query?.search, reset]);
  const searchQuery = watch('field');

  React.useEffect(() => {
    if (query?.search && !searchQuery) {
      setQuery({ ...query, search: '' });
    }
  }, [searchQuery]);

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Helmet>
        <title>{title.replace(/%20/g, ' ')} - Adjeem</title>
      </Helmet>
      {!disableAddButton && (
        <Tooltip title="Add new" onClick={onAddNew}>
          <IconButton aria-label="filter list">
            <AddBoxIcon color="secondary" />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title.replace(/%20/g, ' ')}
        </Typography>
      )}
      {allowNavigator && (
        <>
          <ToggleButtonGroup
            size="small"
            value={navigatorValue}
            exclusive
            onChange={(event, newValue) => {
              setNavigatorValue(newValue);
            }}
            aria-label="text alignment"
          >
            {navigators.map((navigator, index) => (
              <ToggleButton
                value={navigator.value}
                aria-label="left aligned"
                key={index}
              >
                {navigator.label} {navigator.icon}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      )}
      {numSelected > 0 ? (
        <>
          {custom ? (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<ReplayIcon />}
              onClick={onClick}
            >
              {customLabel}
            </Button>
          ) : (
            <>
              <Tooltip title="Delete" onClick={onClick}>
                <IconButton aria-label="filter list">
                  <DeleteIcon color="secondary" />
                </IconButton>
              </Tooltip>

              {props.customButtonLabel && (
                <Button
                  onClick={props.handleCustomButtonClick}
                  variant="contained"
                  color="primary"
                >
                  {props.customButtonLabel}
                </Button>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className={classes.search}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className={classes.searchIcon}>
                {searchQuery ? (
                  <ClearIcon className={classes.clearIcon} />
                ) : (
                  <SearchIcon />
                )}
              </div>
              <InputBase
                // inputRef={register('field')}
                {...register('field')}
                name="field"
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </form>
          </div>
          {filterable && (
            <Tooltip title="Filter list">
              <IconButton
                aria-label="filter list"
                ref={anchorRef}
                onClick={handleClickButton}
              >
                <FilterListIcon
                  color={query?.filter?.length > 0 ? 'secondary' : 'disabled'}
                />
              </IconButton>
            </Tooltip>
          )}
          <Popper
            open={(open && !!filterable) || false}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            disablePortal={false}
            className={classes.popper}
            modifiers={{
              flip: {
                enabled: true,
              },
              arrow: {
                enabled: true,
                element: arrowRef,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
              applyStyle: {
                gpuAcceleration: false,
              },
            }}
          >
            <span className={classes.arrow} ref={setArrowRef} />
            <Paper className={classes.popperPaper}>{children}</Paper>
          </Popper>
        </>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
