import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import customStyles from './customStyles';

const Type = ({ value }) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const options = [
    { value: 'text', label: 'Text' },
    { value: 'textarea', label: 'TextArea' },
    { value: 'select_multiple', label: 'Select Multiple' },
    { value: 'text_editor', label: 'Text Editor' },
    { value: 'select', label: 'Select' },
    { value: 'radio', label: 'radio' },
    { value: 'file', label: 'File' },
  ];

  React.useEffect(() => {
    setValue('type', options[0]);
  }, []);

  React.useEffect(() => {
    const result = options.find((obj) => {
      return obj.value === value;
    });
    setValue('type', result);
  }, [value]);

  return (
    <>
      <Grid item xs={12} lg={2}>
        <Form.Group controlId="type" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Field Type</Form.Label>
          <Controller
            control={control}
            name={`type`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Selec Field Type`}
                options={options}
                styles={customStyles}
                inputref={{
                  ...register(`type`),
                }}
              />
            )}
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default Type;
