import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import { pick, compact, omit } from 'lodash';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useQuery, useQueries } from 'react-query';
import * as InputField from './Fields';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';

import ContactDetail from './Sections/ContactDetail.component';
import PricingDetail from './Edit/Sections/PricingDetail.component';
import SalaryDetail from './Edit/Sections/SalaryRange.component';

import LocationDetail from './Sections/LocationDetail.component';
import CkeEditor from './Edit/TextEditor/index';
import Autocomplete from './Fields/autocomplete';

import TitleField from './Fields/TitleField.component';
import MultipleSelect from './Fields/multipleSelect';

import { mapResToPost, sanitizePostData } from './utils';
import { categoryService, fileService, postService } from '../../../services';
import { CountryContext } from '../../../context/countriesContext';
import toast from 'react-hot-toast';
import { isFormValid } from './Edit/helpers/validation';
import Select from './Edit/Fields/select';
import Radio from './Edit/Fields/radio';
import Checkbox from './Edit/Fields/checkbox';
import Textarea from './Edit/Fields/textarea';
import TextField from './Edit/Fields/textField';
import CustomDatePicker from './Edit/Sections/DatePicker.component';

const useStyles = makeStyles((theme) => ({
  rootDetail: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
export default function Options({ onClose, row, onUpdateSuccess }) {
  // const { userData, currentUser } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [deletedImg, setDelete] = useState([]);

  const [post, setPost] = useState(null);

  const [loading, setLoading] = useState({
    page: false,
    submmiting: false,
  });

  const [showMobileVerification, setShowMobileVerification] = useState(false);

  const initInputValues = {
    title: '',
    description: '',
    fields: [],
    city: '',
    user: {
      name: '',
      email: '',
      user_id: '',
      phone: [
        {
          number: '',
          isVerified: false,
        },
      ],
    },
    category: {
      parent: { _id: '', slug: '' },
      child: { _id: '', slug: '', media_type: '', template_type: '' },
    },
    price: {
      currency: '',
      amount: '',
      isNegotiable: '',
    },
    country: {
      name: '',
      code: '',
      _id: '',
    },
    salary: {
      type: 'exact_rate',
      from: null,
      to: null,
      exact: null,
      per: 'per_day',
    },
    city: '',
    location: {
      city_id: '',
      coordinates: [],
      formatted_address: '',
      postal_code: '',
    },
    category_name: { parentName: '', childName: '' },
    images: [],
    selected: {},
  };

  const [inputValues, setValues] = useState(initInputValues);
  const [files, setFiles] = useState([]);
  const [validation, setValidation] = useState({});
  const { value: countriesWithCities } = React.useContext(CountryContext);

  const input = React.createRef();
  const inputTitleRef = React.createRef();
  const inputDescriptionRef = React.createRef();

  const contactDetailRef = useRef();
  const pricingDetailRef = useRef();
  const salaryRef = useRef();

  const isClassifiedAds =
    inputValues?.category?.child?.template_type === 'classified';
  const isPostingJob =
    inputValues?.category?.child?.template_type === 'posting_job';

  const { data, isLoading, isError, error } = useQuery(['post', row._id], () =>
    postService.getPostById(row._id)
  );

  const postDetail = data;
  const [
    { data: d1, isLoading: isFieldsLoading },
    { data: d2, isLoading: isCategoriesLoading },
  ] = useQueries([
    {
      queryKey: ['fields'],
      queryFn: () =>
        categoryService.getFieldsByCategory(postDetail?.category?.child._id),
      enabled: !!postDetail,
    },
    {
      queryKey: ['categories'],
      queryFn: categoryService.getAllCategories,
      enabled: !!postDetail,
    },
  ]);
  const fields = d1?.data?.data;

  const parentCategories = d2?.data?.data?.filter((d) => !d.parent_id);

  useEffect(() => {
    (async function () {
      try {
        setFiles(postDetail?.images.map((i) => ({ file: null, image: i })));

        inputTitleRef.current = { value: postDetail?.title };
        inputDescriptionRef.current = { value: postDetail?.description };

        setValidation({});
        setPost(postDetail);
        setValues((previousData) => ({
          ...previousData,
          ...mapResToPost(postDetail, fields),
        }));
        setLoading((prev) => ({ ...prev, page: false }));
      } catch (ex) {
        setLoading((prev) => ({ ...prev, page: false }));
      }
    })();
  }, [!!postDetail, fields]);

  const handleOnChange = (value, key) => {
    if (validation.errors && validation.errors[key])
      setValidation({ ...validation }, delete validation.errors[key]);

    setValues({ ...inputValues, ...{ [key]: value } });
  };

  const handleOnFieldSelectChange = (field_id, option_id, key, e) => {
    const extras = e
      ? JSON.parse(e.target[e.target.selectedIndex].getAttribute('data-extras'))
      : '';

    if (!option_id) {
      const updatedOption = { ...inputValues, fields: [...inputValues.fields] };

      const matchingIndex = updatedOption.fields.findIndex(
        (field) => field._id == field_id
      );

      //Remove field
      updatedOption.fields.splice(matchingIndex, 1);

      setValues(updatedOption);

      return;
    }

    if (validation.errors && validation.errors[key])
      setValidation({ ...validation }, delete validation.errors[key]);

    const isFieldIdExist = inputValues?.fields.find((f) => f._id === field_id);

    const isFieldOptionExist = inputValues?.fields.find(
      (f) => f.option && f.option._id === option_id
    );

    if (!isFieldIdExist) {
      setValues((previousData) => ({
        ...previousData,
        fields: previousData.fields.concat({
          _id: field_id,
          option: {
            _id: option_id,
            rowExtras: extras,
          },
        }),
      }));
    } else if (isFieldIdExist && !isFieldOptionExist) {
      const updatedOption = {
        ...inputValues,
        fields: [...inputValues?.fields],
      };

      const matchingIndex = updatedOption.fields.findIndex(
        (field) => field._id == field_id
      );

      if (matchingIndex !== -1) {
        updatedOption.fields[matchingIndex] = {
          ...updatedOption.fields[matchingIndex],
          option: {
            _id: option_id,
            rowExtras: extras,
          },
        };
      }
      setValues(updatedOption);
    }
  };

  const handleOnFieldTextChange = (field_id, e) => {
    const value = e.target.value;

    if (validation.errors && validation.errors[field_id])
      setValidation({ ...validation }, delete validation.errors[field_id]);

    const isFieldIdExist = inputValues?.fields.find((f) => f._id === field_id);

    if (!isFieldIdExist) {
      return setValues((previousData) => ({
        ...previousData,
        fields: previousData.fields.concat({
          _id: field_id,
          value: value,
        }),
      }));
    } else if (isFieldIdExist && value) {
      const updatedOption = {
        ...inputValues,
        fields: [...inputValues?.fields],
      };

      const matchingIndex = updatedOption.fields.findIndex(
        (field) => field._id == field_id
      );

      if (matchingIndex !== -1) {
        updatedOption.fields[matchingIndex] = {
          ...updatedOption.fields[matchingIndex],
          value: value,
        };
      }

      return setValues(updatedOption);
    } else {
      const updatedOption = {
        ...inputValues,
        fields: [...inputValues?.fields],
      };

      const matchingIndex = updatedOption.fields.findIndex(
        (field) => field._id == field_id
      );
      //Remove field
      updatedOption.fields.splice(matchingIndex, 1);

      return setValues(updatedOption);
    }
  };

  const getExtra = (
    extras = [],
    extra_id,
    value_id,
    extra_name,
    extra_slug
  ) => {
    let arr = extras;

    const index = extras?.map((extra) => extra._id).indexOf(extra_id);

    if (index == -1) {
      arr.push({
        _id: extra_id,
        value: {
          _id: value_id,
          name: extra_name,
          slug: extra_slug,
        },
      });
    } else {
      arr[index] = {
        _id: extra_id,
        value: {
          _id: value_id,
          name: extra_name,
          slug: extra_slug,
        },
      };
    }

    return arr;
  };

  const handleOnExtraChange = (
    field_id,
    option_id,
    extra_id,
    value_id,
    extra_name,
    extra_slug
  ) => {
    const isFieldIdExist = inputValues?.fields.find((f) => f._id === field_id);

    if (validation.errors && validation.errors[extra_id])
      setValidation({ ...validation }, delete validation.errors[extra_id]);

    const isFieldOptionExist = inputValues?.fields.find(
      (f) => f.option && f.option._id === option_id
    );

    if (isFieldIdExist && isFieldOptionExist) {
      const updatedOption = {
        ...inputValues,
        fields: [...inputValues?.fields],
      };

      const fieldMatchingIndex = updatedOption.fields.findIndex(
        (field) => field._id == field_id
      );

      const currentField = updatedOption.fields[fieldMatchingIndex];

      const option = currentField.option;

      const extras = currentField.option.extras;

      if (fieldMatchingIndex !== -1) {
        if (!value_id) {
          const extraMatchingIndex = extras.findIndex(
            (field) => field._id == extra_id
          );

          extras.splice(extraMatchingIndex, 1);

          updatedOption.fields[fieldMatchingIndex] = {
            ...updatedOption.fields[fieldMatchingIndex],
            option: {
              ...option,
              _id: option._id,
              extras: extras,
            },
          };

          setValues(updatedOption);

          return true;
        }

        updatedOption.fields[fieldMatchingIndex] = {
          ...updatedOption.fields[fieldMatchingIndex],
          option: {
            ...option,
            _id: option._id,
            extras: getExtra(
              extras,
              extra_id,
              value_id,
              extra_name,
              extra_slug
            ),
          },
        };

        setValues(updatedOption);
      }

      return;
    }
  };

  const showExtras = (field_id) => {
    let data = '';
    const mData = fields?.find((f) => f._id === field_id).options;

    const selectedMakeId = inputValues?.fields?.find((m) => m._id === field_id);
    const makeSelectedExtra = selectedMakeId?.option?.extras;

    const makeIdFields = mData?.find(
      (m) => m._id === selectedMakeId?.option?._id
    );
    const makeExtraField = makeIdFields?.extras;

    data = makeExtraField?.map((extra) => {
      const selected = makeSelectedExtra?.find(
        (selected) => extra._id === selected._id
      );
      const getValueName = (id) => extra.values.find((d) => d._id === id);

      return (
        <div key={extra._id} className="mt-2">
          <Typography> {extra.name}</Typography>
          <Form.Group>
            <Form.Control
              className={`${
                validation.errors && validation.errors[extra._id]
                  ? 'is-invalid'
                  : ''
              }`}
              value={selected?.value?._id}
              as="select"
              id={extra._id}
              onChange={(e) =>
                handleOnExtraChange(
                  field_id,
                  makeIdFields._id,
                  extra._id,
                  e.target.value,
                  getValueName(e.target.value)?.name,
                  getValueName(e.target.value)?.slug
                )
              }
            >
              <option></option>
              {extra?.values?.map((value, index) => (
                <option key={index} value={value?._id}>
                  {value?.name}
                </option>
              ))}
            </Form.Control>
            {validation.errors && validation.errors[extra._id] && (
              <p className="invalid-feedback">{validation.errors[extra._id]}</p>
            )}
          </Form.Group>
        </div>
      );
    });

    return data;
  };

  const handleFileChange = (d) => {
    // return
    setFiles(d);
    if (files.length > 0 && validation?.error?.files) {
      const { files, ...rest } = validation?.error;
      setValidation(rest);
    }
  };

  const handleMultipleSelectField = (data, field_id) => {
    const cloned = { ...inputValues };
    cloned.selected = data;

    if (validation.errors && validation.errors[field_id])
      setValidation({ ...validation }, delete validation.errors[field_id]);

    setValues(cloned);
  };

  const isInputFeedNeeded = (fieldId, fieldIndex) => {
    const matchingFieldinCurrentState = inputValues?.fields?.find(
      (f) => f._id === fieldId
    );
    if (matchingFieldinCurrentState) {
      const matchingFieldInAllData = fields[fieldIndex]?.options?.find(
        (f) => f._id === matchingFieldinCurrentState?.option?._id
      );

      return !!matchingFieldInAllData?.require_input_field;
    } else return false;
  };

  const handleSubmit = async (e, field) => {
    e.preventDefault();
    const arg = {
      fields: field,
      inputValues,
      inputTitleRef,
      files,
      contactDetailRef,
      isClassifiedAds,
      isPostingJob,
      pricingDetailRef,
      userData: {},
      router: navigate,
      media_type: inputValues?.category?.child?.media_type,
    };
    const { isValid, errors } = isFormValid(arg);

    setValidation({ errors });
    if (!isValid) return;

    setLoading({ submmiting: true });

    const price = {
      currency: '$',
      amount: pricingDetailRef?.current?.getPricingDetailObject()?.price || 0,
      isNegotiable:
        pricingDetailRef?.current?.getPricingDetailObject().isNegotiable,
    };

    const user = {
      name: inputValues.user.name,
      email: inputValues.user.email,
      user_id: inputValues.user.user_id,
      phone: {
        number: contactDetailRef.current.getContactDetailObject().phone,
        calling_code: '$',
      },
    };

    const fields = inputValues.fields;
    const country = inputValues.country;
    const title = inputTitleRef.current.value;
    const category = inputValues.category;

    const description = inputValues?.description;

    const location = {
      city_id: inputValues.city,
      address: {
        ...inputValues.location,
        name: inputValues?.location?.address?.country || '$',
        country_code: inputValues?.location?.address?.country_code || '$',
      },
    };
    const locale = 'en';

    const images = await Promise.all(
      files.map(async (file) => {
        let formic = new FormData();

        if (!formic.has('files') && file?.preview) {
          formic.append(`files`, file);
          const { data } = await fileService.uploadFileAndApplyWatermark(
            formic
          );
          formic.delete('files');
          return data?.data?.image;
        } else return file?.image;
      })
    );
    if (deletedImg?.length > 0) fileService.deleteFile(deletedImg);

    const salary = salaryRef?.current?.getSalaryRangeObject();

    const data = {
      category,
      price,
      //user,
      country,
      fields,
      title,
      description,
      location,
      locale,
      _id: params.id,
      expiresAt: inputValues?.expiresAt,
      salary: { ...salary, currency: '$' },
      images: compact(images.flat()),
    };

    let multipleFields = inputValues.selected;

    data.fields = data.fields.reduce(
      (acc, red) => [
        ...acc,
        { ...red, option: red.option ? [red.option] : [] },
      ],
      []
    );

    multipleFields = Object.keys(multipleFields).reduce((acc, red) => {
      acc.push({
        _id: red,
        option: multipleFields[red].map((d) => ({
          _id: d.value,
          extras: [],
        })),
      });
      return acc;
    }, []);
    data.fields = [...data.fields, ...multipleFields];

    await postService
      .update({
        ...sanitizePostData(data, {}),
        _id: row._id,
        city: row?.city,
        //user: row?.user,
      })
      .then(({ data }) => {
        toast.success('Updated successfully');
        onClose('updated');
      })
      .catch((error) => {
        console.log(error);
        setLoading({ submmiting: false });
      });
  };
  if (loading.page) return <Spinner />;

  const handleAutoCompleteChange = (data, type) => {
    const clonedValues = { ...inputValues };
    if (type === 'parent') {
      clonedValues.category.parent = pick(data, ['name', 'slug', '_id']);
      clonedValues.category.child = null;
    } else if (type === 'child') {
      clonedValues.category.child = pick(data, [
        'media_type',
        'name',
        'slug',
        '_id',
      ]);
    }

    setValues(clonedValues);
  };

  /**
   *
   */
  const handleDescriptionChange = (event) => {
    setValues({
      ...inputValues,
      description: event.target.value,
    });
    if (event.target.value) {
      if (validation?.errors?.description) {
        setValidation(omit(validation?.errors, ['description']));
      }
    } else {
      setValidation({
        ...validation,
        errors: {
          ...validation.errors,
          description: 'Required Field',
        },
      });
    }
  };

  const subCategories =
    d2?.data?.data?.filter(
      (d) => d.parent_id === inputValues?.category?.parent?._id
    ) || [];
  const city =
    countriesWithCities &&
    countriesWithCities[inputValues?.country?._id]?.cities?.find(
      (c) => c._id === inputValues?.city
    );

  if (isLoading || isFieldsLoading || isCategoriesLoading) return <Spinner />;
  return (
    <div>
      <Dialog
        open={true}
        keepMounted
        onClose={onClose}
        scroll="paper"
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
      >
        <Grid container>
          <h2 className="text-center mb-3">Edit Post</h2>
          <Form onSubmit={(e) => handleSubmit(e, fields)} autoComplete="off">
            <Card className="p-4">
              <Grid container>
                <Autocomplete
                  options={parentCategories}
                  label="Parent Category"
                  selected={inputValues?.category?.parent?._id}
                  onChange={(data) => handleAutoCompleteChange(data, 'parent')}
                />
                {inputValues?.category?.parent?._id && (
                  <Autocomplete
                    options={subCategories}
                    selected={inputValues?.category?.child?._id}
                    label="Child Category"
                    onChange={(data) => handleAutoCompleteChange(data, 'child')}
                  />
                )}
              </Grid>

              <TitleField
                name={'title'}
                placeholder={''}
                isMandatory={true}
                reff={inputTitleRef}
                label="Title"
                validationError={validation.errors}
                initialValue={inputValues['title'] || ''}
              />
              <CkeEditor
                showHint={true}
                value={inputValues.description}
                field={{
                  name: 'Description',
                  mandatory: true,
                }}
                errors={validation?.errors && validation.errors.description}
                setData={handleDescriptionChange}
                data={inputValues.description}
              />

              <h4 className="p-2 mt-4 mb-4">Detail</h4>

              <div className={classes.detailRoot}>
                {fields?.map((field, index) => {
                  const slug = field.slug;

                  let currentValue = '';

                  const matchingIndex = inputValues?.fields?.length
                    ? inputValues.fields.findIndex((f) => f._id == field._id)
                    : false;

                  const fields =
                    inputValues?.fields && inputValues?.fields[matchingIndex];

                  if (fields && fields.option)
                    currentValue = inputValues.fields[matchingIndex].option._id;

                  return (
                    <div key={index} className={`field_${field.type}`}>
                      {field.type === 'select_multiple' && (
                        <div id={field._id}>
                          <MultipleSelect
                            field={field}
                            onChange={handleMultipleSelectField}
                            value={inputValues.selected}
                            validation={validation}
                          />
                        </div>
                      )}
                      {field.type === 'text' && (
                        <div id={field._id}>
                          <TextField
                            validation={validation}
                            field={field}
                            input={input}
                            defaultValue={fields?.value}
                            handleOnFieldTextChange={handleOnFieldTextChange}
                          />
                        </div>
                      )}

                      {field.type === 'select' && (
                        <div id={field._id}>
                          <Select
                            field={field}
                            fields={fields}
                            validation={validation}
                            inputValues={inputValues}
                            handleOnFieldSelectChange={
                              handleOnFieldSelectChange
                            }
                            handleOnFieldTextChange={handleOnFieldTextChange}
                            showExtras={showExtras}
                            index={index}
                            defaultValue={currentValue}
                          />
                        </div>
                      )}

                      {field.type === 'radio' && (
                        <div id={field._id}>
                          <Radio
                            field={field}
                            validation={validation}
                            handleOnFieldSelectChange={
                              handleOnFieldSelectChange
                            }
                            currentValue={currentValue}
                            slug={slug}
                          />
                        </div>
                      )}

                      {field.type === 'checkbox' && (
                        <div id={field._id}>
                          <Checkbox
                            field={field}
                            validation={validation}
                            handleOnFieldSelectChange={
                              handleOnFieldSelectChange
                            }
                            inputValues={inputValues}
                            slug={slug}
                          />
                        </div>
                      )}
                      {field.type === 'text_editor' && (
                        <div id={field._id}>
                          <CkeEditor
                            field={field}
                            errors={
                              validation.errors && validation.errors[field._id]
                            }
                            data={fields?.value}
                            setData={(event) =>
                              handleOnFieldTextChange(field._id, event)
                            }
                          />
                        </div>
                      )}
                      {field.type === 'textarea' && (
                        <div id={field._id}>
                          <Textarea
                            field={field}
                            validation={validation}
                            input={input}
                            defaultValue={fields?.value}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              {isPostingJob && (
                <div id="datepicker">
                  <CustomDatePicker
                    inputValues={inputValues}
                    setValues={(data) => {
                      setValues(data);
                      setValidation(omit(validation, ['expiresAt']));
                    }}
                    validation={validation.errors}
                  />
                </div>
              )}
              {inputValues?.category?.child?.media_type === 'image' ? (
                <>
                  <h4 className="p-2 mt-4 mb-2">Upload File</h4>

                  <p>Upload upto 8 images</p>
                  <InputField.Uploader
                    files={files || []}
                    onDelete={(data) => setDelete([...deletedImg, data])}
                    validation={validation}
                    setFiles={handleFileChange}
                  />
                </>
              ) : (
                <>
                  <h4 className="p-2 mt-4 mb-2">Upload Image</h4>
                  <InputField.FileUploader
                    files={files || []}
                    onDelete={(data) => setDelete([...deletedImg, data])}
                    validation={validation}
                    setFiles={handleFileChange}
                  />
                </>
              )}
              {isClassifiedAds && (
                <PricingDetail
                  userData={{}}
                  ref={pricingDetailRef}
                  initialValue={inputValues.price}
                  errors={validation.errors}
                />
              )}
              {isPostingJob && (
                <SalaryDetail
                  userData={{}}
                  ref={salaryRef}
                  initialValue={inputValues.salary}
                  errors={validation.errors}
                />
              )}
              <div id="contact">
                <ContactDetail
                  userData={post}
                  ref={contactDetailRef}
                  validation={validation}
                  currentUser={inputValues.user}
                  inputValues={inputValues}
                />
              </div>
              <div id="location">
                <LocationDetail
                  inputValues={inputValues}
                  userData={post}
                  setLocation={setValues}
                  validationError={validation}
                  location={inputValues.location}
                  handleOnFieldTextChange={handleOnChange}
                  disabled={inputValues.city ? 0 : 1}
                  value={inputValues.city}
                  setValidation={setValidation}
                />
              </div>
            </Card>
            <DialogActions>
              <Button
                autoFocus
                name="update"
                type="submit"
                disabled={loading.submmiting}
                color="primary"
              >
                Update
              </Button>
              <Button onClick={onClose} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Form>
        </Grid>
      </Dialog>
    </div>
  );
}
