import React from 'react';
import { Helmet } from 'react-helmet';

function Breadcrumb({ title = 'Create', label = 'Back', goBack }) {
  return (
    <div>
      <Helmet>
        <title>{title} - Adjeem</title>
      </Helmet>
      <div className="page-header">
        <h3 className="page-title">{title}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <p onClick={() => goBack()} role="button">
                {label}
              </p>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default Breadcrumb;
