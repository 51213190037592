import React from 'react';
import { useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, Dropdown } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import DownloadForOfflineIcon from '@material-ui/icons/CloudDownload';

import { ReactComponent as NewIcon } from '../../../../assets/images/new.svg';
import { ReactComponent as VerifiedIcon } from '../../../../assets/images/verified.svg';

import { toast } from 'react-hot-toast';
import moment from 'moment';
import qs from 'query-string';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import { postService } from '../../../services';
import Table from '../../common/table';
import CustomChip from '../../common/chip';
import Filter from './components/filter';
import { getFlag } from '../../../utils/countryFlag';
import PostDetail from './components/detail';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '0px',
    fontSize: 11,
    width: 250,
    height: 250,
    borderRadius: '50%',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 250,
    height: 250,
  },
  thumbnail: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
  verifiedIcon: {
    height: '20px',
    width: '20PX',
  },
  promotedIcon: {
    height: '20px',
    width: '20PX',
    fill: '#f7bd0c',
    marginRight: '4px',
  },
  iconContainer: {
    display: 'flex',
  },
}));

function Items() {
  const { search } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [query, setQuery] = React.useState(null);
  const [selectedPost, setSelectedPost] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  const {
    data,
    isLoading: isFetching,
    error,
    isError,
  } = useQuery([query, search], () => postService.paginate(query), {
    refetchOnWindowFocus: true,
    enabled: !!query,
  });

  const { mutate, error: err } = useMutation(
    (row) =>
      postService.updateStatus({
        status: row.status,
        _id: row._id,
        isVerified: true,
        isNewPost: false,
      }),
    {
      onSuccess: () => {
        toast.success('Successfully updated !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?'))
      mutate({ ...row, status: 'deleted' });
  };

  const handleClose = (arg) => {
    setSelectedPost(null);

    if (arg) setQuery({ ...query, at: Date.now() });
  };

  return (
    <>
      {selectedPost ? (
        <PostDetail
          row={selectedPost}
          onClose={handleClose}
          setQuery={() => setQuery({ ...query, at: Date.now() })}
        />
      ) : (
        ''
      )}

      {isError ||
        (err && (
          <Alert variant="info">
            {' '}
            {error?.response?.data?.message || err}{' '}
          </Alert>
        ))}

      <Table
        title="Ads"
        filterable
        open={open}
        onOpen={() => setOpen((prev) => !prev)}
        query={query}
        setQuery={setQuery}
        data={data?.data?.data || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew=""
        columns={[
          {
            label: 'Date',
            path: 'createdAt',
            content: (row) => (
              <>
                <p>{moment(row.createdAt).format('ll')}</p>
                <p>{moment(row.createdAt).format('LT')}</p>
              </>
            ),
          },
          {
            label: 'Image',
            content: (row) => (
              <div>
                {row?.category?.child?.media_type === 'image' && (
                  <LightTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <img
                          src={row?.thumbnail || row?.media?.thumbnail?.medium}
                          alt=""
                          className={classes.image}
                        />
                      </React.Fragment>
                    }
                  >
                    <img
                      src={row?.thumbnail || row?.media?.thumbnail?.medium}
                      alt=""
                      className={classes.thumbnail}
                    />
                  </LightTooltip>
                )}
              </div>
            ),
          },
          { label: 'Title', path: 'title' },
          { label: 'User', path: 'user.name' },
          {
            label: 'Status',
            content: (row) => <CustomChip row={row} alignLeft />,
          },

          {
            label: 'Country',
            content: (row) => (
              <Tooltip title={row?.country?.name} placement="bottom">
                <p> {getFlag(row?.country?.code)} </p>
              </Tooltip>
            ),
          },
          {
            label: '',
            content: (row) => (
              <div className={classes.iconContainer}>
                {row.isVerified && (
                  <Tooltip title="Verified Post" placement="bottom">
                    <VerifiedIcon className={classes.verifiedIcon} />
                  </Tooltip>
                )}
                <Tooltip title="View Detail" placement="bottom">
                  <RemoveRedEyeIcon
                    className="ml-2"
                    onClick={() => setSelectedPost(row)}
                    role="button"
                  />
                </Tooltip>
              </div>
            ),
          },

          {
            label: 'Action',
            content: (row) => (
              <Grid container>
                <Grid item xs={8}>
                  <div className="d-flex flex-wrap" align="left">
                    <Dropdown className="mr-1">
                      <Dropdown.Toggle
                        variant="btn btn-primary"
                        id="dropdownMenuButton1"
                      >
                        Action
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {[
                          { label: 'Pending', status: 'pending' },
                          { label: 'Block', status: 'blocked' },
                          { label: 'Active', status: 'active' },
                        ]
                          .filter((d) => d.status !== row.status)
                          .map((action) => {
                            return (
                              <Dropdown.Item key={action.status}>
                                {action.label}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Grid>
                {row?.isNewPost && (
                  <Grid item xs={4}>
                    <NewIcon />
                  </Grid>
                )}
              </Grid>
            ),
          },
        ]}
      >
        <Filter query={query} open={open} setQuery={setQuery} />
      </Table>
    </>
  );
}

export default Items;
