import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  img: {
    height: 60,
    width: 60,
    marginTop: -20,
    borderRadius: '50%',
  },
  imagContainer: {
    paddingLeft: '40px',
  },
}));

const Upload = ({ onFileSelect, image }) => {
  const classes = useStyles();

  const [preview, setPreview] = React.useState(null);

  const handleChange = (event) => {
    const img = event.target.files[0];
    onFileSelect(img);
    setPreview(URL.createObjectURL(img));
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <input type="file" onChange={handleChange} name="image" />
      </Grid>
      {preview || image ? (
        <Grid item xs={6} className={classes.imagContainer}>
          <img src={preview || image} className={classes?.img} alt="" />
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default Upload;
