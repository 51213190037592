import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

function Phone(props) {
  const { calling_code } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div>
        <Form.Group md="3" controlId={'user_phone'} className="mt-3 mb-0">
          <Form.Label className="font-weight-bold">Business Phone *</Form.Label>

          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{calling_code}</InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              name={`phone`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Form.Control
                  type={'tel'}
                  maxLength={11}
                  value={value || ''}
                  placeholder={'Enter contact number'}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => onChange(e.target.value.slice(0, 11))}
                  name="phone"
                />
              )}
            />
          </InputGroup>
        </Form.Group>

        {errors && errors['phone'] && (
          <RequestErrorMessage message={`Please enter the contact number`} />
        )}
      </div>
    </>
  );
}

export default Phone;
