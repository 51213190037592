import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: '2px',
    marginBottom: '8px',
    marginRight: '8px',
    width: '105px',
    height: '105px',
    position: 'relative',
    boxSizing: 'border-box',
  },
  thumbInner: {
    cursor: 'move',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
    zIndex: 0,
    objectFit: 'contain',
  },
  removeIcon: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    zIndex: 1,
    color: 'red',
    backgroundColor: 'rgb(237, 242, 245)',
    borderRadius: '50%',
    width: '20px',
    cursor: 'pointer',
  },
  thumbContainer: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(115px, 1fr))',
  },
  message: {
    background: '#707889',
    color: 'white',
  },
}));

const SortableItem = SortableElement(({ file, handleClick, classes }) => (
  <div key={file?.name} className={classes.thumb}>
    <div className={classes.thumbInner}>
      <img src={file?.preview || file?.image} className={classes.img} />
      <RemoveCircleIcon onClick={handleClick} className={classes.removeIcon} />
    </div>
  </div>
));

const SortableList = SortableContainer(({ files, handleClick, classes }) => (
  <div>
    {files.map((file, index) => (
      <SortableItem
        file={file}
        key={`item-${file?.path}-${index}`}
        index={index}
        handleClick={() => handleClick(index)}
        classes={classes}
      />
    ))}
  </div>
));

function Previews({ setFiles, files, onDelete, translate: t, validation }) {
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop: (acceptedFiles) => {
      setFiles((prev) =>
        [
          ...prev,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              image: null,
            })
          ),
        ].slice(0, 8)
      );
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFiles(arrayMoveImmutable(files, oldIndex, newIndex));
  };
  const handleRemove = (index) => {
    let newFiles = [...files];
    if (newFiles[index]?.image) onDelete(newFiles[index]?.image);

    newFiles = newFiles.filter((_file, idx) => idx !== index);
    setFiles(newFiles);
  };

  const isError = validation.errors?.files;
  return (
    <div>
      <aside className={classes.thumbContainer}>
        {files.length > 0 && (
          <SortableList
            classes={classes}
            files={files}
            onSortEnd={onSortEnd}
            axis={'xy'}
            handleClick={handleRemove}
            distance={1}
          />
        )}
      </aside>
      {files.length === 8 ? (
        ''
      ) : files.length > 0 && files?.length < 8 ? (
        <div
          {...getRootProps({ className: 'dropzone' })}
          align="center"
          className={classes.message}
          role="button"
        >
          <input {...getInputProps()} />
          <span className={classes.message}>
            {8 - files.length + ' ' + 'remaining images'}
          </span>
        </div>
      ) : (
        <div {...getRootProps({ className: 'dropzone' })} align="center">
          <input {...getInputProps()} />
          <button className={`btn btn-primary `} type="button">
            Upload Image
          </button>
          <br />
        </div>
      )}

      {isError && files.length === 0 ? <p>{isError}</p> : ''}
    </div>
  );
}
export default Previews;
