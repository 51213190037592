import React from 'react';
import { Form, Spinner, Col, Button, Alert, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useMutation } from 'react-query'
import { categoryService } from '../../../../services';

const ref = React.createRef();

function Add({ fields, onAdded, category_id }) {

  const [selected, setSelected] = React.useState([])
  const [validated, setValidated] = React.useState(false)

  const { mutate, isLoading: addLoading, error, isError } = useMutation(formData =>
    categoryService.addField(formData), {
    onSuccess: () => {
      ref.current.clear();
      onAdded();
    }
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true)
    } else mutate({ field_id: selected[0]._id, category_id })
  }
  // const sanitizedField
  return (
    <div className='mb-3'>
      {isError ? <Alert variant="info"> {error?.response?.data?.message} </Alert> : null}

      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group
          as={Col}
          md='12'
          controlId='validationCustom01'
          className='p-0'
        >
          <InputGroup>
            <Typeahead
              required
              ref={ref}
              labelKey={option => `${option.name}  → ${option.hint_admin}`}
              id='typeahead-single'
              options={fields || []}
              placeholder='Choose a Field...'
              onChange={(event) => setSelected(event)}
              inputProps={{ required: true }}
            />
            <InputGroup.Append>
              <Button
                variant='secondary'
                type='submit'
                disabled={addLoading}
              >
                Add
                {addLoading && <Spinner animation='border' size='sm' variant='info' />}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Add;
