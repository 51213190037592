import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { pick, compact } from 'lodash';
import { Helmet } from 'react-helmet';
import {
  Form,
  Spinner,
  Button,
  Alert,
  Accordion,
  Card,
  Table,
  InputGroup,
} from 'react-bootstrap';
import { useMutation, useQuery, useQueries } from 'react-query';
import { useForm } from 'react-hook-form';
import CreatePlanPriceModal from './price/Create';
import { useSelector } from 'react-redux';

import Input from '../../common/input';
import Select from '../../common/select';
import AccordionBody from '../../common/accordionBody';
import { SubmitButton } from '../../common/button';
import PlanService from '../../../services/plan.service';
import CustomInputAppend from '../../common/customInputAppend';
import { validatePost } from '../../../validations/post';

import { bannerType } from '../../../utils/banner';
import { adsType } from '../../../utils/ads';
import planService from '../../../services/plan.service';
import { getSanitizedData } from '../../../utils/sanitizer';

const statusOptions = [
  { id: 'active', name: 'Active' },
  { id: 'blocked', name: 'Blocked' },
];
const planOptions = [
  { id: 'post', name: 'Post' },
  { id: 'banner', name: 'Banner' },
  { id: 'premium', name: 'Premium' },
];

function EditPlan() {
  const navigate = useNavigate();
  const params = useParams();
  const countriesWithCity = useSelector(
    (state) => state.country.countryWithcities
  );
  const [ind, setIndex] = useState(null);
  const [selected, setSelected] = useState(null);
  const { errors, register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: { status: 'active', type: 'post' },
  });
  const [show, setShow] = React.useState(false);
  const [fields, setFields] = React.useState([]);
  const [features, setFeatures] = React.useState([]);
  const [durationError, setDurationError] = React.useState(null);

  const result = useQueries([
    {
      queryKey: ['bannerAds'],
      queryFn: planService.getBannerPlans,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['postAds'],
      queryFn: planService.getPostPlans,
      refetchOnWindowFocus: false,
    },
  ]);
  const { d1: bannerPlan, d2: postPlan } = getSanitizedData(result);

  const {
    data: plan,
    error,
    isError: isPlanError,
  } = useQuery(['plan'], () => PlanService.getItem(params.id), {
    refetchOnWindowFocus: false,
  });

  const countries = countriesWithCity
    ? Object.values(countriesWithCity)?.map((country) =>
        pick(country, ['_id', 'name', 'code'])
      )
    : [];

  const data = plan?.data?.data;

  const {
    mutate,
    error: err,
    isLoading,
    isError,
  } = useMutation((formData) => PlanService.update(formData), {
    onSuccess: () => navigate('/subscriptions/plans'),
  });

  React.useEffect(() => {
    reset({ ...data, type: data?.type ? data?.type : 'post' });
    setFields(data?.durations);
    setFeatures(data?.features);
  }, [data, reset]);
  /**
   * FilterBanner Type To make the unique
   */
  const type = watch('type');
  const filteredBannerType = compact([
    ...bannerType?.filter(
      (d) => !bannerPlan?.map((p) => p?.banner_type)?.includes(d?.id)
    ),
    bannerType?.find((b) => b?.id === data?.banner_type),
  ]);

  const filteredAdType = compact([
    ...adsType?.filter(
      (d) => !postPlan?.map((p) => p?.ads_type)?.includes(d?.id)
    ),
    adsType?.find((b) => b?.id === data?.ads_type),
  ]);
  /**
   * On Banner type selection
   */
  React.useEffect(() => {
    if (type === 'banner') {
      setValue('banner_type', filteredBannerType[0]?.id);
      setValue('ads_type', null);
    } else {
      setValue('ads_type', filteredAdType[0]?.id);
      setValue('banner_type', null);
    }
    //eslint-disable-next-line
  }, [type]);

  /**
   *  Show loading
   */

  const showLoading = () =>
    !data && (
      <div id="loadingOverlay" className="loader-overlay">
        <div className="loader-content loader-center">
          <div className="loader-center loader-text">
            <Spinner animation="border" size="md" variant="danger" />
          </div>
        </div>
      </div>
    );

  const onSubmit = (formData) => {
    console.log(formData);
    const durations = fields.reduce((acc, red) => {
      acc.push(red);
      return acc;
    }, []);
    const res = validatePost(durations);
    if (res) return setDurationError(res);
    else mutate({ ...formData, durations, _id: params.id });
  };

  const onPriceSet = (price) => {
    const field = [...fields];
    if (!!selected) {
      fields[ind].prices[selected.idx] = {
        ...fields[ind]?.prices[selected.idx],
        ...price,
      };
    } else {
      fields[ind]['prices'] = fields[ind]['prices']
        ? [...fields[ind]?.prices, price]
        : [price];
    }
    setFields(field);
    setSelected(null);
    setShow(false);
  };
  const onDayChange = (val, idx) => {
    const field = [...fields];
    field[idx].days = val;
    setFields(field);
  };
  const handleRemove = (idx) => {
    const field = [...fields];
    field.splice(idx, 1);
    setFields(field);
  };
  const onEdit = (index, idx, row) => {
    setIndex(index);
    setSelected({ idx, row });
    setShow(true);
  };
  const onDelete = (index, idx) => {
    const field = [...fields];
    field[index].prices.splice(idx, 1);
    setFields(field);
  };
  const onStandardPriceChange = (target, idx) => {
    const field = [...fields];
    field[idx].standardPrice = target.value;
    setFields(field);
  };

  const handleFeatureRemove = (index) => {
    const feature = [...features];
    feature.splice(index, 1);
    setFeatures(feature);
  };

  const onFeatureTitleChange = (val, idx) => {
    const feature = [...features];
    feature[idx].title = val;
    setFeatures(feature);
  };

  const onFeatureOrderChange = (val, idx) => {
    const feature = [...features];
    feature[idx].order = val;
    setFeatures(feature);
  };

  const onFeatureIsIncludedChange = (val, idx) => {
    const feature = [...features];
    feature[idx].isIncluded = val;
    setFeatures(feature);
  };

  const usedCountry =
    ind !== null ? fields[ind]?.prices?.map((pr) => pr?.country_id) : null;

  const countryObject = countries?.reduce(
    (acc, red) => ({ ...acc, [red._id.toString()]: red }),
    {}
  );

  const filteredCountry = (countryId) =>
    countries?.reduce(
      (acc, red) => {
        if (!usedCountry?.includes(red?._id)) acc.push(red);
        return compact(acc);
      },
      [countryObject[countryId]]
    );

  return (
    <div>
      <CreatePlanPriceModal
        countries={
          selected?.row
            ? filteredCountry(selected?.row?.country_id)
            : filteredCountry()
        }
        show={show}
        addPrice={onPriceSet}
        plan_id={params.id}
        data={selected?.row}
        onHide={() => setShow(false)}
      />

      <Helmet>
        <title>Edit Category - Adjeem</title>
      </Helmet>
      {showLoading()}

      <div className="page-header">
        <h3 className="page-title"> Edit </h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/subscriptions/plans">Back</Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin">
          {isPlanError ||
          isError ||
          (filteredAdType.length === 0 && filteredBannerType.length === 0) ? (
            <Alert variant="warning">
              {' '}
              {error?.response?.data?.message ||
                err?.response?.data?.message ||
                'Cannot create a new Plan (All types of ads are active) '}{' '}
            </Alert>
          ) : null}

          <div className="card">
            <div className="card-body">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Accordion defaultActiveKey="0" className="w-100">
                  <AccordionBody label="General" eventKey="0">
                    <Input
                      col={6}
                      required
                      errors={errors}
                      label="Name"
                      placeholder="Feature ad for 30 days"
                      name="name"
                      register={register}
                    />
                    <Input
                      col={5}
                      label="Description"
                      placeholder="Reach upto 20 times more buyer"
                      name="description"
                      register={register}
                    />
                    <Input
                      col={6}
                      label="Hint"
                      placeholder="Keep your ad at the top of category"
                      name="hint"
                      register={register}
                    />
                    <Input
                      col={5}
                      errors={errors}
                      required
                      type="number"
                      label="Order"
                      placeholder="0"
                      name="order"
                      register={register}
                    />
                    <Input
                      col={5}
                      errors={errors}
                      required
                      type="number"
                      label="Advert Limit"
                      placeholder="1"
                      name="advert_limit"
                      register={register}
                    />
                    <Select
                      required
                      errors={errors}
                      label="Status"
                      options={statusOptions}
                      name="status"
                      register={register}
                    />
                    <Select
                      required
                      disabled={filteredBannerType.length === 0}
                      label="Type"
                      options={planOptions}
                      name="type"
                      register={register}
                    />

                    {type === 'post' && filteredAdType.length > 0 && (
                      <Select
                        required={type === 'post'}
                        label="Ads Type"
                        options={filteredAdType}
                        name="ads_type"
                        register={register}
                      />
                    )}
                    {type === 'banner' ? (
                      <>
                        <Select
                          required
                          label="Banner Type"
                          options={filteredBannerType}
                          name="banner_type"
                          register={register}
                        />
                        <Input
                          col={6}
                          required={type === 'banner'}
                          errors={errors}
                          label="Banner Width *"
                          type="number"
                          placeholder="Required image width"
                          name="image_resolution.width"
                          register={register}
                        />
                        <Input
                          col={5}
                          required={type === 'banner'}
                          errors={errors}
                          required
                          type="number"
                          label="Banner Height *"
                          placeholder="Required image height"
                          name="image_resolution.height"
                          register={register}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </AccordionBody>
                </Accordion>
                <Accordion defaultActiveKey="1" className="w-100">
                  <AccordionBody label="Durations" eventKey="1">
                    <div className="col-12">
                      <h5>Durations</h5>
                      {fields?.map((duration, index) => {
                        return (
                          <div
                            key={index}
                            className="bg-light p-3 mb-3 col-md-12"
                          >
                            {durationError && durationError[index] && (
                              <Alert variant="danger">
                                {' '}
                                {durationError[index]}
                              </Alert>
                            )}
                            <Form.Label>Days</Form.Label>
                            <CustomInputAppend
                              value={duration?.days}
                              name="days"
                              remove={() => handleRemove(index)}
                              onChange={(val) => onDayChange(val, index)}
                            />

                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Standard Cost</Form.Label>
                              <Form.Control
                                name="standardPrice"
                                type="number"
                                value={duration?.standardPrice || ''}
                                placeholder="Enter Standard Price"
                                onChange={({ target }) =>
                                  onStandardPriceChange(target, index)
                                }
                              />
                            </Form.Group>
                            <Card>
                              <Card.Header className="bg-secondary text-white p-1 mb-3">
                                Prices
                              </Card.Header>
                              <Card.Body className="pl-1 pr-1">
                                <Button
                                  onClick={() => {
                                    setShow(true);
                                    setIndex(index);
                                  }}
                                  className="btn-sm mb-2"
                                  variant="light"
                                >
                                  Add Price
                                </Button>

                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      {['Prices', 'Country', 'Action'].map(
                                        (d) => (
                                          <th key={d}>{d}</th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fields[index]?.prices?.length > 0
                                      ? fields[index]?.prices?.map((p, idx) => {
                                          return (
                                            <tr key={idx + p?.country_id}>
                                              <td>{p.price}</td>
                                              <td>
                                                {
                                                  countries?.find(
                                                    (c) =>
                                                      c._id === p.country_id
                                                  )?.name
                                                }
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() =>
                                                    onEdit(index, idx, p)
                                                  }
                                                  size="sm"
                                                  className="mr-1"
                                                  variant="secondary"
                                                >
                                                  Edit
                                                </Button>
                                                |{' '}
                                                <Button
                                                  size="sm"
                                                  onClick={() =>
                                                    onDelete(index, idx)
                                                  }
                                                >
                                                  Delete
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      : ''}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </div>
                        );
                      })}
                      <Button
                        type="button"
                        className="btn btn-success"
                        onClick={() => setFields((prev) => [...prev, {}])}
                      >
                        Add
                      </Button>
                    </div>
                  </AccordionBody>
                </Accordion>

                <Accordion defaultActiveKey="2" className="w-100">
                  <AccordionBody label="Features" eventKey="2">
                    <div className="ml-2 w-100">
                      {/* <h5>Title</h5> */}
                      {features?.map((feature, index) => (
                        <div key={index}>
                          <Form.Label>Title</Form.Label>
                          <InputGroup className="mb-2 ml-0" id={feature._id}>
                            <InputGroup.Prepend>
                              <Form.Control
                                {...register(`features[${index}].order`)}
                                name={`features[${index}].order`}
                                md={1}
                                type="number"
                                placeholder="0 "
                                value={feature?.order}
                                onChange={(event) =>
                                  onFeatureOrderChange(
                                    event.target.value,
                                    index
                                  )
                                }
                              />
                            </InputGroup.Prepend>
                            <Form.Control
                              {...register(`features[${index}].title`)}
                              name={`features[${index}].title`}
                              md={8}
                              type="text"
                              placeholder="Up to 10 times more clients for ads "
                              value={feature?.title}
                              onChange={(event) =>
                                onFeatureTitleChange(event.target.value, index)
                              }
                            />
                            <InputGroup.Append>
                              <Button
                                variant="danger"
                                onClick={() => handleFeatureRemove(index)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>

                          <div className="mt-20">
                            <Form.Check
                              {...register(`features[${index}].isIncluded`)}
                              name={`features[${index}].isIncluded`}
                              type="switch"
                              id={`isIncluded_${index}`}
                              value={feature?.isIncluded}
                              onChange={(event) =>
                                onFeatureIsIncludedChange(
                                  event.target.checked,
                                  index
                                )
                              }
                              label="Is Included?"
                            />
                          </div>
                        </div>
                      ))}
                      <br />
                      <Button
                        type="button"
                        className="btn btn-success"
                        onClick={() =>
                          setFeatures((prev) => (prev ? [...prev, {}] : []))
                        }
                      >
                        Add
                      </Button>
                    </div>
                  </AccordionBody>
                </Accordion>

                <SubmitButton
                  isLoading={isLoading}
                  disabled={
                    filteredAdType.length === 0 &&
                    filteredBannerType.length === 0
                  }
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPlan;
