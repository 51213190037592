import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReorderIcon from '@material-ui/icons/Reorder';
import get from 'lodash/get';

function EnhancedTableBody(props) {
  const {
    rows,
    selected,
    handleClick,
    columns,
    isDragDisabled = true,
    onDragEnd,
  } = props;

  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return get(item, column.path);
  };
  const createKey = (item, column) => {
    return item?._id + (column?.path || column?.key);
  };

  const handleDragEnd = (result, provided) => {
    if (!result.destination) return;

    if (result.destination.index === result.source.index) return;
    onDragEnd({
      source: result.source.index,
      destination: result.destination.index,
    });
  };

  const isSelected = (id) => selected.findIndex((d) => d?._id === id) !== -1;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(droppableProvided) => (
          <TableBody
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {rows?.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              return (
                <Draggable
                  isDragDisabled={isDragDisabled}
                  key={row._id}
                  draggableId={row._id}
                  index={index}
                >
                  {(draggableProvided, snapshot) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row?._id + index + isItemSelected}
                        selected={isItemSelected}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={{
                          ...draggableProvided.draggableProps.style,
                          background: snapshot.isDragging
                            ? 'rgba(245,245,245, 0.75)'
                            : row?.highlight
                            ? '#f0ede4'
                            : 'none',
                        }}
                      >
                        {!isDragDisabled && (
                          <TableCell align="left">
                            <div {...draggableProvided.dragHandleProps}>
                              <ReorderIcon />
                            </div>
                          </TableCell>
                        )}
                        <TableCell
                          padding="checkbox"
                          onClick={(event) => handleClick(event, row)}
                        >
                          <Checkbox checked={isItemSelected} />
                        </TableCell>
                        {columns.map((column, idx) => (
                          <TableCell
                            align="left"
                            key={createKey(row, column) + idx}
                          >
                            {renderCell(row, column)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default EnhancedTableBody;
