import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { pick } from 'lodash';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import compact from 'lodash/compact';
import { useQuery, useMutation } from 'react-query';
import { planPriceService } from '../../../../services';
import CreatePlanPriceModal from './Create.js';
import Table from '../../../common/table';

function PlanPrice() {
  const params = useParams();
  const { search } = useLocation();
  const [addNewMdShow, setAddNewMdShow] = useState(false);
  const [currentPrice, setCurrentPrice] = useState({});

  const countriesWithCity = useSelector(
    (state) => state.country.countryWithcities
  );

  const param = new URLSearchParams(search);
  const plan = param.get('name');
  const duration_id = param.get('duration');

  const [query, setQuery] = React.useState({
    page: 1,
    limit: 10,
    sorted: 'name:desc',
  });

  const {
    data,
    isError,
    isLoading: isFetching,
    error,
  } = useQuery(
    ['planPrice', query, duration_id],
    () =>
      planPriceService.paginate({ ...query, plan_id: params.id, duration_id }),
    { refetchOnWindowFocus: false, enabled: !!duration_id }
  );

  const countries = countriesWithCity
    ? Object.values(countriesWithCity)?.map((country) =>
        pick(country, ['_id', 'name', 'code'])
      )
    : [];

  const planPrice = data?.data?.data;

  const { mutate } = useMutation(
    (data) =>
      planPriceService.create({ ...data, plan_id: params.id, duration_id }),
    {
      onSuccess: () => {
        setAddNewMdShow((prev) => !prev);
        setCurrentPrice({});
        toast.success('Successfully added new item');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const { mutate: priceUpdate } = useMutation(
    (row) =>
      planPriceService.update({
        price_id: currentPrice?._id,
        plan_id: params.id,
        duration_id,
        ...row,
      }),
    {
      onSuccess: () => {
        setQuery({ ...query, at: Date.now() });
        setAddNewMdShow(false);
        setCurrentPrice({});
        toast.success('Successfully updated the price');
      },
    }
  );

  const { mutate: priceDelete } = useMutation(
    (row) => planPriceService.delete(params.id, duration_id, row._id),
    {
      onSuccess: () => {
        setQuery({ ...query, at: Date.now() });
        toast.success('Successfully deleted Price');
      },
    }
  );

  const onEdit = (row) => {
    setCurrentPrice(row);
    setAddNewMdShow(true);
  };

  const onDelete = (row) => {
    if (!window.confirm('Are you sure you wish to delete this item?'))
      return true;
    priceDelete(row);
  };

  const countryObject = countries?.reduce(
    (acc, red) => ({ ...acc, [red._id.toString()]: red }),
    {}
  );

  const planPriceId = planPrice?.docs?.map((p) => p?.country_id);

  const filterCountry = (countryId) =>
    countries?.reduce(
      (acc, red) => {
        if (!planPriceId?.includes(red?._id)) acc.push(red);
        return compact(acc);
      },
      [countryObject[countryId]]
    );

  const isEmpty = Object.values(currentPrice).length === 0;

  return (
    <>
      <CreatePlanPriceModal
        countries={
          isEmpty ? filterCountry() : filterCountry(currentPrice?.country_id)
        }
        show={addNewMdShow}
        data={currentPrice}
        plan_id={params.id}
        addPrice={(data) => (isEmpty ? mutate(data) : priceUpdate(data))}
        onHide={() => {
          setAddNewMdShow((prev) => !prev);
          setCurrentPrice({});
        }}
      />

      {isError && (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      )}

      <Table
        title={`${plan} Plan Price`}
        query={query}
        setQuery={setQuery}
        data={planPrice || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => setAddNewMdShow(true)}
        columns={[
          {
            label: 'Country',
            path: 'country.name',
            content: (row, idx) => (
              <p key={row?._id}>
                {countryObject ? countryObject[row?.country_id]?.name : ''}
              </p>
            ),
          },
          { label: 'Price', path: 'price' },
          { label: `Exchange Rate($ to Country)`, path: 'rate' },
          { label: 'Duration', path: 'price' },
          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <div className="d-flex flex-wrap " key={row._id}>
                <button
                  className="btn btn-sm btn-light mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="icon-pencil text-primary"></i>
                </button>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default PlanPrice;
