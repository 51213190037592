import { buildQueryParam } from '../utils/queryParams';
import http from './httpService';
const promotionEndpoint = '/admin/promotions';

class PromotionService {
  create = (data) => http.post(`${promotionEndpoint}/create`, data);

  paginate = (options) => {
    return http.get(
      `${promotionEndpoint + '/paginate' + buildQueryParam(options)}`
    );
  };

  getItem = (id) => http.get(`${promotionEndpoint}/${id}`);

  update = (data) => http.put(`${promotionEndpoint}/${data._id}`, data);

  delete = (id) => http.delete(`${promotionEndpoint}/${id}`);
}

export default new PromotionService();
