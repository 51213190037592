import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../../../common/requestError';

const Hint = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12} lg={4} className="mt-3">
        <Form.Group controlId="hint" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Hint *</Form.Label>
          <Form.Control
            type={'text'}
            label="hint"
            {...register('hint', {
              required: 'Hint is required',
            })}
            name="hint"
          />
        </Form.Group>

        {errors?.hint && (
          <RequestErrorMessage message={` Please enter the hint.`} />
        )}
      </Grid>
    </>
  );
};

export default Hint;
