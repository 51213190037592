import http from './httpService';
const planEndpoint = '/admin/plans';
class PlanService {
  create = (data) => http.post(`${planEndpoint}/create`, data);

  paginate = (options) => {
    let extraQuery = '';
    extraQuery += `&sort=${options.sorted}`;
    extraQuery += options.search ? `&search=${options.search}` : '';
    return http.get(
      `${planEndpoint}/paginate?page=${options.page}&limit=${
        options.limit + extraQuery
      }`
    );
  };

  getItem = (id) => http.get(`${planEndpoint}/get/${id}`);

  update = (data) => http.put(`${planEndpoint}/update/${data._id}`, data);

  delete = (id) => http.delete(`${planEndpoint}/delete/${id}`);

  all = () => http.get(`${planEndpoint}/all`);

  getBannerPlans = () =>
    http.get(
      `${planEndpoint}/get-plans?locale=en&currency=$country=AED&type=banner`
    );

  getPostPlans = () =>
    http.get(
      `${planEndpoint}/get-plans?locale=en&currency=$country=AED&type=post`
    );
}

export default new PlanService();
