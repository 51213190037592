import React from 'react';
import { Form } from 'react-bootstrap';

export default function Select({
  field,
  fields,
  validation,
  inputValues,
  handleOnFieldSelectChange,
  handleOnFieldTextChange,
  showExtras,
  index,
  defaultValue,
}) {
  const isInputFeedNeeded = (fieldId, fieldIndex) => {
    const matchingFieldinCurrentState = inputValues.fields.find(
      (f) => f._id === fieldId
    );
    if (matchingFieldinCurrentState) {
      const matchingFieldInAllData = fields[fieldIndex]?.options?.find(
        (f) => f._id === matchingFieldinCurrentState?.option?._id
      );

      return !!matchingFieldInAllData?.require_input_field;
    } else return false;
  };

  return (
    <>
      <Form.Group>
        <Form.Label>
          {field.name} {field.mandatory ? '*' : ''}{' '}
        </Form.Label>
        <Form.Control
          className={`${
            validation.errors && validation.errors[field._id]
              ? 'is-invalid'
              : ''
          }`}
          as="select"
          value={defaultValue}
          id={field.slug}
          onChange={(e) => {
            handleOnFieldSelectChange(field._id, e.target.value, field.slug, e);
          }}
        >
          <option></option>
          {field.options.map((option, index) => (
            <option
              key={index}
              value={option._id}
              data-extras={JSON.stringify(option.extras)}
            >
              {' '}
              {option.value}{' '}
            </option>
          ))}
        </Form.Control>
        {validation.errors && validation.errors[field._id] && (
          <p className="invalid-feedback">{validation.errors[field._id]}</p>
        )}
      </Form.Group>

      <div id={`child_${field.slug}`}>{showExtras(field._id)}</div>
      {isInputFeedNeeded(field._id, index) && (
        <Form.Group>
          <Form.Label>Other</Form.Label>
          <Form.Control
            className={`${
              validation.errors && validation.errors[field._id]
                ? 'is-invalid'
                : ''
            }`}
            type="text"
            placeholder=""
            // ref={input}
            defaultValue={fields?.value}
            onChange={(e) => handleOnFieldTextChange(field._id, e)}
            name={field.slug}
          />
        </Form.Group>
      )}
    </>
  );
}
