import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';

import Breadcrumb from '../../../common/breadcrumb';
import MuiAccordion from '../../../common/muiAccordion';
import { fieldService, languageService } from '../../../../services';
import Name from './fields/nameField';
import Hint from './fields/hintField';
import { Spinner } from 'react-bootstrap';

function EditLanguageSpecificField() {
  const navigate = useNavigate();
  const params = useParams();

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const { data: d1 } = useQuery('field', () => fieldService.getItem(params.id));

  const { data: l1 } = useQuery('language', () =>
    languageService.getItem(params.languageId)
  );

  const currentItem = d1?.data?.data;
  const language = l1?.data?.data;

  const onSubmit = async (formData) => {
    setLoading(true);
    mutate(formData);
  };

  const update = async (formData) => {
    const data = TransformFieldData({
      formData,
      id: currentItem?._id,
      language,
      currentItem,
    });

    await fieldService.update(data);
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => update(formData), {
    onSuccess: () => {
      setLoading(false);
      toast.success(`Successfully  added new category`);
      navigate('/fields');
    },
  });

  if (!currentItem || !language) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb
        goBack={() => navigate('/fields')}
        title={`Edit: ${currentItem.name} - ${language.name}`}
      />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name value={currentItem?.[language?.code]?.name} />

            <Hint value={currentItem?.[language?.code]?.hint} />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid || loading}
            >
              Submit
              {isSubmitting ||
                (loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ))}
            </button>
          </div>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default EditLanguageSpecificField;

export const TransformFieldData = ({ formData, id, language, currentItem }) => {
  console.log(formData);
  const responseData = {
    _id: id,
    language_code: language?.code,
    ...currentItem,
    ...formData,
  };

  return responseData;
};
