import React, { Component } from 'react';
import { Form, Spinner, Col, Button, Alert } from 'react-bootstrap';

import { optionService } from '../../../../../services';

class AddData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      errors: {},
      message: null,
      loading: false,

      name: '',
      order: 1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.onDataAdded = this.onDataAdded.bind(this);
  }

  inputChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onDataAdded = (data) => {
    this.props.onAdded(data);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    this.setState({ loading: true });

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true, loading: false });
    } else {
      optionService
        .addExtraData({
          name: this.state.name,
          order: parseInt(this.state.order),
          option_id: this.props.option_id,
          field_id: this.props.field_id,
          extra_id: this.props.extra_id,
        })
        .then(({ data }) => {
          this.setState({ message: data.message, loading: false, extra: '' });
          this.onDataAdded(data.data);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ message: 'Error! Please try again', loading: false });
        });
    }
  };

  render() {
    return (
      <div className="mb-3">
        {this.state.message ? (
          <Alert variant="info"> {this.state.message} </Alert>
        ) : null}

        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <Form.Group as={Col}>
            <Form.Label>name</Form.Label>
            <Form.Control
              required
              type="text"
              name="name"
              value={this.state.name || ''}
              onChange={this.inputChangeHandler}
              placeholder="Like: model name..."
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Order</Form.Label>
            <Form.Control
              required
              type="number"
              name="order"
              value={this.state.order || ''}
              onChange={this.inputChangeHandler}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Button
              variant="secondary"
              type="submit"
              disabled={this.state.loading}
            >
              Add
              {this.state.loading ? (
                <Spinner animation="border" size="sm" variant="info" />
              ) : null}
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default AddData;
