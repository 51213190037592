import React from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';

export const MuiInput = ({
  required = false,
  type = 'text',
  name,
  placeholder = '',
  label,
  errors,
  register,
  fullWidth = true,
  value = '',
  disabled = false,
}) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <Typography variant="subtitle1">{label}</Typography>
      <TextField
        error={errors[name]}
        fullWidth={fullWidth}
        size="small"
        variant="outlined"
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        inputRef={register(name, { required })}
      />
    </Grid>
  );
};

export const MuiSelect = ({
  required = true,
  name,
  label,
  errors,
  register,
  options,
  control,
  fullWidth = false,
}) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <Typography variant="subtitle1">{label}</Typography>
      <Controller
        as={
          <TextField
            select
            error={errors[name]}
            name={name}
            fullWidth
            variant="outlined"
            size="small"
            inputRef={register(name, { required })}
          >
            {options?.map((option) => (
              <MenuItem
                key={option?.id || option?._id}
                value={option?.id || option?._id}
              >
                {option?.name}
              </MenuItem>
            ))}
          </TextField>
        }
        rules={{ required }}
        name={name}
        control={control}
      />
    </Grid>
  );
};

export const MuiCheckbox = ({ name, label, control, ...rest }) => {
  return (
    <Grid item className="pr-1 pl-2 mt-3">
      <Typography variant="subtitle1"></Typography>
      <Controller
        as={
          <FormControlLabel
            control={
              <Controller
                name={name}
                control={control}
                render={(props) => (
                  <Checkbox
                    {...props}
                    {...rest}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={label}
          />
        }
        name={name}
        control={control}
      />
    </Grid>
  );
};

export const MuiRadio = ({ name, label, control, options, defaultValue }) => {
  return (
    <Grid item className="pr-1 pl-2 mt-3" key={label}>
      <Typography variant="subtitle1">{label}</Typography>

      <Controller
        rules={{ required: true }}
        control={control}
        defaultValue={defaultValue}
        name={name}
        as={
          <RadioGroup>
            <Form.Row>
              {options.map((option) => {
                return (
                  <FormControlLabel
                    value={option.id}
                    control={<Radio />}
                    label={option.name}
                  />
                );
              })}
            </Form.Row>
          </RadioGroup>
        }
      />
    </Grid>
  );
};

export const MuiButtonGroup = ({
  label,
  setPaymentMode,
  classes,
  paymentMode,
  option1,
  option2,
}) => {
  return (
    <Grid item xs={6} className="pr-1 pl-3 mt-3">
      <Typography variant="subtitle1">{label}</Typography>
      <ButtonGroup variant="contained" size="medium">
        <Button
          onClick={() => setPaymentMode(option1)}
          className={clsx(classes.defaultTab, {
            [classes.activeTab]: paymentMode === option1,
          })}
        >
          {option1}
        </Button>
        <Button
          onClick={() => setPaymentMode(option2)}
          className={clsx(classes.defaultTab, {
            [classes.activeTab]: paymentMode === option2,
          })}
        >
          {option2}
        </Button>
      </ButtonGroup>
    </Grid>
  );
};
