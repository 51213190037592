import http from './httpService';

const categoryEndpoint = '/admin/categories';
class CategoryService {
  create = (data) => {
    return !data?._id
      ? http.post(`${categoryEndpoint}/create`, data)
      : http.put(`${categoryEndpoint}/update/${data._id} `, data);
  };

  async paginate({
    page = 1,
    limit = 10,
    sorted = 'createdAt:desc',
    search = '',
    parent_id,
  }) {
    let extraQuery = '&parent_id=' + (parent_id || null);
    extraQuery += `&sort=${sorted}`;
    extraQuery += search ? `&search=${search}` : '';
    return http.get(
      `${categoryEndpoint}/paginate?page=${page}&limit=${limit + extraQuery}`
    );
  }

  getItem = (id) => http.get(`${categoryEndpoint}/get/${id}`);

  update = (data) => http.put(`${categoryEndpoint}/update/${data._id}`, data);

  delete = (id) => http.delete(`${categoryEndpoint}/delete/${id}`);

  parents = () => http.get(`${categoryEndpoint}/parents/`);

  addField = (data) =>
    http.post(`${categoryEndpoint}/create_field/${data.category_id}`, data);

  fields = (data) =>
    http.get(
      `${categoryEndpoint}/fields/${data._id}?page=${data.page}&limit=${data?.limit}&search=${data.search}&sorted=${data.sorted}`
    );

  updateFieldIndex = (categoryId, data) =>
    http.put(`${categoryEndpoint}/fields/update/${categoryId}`, data);

  deleteField = (data) =>
    http.delete(
      `${categoryEndpoint}/delete_field/${data.category_id}/${data.field_id}`
    );

  deleteMultipleFields = async (categoryId, fields) => {
    const { data } = await http.put(
      `${categoryEndpoint}/delete_fields/${categoryId}`,
      fields
    );
    return data;
  };

  disableMultipleFields = async (categoryId, fields) => {
    const { data } = await http.put(
      `${categoryEndpoint}/disable_fields/${categoryId}`,
      fields
    );
    return data;
  };

  getAllCategories = () => http.get(`${categoryEndpoint}/all?locale=en-US`);

  getAllIndustries = () => http.get(`${categoryEndpoint}/industries`);

  getFieldsByCategory = (categoryId) =>
    http.get(`${categoryEndpoint}/${categoryId}/fields?locale=en`);
}

export default new CategoryService();
