import http from './httpService';
const OPINION_API_URL = '/admin/opinions';

class OpinionService {
  getPaginatedOpinion = async (id, page = 1, stats) => {
    const { data } = await http.get(
      `${OPINION_API_URL}/paginate/${id}?page=${page}&stats=${stats}`
    );
    return data?.data;
  };
}
export default new OpinionService();
