import React from 'react';
function RequestErrorMessage({ message }) {
  return (
    <div
      className="alert alert-warning validation-error"
      role="alert"
      style={{ marginTop: 0 }}
    >
      {message}
    </div>
  );
}

export default RequestErrorMessage;
