import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Alert, Dropdown } from 'react-bootstrap';
import { useMutation, useQueries } from 'react-query';
import { toast } from 'react-hot-toast';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import qs from 'query-string';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { Avatar, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { fieldService, fileService, optionService } from '../../../../services';
import AddOption from './Add';
import EditOption from './Edit';
import Table from '../../../common/table';
import { getSanitizedData } from '../../../../utils/sanitizer';

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  uploadIcon: {
    color: 'green',
  },
}));

function CategoryFields() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const languages = useSelector((state) => state.language.languages);

  const [show, setShow] = React.useState(false);
  const [option, setOption] = React.useState({});
  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  const result = useQueries([
    {
      queryKey: 'fields',
      queryFn: () => fieldService.getItem(params.fieldId),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['options', query],
      queryFn: () =>
        optionService.paginate({ ...query, field_id: params.fieldId }),
      refetchOnWindowFocus: false,
      enabled: !!query,
    },
  ]);

  const { d1: field, d2: options, err } = getSanitizedData(result);

  const { mutate: updateMutate } = useMutation(
    (formData) => optionService.update(formData),
    {
      onSuccess: () => {
        onOptionAdded();
      },
    }
  );

  const { mutate, isError, error, isLoading } = useMutation(
    (row) => optionService.delete(row._id, field?._id),
    {
      onSuccess: () => {
        toast.success('Successfully deleted !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };

  const onManageExtras = (row) => {
    navigate(
      '/fields/' +
        row.field_id +
        '/options/' +
        row._id +
        '/extras?name=' +
        row.value
    );
  };

  const onEdit = (row, language) => {
    const data = {
      _id: row._id,
      order: row.order,
      field_id: row.field_id,
      language: language.code,
      is_featured: row.is_featured || false,
      require_input_field: row.require_input_field || false,
      value: language.code !== 'en' ? row[language.code]?.value : row?.value,
    };
    setShow(true);
    setOption(data);
  };

  const onOptionAdded = () => {
    setQuery({ ...query, at: Date.now() });
    setShow(false);
  };

  const handleSwitch = (event, row) => {
    const checked = event.target.checked;
    updateMutate({ ...row, require_input_field: checked });
  };

  const handleFeature = (event, row) => {
    const checked = event.target.checked;
    updateMutate({ ...row, is_featured: checked });
  };

  const handleImageUpload = async (event, row) => {
    try {
      if (event.target.files[0]) {
        const formData = new FormData();
        formData.append('files', event.target.files[0]);
        const res = await fileService.fileUpload(formData);
        updateMutate({ ...row, logo: res?.data?.data?.image });
      }
    } catch (ex) {
      console.log('exception occurred on file upload');
    }
  };

  const categoryName = qs.parse(location.search)?.name;

  return (
    <>
      {isError ||
        (err && (
          <Alert variant="info">{error?.response?.data?.message || err} </Alert>
        ))}
      <AddOption field_id={field?._id} onAdded={onOptionAdded} />
      <EditOption
        show={show}
        onHide={() => setShow(false)}
        option={option}
        field_id={field?._id}
        onAdded={onOptionAdded}
      />

      <Table
        title={`${categoryName} → Options`}
        query={query}
        addDisable
        setQuery={setQuery}
        data={options || {}}
        onToolbarAction={onDelete}
        isFetching={isLoading}
        columns={[
          {
            label: 'Value',
            path: 'value',
          },
          { label: 'Order', path: 'order' },
          {
            label: 'Require Input FIeld',
            path: 'require_input_field',
            content: (row) => (
              <Switch
                checked={row.require_input_field}
                onChange={(e) => handleSwitch(e, row)}
                name="checkedA"
              />
            ),
          },
          ...(options?.otherInfo?.field?.sub_type === 'brand'
            ? [
                {
                  label: 'Feature',
                  content: (row) => (
                    <Switch
                      checked={row.is_featured}
                      onChange={(e) => handleFeature(e, row)}
                      name=""
                    />
                  ),
                },
              ]
            : []),
          ...(options?.otherInfo?.field?.sub_type === 'brand'
            ? [
                {
                  label: 'Image',
                  content: (row) => (
                    <Box display="flex">
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        component="label"
                        className={classes.uploadButton}
                        startIcon={
                          <CloudUploadIcon
                            fontSize="large"
                            className={classes.uploadIcon}
                          />
                        }
                      >
                        <input
                          accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => handleImageUpload(event, row)}
                        />
                      </Button>
                      {row.logo && <Avatar src={row.logo} alt={row.value} />}
                    </Box>
                  ),
                },
              ]
            : []),

          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <div className="d-flex flex-wrap ">
                <Dropdown className="mr-1">
                  <Dropdown.Toggle
                    variant="btn btn-primary"
                    id="dropdownMenuButton1"
                  >
                    Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languages
                      ? languages.map((language) =>
                          language.code !== 'en' ? (
                            <Dropdown.Item
                              onClick={() => onEdit(row, language)}
                              key={language._id}
                            >
                              {' '}
                              Edit - {language.name}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => onEdit(row, language)}
                              key={language._id}
                            >
                              {' '}
                              Edit{' '}
                            </Dropdown.Item>
                          )
                        )
                      : null}
                  </Dropdown.Menu>
                </Dropdown>

                <button
                  className="btn btn-default btn-light mr-1"
                  onClick={() => onManageExtras(row)}
                >
                  Manage Extras
                </button>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default CategoryFields;
