import { buildQueryParam } from '../utils/queryParams';
import http from './httpService';

const userEndpoint = '/admin/users';
class UserService {
  create = (data) =>
    !data?._id
      ? http.post(`${userEndpoint}/create`, data)
      : http.put(`${userEndpoint}/update/${data._id}`, data);

  get = (id) => http.get(`${userEndpoint}/user-detail/${id}`);

  paginate = (options) => {
    return http.get(`${userEndpoint}/paginate${buildQueryParam(options)}`);
  };

  delete = (id) => http.delete(`${userEndpoint}/delete/${id}`);

  update = (id, data) => http.put(`${userEndpoint}/update/${id}`, data);

  suggestions = (query) => {
    if (!query) return;
    return http.get(`${userEndpoint}/suggestions?search=${query}`);
  };

  getUserBoard = () => http.get(`${userEndpoint}/user`);

  getModeratorBoard = () => http.get(`${userEndpoint}/mod`);

  getAdminBoard = () => http.get(`${userEndpoint}/admin`);

  roles = (id) => http.get(`${userEndpoint}/roles`);
}

export default new UserService();
