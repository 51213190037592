import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import customStyles from './customStyles';

const Status = (props) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const statusOptions = [
    { value: 'pending', label: 'Pending' },
    { value: 'active', label: 'Active' },
    { value: 'blocked', label: 'Blocked' },
    { value: 'deleted', label: 'Deleted' },
  ];

  React.useEffect(() => {
    setValue('status', statusOptions[1]);
  }, []);

  return (
    <>
      <Grid item xs={12} lg={2} className="mt-3">
        <Form.Group controlId="status" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Status</Form.Label>
          <Controller
            control={control}
            name={`status`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Selec status`}
                options={statusOptions}
                styles={customStyles}
                inputref={{
                  ...register(`status`),
                }}
              />
            )}
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default Status;
