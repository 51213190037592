import React from 'react';
import { Modal, Button } from 'react-bootstrap'
function CustomModal({ show, onHide, headerTitle, children, btnLabel }) {
    return (
        <div>
            <Modal show={show} onHide={onHide}  >
                <Modal.Header>
                    <Modal.Title>{headerTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {children}
                </Modal.Body>

                {btnLabel && <Modal.Footer className='flex-wrap'>
                    <Button variant='light m-2' onClick={onHide}>
                        {btnLabel}
                    </Button>
                </Modal.Footer>}
            </Modal>
        </div>
    );
}

export default CustomModal;