import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import * as arrayMove from 'array-move';
import { useFormContext } from 'react-hook-form';

import styles from '../add.module.scss';

const SortableItem = SortableElement(({ file, handleClick }) => (
  <div className={styles.thumb} key={file?.name}>
    <div className={styles.thumbInner}>
      <img src={file?.preview || file?.image} className={styles.img} />

      <label className={styles.remove_icon} onClick={handleClick}>
        &times;
      </label>
    </div>
  </div>
));

const SortableList = SortableContainer(({ files, handleClick }) => (
  <div>
    {files.map((file, index) => (
      <SortableItem
        file={file}
        key={`item-${file?.path}-${index}`}
        index={index}
        handleClick={() => handleClick(index)}
      />
    ))}
  </div>
));

function ImageUploader() {
  const { register, unregister, setValue } = useFormContext();

  const [showWarning, setWarning] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setValue('files', files);
  }, [files]);

  useEffect(() => {
    register('files');
    return () => {
      unregister('files');
    };
  }, [register, unregister, 'files']);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpeg,.png,.jpg',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setError(`Error: ${err.message}`);
          }

          if (err.code === 'file-invalid-type') {
            setError(`Error: ${err.message}`);
          }
        });
      });

      acceptedFiles.reduce((acc = [], file) => {
        let img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = function () {
          if (img.width > 100 && img.height > 100) {
            const image = Object.assign(file, {
              preview: URL.createObjectURL(file),
            });

            setFiles((prev) => [...prev, image].slice(0, 8));
            setWarning(false);
          } else {
            if (!showWarning) setWarning(true);
          }
        };
        return acc;
      }, []);
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFiles(arrayMove(files, oldIndex, newIndex));
  };

  const handleRemove = (index) => {
    let newFiles = [...files];

    newFiles = newFiles.filter((_file, idx) => idx !== index);
    setFiles(newFiles);
  };

  return (
    <div>
      <label className="mt-4 mb-3">Upload Images</label>
      <aside className={styles.thumbsContainer}>
        {files.length > 0 && (
          <SortableList
            files={files}
            onSortEnd={onSortEnd}
            axis={'xy'}
            handleClick={handleRemove}
            distance={1}
          />
        )}
      </aside>

      {files.length !== 8 &&
        (files.length > 0 && files?.length < 8 ? (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={styles.file_uploader_input}
            align="center"
          >
            <input {...getInputProps()} className={styles.dropzone_input} />
            <span className={styles.image_remaining}>
              {8 - files.length + ' ' + 'images remaining'} |{' '}
              <a role="button" className="btn btn-dark">
                Upload More
              </a>
            </span>
          </div>
        ) : (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={styles.file_uploader_input}
            align="center"
          >
            <input {...getInputProps()} className={styles.dropzone_input} />
            <button
              className={`btn btn-dark ${styles.upload_button}`}
              type="button"
            >
              Upload Files
            </button>
            <br />
          </div>
        ))}

      {error && files.length === 0 && (
        <p className={styles.invalid_input}>
          Please upload minimum of one (1) photo to attract buyers.
        </p>
      )}

      <p className="text-danger mt-2">
        {showWarning &&
          files.length < 8 &&
          'Some images are too small to upload'}
      </p>
    </div>
  );
}
export default ImageUploader;
