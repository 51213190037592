import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const Name = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Form.Group controlId="name" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Name *</Form.Label>
          <Form.Control
            type={'text'}
            label="name"
            {...register('name', {
              required: 'Name is required',
            })}
            name="name"
          />
        </Form.Group>

        {errors?.name && (
          <RequestErrorMessage message={` Please enter the category name.`} />
        )}
      </Grid>
    </>
  );
};

export default Name;
