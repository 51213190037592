import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, Dropdown } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from 'react-query';
import { camelCase } from 'lodash';
import { bannerService } from '../../services';
import { toast } from 'react-hot-toast';
import Table from '../common/table';
import CustomChip from '../common/chip';
import Filter from './filter';

import { getFlag } from '../../utils/countryFlag';
import { getQueryParam } from '../../utils/queryParams';
import moment from 'moment';
import qs from 'query-string';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '0px',
    fontSize: 11,
    width: 250,
    height: 250,
    borderRadius: '50%',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  image: {
    cursor: 'pointer',
    height: 90,
  },
  thumbnail: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
  verifiedIcon: {
    color: 'green',
  },
  price: {
    color: '#175473',
    fontWeight: 600,
  },
}));

function Banners() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState(null);

  const queryParams = getQueryParam(search, [
    'limit',
    'sorted',
    'page',
    'search',
  ]);
  // eslint-disable-next-line

  React.useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  const {
    data,
    isLoading: isFetching,
    error,
    isError,
  } = useQuery(
    ['promotions', query, search],
    () => bannerService.paginate(query),
    { refetchOnWindowFocus: true, enabled: !!query }
  );

  const { mutate, error: err } = useMutation(
    (row) =>
      bannerService.updateStatus({
        status: row.status,
        planId: row.position.plan.plan_id,
        _id: row._id,
      }),
    {
      onSuccess: () => {
        toast.success('Successfully updated !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  React.useEffect(() => {
    if (Object.keys(queryParams).length === 0) {
      navigate('/banners?limit=10&page=1&search=');
      setQuery({ limit: 10, page: 1, search: '', sorted: 'createdAt:desc' });
    }
  }, []);

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?'))
      mutate({ ...row, status: 'deleted' });
  };

  const onEdit = (row, status) => {
    if (
      window.confirm(`Are you sure you want to update the status to ${status}?`)
    )
      mutate({ ...row, status });
  };

  return (
    <>
      {isError || err ? (
        <Alert variant="info"> {error?.response?.data?.message || err} </Alert>
      ) : null}
      <Table
        title="Banner Ads"
        filterable
        open={open}
        onOpen={() => setOpen((prev) => !prev)}
        query={query}
        setQuery={setQuery}
        data={data?.data?.data || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => navigate('/banners/create')}
        columns={[
          {
            label: 'Date',
            path: 'createdAt',
            content: (row) => <p>{moment(row.createdAt).format('ll')}</p>,
          },
          {
            label: 'Image',
            content: (row) => (
              <div style={{ display: 'inline' }}>
                <LightTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <img
                        src={row?.position?.image}
                        alt=""
                        className={classes.image}
                      />
                    </React.Fragment>
                  }
                >
                  <img
                    src={row?.position?.image}
                    alt=""
                    className={classes.thumbnail}
                  />
                </LightTooltip>
              </div>
            ),
          },
          { label: 'Title', path: 'title' },
          { label: 'Position', path: 'positionName' },
          {
            label: 'Status',
            content: (row) => (
              <CustomChip
                row={{ ...row, status: row.position.status }}
                alignLeft
              />
            ),
          },
          {
            label: 'Payment Status',
            content: (row) => (
              <p>
                {camelCase(row?.payment?.status).split('payment')[1]}
                <span className={classes.price}>
                  {`   (${parseInt(row?.payment?.dirhamPrice)} AED)`}
                </span>
              </p>
            ),
          },

          {
            label: 'Country',
            content: (row) => (
              <Tooltip title={row?.country?.name} placement="bottom">
                <p> {getFlag(row?.country?.code)} </p>
              </Tooltip>
            ),
          },
          {
            label: 'Action',
            content: (row) => (
              <div className="d-flex flex-wrap">
                <>
                  <Dropdown className="mr-1">
                    <Dropdown.Toggle
                      variant="btn btn-primary"
                      id="dropdownMenuButton1"
                    >
                      Change Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[
                        { label: 'Pending', status: 'pending' },
                        { label: 'Block', status: 'blocked' },
                        { label: 'Active', status: 'active' },
                        { label: 'Disable', status: 'disabled' },
                      ]
                        .filter((d) => d.status !== row.status)
                        .map((action) => {
                          return (
                            <>
                              <Dropdown.Item
                                onClick={() => onEdit(row, action.status)}
                                key={row._id}
                              >
                                {action.label}
                              </Dropdown.Item>
                            </>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    type="small"
                    spacing={3}
                    variant="outlined"
                    onClick={() => navigate(`/banners/edit/${row.bannerId}`)}
                  >
                    Edit
                  </Button>
                </>
              </div>
            ),
          },
        ]}
      >
        <Filter query={query} open={open} setQuery={setQuery} />
      </Table>
    </>
  );
}

export default Banners;
