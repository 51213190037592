// import axios from 'axios';
// import AuthService from './auth.service';

// //axios.defaults.baseURL = 'http://localhost:3000/api';

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// axios.interceptors.response.use(null, (error) => {
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500;

//   if (error.response?.data?.message === 'Fatal error! Invalid Token') {
//     AuthService.logout();
//     window.location = '/login';
//   }
//   if (!expectedError) {
//     // toast.error('The server has crashed')
//     console.error(error.message);
//   }
//   return Promise.reject(error);
// });

// axios.interceptors.request.use(
//   (config) => {
//     config.headers = {
//       Authorization: `${AuthService.getCookie('token')}`,
//       locale: 'en',
//     };
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // const setToken = (token) => {
// //     axios.defaults.headers.common["Authorization"] = token;
// // }

// export default {
//   get: axios.get,
//   post: axios.post,
//   patch: axios.patch,
//   put: axios.put,
//   delete: axios.delete,
//   // setToken
// };

import axios from 'axios';
import store from '../../store/store';
import { logoutUser } from '../../store/reducers/auth';

const intance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

intance.interceptors.request.use(
  (request) => {
    const { token } = store.getState().auth;
    if (token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

intance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.status === 401) {
      store.dispatch(logoutUser());
    }
    return Promise.reject(error);
  }
);

export default intance;
