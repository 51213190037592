import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    placeItems: 'center',
  },
  root: {
    width: '100%',
    // width: 600,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    display: 'block',
    margin: '10px 0px 5px 0px',
  },
  fieldContainer: {
    padding: '5px 15px 5px 0px',
    marginTop: 7,
  },

  submitBtn: {
    margin: 20,
  },
  img: {
    maxHeight: '90px',
    float: 'right',
  },
  name: {
    fontSize: 16,
  },
  description: {
    margin: '0px 0px 0px 60px',
    color: '#6d6d6d',
  },
  totalPrice: {
    color: '#994c46',
    float: 'right',
    fontSize: 16,
  },
  activeTab: {
    background: '#176c80 !important',
    color: '#fff',
  },
  defaultTab: {
    border: '1px solid #176c80',
    background: '#fff',
  },
  // breadcrumb: {
  //     paddingLeft: '32%',
  //     paddingRight:'32%'
  // }
}));
