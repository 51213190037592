import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import React, { useRef } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Form, Row, Col, Overlay } from 'react-bootstrap';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const dropDownStyle = {
  backgroundColor: '#fff',
  padding: '2px 10px',
  color: '#6d6d6d',
  width: '80%',
  maxWidth: '480px',
  borderRadius: 3,
  position: 'absolute',
};
const placeContainer = {
  position: 'absolute',
  top: '-110px',
  width: '100%',
};
const imageContainer = {
  height: `230px`,
  width: '100%',
};
const MyMapComponent = withScriptjs(
  withGoogleMap((props) => {
    const { validation, t, target } = props;
    const defaultCenter = { lng: 4.3758291, lat: 54.4088823 };
    return (
      <>
        <div style={placeContainer}>
          <PlacesAutocomplete
            value={props.address}
            onChange={props.handleChange}
            onSelect={props.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ position: 'relative' }}>
                <Form.Group md="3" controlId="validationFormik04">
                  <p>Location Info</p>
                  <Form.Control
                    readOnly={true}
                    ref={props.target}
                    {...getInputProps({
                      placeholder: 'Enter the address',
                      className:
                        validation.errors && validation.errors.location
                          ? 'is-invalid location-search-input'
                          : 'location-search-input',
                    })}
                    autoComplete="off"
                  />
                  {validation.errors && validation.errors.location ? (
                    <p className="invalid-feedback">
                      {validation.errors.location}
                    </p>
                  ) : null}
                </Form.Group>

                <div
                  className="autocomplete-dropdown-container"
                  style={{ overflow: 'hidden', width: '100%' }}
                >
                  <Overlay
                    target={target.current}
                    show={suggestions ? true : false}
                    placement="bottom"
                  >
                    {({
                      placement,
                      arrowProps,
                      show: _show,
                      popper,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{ ...dropDownStyle, ...props.style }}
                      >
                        {loading && <div>Loading</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';

                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                                marginBottom: 4,
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                                marginBottom: 4,
                              };
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <Row>
                                <Col xs={1}>
                                  <LocationOnIcon />
                                  {/* <FontAwesomeIcon icon={faMapMarkerAlt} /> */}
                                </Col>
                                <Col xs={11}>
                                  <span className="font-weight-bold d-block">
                                    {suggestion.formattedSuggestion.mainText}
                                  </span>
                                  <span>
                                    {
                                      suggestion.formattedSuggestion
                                        .secondaryText
                                    }
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Overlay>
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        {props.center ? (
          <GoogleMap
            options={props.options}
            defaultZoom={14}
            center={props.center || defaultCenter}
            defaultCenter={defaultCenter}
          >
            {props.isMarkerShown && <Marker position={props.center} />}
          </GoogleMap>
        ) : (
          <img
            src="https://subli.info/wp-content/uploads/2015/05/google-maps-blur-1024x515.png"
            style={imageContainer}
          />
        )}
      </>
    );
  })
);

const mapOptions = {
  disableDefaultUI: false, // a way to quickly hide all controls
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  zoomControl: false,
  minZoom: 15,
  maxZoom: 20,
};

const containerStyle = (blur) => {
  return {
    position: 'relative',
    height: '400px',
    cursor: 'pointer',
    marginTop: '10px',
    filter: !blur || !blur?.filter ? 'blur(4px)' : '',
    pointerEvents: !!blur ? 0 : 1,
    marginTop: 110,
    background: 'red',
  };
};

function App({ validation, t, location, setLocation, disabled = false }) {
  const [address, setAddress] = React.useState('');
  const target = useRef(null);

  React.useEffect(() => {
    if (!address && location?.formatted_address)
      setAddress(location?.formatted_address);
  }, [location]);

  const handleChange = (add) => setAddress(add);

  const handleSelect = (add, ...other) => {
    let temp = {};
    setAddress(add);
    geocodeByAddress(add)
      .then((results) => {
        temp = results[0].address_components.reduce((acc, red) => {
          if (red.types.some((d) => d === 'country')) {
            acc.country = red.long_name;
            acc.country_code = red.short_name;
          }
          if (red.types.some((d) => d === 'postal_code'))
            acc.postal_code = red.long_name;
          if (red.types.some((d) => d === 'street_number'))
            acc.street_number = red.long_name;

          const addressArr = add.split(', ');
          //   addressArr?.splice(addressArr?.length - 1, 1);

          acc['formatted_address'] = addressArr?.join(', ') || '';
          return acc;
        }, {});

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setLocation({ latLng, ...temp });
      })
      .catch((error) => console.error('Error in map', error));
  };

  return (
    <div className="App">
      <MyMapComponent
        target={target}
        handleSelect={handleSelect}
        address={address}
        handleChange={handleChange}
        validation={validation}
        disabled={disabled}
        t={t}
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDPJSXD9RDnrk73OTW-mKbbaI3Rda7mInc&libraries=places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div
            style={containerStyle(
              containerStyle(!location?.latLng?.lng ? '' : null)
            )}
          />
        }
        center={location?.latLng}
        options={mapOptions}
        mapElement={
          <div style={{ height: location?.latLng ? '400px' : '0px' }} />
        }
      />
    </div>
  );
}
export default React.memo(App);
