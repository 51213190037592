import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useQuery, useMutation } from 'react-query';
import { compact } from 'lodash';

import { planService, promotionService } from '../../../services';

import CustomizedSelect from '../../common/muiSelect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Promotion({ onClose, onShow, data }) {
  const state = {
    position: [],
    user_id: data?.user?.user_id,
  };

  const [promote, setPromote] = React.useState(state);

  const [errors, setErrors] = React.useState([]);
  const [checked, setChecked] = React.useState([]);

  const { isFetching, data: p } = useQuery(
    ['plans', data],
    planService.getPostPlans,
    {
      refetchOnWindowFocus: false,
    }
  );

  const plans = p?.data?.data || [];

  const onDaySelect = (val, _id) => {
    let currState = { ...promote };

    const index = promote.position.map((p) => p.plan).indexOf(_id);

    if (index === -1) {
      currState.position.push({ plan: _id, day: val });
    } else {
      currState.position[index].day = val;
      currState.position[index].plan = _id;
    }
    setPromote(currState);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newPlans = [...promote.position];
    if (currentIndex === -1) {
      newChecked.push(value);
      newPlans.push({
        plan: value._id,
        day: value?.durations[0]?._id,
      });
    } else {
      newChecked.splice(currentIndex, 1);
      const index = newPlans.map((d) => d?.plan).indexOf(value?.plan);
      newPlans.splice(index, 1);
    }
    setChecked(newChecked);
    setPromote({ ...promote, position: newPlans });

    if (errors.length > 0) {
      console.log(errors);
    }
  };

  const totalCost = () => {
    const totalPrice = promote.position?.reduce((acc, red) => {
      const selectedPlan = plans?.find((p) => p?._id === red?.plan);
      const cost = selectedPlan?.durations.find((d) => d._id === red.day);
      const price = cost?.localePrice?.code
        ? cost?.localePrice?.price
        : cost?.standardPrice?.price;
      return acc + price;
    }, 0);
    if (totalPrice === 0) return { currency: '', price: 0 };
    const code = plans[0]?.durations[0]?.localePrice?.code;
    const standardCode = plans[0]?.durations[0]?.standardPrice?.code;

    return code
      ? { currency: code, price: totalPrice }
      : { currency: standardCode, price: totalPrice };
  };

  const hasError = (key) => errors.indexOf(key) !== -1;

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (formData) => promotionService.create(formData),
    {
      onSuccess: () => {
        window.location.reload(false);
      },
    }
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    const selectedPlan = plans.reduce((acc, red) => {
      //eslint-disable-next-line
      const pl = promote.position.map((plan) => {
        if (plan.plan === red._id) {
          const dur = red.durations.find((d) => d._id === plan.day);

          return {
            plan_id: red._id,
            days: dur.days,
            ads_type: red.ads_type,
            price: {
              amount: dur?.localePrice?.code
                ? dur?.localePrice?.price
                : dur?.standardPrice?.price,
              currency: dur?.localePrice?.code
                ? dur?.localePrice?.code
                : dur?.standardPrice?.code,
            },
          };
        }
      });
      return [...acc, ...compact(pl)];
    }, []);

    const sanitizedData = {
      plans: selectedPlan,
      user_id: data?.user?.user_id,
      post_id: data?._id,
      payment_mode: 'offline',
      source: 'admin',
    };
    mutate(sanitizedData);
  };

  return (
    <div>
      <Dialog
        open={!!onShow}
        TransitionComponent={Transition}
        keepMounted
        scroll="paper"
        onClose={onClose}
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          Create Promotion
        </DialogTitle>
        <Divider />
        <DialogContent>
          {isFetching && (
            <p className="text-center">
              <CircularProgress />
            </p>
          )}
          <form onSubmit={onSubmit}>
            {plans.map((plan) => {
              const options =
                plan?.durations?.reduce((acc = [], red) => {
                  acc.push({ value: red._id, label: red.days });
                  return acc;
                }, []) || [];

              const defaultPlan = promote?.position.find(
                (p) => p.plan === plan._id
              );
              const defaultDate = defaultPlan
                ? defaultPlan.day
                : plan?.durations[0]?._id;
              const index = plan?.durations
                ?.map((d) => d._id)
                .indexOf(defaultPlan?.day);

              const price =
                index !== -1
                  ? plan.durations[index]?.localePrice?.code
                    ? `${plan.durations[index]?.localePrice?.code} ${plan.durations[index]?.localePrice?.price}`
                    : `${plan.durations[index]?.standardPrice?.code} ${plan.durations[index]?.standardPrice?.price}`
                  : plan?.durations[0]?.localePrice?.code
                  ? `${plan?.durations[0]?.localePrice?.code} ${plan?.durations[0]?.localePrice?.price}`
                  : `${plan?.durations[0]?.standardPrice?.code} ${plan?.durations[0]?.standardPrice?.price}`;
              const isChecked = checked.map((c) => c._id).includes(plan._id);
              return (
                <div key={plan._id}>
                  <ListItemIcon>
                    <Checkbox
                      disabled={
                        plan?.durations?.length === 0 || !plan?.durations
                      }
                      edge="end"
                      onChange={handleToggle(plan)}
                      checked={isChecked}
                    />
                  </ListItemIcon>

                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {plan?.name}
                  </Typography>

                  <CustomizedSelect
                    options={options}
                    value={defaultDate}
                    disabled={!isChecked}
                    onValueChange={(val) => onDaySelect(val, plan?._id)}
                    extraLabel="days"
                  />
                  {` — ${price}`}

                  <Divider />
                </div>
              );
            })}

            <Typography variant="h5" className="mt-3 mb-3">
              {totalCost().price > 0 &&
                `Total - ${totalCost().currency} ${totalCost().price}`}
            </Typography>

            {hasError('position') && (
              <span className="text-danger">
                Please select at-least one position
              </span>
            )}

            {totalCost().price > 0 && (
              <Button
                fullWidth
                variant="contained"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? <CircularProgress /> : 'Submit'}
              </Button>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
