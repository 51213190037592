import React from 'react';
import { useDropzone } from 'react-dropzone';

//
function Previews({ setFiles, files, validation }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf,application/doc,application/docx',
    maxSize: 5242880,
    onDrop: (acceptedFiles) => {
      setFiles((prev) =>
        [
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ].slice(0, 1)
      );
    },
  });

  const handleRemove = () => {
    setFiles([]);
  };

  const isError = validation.errors?.files;
  return (
    <div>
      <aside>
        {files.map((file) => {
          return (
            <div key={file?.name}>
              <div>
                <h4>{file?.name || 'file'}</h4>
                <label onClick={handleRemove}></label>
              </div>
            </div>
          );
        })}
      </aside>

      <div {...getRootProps({ className: 'dropzone' })} align="center">
        <input {...getInputProps()} />
        <button className={`btn btn-primary`} type="button">
          Upload{' '}
        </button>
        <br />
      </div>

      {isError && files.length === 0 ? <p>{isError}</p> : ''}
    </div>
  );
}
export default Previews;
