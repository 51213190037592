import React from 'react';
import { Form, Col } from 'react-bootstrap';

const Select = ({
  label,
  options,
  required = false,
  col = 6,
  register,
  errors,
  ...rest
}) => {
  const createKey = (item) => (item?._id || item?.id) + item?.name;
  return (
    <Form.Group as={Col} md={col} controlId="validationCustom01">
      <Form.Label>{label}</Form.Label>
      <select
        {...rest}
        className="form-control"
        id={rest.name}
        {...register(rest.name, { required })}
      >
        {!required && <option value="">Select</option>}
        {options?.map((option) => {
          return (
            <option value={option?.id || option?._id} key={createKey(option)}>
              {option?.name}
            </option>
          );
        })}
      </select>
    </Form.Group>
  );
};
export default Select;
