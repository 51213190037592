import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import customStyles from './customStyles';
import { useQuery } from 'react-query';
import { categoryService } from '../../../../services';
import { categoryBanner } from '../../../../utils/banner';

const Category = ({ checked, value }) => {
  const [isCategorySelected, setSelectedCategory] = React.useState(null);

  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const { data: categories, isFetching } = useQuery(
    ['categories'],
    categoryService.getAllCategories,
    {
      refetchOnWindowFocus: false,
    }
  );

  const cats = categories?.data?.data || [];

  const selectedCategory = watch('category.parent');

  React.useEffect(() => {
    setSelectedCategory(null);
  }, []);

  React.useEffect(() => {
    setSelectedCategory(
      selectedCategory && Object.values(selectedCategory).length > 0
    );
  }, [selectedCategory]);

  const subcategories =
    selectedCategory &&
    cats?.filter((c) => c?.parent_id === selectedCategory?.id);

  const isCategoryRequired = checked?.some((c) =>
    categoryBanner.includes(c.banner_type)
  )
    ? 1
    : 0;

  const categoryOptions =
    React.useMemo(() => {
      if (cats.length > 0) {
        const options = [];
        cats.forEach((category) => {
          const data = {};
          if (!category.parent_id) {
            data.label = category.name;
            data.value = category.name;
            data.id = category._id;
            data.slug = category.slug;
            options.push(data);
          }
        });
        return options;
      }
    }, [cats]) || [];

  const subCategoryOptions =
    React.useMemo(() => {
      if (subcategories && subcategories.length > 0) {
        const options = [];
        subcategories.forEach((category) => {
          const data = {};

          data.label = category.name;
          data.value = category.name;
          data.id = category._id;
          data.slug = category.slug;
          options.push(data);
        });
        return options;
      }
    }, [subcategories]) || [];

  React.useEffect(() => {
    if (value) {
      const category = categoryOptions.find((obj) => {
        return obj.id === value.parent._id;
      });
      setValue('category.parent', category);
    }
  }, [categoryOptions, value]);

  React.useEffect(() => {
    if (value && subCategoryOptions) {
      const category = subCategoryOptions.find((obj) => {
        return obj.id === value.child._id;
      });
      setValue('category.subcategory', category);
    }
  }, [subCategoryOptions, value]);

  if (isFetching) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <>
      <div className="col-sm-4">
        <Form.Label className="font-weight-bold">Category</Form.Label>
        <Controller
          control={control}
          name={`category.parent`}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              placeholder={`Search a category`}
              options={categoryOptions}
              styles={customStyles}
              inputref={{
                ...register(`category.parent`, {
                  required: isCategoryRequired,
                }),
              }}
            />
          )}
        />
      </div>

      {isCategorySelected && subcategories?.length > 0 && (
        <>
          <div className="col-sm-4">
            <Form.Label className="font-weight-bold">
              SubCategory(Optional)
            </Form.Label>
            <Controller
              control={control}
              name={`category.subcategory`}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(data) => {
                    onChange(data);
                  }}
                  placeholder={`Search a subcategory`}
                  options={subCategoryOptions}
                  styles={customStyles}
                  inputref={{
                    ...register(`category.subcategory`),
                  }}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Category;
