import { buildQueryParam } from '../utils/queryParams';
import http from './httpService';
const metatagEndpoint = '/admin/meta-tag';

class MetatagService {
  create = (data) => http.post(`${metatagEndpoint}/create`, data);

  update = (data) => http.put(`${metatagEndpoint}/update/${data._id}`, data);

  delete = (id) => http.delete(`${metatagEndpoint}/delete/${id}`);

  get = (id) => http.get(`${metatagEndpoint}/get/${id}`);

  paginate = (query) =>
    http.get(`${metatagEndpoint}/paginate${buildQueryParam(query)}`);
}

export default new MetatagService();
