import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import customStyles from './customStyles';

const CategoryField = (props) => {
  const { currentCategory, categories } = props;

  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const activeParent = watch('parent');

  const parents = categories.filter((category) => !category.parent_id);

  const parentOptions = [];
  parents.forEach(function (item) {
    parentOptions.push({ value: item._id, label: item.name, slug: item.slug });
  });
  const parentFound = parentOptions.find(
    (opt) => opt.value === currentCategory?.parent?._id
  );

  const child = categories.filter(
    (category) => category.parent_id === activeParent?.value
  );
  const childOptions = [];

  child.forEach(function (item) {
    childOptions.push({ value: item._id, label: item.name, slug: item.slug });
  });

  const childFound = childOptions.find(
    (opt) => opt.value === currentCategory?.child?._id
  );

  React.useEffect(() => {
    setValue('parent', parentFound);
  }, [currentCategory]);

  React.useEffect(() => {
    if (activeParent?.value === currentCategory?.parent?._id) {
      setValue('child', childFound);
    } else {
      setValue('child', null);
    }
  }, [activeParent]);

  return (
    <>
      <Grid item xs={12} lg={6} className="mt-3">
        <Form.Group controlId="parent" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Category</Form.Label>
          <Controller
            control={control}
            name={`parent`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Select parent category`}
                options={parentOptions}
                styles={customStyles}
                inputref={{
                  ...register(`parent`, { required: true }),
                }}
              />
            )}
          />
        </Form.Group>

        {errors?.parent && (
          <div
            className="alert alert-warning validation-error"
            role="alert"
            style={{ marginTop: 0 }}
          >
            Please select a category
          </div>
        )}
      </Grid>

      <Grid item xs={12} lg={6} className="mt-3">
        <Form.Group controlId="Child" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Sub Category</Form.Label>
          <Controller
            control={control}
            name={`child`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Selec Type`}
                options={childOptions}
                styles={customStyles}
                inputref={{
                  ...register(`child`, { required: true }),
                }}
              />
            )}
          />
        </Form.Group>
        {errors?.child && (
          <div
            className="alert alert-warning validation-error"
            role="alert"
            style={{ marginTop: 0 }}
          >
            Please select a sub category
          </div>
        )}
      </Grid>
    </>
  );
};

export default CategoryField;
