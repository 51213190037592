import http from './httpService';

const planPriceEndpoint = '/admin/plan_price';
class PlanPriceService {
  paginate = (options) => {
    let extraQuery = '';

    extraQuery += `&sort=${options.sorted}`;
    extraQuery += options.search ? `&search=${options.search}` : '';

    return http.get(
      `${planPriceEndpoint}/paginate/${options.plan_id}?page=${
        options.page
      }&duration_id=${options.duration_id}&limit=${options.limit + extraQuery}`
    );
  };

  create = (data) => {
    if (data?._id)
      return http.put(`${planPriceEndpoint}/update/${data._id}`, data);
    else http.post(`${planPriceEndpoint}/create`, data);
  };

  getItem = (id) => http.get(`${planPriceEndpoint}/get/${id}`);

  update = (data) =>
    http.put(`${planPriceEndpoint}/update/${data.plan_id}`, data);

  delete = (plan_id, duration_id, price_id) =>
    http.delete(
      `${planPriceEndpoint}/delete/${plan_id}/${duration_id}/${price_id}`
    );

  all = (data) => http.get(`${planPriceEndpoint}/all`);
}

export default new PlanPriceService();
