import React from 'react';
import { Accordion, Card, Form, Button } from 'react-bootstrap'

function AccordionBody({ label, children, eventKey }) {

    return (
        <Card >
            <Card.Header className='bg-secondary text-white p-1 mb-3 col-12'>
                <Accordion.Toggle as={Button} variant='link' eventKey={eventKey.toString()} className="pl-1">
                    {label}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey.toString()}>
                <Card.Body className='pl-1 pr-1'>
                    <Form.Row>
                        {children}
                    </Form.Row>
                </Card.Body>
            </Accordion.Collapse>
        </Card>

    );
}

export default AccordionBody;