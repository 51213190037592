import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, Dropdown } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from 'react-query';
import { mobileBannerService } from '../../services';
import { toast } from 'react-hot-toast';
import Table from '../common/table';
import CustomChip from '../common/chip';

import { getFlag } from '../../utils/countryFlag';
import { getQueryParam } from '../../utils/queryParams';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '0px',
    fontSize: 11,
    width: 250,
    height: 250,
    borderRadius: '50%',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  image: {
    cursor: 'pointer',
    height: 90,
  },
  thumbnail: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
  verifiedIcon: {
    color: 'green',
  },
}));

function Banners() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState(null);

  const queryParams = getQueryParam(search, [
    'limit',
    'sorted',
    'page',
    'search',
  ]);
  // eslint-disable-next-line
  React.useEffect(() => setQuery(queryParams), []);

  const {
    data,
    isLoading: isFetching,
    error,
    isError,
  } = useQuery([query, search], () => mobileBannerService.paginate(query), {
    refetchOnWindowFocus: true,
    enabled: !!query,
  });

  //Update Status
  const { mutate: updateStatus, error: err } = useMutation(
    (row) =>
      mobileBannerService.updateStatus({
        status: row?.status,
        _id: row?._id,
      }),
    {
      onSuccess: () => {
        toast.success('Successfully updated !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  //Delete
  const { mutate: deleteItem, isLoading } = useMutation(
    (row) => mobileBannerService.delete(row),
    {
      onSuccess: () => {
        toast.success('Successfully Deleted !');
      },
    }
  );

  const onDelete = async (row) => {
    if (window.confirm('Are you sure you want to delete?')) deleteItem(row);
    return;
  };

  const onEdit = (row, status) => {
    if (
      window.confirm(`Are you sure you want to update the status to ${status}?`)
    ) {
      updateStatus({ ...row, status });
    }
  };

  React.useEffect(() => {
    if (Object.keys(queryParams).length === 0) {
      navigate('/mobile_banners?limit=10&page=1&search=');
      setQuery({ limit: 10, page: 1, search: '', sorted: 'createdAt:desc' });
    }
  }, []);

  return (
    <>
      {isError || err ? (
        <Alert variant="info"> {error?.response?.data?.message || err} </Alert>
      ) : null}
      <Table
        title="Mobile Banner"
        filterable
        open={open}
        onOpen={() => setOpen((prev) => !prev)}
        query={query}
        setQuery={setQuery}
        data={data?.data?.data || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => navigate('/mobile_banners/create')}
        columns={[
          { label: 'Title', path: 'title' },
          {
            label: 'Banner Image',
            content: (row) => (
              <div style={{ display: 'inline' }}>
                <LightTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <img
                        src={row?.images?.banner}
                        alt=""
                        className={classes.image}
                      />
                    </React.Fragment>
                  }
                >
                  <img
                    src={row?.images?.banner}
                    alt=""
                    className={classes.thumbnail}
                  />
                </LightTooltip>
              </div>
            ),
          },

          {
            label: 'Status',
            content: (row) => (
              <CustomChip row={{ ...row, status: row?.status }} alignLeft />
            ),
          },

          {
            label: 'Country',
            content: (row) => (
              <Tooltip title={row?.country?.name} placement="bottom">
                <p> {getFlag(row?.country?.code)} </p>
              </Tooltip>
            ),
          },
          {
            label: 'Action',
            content: (row) => (
              <div className="d-flex flex-wrap">
                <>
                  <Dropdown className="mr-1">
                    <Dropdown.Toggle
                      variant="btn btn-primary"
                      id="dropdownMenuButton1"
                    >
                      Change Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {[
                        { label: 'Pending', status: 'pending' },
                        { label: 'Active', status: 'active' },
                      ]
                        .filter((d) => d.status !== row.status)
                        .map((action) => {
                          return (
                            <>
                              <Dropdown.Item
                                onClick={() => onEdit(row, action.status)}
                                key={row._id}
                              >
                                {action.label}
                              </Dropdown.Item>
                            </>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    type="small"
                    spacing={3}
                    variant="outlined"
                    onClick={() => navigate(`/mobile_banners/edit/${row._id}`)}
                  >
                    Edit
                  </Button>
                  -
                  <Button
                    type="small"
                    spacing={3}
                    variant="outlined"
                    color="secondary"
                    onClick={() => onDelete(row)}
                  >
                    {isLoading ? 'Deleting...' : 'Delete'}
                  </Button>
                </>
              </div>
            ),
          },
        ]}
      ></Table>
    </>
  );
}

export default Banners;
