import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import Select from 'react-select';
import { useMutation, useQueries } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { mobileBannerService, fileService } from '../../services';
import Breadcrumb from '../common/breadcrumb';
import Upload from '../../utils/upload';
import { useStyles } from './styles';
import { getMobileBannerPath } from '../../config/path';
import { Form, Spinner } from 'react-bootstrap';
import RequestErrorMessage from '../common/requestError';
import customStyles from './customStyles';

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'pending', label: 'pending' },
  { value: 'deleted', label: 'Deleted' },
];

const state = {
  _id: '',
  title: '',
  link: '',
  order: [],
  location: {
    city: '',
    country: '',
  },
  status: '',
  images: {
    banner: '',
  },
};

const Edit = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  //Back URL
  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getMobileBannerPath.fullPath());
  };

  const classes = useStyles();
  const [errors, setErrors] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [files, setFiles] = React.useState(null);
  const [bannerImage, setBannerImage] = React.useState(null);
  const [isFormValid, setIsFormValid] = React.useState(false);

  const countriesWithCities = useSelector(
    (state) => state.country.countryWithcities
  );

  const { control, handleSubmit, formState, setValue, register, reset, watch } =
    useForm({ defaultValues: state });

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const bannerIcon = watch('images.banner');

  const [{ data: resBanner, isFetching }] = useQueries([
    {
      queryKey: ['banner'],
      queryFn: () => mobileBannerService.getBannerById(params.id),
      refetchOnWindowFocus: false,
    },
  ]);

  const banner = resBanner?.data?.data;

  const countries =
    React.useMemo(() => {
      if (countriesWithCities) {
        const c = Object.values(countriesWithCities);
        const countriesOptions = [];
        c.forEach((country) => {
          const data = {};
          data.label = country.name;
          data.value = country.name;
          data.id = country._id;
          data.code = country.code;
          data.calling_code = country.calling_code;
          data.currency_code = country.currency_code;
          data.cities = country.cities;
          countriesOptions.push(data);
        });

        return countriesOptions;
      }
    }, [countriesWithCities]) || [];

  const uploadImage = async (files) => {
    if (files) {
      let form = new FormData();
      form.append(`files`, files);
      const { data } = await fileService.fileUpload(form);
      const img = data?.data?.image;
      form.delete(`files`);
      return img;
    }
  };

  const {
    mutate,
    isError: isBannerError,
    isLoading: isBannerLoading,
    error: bannerError,
  } = useMutation((formData) => mobileBannerService.update(formData), {
    onSuccess: (data) => {
      toast.success(`Successfully updated`);
      getRoute();
    },
  });

  const onSubmit = async (formData) => {
    if (isSuccess) return;

    const err = false;

    if (err.length > 0) {
      return setErrors(err);
    }

    setSubmitting(true);

    const sanitizedData = {
      order: Number(formData.order),
      title: formData.title,
      link: formData.link,
      location: {
        city: {
          _id: formData?.location?.city?.id,
        },
        country: {
          _id: formData?.location?.country?.id,
          code: formData?.location?.country?.code,
        },
      },
      status: formData?.status.value,
      _id: params.id,
    };

    if (files) {
      const [banner] = await Promise.all([uploadImage(files)]);
      mutate({
        ...sanitizedData,
        images: {
          banner: banner || bannerImage,
        },
      });
    } else {
      mutate(sanitizedData);
    }
    setSubmitting(false);
  };

  const getErrorMsg = (e) => e?.response?.data?.message;
  const isError = isBannerError;
  const error = getErrorMsg(bannerError);
  const isLoading = isBannerLoading || submitting;

  const selectedCountry = watch('location.country');

  const isCountrySelected =
    selectedCountry && Object.values(selectedCountry).length > 0;

  const cities = [];

  if (
    isCountrySelected &&
    selectedCountry.cities &&
    selectedCountry.cities.length > 0
  ) {
    selectedCountry.cities.forEach((city) => {
      const data = {};
      data.label = city.name;
      data.value = city.name;
      data.id = city._id;
      data.slug = city.slug;
      cities.push(data);
    });
  }

  React.useEffect(() => {
    if (banner) {
      reset({
        ...banner,
      });

      const status = statusOptions.find((obj) => {
        return obj.value === banner.status;
      });
      setValue('status', status);

      const country = countries.find((obj) => {
        return obj.id === banner?.location?.country?._id;
      });
      setValue('location.country', country);

      setBannerImage(banner?.images?.banner);
    }
  }, [banner, countries, reset]);

  React.useEffect(() => {
    const city = cities.find((obj) => {
      return obj.id === banner?.location.city._id;
    });
    setValue('location.city', city);
  }, [isCountrySelected, banner, cities]);

  if (isFetching) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <>
      <div className={classes.breadcrumb}>
        <Breadcrumb goBack={() => getRoute()} title="Edit" />
      </div>
      <div className={classes.container}>
        <Card className={classes.root}>
          {isError && <Alert severity="error">{error}</Alert>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <div className="row">
                <div className="col-sm-6">
                  <Form.Group controlId="title" className="mt-0 mb-0">
                    <Form.Label className="font-weight-bold">
                      Title *
                    </Form.Label>
                    <Form.Control
                      type={'text'}
                      label="title"
                      {...register('title', {
                        required: 'Title is required',
                      })}
                      name="title"
                    />
                  </Form.Group>

                  {errors?.title && (
                    <RequestErrorMessage message={` Please enter the title.`} />
                  )}
                </div>
                <div className="col-sm-6">
                  <Form.Group controlId="Link" className="mt-0 mb-0">
                    <Form.Label className="font-weight-bold">Link</Form.Label>
                    <Form.Control
                      type={'text'}
                      label="link"
                      {...register('link')}
                      name="link"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-1">
                  <Form.Group controlId="order">
                    <Form.Label className="font-weight-bold">Order</Form.Label>
                    <Form.Control
                      type={'number'}
                      label="order"
                      {...register('order')}
                      name="order"
                    />
                  </Form.Group>
                </div>

                <div className="col-sm-3">
                  <Form.Group controlId="country">
                    <Form.Label className="font-weight-bold">
                      Country *
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`location.country`}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          placeholder={`Select country`}
                          options={countries}
                          styles={customStyles}
                          inputref={{
                            ...register(`location.country`, {
                              required: true,
                            }),
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>

                {isCountrySelected && (
                  <div className="col-sm-3">
                    <Form.Group controlId="city">
                      <Form.Label className="font-weight-bold">City</Form.Label>
                      <Controller
                        control={control}
                        name={`location.city`}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={(data) => {
                              onChange(data);
                            }}
                            placeholder={`Select city`}
                            options={cities}
                            styles={customStyles}
                            inputref={{
                              ...register(`location.city`),
                            }}
                          />
                        )}
                      />
                    </Form.Group>
                  </div>
                )}

                <div className="col-sm-3">
                  <Form.Group controlId="status">
                    <Form.Label className="font-weight-bold">
                      Status *
                    </Form.Label>

                    <Controller
                      control={control}
                      name={`status`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={(data) => {
                            onChange(data);
                          }}
                          placeholder={`Select status`}
                          options={statusOptions}
                          styles={customStyles}
                          inputref={{
                            ...register(`status`, {
                              required: true,
                            }),
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-12">
                  <Form.Label className="font-weight-bold">
                    Banner Image
                  </Form.Label>
                  <Upload
                    image={bannerIcon}
                    onFileSelect={(f) => setFiles(f)}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-12">
                  <div className="action mt-4 mb-4">
                    <button
                      className="btn btn-primary btn-block btn-lg"
                      type="submit"
                      disabled={isLoading || !isFormValid}
                    >
                      Submit
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm ml-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </>
  );
};

export default Edit;
