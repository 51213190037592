import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useQuery, useMutation } from 'react-query';
import qs from 'query-string';

import LanguageService from '../../../services/language.service';
import { toast } from 'react-hot-toast';
import Table from '../../common/table';

function Language() {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  const { data, isLoading: isFetching } = useQuery(
    ['languages', query],
    () => LanguageService.paginate(query),
    { enabled: !!query }
  );

  const { mutate, error, isError } = useMutation(
    (row) => LanguageService.delete(row._id),
    {
      onSuccess: () => {
        toast.success('Successfully deleted !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };
  const onEdit = (row) => navigate('/settings/languages/' + row._id);

  const languages = data?.data?.data;

  return (
    <>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Table
        title="Language"
        query={query}
        setQuery={setQuery}
        data={languages || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => navigate(`/settings/languages/new`)}
        columns={[
          {
            label: 'Name',
            path: 'name',
          },
          {
            label: 'Code',
            path: 'code',
          },
          {
            label: 'Order',
            path: 'order',
          },
          {
            label: 'Status',
            path: 'status',
          },
          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <>
                <button
                  className="btn btn-md btn-light mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="icon-pencil text-primary"></i>
                </button>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default React.memo(Language);
