import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import styles from '../edit.module.scss';

function BusinessHours({ currentItems }) {
  const { fields, append, remove } = useFieldArray({
    name: 'business_hours',
  });

  const [weekDays, setWeekDays] = useState([
    {
      name: 'Monday',
      value: 'monday',
    },
    {
      name: 'Tuesday',
      value: 'tuesday',
    },
    {
      name: 'Wednesday',
      value: 'wednesday',
    },
    {
      name: 'Thursday',
      value: 'thursday',
    },
    {
      name: 'Friday',
      value: 'friday',
    },
    {
      name: 'Saturday',
      value: 'saturday',
    },
    {
      name: 'Sunday',
      value: 'sunday',
    },
  ]);

  const hours = [];
  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format('h:mm A'));
    hours.push(
      moment({
        hour,
        minute: 30,
      }).format('h:mm A')
    );
  }

  const [inputs, setInputs] = useState({
    name: 'Monday',
    day: 'monday',
    opening: '8:00 AM',
    closing: '5:00 PM',
    is_24_hours_open: false,
  });

  const AddDays = () => {
    const updateDays = [inputs];
    append(updateDays);
    setWeekDays(weekDays.filter((item) => item.value !== inputs.day));
  };

  const ApplyCurrentData = () => {
    currentItems.forEach((item) => {
      append([
        {
          name: item.day,
          day: item.day,
          opening: item.opening,
          closing: item.closing,
          is_24_hours_open: item.is_24_hours_open,
        },
      ]);
    });
  };

  useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      ApplyCurrentData();
    }
  }, [currentItems]);

  useEffect(() => {
    if (weekDays[0]) {
      setInputs({
        ...inputs,
        name: weekDays[0].name,
        day: weekDays[0].value,
      });
    }

    if (fields?.length === currentItems?.length) {
      const notSelected = currentItems.filter(function (cv) {
        return !fields.find(function (e) {
          return e.day == cv.day;
        });
      });

      setWeekDays(notSelected);
    }
  }, [fields]);

  return (
    <>
      <div className="bg-light p-3 mt-4">
        <div className="row mt-4">
          <div className="form-group mb-0 col-md-12">
            <div className="form-group mb-0">
              <Form.Label className="font-weight-bold">
                Business Hours
              </Form.Label>
              <div className={styles.day_hours}>
                <div className="hours-display">
                  {fields.map((field, index) => {
                    return (
                      <div key={index}>
                        <Form.Control type={'hidden'} value={fields[index]} />
                        <InputComponent
                          index={index}
                          onRemove={(index) => {
                            const currentField = fields[index];
                            setWeekDays((oldValue) => [
                              ...oldValue,
                              {
                                name: currentField.name,
                                value: currentField.day,
                              },
                            ]);
                            remove(index);
                          }}
                          item={field}
                        />
                      </div>
                    );
                  })}
                </div>

                {weekDays[0] && (
                  <div className="days ml-3">
                    <div className="row">
                      <div className="col-lg-2 px-0">
                        <Form.Group
                          controlId="business_hours.day[]"
                          className="mt-3 mb-0"
                        >
                          <Form.Control
                            as="select"
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            onChange={(e) => {
                              setInputs({
                                ...inputs,
                                name: e.target.selectedOptions[0].dataset.name,
                                day: e.target.value,
                              });
                            }}
                          >
                            {weekDays.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.value}
                                  data-name={item.name}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2 px-1">
                        <Form.Group
                          controlId="business_hours.opening[]"
                          className="mt-3 mb-0"
                        >
                          <Form.Control
                            as="select"
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            name="business_hours.opening"
                            label="business_hours.opening"
                            value={inputs.opeing}
                            onChange={(e) =>
                              setInputs({
                                ...inputs,
                                opening: e.target.value,
                              })
                            }
                            disabled={inputs.is_24_hours_open}
                          >
                            {hours.map((hour, index) => {
                              return (
                                <option key={index} value={hour}>
                                  {hour}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-2 px-0">
                        <Form.Group
                          controlId="business_hours.closing[]"
                          className="mt-3 mb-0"
                        >
                          <Form.Control
                            as="select"
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            name="business_hours.closing"
                            label="business_hours.closing"
                            value={inputs.closing}
                            onChange={(e) =>
                              setInputs({
                                ...inputs,
                                closing: e.target.value,
                              })
                            }
                            disabled={inputs.is_24_hours_open}
                          >
                            {hours.map((hour, index) => {
                              return (
                                <option key={index} value={hour}>
                                  {hour}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-3">
                        <Form.Check
                          inline
                          label="24 Hours"
                          name="is_24_hours_open"
                          type="checkbox"
                          checked={inputs.is_24_hours_open}
                          onChange={(e) => {
                            setInputs({
                              ...inputs,
                              is_24_hours_open: !inputs.is_24_hours_open,
                            });
                          }}
                          className={`${styles.add_business_hours_24}`}
                        />
                      </div>
                      <div className="col-lg-3">
                        <a
                          className={`btn btn-dark text-white float-right ${styles.add_business_hours_btn}`}
                          role="button"
                          onClick={AddDays}
                        >
                          <span style={{ fontSize: 22 }}>&#43;</span>
                        </a>
                      </div>
                    </div>
                    {/* <ul className={`${styles.hours_select} clearfix list-inline`}>
                    <li className="list-inline-item">
                      <Form.Group
                        controlId="business_hours.day[]"
                        className="mt-3 mb-0"
                      >
                        <Form.Control
                          as="select"
                          style={{
                            background: '#fff',
                            minHeight: '40px',
                            borderRadius: '4px',
                          }}
                          onChange={(e) => {
                            setInputs({
                              ...inputs,
                              name: e.target.selectedOptions[0].dataset.name,
                              day: e.target.value,
                            });
                          }}
                        >
                          {weekDays.map((item, index) => {
                            return (
                              <option
                                key={index}
                                value={item.value}
                                data-name={item.name}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </li>
                    <li className="list-inline-item">
                      <Form.Group
                        controlId="business_hours.opening[]"
                        className="mt-3 mb-0"
                      >
                        <Form.Control
                          as="select"
                          style={{
                            background: '#fff',
                            minHeight: '40px',
                            borderRadius: '4px',
                          }}
                          name="business_hours.opening"
                          label="business_hours.opening"
                          value={inputs.opeing}
                          onChange={(e) =>
                            setInputs({
                              ...inputs,
                              opening: e.target.value,
                            })
                          }
                          disabled={inputs.is_24_hours_open}
                        >
                          {hours.map((hour, index) => {
                            return (
                              <option key={index} value={hour}>
                                {hour}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </li>
                    <li className="list-inline-item">
                      <Form.Group
                        controlId="business_hours.closing[]"
                        className="mt-3 mb-0"
                      >
                        <Form.Control
                          as="select"
                          style={{
                            background: '#fff',
                            minHeight: '40px',
                            borderRadius: '4px',
                          }}
                          name="business_hours.closing"
                          label="business_hours.closing"
                          value={inputs.closing}
                          onChange={(e) =>
                            setInputs({
                              ...inputs,
                              closing: e.target.value,
                            })
                          }
                          disabled={inputs.is_24_hours_open}
                        >
                          {hours.map((hour, index) => {
                            return (
                              <option key={index} value={hour}>
                                {hour}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </li>
                    <li className="list-inline-item">
                      <Form.Check
                        inline
                        label="24 Hours"
                        name="is_24_hours_open"
                        type="checkbox"
                        checked={inputs.is_24_hours_open}
                        onChange={(e) => {
                          setInputs({
                            ...inputs,
                            is_24_hours_open: !inputs.is_24_hours_open,
                          });
                        }}
                        className={`${styles.add_business_hours_24}`}
                      />

                      <a
                        className={`btn btn-dark text-white ${styles.add_business_hours_btn}`}
                        role="button"
                        onClick={AddDays}
                      >
                        <span style={{ fontSize: 22 }}>&#43;</span>
                      </a>
                    </li>
                  </ul> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessHours;

function InputComponent({ index, onRemove, item }) {
  return (
    <div
      className="hours"
      style={{
        borderBottom: '2px solid #fafafa',
        paddingTop: 10,
        paddingBottom: 10,
        position: 'relative',
      }}
    >
      <span className="weekday font-weight-bold text-capitalize">
        {item.day}
      </span>
      <span className="fullday ml-2 mr-2"></span>
      {!item.is_24_hours_open && (
        <>
          <span className="start ml-2 mr-2">{item.opening}</span>
          <span>~</span>
          <span className="end ml-2 mr-2">{item.closing}</span>
        </>
      )}

      {item.is_24_hours_open && <label>24 hours open</label>}
      <a
        className="link-primary"
        role="button"
        style={{
          position: 'absolute',
          right: 15,
          top: 10,
        }}
        onClick={() => onRemove(index)}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </a>
    </div>
  );
}
