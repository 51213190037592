import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const Video = ({ value }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('video', value);
  }, [value]);

  return (
    <>
      <Form.Group controlId="video" className="mt-3 mb-0">
        <Form.Label className="font-weight-bold">Business Video</Form.Label>
        <Form.Control
          style={{ background: '#fff', minHeight: '40px', borderRadius: '4px' }}
          type={'text'}
          label="video"
          placeholder="ex: https://youtu.be/CUSxJKNP2e0"
          {...register('video')}
          name="video"
        />
      </Form.Group>
    </>
  );
};

export default Video;
