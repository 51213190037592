import React from 'react';
function ValidationErrors({ errors }) {
  return (
    <>
      {errors.map((message) => {
        return (
          <div
            className="alert alert-warning validation-error mb-2"
            role="alert"
            style={{ marginTop: 0 }}
          >
            {message}
          </div>
        );
      })}
    </>
  );
}

export default ValidationErrors;
