import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { SubmitButton } from '../common/button';
import { authService } from '../../services';
import { loginUser } from '../../../store/reducers/auth';

function Login() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, error, isLoading } = useMutation(
    (formData) => authService.login(formData),
    {
      onSuccess: (data) =>
        authService.authenticate(data?.data?.data, () => {
          dispatch(loginUser(data?.data?.data));
          navigate(location?.state?.from?.pathname ?? '/dashboard');
        }),
    }
  );

  const onSubmit = (userInfo) => {
    mutate(userInfo);
  };

  return (
    <div>
      <Helmet>
        <title>Administrator - Adjeem</title>
      </Helmet>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5 mt-5">
              {error?.response?.data?.message && (
                <Alert variant="danger" className="border-0 rounded-0">
                  {error?.response?.data?.message}
                </Alert>
              )}
              <h6 className="font-weight-light">Sign in to continue.</h6>

              <Form
                className="pt-3"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <Form.Group controlId="title" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">Email *</Form.Label>
                  <Form.Control
                    type={'email'}
                    label="email"
                    placeholder="email@domain.com"
                    {...register('email', {
                      required: 'Email is required',
                    })}
                    name="email"
                  />
                </Form.Group>

                {errors?.email && (
                  <Alert variant="warning" className="border-0 rounded-0">
                    Please enter your email address
                  </Alert>
                )}

                <Form.Group controlId="title" className="mt-0 mb-3">
                  <Form.Label className="font-weight-bold mt-3">
                    Password *
                  </Form.Label>
                  <Form.Control
                    type={'password'}
                    label="password"
                    placeholder="Your password"
                    {...register('password', {
                      required: 'Password is required',
                    })}
                    name="password"
                  />

                  {errors?.password && (
                    <Alert variant="warning" className="border-0 rounded-0">
                      Please enter your password
                    </Alert>
                  )}
                </Form.Group>
                <SubmitButton label="Sign In" fullWidth isLoading={isLoading} />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
