import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useMutation, useQueries } from 'react-query';
import { useForm, useFieldArray } from 'react-hook-form';
import { Form, Alert, Accordion, Button, InputGroup } from 'react-bootstrap';

import Input from '../../common/input';
import Select from '../../common/select';
import AccordionBody from '../../common/accordionBody';
import Breadcrumb from '../../common/breadcrumb';
import { SubmitButton } from '../../common/button';
import planService from '../../../services/plan.service';
import { bannerType } from '../../../utils/banner';
import { adsType } from '../../../utils/ads';
import { getSanitizedData } from '../../../utils/sanitizer';
import { getPlansPath } from '../../../config/path';

const statusOptions = [
  { id: 'active', name: 'Active' },
  { id: 'blocked', name: 'Blocked' },
];
const planOptions = [
  { id: 'post', name: 'Post' },
  { id: 'banner', name: 'Banner' },
  { id: 'premium', name: 'Premium' },
];

export default function Plan() {
  const navigate = useNavigate();
  const params = useParams();
  const { register, control, watch, handleSubmit, setValue, errors } = useForm({
    defaultValues: { status: 'active', type: 'post' },
  });

  const {
    fields: durationsFields,
    append: durationsAppend,
    remove: durationsRemove,
  } = useFieldArray({
    control,
    name: 'durations',
  });

  const {
    fields: premiumFeaturesFields,
    append: premiumFeaturesAppend,
    remove: premiumFeaturesRemove,
  } = useFieldArray({ control, name: 'features' });

  const result = useQueries([
    {
      queryKey: ['bannerAds'],
      queryFn: planService.getBannerPlans,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['postAds'],
      queryFn: planService.getPostPlans,
      refetchOnWindowFocus: false,
    },
  ]);
  const { d1: bannerPlan, d2: postPlan } = getSanitizedData(result);

  const bannerTypePlan = bannerPlan?.map((d) => d.banner_type);
  const adTypePlan = postPlan?.map((d) => d?.ads_type);

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getPlansPath.fullPath());
  };

  const { mutate, isLoading, error, isError } = useMutation(
    (formData) => planService.create(formData),
    {
      onSuccess: () => {
        toast.success(
          `Successfully ${params.id === 'new' ? 'added new' : 'updated'} plan`
        );
        getRoute();
      },
    }
  );

  const onSubmit = (formData) => {
    mutate(formData);
  };

  const type = watch('type');

  const filteredBannerType = bannerType?.filter(
    (d) => !bannerTypePlan?.includes(d.id)
  );
  const filterAdType = adsType?.filter((d) => !adTypePlan?.includes(d.id));

  React.useEffect(() => {
    if (type === 'banner') setValue('banner_type', filteredBannerType[0].id);
    else setValue('banner_type', null);
    //eslint-disable-next-line
  }, [type]);

  const isOccupied =
    filterAdType.length === 0 && filteredBannerType.length === 0;
  return (
    <div>
      <Breadcrumb
        goBack={() => getRoute()}
        title={params.id !== 'new' ? 'Edit' : 'Create'}
      />
      <div className="row ">
        {isError || isOccupied ? (
          <Alert variant="danger">
            {' '}
            {error?.response?.data?.message ||
              'Cannot create a new Plan (All types of ads are active) '}{' '}
          </Alert>
        ) : null}
        <div className="card col-12">
          <div className="card-body col-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Accordion defaultActiveKey="0" className="w-100">
                  <AccordionBody label="General" eventKey="0">
                    <Input
                      col={6}
                      required
                      errors={errors}
                      label="Name"
                      placeholder="Feature ad for 30 days"
                      name="name"
                      register={register}
                    />
                    <Input
                      col={5}
                      label="Description"
                      placeholder="Reach upto 20 times more buyer"
                      name="description"
                      register={register}
                    />
                    <Input
                      col={6}
                      label="Hint"
                      placeholder="Keep your ad at the top of category"
                      name="hint"
                      register={register}
                    />
                    <Input
                      col={5}
                      errors={errors}
                      required
                      type="number"
                      label="Order"
                      placeholder="0"
                      name="order"
                      register={register}
                    />

                    <Input
                      col={5}
                      errors={errors}
                      required
                      type="number"
                      label="Advert Limit"
                      placeholder="1"
                      name="advert_limit"
                      register={register}
                    />

                    <Select
                      required
                      label="Status"
                      options={statusOptions}
                      name="status"
                      register={register}
                    />
                    <Select
                      required
                      disabled={filteredBannerType?.length === 0}
                      label="Type"
                      options={planOptions}
                      name="type"
                      register={register}
                    />
                    {type === 'post' && (
                      <Select
                        required
                        label="Ads Type"
                        options={filterAdType}
                        name="ads_type"
                        register={register}
                      />
                    )}
                    {type === 'banner' && (
                      <Select
                        required
                        label="Banner Type"
                        options={filteredBannerType}
                        name="banner_type"
                        register={register}
                      />
                    )}
                    {type == 'banner' ? (
                      <>
                        <Input
                          col={6}
                          required={type === 'banner'}
                          errors={errors}
                          label="Width"
                          type="number"
                          placeholder="Required image width"
                          name="image_resolution.width"
                          register={register}
                        />
                        <Input
                          col={5}
                          required={type === 'banner'}
                          errors={errors}
                          type="number"
                          label="Height"
                          placeholder="Required image height"
                          name="image_resolution.height"
                          register={register}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </AccordionBody>
                </Accordion>

                <Accordion className="w-100">
                  <AccordionBody label="Meta Data" eventKey="1">
                    <div className="ml-2">
                      <h5>Durations</h5>
                      {durationsFields?.map((field, index) => (
                        <InputGroup
                          className="mb-2 ml-0"
                          id={field.id}
                          key={index}
                        >
                          <Form.Control
                            {...register(`durations[${index}].days`)}
                            name={`durations[${index}].days`}
                            md={12}
                            type="number"
                            placeholder="1 = 1 Days"
                            defaultValue={field.value}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="danger"
                              onClick={() => durationsRemove(index)}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      ))}
                      <br />
                      <Button
                        type="button"
                        className="btn btn-success"
                        onClick={() => durationsAppend({})}
                      >
                        Add
                      </Button>
                    </div>
                  </AccordionBody>
                </Accordion>

                <Accordion className="w-100">
                  <AccordionBody label="Features" eventKey="2">
                    <div className="ml-2 w-100">
                      {premiumFeaturesFields?.map((feature, index) => (
                        <div key={index}>
                          <Form.Label>Title</Form.Label>
                          <InputGroup className="mb-2 ml-0" id={feature.id}>
                            <Form.Control
                              {...register(`features[${index}].title`)}
                              name={`features[${index}].title`}
                              md={12}
                              type="text"
                              placeholder="Up to 10 times more clients for ads "
                              defaultValue={feature.value}
                            />
                            <InputGroup.Append>
                              <Button
                                variant="danger"
                                onClick={() => premiumFeaturesRemove(index)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>

                          <div className="mt-20">
                            <Form.Check
                              {...register(`features[${index}].isIncluded`)}
                              name={`features[${index}].isIncluded`}
                              type="switch"
                              id={`isIncluded_${feature.id}`}
                              defaultValue={feature.value}
                              label="Is Included?"
                            />
                          </div>
                        </div>
                      ))}
                      <br />
                      <Button
                        type="button"
                        className="btn btn-success"
                        onClick={() => premiumFeaturesAppend({})}
                      >
                        Add
                      </Button>
                    </div>
                  </AccordionBody>
                </Accordion>
              </Form.Row>
              <SubmitButton isLoading={isLoading} disabled={isOccupied} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
