import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const MenuText = ({ value }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('menu_text', value);
  }, [value]);

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Form.Group controlId="menu_text" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Menu Text *</Form.Label>
          <Form.Control
            type={'text'}
            label="menu_text"
            {...register('menu_text', {
              required: 'Menu text is required',
            })}
            name="menu_text"
          />
        </Form.Group>

        {errors?.menu_text && (
          <RequestErrorMessage message={` Please enter the menu text name.`} />
        )}
      </Grid>
    </>
  );
};

export default MenuText;
