import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';

import Breadcrumb from '../../../common/breadcrumb';
import MuiAccordion from '../../../common/muiAccordion';
import { fieldService, fileService } from '../../../../services';
import Name from './fields/nameField';
import Type from './fields/typeField';
import IsMandatory from './fields/isMandatoryField';
import IsSearchable from './fields/isSearchableField';
import FeaturedStyle from './fields/featuredStyleField';
import Hint from './fields/hintField';
import AdminHint from './fields/adminHintField';
import Upload from './fields/upload';
import SubType from './fields/subTypeField';
import { Spinner } from 'react-bootstrap';

function EditField() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const lang = query.get('lang');

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  const watchType = methods.watch('type');

  const isSubTypeActive =
    watchType?.value === 'select' ||
    watchType?.value === 'select_multiple' ||
    watchType?.value === 'radio';

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const {
    data: d1,
    error: err,
    isLoading: isFieldLoading,
  } = useQuery('field', () => fieldService.getItem(params.id));

  const currentItem = d1?.data?.data;

  const onSubmit = async (formData) => {
    setLoading(true);
    if (formData?.image) {
      const [image] = await Promise.all([uploadImage(formData.image)]);
      mutate({
        ...formData,
        image: image,
      });
    } else {
      setLoading(true);
      mutate(formData);
    }
  };

  const update = async (formData) => {
    const data = TransformFieldData({ formData, id: currentItem?._id });

    await fieldService.update(data);
  };

  const uploadImage = async (files) => {
    if (files) {
      let form = new FormData();
      form.append(`files`, files);
      const { data } = await fileService.fileUpload(form);
      const img = data?.data?.image;
      form.delete(`files`);
      return img;
    }
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => update(formData), {
    onSuccess: () => {
      setLoading(false);
      toast.success(`Successfully  added new category`);
      navigate('/fields');
    },
  });

  if (!currentItem) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb
        goBack={() => navigate('/fields')}
        title={`Edit: ${currentItem.name}`}
      />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name value={currentItem?.name} />
            <Type value={currentItem?.type} />
            <IsMandatory value={currentItem?.mandatory} />
            <IsSearchable value={currentItem?.is_searchable} />
            <FeaturedStyle value={currentItem?.featured} />
            <Hint value={currentItem?.hint} />
            <AdminHint value={currentItem?.hint_admin} />
            {isSubTypeActive && <SubType value={currentItem?.sub_type} />}
            <Upload
              name="image"
              label={`Icon Image`}
              value={currentItem?.image}
              existingName="existing_image"
            />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid || loading}
            >
              Submit
              {isSubmitting ||
                (loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ))}
            </button>
          </div>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default EditField;

export const TransformFieldData = ({ formData, id }) => {
  const responseData = {
    ...formData,
    _id: id,
    featured: formData.featured.value,
    is_searchable: formData.is_searchable.value,
    mandatory: formData.mandatory.value,
    type: formData.type.value,
    image: formData?.image ? formData?.image : formData?.existing_image,
  };

  if (formData.sub_type.value) {
    responseData.sub_type = formData.sub_type.value;
  }
  return responseData;
};
