import React from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useMutation } from 'react-query'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { settingService,fileService } from '../../../services'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: 370,
        margin: "10px 10px 10px 0px"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 200,
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 170,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    uploadButton: {
        height: 60,
        width: 40,
        borderRadius: '50%'
    },
    uploadIcon: {
        marginLeft: 10,
        height: 20,
        width: 20,
        color: 'red'
    }
}));

export default function MediaControlCard({ name = '', label = "", data }) {
    const classes = useStyles();
    const [showSnackbar, setSnackbar] = React.useState(false)

    const { mutate: setting } = useMutation(formData => data?._id ? settingService.update({ ...data, ...formData }) : settingService.create(formData), {
        onSuccess: () => {
            setSnackbar(true)
        }
    })

    const { mutate, isLoading, data: imageUrl } = useMutation((formData) => fileService.fileUpload(formData), {
        onSuccess: (data) => {
            setting({ key: name, value: data?.data?.data?.image })
        }
    })

    const handleImageUpload = (event) => {
        try {
            if (event.target.files[0]) {
                const formData = new FormData();
                formData.append(
                    "files",
                    event.target.files[0]
                );
                mutate(formData)
            }
        } catch (ex) {
            console.log("exception occurred on file upload")
        }

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Successfully uploaded the image
                </Alert>
            </Snackbar>

            <Card className={classes.root}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            {label}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Click the below button to upload image.<span role="img" aria-label="down"> 🔽</span>
                        </Typography>
                    </CardContent>
                    <div className={classes.controls}>
                        <IconButton aria-label="previous">
                        </IconButton>
                        <IconButton aria-label="play/pause">
                            <Button
                                variant="contained"
                                disabled={isLoading}
                                component="label"
                                className={classes.uploadButton}
                                startIcon={<CloudUploadIcon fontSize="large" className={classes.uploadIcon} />}
                            >
                                <input
                                    accept="image/*"
                                    type="file"
                                    hidden
                                    onChange={handleImageUpload}
                                />
                            </Button>
                        </IconButton>
                        <IconButton aria-label="next">
                        </IconButton>
                    </div>
                </div>
                {isLoading ?
                    <Skeleton variant="react" width={200} height={215} />
                    :
                    <CardMedia
                        component="img"
                        className={classes.cover}
                        image={imageUrl?.data?.data?.image || data?.value}
                        title=""
                    />
                }
            </Card>
        </>
    );
}
