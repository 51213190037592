import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const Order = ({ value }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('order', value);
  }, [value]);

  return (
    <>
      <Grid item xs={12} lg={2} className="mt-3">
        <Form.Group controlId="order" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Order</Form.Label>
          <Form.Control
            type={'number'}
            label="order"
            {...register('order')}
            name="order"
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default Order;
