import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Alert, Dropdown } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import qs from 'query-string';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import Table from '../../../common/table';
import { getFieldsBasedOnCategory } from '../../../../config/path';
import { categoryService } from '../../../../services';

function SubCategory() {
  const params = useParams();
  const languages = useSelector((state) => state.language.languages);
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = React.useState(null);

  const { data, isLoading } = useQuery(
    ['cate', query],
    () => categoryService.paginate({ ...query, parent_id: params.parentId }),
    { refetchOnWindowFocus: false, enabled: !!query }
  );

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location]);

  const { mutate, isError, error } = useMutation(
    (row) => categoryService.delete(row._id),
    {
      onSuccess: (_data) => {
        setQuery({ ...query, at: Date.now() });
        toast.success('Successfully deleted !');
      },
    }
  );
  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };

  const parentName = qs.parse(location.search).name;

  const onEdit = (row, language) => {
    language.code === 'en'
      ? navigate(
          `/categories/edit/${row._id}?parent_name=${parentName}&cat_name=${row.name}&parent_id=${params.parentId}`
        )
      : navigate(
          `/categories/edit/${row._id}/language/${language._id}?cat_name=${parentName}&name=${row.name}&lang=${language.name}&parent_id=${params.parentId}`
        );
  };

  const subs = data?.data?.data;

  return (
    <>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Table
        title={`${parentName} → SubCategories`}
        query={query}
        setQuery={setQuery}
        data={subs || {}}
        onToolbarAction={onDelete}
        isFetching={isLoading}
        onAddNew={() =>
          navigate(
            `/categories/add?parent_id=${params.parentId}&parent_name=${parentName}`
          )
        }
        route={`/categories/${params.parentId}/subs`}
        columns={[
          {
            label: 'Name',
            path: 'name',
          },
          {
            label: 'Order',
            path: 'order',
          },
          {
            label: 'Status',
            path: 'status',
          },
          {
            label: 'Is Industry',
            path: 'is_industry_category',
            content: (row) => (
              <div className="d-flex flex-wrap ">
                {row.is_industry_category ? 'Yes' : 'No'}
              </div>
            ),
          },
          {
            label: 'Action',
            path: 'action',
            disableSort: true,
            content: (row) => (
              <div className="d-flex flex-wrap ">
                <Dropdown className="mr-1">
                  <Dropdown.Toggle
                    variant="btn btn-primary"
                    id="dropdownMenuButton1"
                  >
                    Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languages
                      ? languages.map((language) =>
                          language.code !== 'en' ? (
                            <Dropdown.Item
                              onClick={() => onEdit(row, language)}
                              key={language._id}
                            >
                              Edit - {language.name}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => onEdit(row, language)}
                              key={language._id}
                            >
                              Edit
                            </Dropdown.Item>
                          )
                        )
                      : null}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="mr-1">
                  <Dropdown.Toggle
                    variant="btn btn-secondary"
                    id="dropdownMenuButton1"
                  >
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        navigate(
                          getFieldsBasedOnCategory(row._id, {
                            name: row.name,
                          }).fullPath()
                        )
                      }
                    >
                      Fields
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default SubCategory;
