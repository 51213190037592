import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Alert } from 'react-bootstrap';
import { useMutation } from 'react-query';
import Button from '@material-ui/core/Button';
import { optionService } from '../../../../services';
import { Grid } from '@material-ui/core';
import RequestErrorMessage from '../../../common/requestError';

function Add({ field_id, onAdded }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { mutate, isError, error, isLoading } = useMutation(
    (formData) =>
      optionService.create({
        value: formData.value,
        order: Number(formData.order),
        field_id: field_id,
      }),
    {
      onSuccess: () => {
        onAdded();
        reset();
      },
    }
  );

  const onSubmit = (data) => mutate(data);

  return (
    <div className="mb-3">
      {isError && (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" alignItems="baseline">
          <Grid item xs={5}>
            <Form.Group controlId="value" className="mt-0 mb-0">
              <Form.Label className="font-weight-bold">Value *</Form.Label>
              <Form.Control
                type={'text'}
                label="value"
                {...register('value', {
                  required: 'Value is required',
                })}
                name="value"
              />
            </Form.Group>

            {errors?.value && (
              <RequestErrorMessage message={` Please enter the value.`} />
            )}
          </Grid>
          <Grid item xs={5}>
            <Form.Group controlId="order" className="mt-0 mb-0">
              <Form.Label className="font-weight-bold">Order * </Form.Label>
              <Form.Control
                type={'number'}
                label="order"
                {...register('order', {
                  required: 'order is required',
                })}
                name="order"
              />
            </Form.Group>

            {errors?.order && (
              <RequestErrorMessage message={` Please enter the order.`} />
            )}
          </Grid>

          <Grid item xs={2}>
            <Button
              type="submit"
              disabled={isLoading}
              color="secondary"
              variant="contained"
              className="mt-5 ml-4"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}

export default Add;
