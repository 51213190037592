import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

export const SubmitButton = ({
  label = 'Submit',
  disabled,
  isLoading,
  onRequesting = 'Submitting',
  fullWidth = false,
  className,
}) => {
  return (
    <Button
      disabled={disabled ? disabled : isLoading}
      type="submit"
      className={fullWidth && 'btn btn-block' + className}
    >
      {isLoading ? onRequesting : label}
      {isLoading && <Spinner animation="border" size="sm" variant="info" />}
    </Button>
  );
};
