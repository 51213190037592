import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Main from './app/Main';
import Login from './app/components/login/Index';
import Dashboard from './app/components/dashboard/Dashboard';
import Error404 from './app/pages/Error404';
import Categories from './app/components/posts/categories/Index';
import SubCategory from './app/components/posts/categories/subs/Index';
import CategoryFields from './app/components/posts/categories/fields/Index';
import Fields from './app/components/posts/fields/Index';
import FieldsCreate from './app/components/posts/fields/Create';
import EditLanguageSpecificField from './app/components/posts/fields/edit/editLanguageSpecificField';
import FieldOptions from './app/components/posts/fields/options/Index';
import FieldOptionExtras from './app/components/posts/fields/options/extras/Index';
import Ads from './app/components/posts/ads/Index';
import Users from './app/components/user/Users';
import UsersCreate from './app/components/user/CreateUser';
import EditUser from './app/components/user/EditUser';
import Languages from './app/components/settings/languages/Language';
import CreateLanguages from './app/components/settings/languages/Create';
import Countries from './app/components/settings/countries/Index';
import CountriesCreate from './app/components/settings/countries/Create';
import EditLanguageSpecificCountry from './app/components/settings/countries/editLanguage';
import Cities from './app/components/settings/countries/cities/Index';
import CitiesCreate from './app/components/settings/countries/cities/Create';
import EditLanguageSpecificCity from './app/components/settings/countries/cities/editLanguage';
import MetaTag from './app/components/settings/seo/index';
import MetaTagCreate from './app/components/settings/seo/create';
import EditLanguageSpecificMeta from './app/components/settings/seo/editLanguage';
import DefaultSetting from './app/components/settings/default/index';
import Plans from './app/components/subscription/plan/Index';
import PlansCreate from './app/components/subscription/plan/Create';
import PlansEdit from './app/components/subscription/plan/Edit';
import EditLanguageSpecificPlan from './app/components/subscription/plan/EditLanguageSpecific';
import PlanPrice from './app/components/subscription/plan/price/Index';
import Payments from './app/components/subscription/payment/Index';
import Banners from './app/components/banner/index';
import MobileBanners from './app/components/bannerMobile/index';
import MobileBannersCreate from './app/components/bannerMobile/create';
import MobileBannerEdit from './app/components/bannerMobile/edit';
import Businesses from './app/components/listing/businesses/Index';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import Add from './app/components/listing/businesses/add/Index';
import EditListing from './app/components/listing/businesses/edit/Index';
import AddCategory from './app/components/posts/categories/create/addCategory';
import EditCategory from './app/components/posts/categories/edit/editCategory';
import EditLanguageSpecificCategory from './app/components/posts/categories/edit/languageSpecific';

import AddField from './app/components/posts/fields/create/addField';
import EditField from './app/components/posts/fields/edit/editField';
import CreateBanner from './app/components/banner/create/createBanner';
import EditBanner from './app/components/banner/edit/editBanner';
import DashboardMarketing from './app/components/marketing/Dashboard';
import Items from './app/components/marketing/Items';
import QuickEdit from './app/components/posts/ads/quickEdit';

function App() {
  const roles = useSelector((state) => state.auth.currentUser.roles);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            {roles && roles[0] == 'marketing' && (
              <>
                <Route path="/dashboard" element={<DashboardMarketing />} />
                <Route path="/items" element={<Items />} />
              </>
            )}

            {roles && roles[0] == 'admin' && (
              <>
                <Route path="/dashboard" element={<Dashboard />} />

                <Route path="/categories" element={<Categories />} />
                <Route path="/categories/add" element={<AddCategory />} />
                <Route path="/categories/edit/:id" element={<EditCategory />} />
                <Route
                  path="/categories/edit/:id/language/:language_id"
                  element={<EditLanguageSpecificCategory />}
                />

                <Route
                  path="/categories/:parentId/subs"
                  element={<SubCategory />}
                />
                <Route
                  path="/categories/:categoryId/fields"
                  element={<CategoryFields />}
                />
                <Route path="/fields" element={<Fields />} />
                <Route path="/fields/add" element={<AddField />} />
                <Route path="/fields/edit/:id" element={<EditField />} />

                <Route
                  path="/fields/edit/:id/language/:languageId"
                  element={<EditLanguageSpecificField />}
                />
                <Route
                  path="/fields/:fieldId/options"
                  element={<FieldOptions />}
                />
                <Route
                  path="/fields/:fieldId/options/:optionId/extras"
                  element={<FieldOptionExtras />}
                />
                <Route path="/ads" element={<Ads />} />
                <Route path="/ads/:id" element={<QuickEdit />} />
                <Route path="/users/list" element={<Users />} />
                <Route path="/users/new" element={<UsersCreate />} />
                <Route path="/users/edit/:id" element={<EditUser />} />
                <Route path="/settings/languages" element={<Languages />} />
                <Route
                  path="/settings/languages/:id"
                  element={<CreateLanguages />}
                />
                <Route path="/settings/countries" element={<Countries />} />
                <Route
                  path="/settings/countries/:id"
                  element={<CountriesCreate />}
                />
                <Route
                  path="/settings/countries/:id/:lang"
                  element={<EditLanguageSpecificCountry />}
                />
                <Route path="/settings/cities/:id" element={<Cities />} />
                <Route
                  path="/settings/cities/:id/:city_id"
                  element={<CitiesCreate />}
                />
                <Route
                  path="/settings/cities/:id/:city_id/:lang"
                  element={<EditLanguageSpecificCity />}
                />
                <Route path="/settings/seo" element={<MetaTag />} />
                <Route path="/settings/seo/:id" element={<MetaTagCreate />} />
                <Route
                  path="/settings/seo/:id/:lang"
                  element={<EditLanguageSpecificMeta />}
                />
                <Route path="/settings/default" element={<DefaultSetting />} />
                <Route path="/subscriptions/plans" element={<Plans />} />
                <Route
                  path="/subscriptions/plans/:id"
                  element={<PlansCreate />}
                />
                <Route
                  path="/subscriptions/plans/edit/:id"
                  element={<PlansEdit />}
                />
                <Route
                  path="/subscriptions/plans/edit/:id/:languageId"
                  element={<EditLanguageSpecificPlan />}
                />
                <Route
                  path="/subscriptions/plans/price/:id"
                  element={<PlanPrice />}
                />
                <Route path="/subscriptions/payments" element={<Payments />} />
                <Route path="/banners" element={<Banners />} />
                <Route path="/banners/create" element={<CreateBanner />} />
                <Route path="/banners/edit/:id" element={<EditBanner />} />
                <Route path="/mobile_banners" element={<MobileBanners />} />
                <Route
                  path="/mobile_banners/create"
                  element={<MobileBannersCreate />}
                />
                <Route
                  path="/mobile_banners/edit/:id"
                  element={<MobileBannerEdit />}
                />
                <Route path="/businesses/list" element={<Businesses />} />
                <Route path="/businesses/add" element={<Add />} />
                <Route
                  path="/businesses/edit/:listing_id"
                  element={<EditListing />}
                />
              </>
            )}
          </Route>
        </Route>

        <Route path="/" element={<Main />}>
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
