import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import customStyles from './customStyles';

const Location = (props) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const countriesWithCities = useSelector(
    (state) => state.country.countryWithcities
  );

  const countries =
    React.useMemo(() => {
      if (countriesWithCities) {
        const c = Object.values(countriesWithCities);
        const countriesOptions = [];
        c.forEach((country) => {
          const data = {};
          data.label = country.name;
          data.value = country.name;
          data.id = country._id;
          data.code = country.code;
          data.calling_code = country.calling_code;
          data.currency_code = country.currency_code;
          data.cities = country.cities;
          countriesOptions.push(data);
        });

        return countriesOptions;
      }
    }, [countriesWithCities]) || [];

  const selectedCountry = watch('location.country');

  const isCountrySelected =
    selectedCountry && Object.values(selectedCountry).length > 0;

  const cities = [];

  if (isCountrySelected && selectedCountry.cities.length > 0) {
    selectedCountry.cities.forEach((city) => {
      const data = {};
      data.label = city.name;
      data.value = city.name;
      data.id = city._id;
      data.slug = city.slug;
      cities.push(data);
    });
  }

  return (
    <>
      <div className="col-sm-4">
        <Form.Label className="font-weight-bold">Country *</Form.Label>
        <Controller
          control={control}
          name={`location.country`}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              placeholder={`Select country`}
              options={countries}
              styles={customStyles}
              inputref={{
                ...register(`location.country`, {
                  required: true,
                }),
              }}
            />
          )}
        />
      </div>

      {isCountrySelected && (
        <div className="col-sm-4">
          <Form.Label className="font-weight-bold">City</Form.Label>
          <Controller
            control={control}
            name={`location.city`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Select city`}
                options={cities}
                styles={customStyles}
                inputref={{
                  ...register(`location.city`),
                }}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default Location;
