import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Dropdown } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { useMutation, useQuery } from 'react-query';
import { metatagService } from '../../../services';

import Table from '../../common/table';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '0px',
    fontSize: 11,
    width: 250,
    height: 250,
    borderRadius: '50%',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 250,
    height: 250,
  },
  thumbnail: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
  verifiedIcon: {
    color: 'green',
  },
}));

function Index() {
  const navigate = useNavigate();
  const classes = useStyles();
  const languages = useSelector((state) => state.language.languages);

  const [query, setQuery] = React.useState({
    page: 1,
    limit: 10,
    sorted: 'createdAt:desc',
    search: '',
  });

  const { data, isLoading, error, isError, refetch } = useQuery(
    ['categories', query],
    () => metatagService.paginate(query),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const metatag = data?.data?.data;

  React.useEffect(() => {
    refetch();
  }, []);

  const { mutate } = useMutation((row) => metatagService.delete(row._id), {
    onSubmit: () => {
      toast.error('Successfully deleted the metatag');
      refetch();
    },
  });

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?'));
    mutate(row);
    window.location.reload(true);
  };
  const onEdit = (row, language) => {
    navigate(`/settings/seo/${row._id}/${language._id}?name=${language.name}`);
  };

  return (
    <>
      {isError && (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      )}
      <Table
        title="Meta Information"
        query={query}
        setQuery={setQuery}
        data={metatag || {}}
        onToolbarAction={onDelete}
        isFetching={isLoading}
        onAddNew={() => navigate('/settings/seo/new')}
        columns={[
          { label: 'Page', path: 'page' },
          { label: 'Meta Title', path: 'title' },
          { label: 'Meta Description', path: 'description' },
          { label: 'Meta Keywords', path: 'keywords' },
          {
            label: 'Image',
            content: (row) => (
              <div style={{ display: 'inline' }}>
                <LightTooltip
                  placement="top"
                  title={
                    <React.Fragment>
                      <img src={row?.image} alt="" className={classes.image} />
                    </React.Fragment>
                  }
                >
                  <img src={row?.image} alt="" className={classes.thumbnail} />
                </LightTooltip>
              </div>
            ),
          },
          { label: 'Alt', path: 'alt' },
          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <div className="d-flex flex-wrap">
                <Dropdown className="mr-1">
                  <Dropdown.Toggle
                    variant="btn btn-primary"
                    id="dropdownMenuButton1"
                  >
                    Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languages?.map((language) =>
                      language.code !== 'en' ? (
                        <Dropdown.Item
                          onClick={() => onEdit(row, language)}
                          key={language._id}
                        >
                          {' '}
                          Edit - {language.name}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => navigate(`/settings/seo/${row._id}`)}
                          key={language._id}
                        >
                          Edit
                        </Dropdown.Item>
                      )
                    )}
                    {row.page === 'homepage' &&
                      languages?.map(
                        (language) =>
                          language.code !== 'en' && (
                            <Dropdown.Item
                              onClick={() =>
                                navigate(
                                  `/settings/seo/${row._id}/${language._id}?name=${language.name}&default=true`
                                )
                              }
                              key={language._id}
                            >
                              {' '}
                              Edit default- {language.name}
                            </Dropdown.Item>
                          )
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default Index;
