import http from './httpService';
const logEndpoint = '/admin/logs';

class LogService {
  getFile = async () => {
    const { data } = await http.get(`${logEndpoint}/folder`);
    return data;
  };

  getLogDetail = (name) => http.get(`${logEndpoint}/detail?name=${name}`);
}

export default new LogService();
