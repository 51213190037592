import { buildQueryParam } from '../utils/queryParams';
import http from './httpService';

const bannerEndpoint = '/admin/mobile_banners';

class BannerMobileService {
  getBanner() {
    return http.get(`${bannerEndpoint}`);
  }

  createBanner(data) {
    return http.post(`${bannerEndpoint}/create`, data);
  }

  paginate(options) {
    return http.get(`${bannerEndpoint}/paginate${buildQueryParam(options)}`);
  }

  getBannerById(id) {
    return http.get(`${bannerEndpoint}/get/${id}`);
  }

  update(data) {
    return http.put(`${bannerEndpoint}/update/${data._id}`, data);
  }

  updateStatus(data) {
    return http.put(`${bannerEndpoint}/update-status/${data._id}`, data);
  }

  delete(data) {
    return http.delete(`${bannerEndpoint}/delete/${data._id}`);
  }
}

export default new BannerMobileService();
