import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';

import styles from '../edit.module.scss';

function Faq({ currentItem }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: 'faqs',
  });

  const [inputs, setInputs] = useState({
    title: '',
    description: '',
  });

  React.useEffect(() => {
    const faqs = currentItem?.faqs;
    remove('');

    if (faqs && faqs.length > 0) {
      faqs.forEach((item) => {
        const data = {
          description: item?.detail,
          title: item?.title,
        };
        const updateData = [data];
        append(updateData);
      });
    }
  }, [currentItem]);

  const AddQuestion = (index) => {
    const updateMedia = [inputs];
    append(updateMedia);
  };

  return (
    <>
      <div className="bg-light p-3 mt-4">
        <div className="row">
          <div className="form-group clearfix margin-bottom-0 col-md-12">
            <div className="form-group clearfix">
              <Form.Label className="font-weight-bold">FAQS</Form.Label>
              <div className={styles.day_hours}>
                <div className="faqs">
                  {fields.length === 0 && (
                    <div className="row">
                      <div className="col-md-2">
                        <label className="mt-4 mb-0">FAQ</label>
                      </div>
                      <div className="col-md-10">
                        <Form.Group controlId="title[]" className="mt-3 mb-0">
                          <Form.Control
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            type={'text'}
                            placeholder="FAQ"
                            {...register(`faqs.0.title`)}
                          />
                        </Form.Group>
                        <Form.Group
                          controlId="description[]"
                          className="mt-3 mb-0"
                        >
                          <Form.Control
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            as="textarea"
                            rows={4}
                            placeholder="Answer"
                            {...register(`faqs.0.description`)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  )}

                  {fields.length > 0 &&
                    fields.map((item, index) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col-md-2">
                            <label className="mt-4 mb-0">
                              {index === 0 ? 'FAQ' : `FAQ ${index + 1}`}
                            </label>
                          </div>
                          <div className="col-md-10">
                            <Form.Group
                              controlId="title[]"
                              className="mt-3 mb-0"
                            >
                              <Form.Control
                                style={{
                                  background: '#fff',
                                  minHeight: '40px',
                                  borderRadius: '4px',
                                }}
                                type={'text'}
                                placeholder="FAQ"
                                {...register(`faqs.${index}.title`)}
                              />
                            </Form.Group>
                            <Form.Group
                              controlId="description[]"
                              className="mt-3 mb-0"
                            >
                              <Form.Control
                                style={{
                                  background: '#fff',
                                  minHeight: '40px',
                                  borderRadius: '4px',
                                }}
                                as="textarea"
                                rows={4}
                                placeholder="Answer"
                                {...register(`faqs.${index}.description`)}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      );
                    })}

                  <a
                    className={`btn btn-dark mt-3 float-right text-white ${styles.add_faq_btn}`}
                    role="button"
                    onClick={AddQuestion}
                  >
                    <span>&#43; Add New</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
