import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import store from './store/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './app/App.scss';

const queryClient = new QueryClient();

const toasterConfiguration = {
  position: 'bottom-right',
  gutter: 5,
  reverseOrder: false,
  toastOptions: {
    className: '',
    style: {
      background: '#363636',
      color: '#fff',
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Toaster {...toasterConfiguration} />
      <App />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </Provider>
);

serviceWorker.unregister();
