import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, Dropdown, Button } from 'react-bootstrap';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import DownloadForOfflineIcon from '@material-ui/icons/CloudDownload';

import { ReactComponent as NewIcon } from '../../../../assets/images/new.svg';
import { ReactComponent as AnnouncementIcon } from '../../../../assets/images/loudspeaker.svg';
import { ReactComponent as VerifiedIcon } from '../../../../assets/images/verified.svg';

import { toast } from 'react-hot-toast';
import moment from 'moment';
import qs from 'query-string';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import { postService } from '../../../services';
import Table from '../../common/table';
import CustomChip from '../../common/chip';
import Filter from './filter';
import { getFlag } from '../../../utils/countryFlag';
import PostDetail from './detail';
import EditPage from './edit';
import PromotionDetail from './popper';
import Promotion from './promotion';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '0px',
    fontSize: 11,
    width: 250,
    height: 250,
    borderRadius: '50%',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 250,
    height: 250,
  },
  thumbnail: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
  verifiedIcon: {
    height: '20px',
    width: '20PX',
  },
  promotedIcon: {
    height: '20px',
    width: '20PX',
    fill: '#f7bd0c',
    marginRight: '4px',
  },
  iconContainer: {
    display: 'flex',
  },
}));

function Ads() {
  const { search } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [query, setQuery] = React.useState(null);
  const [selectedPost, setSelectedPost] = React.useState(null);
  const [showPromotion, setShowPromotion] = React.useState({
    data: [],
    status: false,
  });

  React.useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  const {
    data,
    isLoading: isFetching,
    error,
    isError,
  } = useQuery(
    ['promotions', query, search],
    () => postService.paginate(query),
    { refetchOnWindowFocus: true, enabled: !!query }
  );

  const { mutate, error: err } = useMutation(
    (row) =>
      postService.updateStatus({
        status: row.status,
        _id: row._id,
        isVerified: true,
        isNewPost: false,
      }),
    {
      onSuccess: () => {
        toast.success('Successfully updated !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?'))
      mutate({ ...row, status: 'deleted' });
  };

  const onEdit = (row, status) => {
    if (
      window.confirm(`Are you sure you want to update the status to ${status}?`)
    )
      mutate({ ...row, status });
  };

  const handleClose = (arg) => {
    setSelectedPost(null);
    setEdit(false);
    if (arg) setQuery({ ...query, at: Date.now() });
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleUpdateSuccess = () => {
    setSelectedPost(null);
    setEdit(false);
    setQuery({ ...query, at: Date.now() });
  };

  const handleDownloadImage = (url) => {
    saveAs(url, 'adjeem_file');
  };

  return (
    <>
      {selectedPost ? (
        <PostDetail
          row={selectedPost}
          onClose={handleClose}
          onEdit={() => handleEdit()}
          setQuery={() => setQuery({ ...query, at: Date.now() })}
        />
      ) : (
        ''
      )}

      {showPromotion && (
        <Promotion
          onShow={showPromotion.status}
          data={showPromotion.data}
          onClose={() =>
            setShowPromotion({
              status: false,
            })
          }
          setQuery={() => setQuery({ ...query, at: Date.now() })}
        />
      )}

      {edit && selectedPost ? (
        <EditPage
          row={selectedPost}
          onClose={handleClose}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : (
        ''
      )}
      {isError || err ? (
        <Alert variant="info"> {error?.response?.data?.message || err} </Alert>
      ) : null}
      <Table
        title="Ads"
        filterable
        open={open}
        onOpen={() => setOpen((prev) => !prev)}
        query={query}
        setQuery={setQuery}
        data={data?.data?.data || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        columns={[
          {
            label: 'Date',
            path: 'createdAt',
            content: (row) => (
              <>
                <p>{moment(row.createdAt).format('ll')}</p>
                <p>{moment(row.createdAt).format('LT')}</p>
              </>
            ),
          },
          {
            label: 'Image',
            content: (row) => (
              <div>
                {row?.category?.child?.media_type === 'image' ? (
                  <LightTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <img
                          src={row?.thumbnail || row?.media?.thumbnail?.medium}
                          alt=""
                          className={classes.image}
                        />
                      </React.Fragment>
                    }
                  >
                    <img
                      src={row?.thumbnail || row?.media?.thumbnail?.medium}
                      alt=""
                      className={classes.thumbnail}
                    />
                  </LightTooltip>
                ) : row?.category?.child?.media_type === 'document' ? (
                  <DownloadForOfflineIcon
                    role="button"
                    color="secondary"
                    onClick={() => handleDownloadImage(row?.thumbnail)}
                  />
                ) : (
                  ''
                )}
              </div>
            ),
          },
          { label: 'Title', path: 'title' },
          { label: 'User', path: 'user.name' },
          {
            label: 'Status',
            content: (row) => <CustomChip row={row} alignLeft />,
          },

          {
            label: 'Country',
            content: (row) => (
              <Tooltip title={row?.country?.name} placement="bottom">
                <p> {getFlag(row?.country?.code)} </p>
              </Tooltip>
            ),
          },
          {
            label: '',
            content: (row) => (
              <div className={classes.iconContainer}>
                {row.paymentStatus && <PromotionDetail rows={row} />}

                <Button
                  className="mr-2 button btn-default"
                  onClick={() =>
                    setShowPromotion({
                      data: row,
                      status: true,
                    })
                  }
                  role="button"
                >
                  Promote
                </Button>

                {row.isVerified && (
                  <Tooltip title="Verified Post" placement="bottom">
                    <VerifiedIcon className={classes.verifiedIcon} />
                  </Tooltip>
                )}
                <Tooltip title="View Detail" placement="bottom">
                  <RemoveRedEyeIcon
                    className="ml-2"
                    onClick={() => setSelectedPost(row)}
                    role="button"
                  />
                </Tooltip>
                {!row.paymentStatus && (
                  <Tooltip
                    title="Total Promotion Email Sent"
                    placement="bottom"
                  >
                    <Badge
                      badgeContent={row.promotionalMail}
                      color="primary"
                      className="ml-3"
                    >
                      <AnnouncementIcon
                        role="button"
                        className={classes.verifiedIcon}
                        disabled
                      />
                    </Badge>
                  </Tooltip>
                )}
              </div>
            ),
          },

          {
            label: 'Action',
            content: (row) => (
              <Grid container>
                <Grid item xs={8}>
                  <div className="d-flex flex-wrap" align="left">
                    <Dropdown className="mr-1">
                      <Dropdown.Toggle
                        variant="btn btn-primary"
                        id="dropdownMenuButton1"
                      >
                        Action
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {[
                          { label: 'Pending', status: 'pending' },
                          { label: 'Block', status: 'blocked' },
                          { label: 'Active', status: 'active' },
                        ]
                          .filter((d) => d.status !== row.status)
                          .map((action) => {
                            return (
                              <Dropdown.Item
                                onClick={() => onEdit(row, action.status)}
                                key={action.status}
                              >
                                {action.label}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Grid>
                {row?.isNewPost && (
                  <Grid item xs={4}>
                    <NewIcon />
                  </Grid>
                )}
              </Grid>
            ),
          },
        ]}
      >
        <Filter query={query} open={open} setQuery={setQuery} />
      </Table>
    </>
  );
}

export default Ads;
