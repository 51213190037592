import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const configuration = {
  height: 300,
  maxCharCount: 100,
  toolbar: [
    // 'heading',
    '|',
    'bold',
    'italic',
    // 'link',
    'bulletedList',
    // 'numberedList',
    '|',
    // 'indent',
    // 'outdent',
    '|',
    // 'blockQuote',
    // 'insertTable',
    // 'mediaEmbed',
    'undo',
    'redo',
  ],
};
export default function CustomEditor({
  // data,
  setData,
  field,
  errors,
  value,
  showHint,
}) {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [titleMouseOut, setTitleMouseOut] = useState('');

  const editorRef = React.useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const maxDescriptionLimit = 600;
  const minTitleLimit = 20;
  const titleHint = 'input Hint';

  React.useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditorLoaded(true);
  }, []);

  const handleOnMouseOut = (e) => {
    setTitleMouseOut('min 10 ');
  };

  if (!editorLoaded) return <p>Loading...</p>;

  const count = value
    ? // ? value.replace(/(<([^>]+)>)/gi, '').replace('&nbsp;', '').length
      value.length
    : 0;

  const charCount = ` ${count} / ${maxDescriptionLimit}`;
  // console.log('value is', value)
  return (
    <div id={field.name}>
      <Form.Group className="mt-3">
        <Form.Label>
          {field.name}
          {field.mandatory ? <span className="text-danger">*</span> : ''}
        </Form.Label>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          config={configuration}
          onChange={(event, editor) => {
            const data = editor.getData();

            const newValue = data.length > 600 ? data.substr(0, 595) : data;
            setData({ target: { value: newValue } });
          }}
          onBlur={handleOnMouseOut}
        />

        {errors ? (
          <Form.Text className="text-danger">{errors}</Form.Text>
        ) : (
          <>
            {(!titleMouseOut || count >= minTitleLimit) && showHint && (
              <Form.Text>
                {titleHint} {charCount}
              </Form.Text>
            )}

            {titleMouseOut && count < minTitleLimit && showHint && (
              <Form.Text className="text-danger">
                {titleMouseOut}
                {charCount}
              </Form.Text>
            )}
          </>
        )}
      </Form.Group>
    </div>
  );
}
