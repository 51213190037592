import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useFormContext, Controller } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
  group: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menu: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 5,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e1f2fb' : 'white',
    color: state.isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)',
  }),
};

const Status = (props) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
  ];

  return (
    <>
      <Form.Group controlId="title" className="mt-4 mb-0">
        <Form.Label className="font-weight-bold">Business Status *</Form.Label>

        <Controller
          control={control}
          name={`status`}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              placeholder={`Select status`}
              options={statusOptions}
              styles={customStyles}
              inputref={{
                ...register(`status`, {
                  required: true,
                }),
              }}
            />
          )}
        />
      </Form.Group>

      {errors?.status && (
        <RequestErrorMessage message={` Please business status.`} />
      )}
    </>
  );
};

export default Status;
