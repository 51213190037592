import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const TitleField = (props) => {
  const [inputValues, setValues] = useState('');
  const [validation, setValidation] = useState({ [props.name]: '' });
  const [titleMouseOut, setTitleMouseOut] = useState('');
  const maxTitleLimit = 70;
  const minTitleLimit = 10;
  const titleHint = 'Title';
  const {
    name,
    reff,
    label,
    isReadOnly,
    isMandatory,
    initialValue,
    validationError,
  } = props;

  useEffect(() => {
    setValues(initialValue);
  }, []);

  useEffect(() => {
    setValidation(validationError);
  }, [validationError]);

  const handleTitleOnMouseOut = () => {
    setTitleMouseOut('Min character should be 10');
  };

  const handleOnChange = (value, key) => {
    if (validation && validation[key]) setValidation({ [key]: '' });

    setValues(value);
  };
  return (
    <>
      <Form.Group controlId={name} className="mt-3">
        <Form.Label>
          {label} {isMandatory && '*'}
        </Form.Label>
        <Form.Control
          ref={reff}
          type={'text'}
          label={label}
          value={inputValues}
          readOnly={isReadOnly}
          maxLength={maxTitleLimit}
          onChange={(e) => handleOnChange(e.target.value, name)}
          className={`${validation && validation[name] ? 'is-invalid' : ''}`}
          onBlur={() => handleTitleOnMouseOut()}
        />
        {validation && validation[name] && (
          <p className="invalid-feedback">{validation[name]}</p>
        )}

        {(!titleMouseOut || inputValues.length >= minTitleLimit) && (
          <Form.Text>
            {titleHint} {inputValues ? inputValues.length : 0} / {maxTitleLimit}
          </Form.Text>
        )}

        {titleMouseOut && inputValues.length < minTitleLimit && (
          <Form.Text className="text-danger">
            {titleMouseOut} {inputValues ? inputValues.length : 0} /
            {maxTitleLimit}
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};

TitleField.defaultProps = {
  rows: 0,
  value: '',
  onBlur: null,
  maxLength: 200,
  initialValue: '',
  textMouseOut: '',
  isReadOnly: false,
  charLimitText: 200,
  isMandatory: false,
  label: 'Input Field Label',
  placeholder: 'Placeholder',
};

export default TitleField;
