import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Navbar from './components/shared/Navbar';
import Sidebar from './components/shared/Sidebar';
import Footer from './components/shared/Footer';

import { loadUser, logoutUser } from '../store/reducers/auth';
import httpService from './services/httpService';
import { loadLanguage } from '../store/reducers/language';
import { loadCountryWithCities } from '../store/reducers/country';
import MarketingSidebar from './components/shared/SidebarMarketing';

const langEndpoint = '/admin/languages';
const countryEndpoint = '/admin/countries';

function Main() {
  const dispatch = useDispatch();

  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const fetchProfile = async () => {
    try {
      const { data } = await httpService.get('users/profile');

      setCurrentUser(data.data);
      dispatch(loadUser(data.data));
      setIsAppLoaded(true);
    } catch (error) {
      dispatch(logoutUser());
      setIsAppLoaded(true);
    }
  };

  const fetchLanguage = async () => {
    try {
      const { data } = await httpService.get(`${langEndpoint}/all`);
      dispatch(loadLanguage(data.data));
      setIsAppLoaded(true);
    } catch (error) {
      setIsAppLoaded(true);
    }
  };

  const fetchCountriesWithCity = async () => {
    try {
      const { data } = await httpService.get(`${countryEndpoint}/cities`);
      dispatch(loadCountryWithCities(data.data));
      setIsAppLoaded(true);
    } catch (error) {
      setIsAppLoaded(true);
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchLanguage();
    fetchCountriesWithCity();
  }, []);

  const getAppTemplate = useCallback(() => {
    if (currentUser && currentUser?.roles[0] == 'admin') {
      return <AdminView />;
    } else if (currentUser && currentUser?.roles[0] == 'marketing') {
      return <MarketingView />;
    }
    // else {
    //   return <NotAuthorized />;
    // }
  }, [isAppLoaded]);

  return <>{getAppTemplate()}</>;
}

function AdminView() {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

function MarketingView() {
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <MarketingSidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Main;
