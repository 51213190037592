import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';

import Breadcrumb from '../../../common/breadcrumb';
import MuiAccordion from '../../../common/muiAccordion';
import { categoryService, fileService } from '../../../../services';
import Name from './fields/nameField';
import MenuText from './fields/menuTextField';
import Order from './fields/orderField';
import Type from './fields/typeField';
import SubType from './fields/subtypeField';
import Status from './fields/statusField';
import IsMenuItem from './fields/isMenuItemField';
import IsPopularItem from './fields/isPopularItemField';
import IsFeaturedItem from './fields/isFeaturedItemField';
import IsLatestItem from './fields/isLatestItemField';
import IsIndustry from './fields/isIndustryField';
import MediaType from './fields/mediaTypeField';
import Description from './fields/descriptionField';
import Meta from './fields/metaField';
import Upload from './fields/upload';
import { useMutation, useQueries } from 'react-query';
import { Spinner } from 'react-bootstrap';
import TextareaField from './fields/textareaField';

function AddCategory() {
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = React.useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const parent_id = query.get('parent_id');
  const parentName = query.get('parent_name')?.replace('%20', ' ');

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  let [{ data: parentCategory, isLoading, error: pError }] = useQueries([
    {
      queryKey: ['category', parent_id],
      queryFn: () => categoryService.getItem(parent_id),
      enabled: !!parent_id,
    },
  ]);

  parentCategory = parentCategory?.data?.data;
  const type = parentCategory?.type;

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const onSubmit = async (formData) => {
    if (formData) {
      const [icon, thumbnail] = await Promise.all([
        uploadImage(formData.images.icon),
        uploadImage(formData.images.thumbnail),
      ]);
      mutate({
        ...formData,
        images: {
          icon: icon,
          thumbnail: thumbnail,
        },
      });
    } else {
      mutate(formData);
    }
  };

  const uploadImage = async (files) => {
    if (files) {
      let form = new FormData();
      form.append(`files`, files);
      const { data } = await fileService.fileUpload(form);
      const img = data?.data?.image;
      form.delete(`files`);
      return img;
    }
  };

  const create = async (formData) => {
    const data = TransformCategoryData({ formData, parent_id });
    await categoryService.create(data);
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => create(formData), {
    onSuccess: () => {
      toast.success(`Successfully  added new category`);
      navigate('/categories');
    },
  });

  const getTitle = () => {
    if (parentName) {
      return `${parentName} → ${'create'}`;
    } else {
      return 'Add category';
    }
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb goBack={() => navigate('/categories')} title={getTitle()} />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name />
            <MenuText />
            <Order />
            <Type parentCategory={parentCategory} parent_id={parent_id} />
            {parent_id && type === 'real_state' && <SubType />}
            <IsMenuItem />
            <IsPopularItem />
            <IsFeaturedItem />
            <IsLatestItem />
            <IsIndustry />
            <MediaType />
            <Description />
            <TextareaField name="short_description" label="Short Description" />
            <Status />
          </MuiAccordion>
          <MuiAccordion title="Default Meta (For {country} only)">
            <Meta name="meta.default.title" label="Title" />
            <Meta name="meta.default.description" label="Description" />
            <Meta name="meta.default.keywords" label="Keywords" />
          </MuiAccordion>

          <MuiAccordion title="Meta  (For {country} & {City} )">
            <Meta name="meta.title" label="Title" />
            <Meta name="meta.description" label="Description" />
            <Meta name="meta.keywords" label="Keywords" />
          </MuiAccordion>

          <MuiAccordion title="Media">
            <Upload name="images.icon" label="Icon Image" />
            <Upload name="images.thumbnail" label="Thumbnail Image" />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid}
            >
              Submit
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default AddCategory;

export const TransformCategoryData = ({ formData, parent_id }) => {
  const responseData = {
    ...formData,
    parent_id: parent_id ?? null,
    type: formData.type.value,
    media_type: formData.media_type.value,
    icon_image: formData?.images?.icon,
    thumbnail_image: formData?.images?.thumbnail,
    is_menu_item: formData.is_menu_item.value,
    is_popular_item: formData.is_popular_item.value,
    is_featured: formData.is_featured.value,
    is_latest_item: formData.is_latest_item.value,
    is_industry_category: formData.is_industry_category.value,
    status: formData.status.value,
  };
  return responseData;
};
