import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {
  Avatar,
  Grid,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { useQuery, useMutation } from 'react-query';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import Badge from '@material-ui/core/Badge';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { take, compact } from 'lodash';
import toast from 'react-hot-toast';

import { postService } from '../../../services';
import { ReactComponent as AnnouncementIcon } from '../../../../assets/images/loudspeaker.svg';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    marginTop: '73px',
    marginLeft: '82px',
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  actions: {
    marginTop: 'auto',
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  user_detail_container: {
    marginTop: '30px',
    color: '#6d6d6',
  },
  name: {
    // color:'#'
    marginTop: '10px',
  },
  link: {
    display: 'flex',
    color: '#6d6d6d',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: '#6d6d6d',
  },
  breadcrumb: {
    marginLeft: '20px',
  },
}));
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function PostDetail({
  onClose,
  row,
  onEdit,
  setQuery,
  onQuickEdit,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const navigate = useNavigate();

  const {
    mutate,
    error: err,
    isLoading: isStatusUpdating,
    isSuccess,
  } = useMutation(
    (row) => {
      postService.updateStatus({
        status: row.status,
        _id: row._id,
        isVerified: true,
        isNewPost: false,
      });
    },
    {
      onSuccess: () => {
        toast.success('success');
        setQuery();
        onClose();
        window.location.reload(false);
      },
    }
  );

  const { mutate: promotionMutate } = useMutation(
    (row) => postService.sendPromotionMail(row),
    {
      onSuccess: () => {
        toast.success('Promotional mail successfully sent!');
        setQuery();
        onClose();
      },
    }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const data = useQuery(['post'], () => postService.getPostDetail(row?.slug));

  const detail = data?.data?.post;
  const user = data?.data?.seller_details;

  const maxSteps = detail?.media?.images?.large?.length || 0;

  const normalizedFields =
    detail &&
    detail?.fields.reduce((acc, curr) => {
      const getValues = curr?.options?.map((d) => d?.value).join();
      acc = [...acc, { name: curr.name, value: getValues }];
      const nestedData = curr?.options
        ?.map((option) =>
          option?.extra?.map((extra) => ({
            name: extra?.name,
            value: extra?.value?.name,
          }))
        )
        .flat();
      acc = [...acc, ...nestedData];
      return acc;
    }, []);

  const handleApprove = () => {
    mutate({ status: 'active', _id: detail._id });
  };

  const handleReject = () => {
    mutate({ status: 'declined', _id: detail._id });
  };

  const sendPromotionalEmail = () => {
    if (window.confirm('Are you sure you want to send promotional email?'))
      promotionMutate(row);
  };
  return (
    <div>
      <Dialog
        open={!!row}
        TransitionComponent={Transition}
        keepMounted
        scroll="paper"
        onClose={onClose}
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            <DialogTitle id="alert-dialog-slide-title">
              {detail?.title}
            </DialogTitle>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
              {take(data?.data?.breadcrumb_data, 2)?.map((b, index) => {
                return (
                  <Typography color="textPrimary" className={classes.link}>
                    {index === 0 ? (
                      <HomeIcon className={classes.icon} />
                    ) : index === 1 ? (
                      <WhatshotIcon className={classes.icon} />
                    ) : (
                      <GrainIcon className={classes.icon} />
                    )}
                    {b?.title}
                  </Typography>
                );
              })}
            </Breadcrumbs>
            <DialogContent>
              {!!detail?.media?.images?.large?.length && (
                <>
                  {' '}
                  <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {detail?.media?.images?.large?.map((step, index) => (
                      <div key={step}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          <img className={classes.img} src={step} alt="" />
                        ) : null}
                      </div>
                    ))}
                  </AutoPlaySwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        Next
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </>
              )}
              <DialogContentText id="alert-dialog-slide-description">
                <div
                  dangerouslySetInnerHTML={{ __html: detail?.description }}
                ></div>
              </DialogContentText>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compact(normalizedFields)?.map((row) => (
                    <TableRow key={row?.name}>
                      <TableCell align="left">{row?.name}</TableCell>
                      <TableCell align="left">{row?.value}</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
          </Grid>
          <Grid item xs={4}>
            <div align="center" className={classes.user_detail_container}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                variant="dot"
              >
                <Avatar
                  alt="Profile Picture"
                  src={user?.image}
                  className={classes.large}
                />
              </StyledBadge>
              <h5 className={classes.name}>{user?.name}</h5>
              <p>
                {user?.phone?.calling_code} {user?.phone?.number}
              </p>
              <p>{user?.formatted_address} </p>
            </div>
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => navigate(`/ads/${row._id}`)}
          >
            Quick Edit
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => sendPromotionalEmail()}
            startIcon={
              <Badge badgeContent={row.promotionalMail} color="primary">
                <AnnouncementIcon
                  // onClick={() => promotionMutate(row)}
                  role="button"
                  style={{ height: '20px', width: '20px' }}
                  disabled
                />
              </Badge>
            }
          >
            Send Promo Email
          </Button>
          {
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => onEdit()}
            >
              Edit
            </Button>
          }
          {isSuccess || !row?.isNewPost ? (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => onClose()}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DoneAllIcon />}
                onClick={() => handleApprove()}
                disabled={isStatusUpdating}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<BackspaceIcon />}
                onClick={() => handleReject()}
                disabled={isStatusUpdating}
              >
                Reject
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
