import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: '#6d6d6d',
    margin: '5px 10px 0px 0px',
    fortWeight: 700,
  },
  gridContainer: {
    margin: '10px',
    paddingBottom: '20px',
    width: 500,
  },
  grid: {
    marginTop: 10,
  },
  datePickerGrid: {
    display: 'flex',
  },
  arrowIcon: {
    margin: '10px 12px 0px 12px',
  },
  applyBtn: {
    marginTop: 15,
    color: '#fff',
    background: '#0f414a !important',
    borderRadius: '5px',
    textTransform: 'none',
  },
  cancelBtn: {
    margin: '15px 5px 0px 0px',
    color: '#fff',
    background: '#44573c !important',
    borderRadius: '5px',
    textTransform: 'none',
  },
  btnContainer: {
    marginLeft: 'auto',
  },
}));
function FilterForm({
  onSubmit,
  onClearFilter,
  onSetFilter,
  children,
  filter,
}) {
  const classes = useStyles();

  return (
    <form onSubmit={onSubmit} noValidate autoComplete="off">
      <Grid container className={classes.gridContainer}>
        {children}

        <Box className={classes.btnContainer}>
          <Button
            onClick={onClearFilter}
            variant="outlined"
            className={classes.cancelBtn}
            startIcon={<CancelScheduleSendIcon />}
          >
            Clear Filter
          </Button>
          <Button
            type="submit"
            variant="outlined"
            className={classes.applyBtn}
            startIcon={<SaveIcon />}
          >
            Apply Filter
          </Button>
        </Box>
      </Grid>
    </form>
  );
}

export default FilterForm;
