import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Alert, Card } from 'react-bootstrap';

import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Select from 'react-select';

import { SubmitButton } from '../../common/button';
import Breadcrumb from '../../common/breadcrumb';
import { languageService } from '../../../services';
import Spinner from '../../common/spinner';
import { getLanguagePath } from '../../../config/path';
import customStyles from './customStyles';

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'deleted', label: 'Deleted' },
];

function Language() {
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      status: statusOptions[0],
    },
  });

  const { data, isLoading } = useQuery(
    ['language', params.id],
    () => languageService.getItem(params.id),
    { enabled: !!(params.id !== 'new' && params.id !== 'create') }
  );

  const language = data?.data?.data;

  React.useEffect(() => {
    if (language) {
      reset(language);
      const result = statusOptions.find((obj) => {
        return obj.value === language.status;
      });
      setValue('status', result);
    }
  }, [language, reset]);

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getLanguagePath.fullPath());
  };

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      languageService.create({
        ...language,
        ...formData,
        _id: params.id,
        status: formData?.status?.value,
      }),
    {
      onSuccess: () => {
        toast.success(
          `Successfully ${
            params.id === 'new' ? 'added new' : 'updated'
          } language`
        );
        getRoute();
      },
    }
  );

  const onSubmit = (formData) => mutate(formData);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <Breadcrumb
        goBack={() => getRoute()}
        title={params.id !== 'new' ? 'Edit' : 'Create'}
      />
      <Card className="p-5">
        {isError && (
          <Alert variant="danger"> {error?.response?.data?.message} </Alert>
        )}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-3">
              <Form.Group controlId="name" className="mt-0 mb-0 ">
                <Form.Label className="font-weight-bold">Name *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="name"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  name="name"
                  placeholder="English"
                />
              </Form.Group>
            </div>
            <div className="col-sm-2">
              <Form.Group controlId="code" className="mt-0 mb-0 ">
                <Form.Label className="font-weight-bold">Code *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="code"
                  {...register('code', {
                    required: 'code is required',
                  })}
                  name="code"
                  placeholder="en"
                />
              </Form.Group>
            </div>
            <div className="col-sm-1">
              <Form.Group controlId="order" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Order *</Form.Label>
                <Form.Control
                  type={'number'}
                  label="order"
                  {...register('order', {
                    required: 'Order is required',
                  })}
                  name="order"
                  placeholder="1"
                />
              </Form.Group>
            </div>
            <div className="col-sm-2">
              <Form.Group controlId="status" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Status</Form.Label>
                <Controller
                  control={control}
                  name={`status`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      placeholder={`Selec status`}
                      options={statusOptions}
                      styles={customStyles}
                      inputref={{
                        ...register(`status`),
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>
            <div className="col-sm-2">
              <div className="action mt-4">
                <SubmitButton isLoading={isSubmitting} />
              </div>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default Language;
