import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Input from '../../common/input';
import AccordionBody from '../../common/accordionBody';
import Breadcrumb from '../../common/breadcrumb';
import { SubmitButton } from '../../common/button';
import { useMutation, useQueries } from 'react-query';
import { useForm } from 'react-hook-form';
import { Form, Alert, Accordion } from 'react-bootstrap';
import { languageService, planService } from '../../../services';
import { getSanitizedData } from '../../../utils/sanitizer';
import { getPlansPath } from '../../../config/path';

const mapData = (data, code) => ({
  name: data[code]?.name,
  description: data[code]?.description,
  hint: data[code]?.hint,
});

export default function EditLanguageSpecificPlan() {
  const navigate = useNavigate();
  const params = useParams();

  const { register, handleSubmit, reset, errors } = useForm({});

  const result = useQueries([
    {
      queryKey: 'language',
      queryFn: () => languageService.getItem(params.languageId),
    },
    { queryKey: 'plan', queryFn: () => planService.getItem(params.id) },
  ]);

  const { d1: language, d2: plan, error: err } = getSanitizedData(result);
  //eslint-disable-next-line
  React.useEffect(
    () => plan && reset(mapData(plan, language?.code)),
    [plan, language?.code, reset]
  );

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getPlansPath.fullPath());
  };

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      planService.update({
        ...formData,
        language_code: language.code,
        _id: plan._id,
      }),
    {
      onSuccess: () => {
        toast.success(`Successfully updated plan`);
        getRoute();
      },
    }
  );

  const onSubmit = (formData) => mutate(formData);

  return (
    <div>
      <Breadcrumb goBack={() => getRoute()} title="Edit" />
      <div className="row ">
        {isError || err ? (
          <Alert variant="danger">
            {' '}
            {error?.response?.data?.message || err}{' '}
          </Alert>
        ) : null}
        <div className="card col-12">
          <div className="card-body col-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Accordion defaultActiveKey="0" className="w-100">
                  <AccordionBody label="General" eventKey="0">
                    <Input
                      required
                      errors={errors}
                      label="Name"
                      col={12}
                      placeholder="Motors"
                      name="name"
                      register={register}
                    />
                    <Input
                      label="Description"
                      col={12}
                      placeholder="description"
                      name="description"
                      register={register}
                    />
                    <Input
                      label="Hint"
                      placeholder="hint"
                      name="hint"
                      register={register}
                    />
                  </AccordionBody>
                </Accordion>
              </Form.Row>
              <SubmitButton isLoading={isSubmitting} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
