import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../../common/requestError';

const Name = ({ value }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('name', value);
  }, [value]);

  return (
    <>
      <Form.Group controlId="name" className="mt-0 mb-0">
        <Form.Label className="font-weight-bold">Name *</Form.Label>
        <Form.Control
          type={'text'}
          label="name"
          {...register('name', {
            required: 'Name is required',
          })}
          name="name"
        />
      </Form.Group>

      {errors?.name && (
        <RequestErrorMessage message={` Please enter the banner name.`} />
      )}
    </>
  );
};

export default Name;
