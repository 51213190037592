import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useQuery, useMutation } from 'react-query';
import MediaControlCard from './card';
import { settingService } from '../../../services';
import { data as fields } from './fields.json';
import { IOSSwitch } from '../../common/iosSwitch';
import { FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    background: '#187858 !important',
    margin: theme.spacing(1),
  },
  input: {
    cursor: 'pointer',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      minWidth: '10ch',
    },
  },
  key: {
    color: '#6d6d66d',
    marginLeft: 20,
    textTransform: 'capitalize',
  },
  payment_title: {
    color: '#6d6d6d',
    marginTop: '5px',
    marginRight: '10px',
  },
  payment_card: {
    padding: '10px 10px 10px 15px',
    margin: theme.spacing(1),
  },
}));

const filtered = (setting, fields) => {
  if (!setting || !fields) return [];

  const settings = setting
    ?.reduce((acc = [], red) => {
      if (!['profileImage', 'productImage'].includes(red.key)) {
        const field = fields.find((f) => f.key === red.key);
        if (field) acc.push({ ...field, ...red });
      }
      return acc;
    }, [])
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  const field = fields?.filter(
    (f) => !settings?.map((k) => k.key).includes(f.key)
  );

  return [...settings, ...field];
};

export default function IconLabelButtons() {
  const classes = useStyles();
  const [settings, setSettings] = React.useState([]);

  const { data, refetch } = useQuery(['settings'], settingService.get, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  let setting = data?.data?.data;

  const profilePicData = setting?.find((s) => s?.key === 'profileImage');
  const productImgData = setting?.find((s) => s?.key === 'productImage');
  const watermarkImgData = setting?.find((s) => s?.key === 'watermarkImage');
  const paypalLiveMode = setting?.find((s) => s?.key === 'paypal_live_mode');

  React.useEffect(() => {
    if (!setting) return;
    const dataToBeMapped = filtered(setting, fields);
    setSettings(dataToBeMapped);
    // eslint-disable-next-line
  }, [setting, fields]);

  const { mutate } = useMutation(
    (formData) =>
      formData?._id
        ? settingService.update(formData)
        : settingService.create(formData),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleChange = (target, idx) => {
    let clone = [...settings];
    clone[idx].value = target.value;
    setSettings(clone);
  };
  const handleSubmit = (event, field) => {
    event.preventDefault();
    mutate(field);
  };

  const handlePaymentMode = (event) => {
    mutate({
      ...paypalLiveMode,
      key: event.target.name,
      value: event.target.checked,
    });
  };

  return (
    <>
      <h2>Default Settings</h2>
      <Box display="flex">
        <MediaControlCard
          label="Profile Picture"
          name="profileImage"
          data={profilePicData}
        />
        <MediaControlCard
          label="Product Image"
          name="productImage"
          data={productImgData}
        />
        <MediaControlCard
          label="Watermark Image"
          name="watermarkImage"
          data={watermarkImgData}
        />
        <Card className={classes.payment_card}>
          <Box display="flex">
            <Typography variant="h6" className={classes.payment_title}>
              Paypal Live Mode
            </Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={paypalLiveMode?.value === 'true' ? true : false}
                  onChange={handlePaymentMode}
                  name="paypal_live_mode"
                />
              }
            />
          </Box>
        </Card>
      </Box>
      <Card>
        {settings.map((field, index) => (
          <form
            onSubmit={(event) => handleSubmit(event, field)}
            key={field.key}
          >
            <Box display="flex" className={classes.root}>
              <Typography variant="h6" className={classes.key}>
                {field.label}
              </Typography>
              <TextField
                required
                type={field.type}
                name={field.key}
                value={field.value}
                onChange={({ currentTarget }) =>
                  handleChange(currentTarget, index)
                }
                variant="outlined"
                size="small"
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
              >
                Update
              </Button>
            </Box>
          </form>
        ))}
      </Card>
    </>
  );
}
