import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import EnhancedTableBody from './tableBody';
import EnhancedTableHead from './tableHeader';
import EnhancedTableToolbar from './tableToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: 500,
    position: 'relative',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerCell: {
    fontWeight: 700,
    color: '#6d6d6d ',
    fontSize: 17,
  },

  progress: {
    position: 'absolute',
    top: '45%',
    left: '45%',
  },
}));
function EnhancedTable(props) {
  const {
    options = [10, 20, 30],
    filterable,
    query,
    open,
    onOpen,
    isFetching,
    onClearFilter,
    columns,
    data,
    children,
    onAddNew,
    onFilter,
    addDisable,
    custom,
    onToolbarAction,
    sortDisabled = false,
    title,
    onApplyFilter,
    isDragDisabled,
    onDragEnd,
    onCustomButtonClick,
    multiple = false,
    route: pathname,
    ...rest
  } = props;

  const navigate = useNavigate();
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);

  const order = query?.sorted?.split(':')[1];
  const orderBy = query?.sorted?.split(':')[0];

  const handleChangePage = (event, newPage) => {
    const search = queryString.stringify({ ...query, page: newPage + 1 });
    navigate({ pathname, search: '?' + search });
  };

  const handleChangeRowsPerPage = (event) => {
    const search = queryString.stringify({
      ...query,
      limit: event.target.value,
    });
    navigate({ pathname, search: '?' + search });
  };
  const onRequestSort = (event, property) => {
    if (sortDisabled) return;
    const isAsc = orderBy === property && order === 'asc';
    const sorted = `${property}:${isAsc ? 'desc' : 'asc'}`;
    const search = queryString.stringify({ ...query, sorted });
    navigate({ pathname, search: '?' + search });
  };

  const setQuery = (q) => {
    const search = queryString.stringify(q);
    navigate({ pathname, search: '?' + search });
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.findIndex((data) => data._id === row?._id);
    let newSelected = [];
    if (multiple) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [row];
      } else {
        selected.slice(0, selectedIndex);
      }
    }

    setSelected(newSelected);
  };

  const handleToolbarAction = () => {
    onToolbarAction(!multiple ? selected[0] : selected);
    setSelected([]);
  };

  const handleCustomButtonClick = () => {
    onCustomButtonClick(!multiple ? selected[0] : selected);
    setSelected([]);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          {...rest}
          handleCustomButtonClick={handleCustomButtonClick}
          numSelected={selected.length}
          custom={custom}
          onClick={handleToolbarAction}
          title={title}
          onAddNew={onAddNew}
          query={query}
          setQuery={setQuery}
          onFilter={onFilter}
          onClearFilter={onClearFilter}
          onApplyFilter={onApplyFilter}
          addDisable={addDisable}
          filterable={filterable}
          open={open}
          setOpen={onOpen}
        >
          {children}
        </EnhancedTableToolbar>
        {isFetching ? (
          <div className={classes.progress}>
            <CircularProgress color="primary" />
          </div>
        ) : data?.totalDocs === 0 ? (
          <div className={classes.progress}>
            <Alert severity="info">No data found!</Alert>
          </div>
        ) : (
          <>
            <TableContainer style={{ minHeight: 500, width: '100%' }}>
              <Table
                className={classes.table}
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  isDragDisabled={isDragDisabled}
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  numSelected={selected.length}
                  rowCount={data?.docs?.length}
                  onRequestSort={onRequestSort}
                  columns={columns}
                />

                <EnhancedTableBody
                  isDragDisabled={isDragDisabled}
                  columns={columns}
                  onDragEnd={onDragEnd}
                  handleClick={handleClick}
                  selected={selected}
                  rows={data?.docs}
                  isFetching={isFetching}
                  classes={classes}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={options}
              component="div"
              count={data?.totalDocs || 10}
              rowsPerPage={Number(query?.limit || 0)}
              page={query?.page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </div>
  );
}

export default EnhancedTable;
