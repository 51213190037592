import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const configuration = {
  height: 500,
  minHeight: 500,
  toolbar: ['bold', 'italic', 'bulletedList', 'undo', 'redo'],
};

function Description({ value }) {
  const [editorLoaded, setEditorLoaded] = useState(false);

  const editorRef = React.useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditorLoaded(true);
  }, []);

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('description', value);
  }, [value]);

  if (editorLoaded) {
    return (
      <Grid item xs={12} className="mt-3">
        <Form.Group className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Description</Form.Label>

          <Controller
            control={control}
            name={`description`}
            render={({ field: { onChange, value } }) => (
              <CKEditor
                editor={ClassicEditor}
                config={configuration}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  if (data) {
                    onChange(data);
                  } else {
                    onChange(null);
                  }
                }}
                value={value}
                data={value}
              />
            )}
          />
        </Form.Group>
      </Grid>
    );
  }
}

export default Description;
