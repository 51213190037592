import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  ads,
  getBannerPath,
  getBusinessesPath,
  getCategoryPath,
  getCountryPath,
  getFieldPath,
  getLanguagePath,
  getMobileBannerPath,
  getOptionionsPath,
  getPaymentsPath,
  getPlansPath,
  getRolesPath,
  getSeoPath,
  getUsersPath,
} from '../../config/path';

const navigators = [
  {
    type: 'link',
    path: '/dashboard',
    fabIcon: 'mdi mdi-shield-check menu-icon',
    label: 'Dashboard',
  },
  {
    type: 'toggler',
    label: 'Post',
    value: 'postMenuOpen',
    fabIcon: 'mdi mdi-format-list-bulleted-square menu-icon',
    subNav: [
      {
        path: '/categories',
        toPath: getCategoryPath.fullPath(),
        fabIcon: 'mdi mdi-shield-check menu-icon',
        label: 'Categories',
        model: 'Category',
      },
      {
        path: '/fields',
        toPath: getFieldPath.fullPath(),
        fabIcon: 'mdi mdi-shield-check menu-icon',
        label: 'Fields',
        model: 'Field',
      },
      {
        path: '/ads',
        toPath: ads.param(),
        fabIcon: 'mdi mdi-shield-check menu-icon',
        label: 'Ads',
        model: 'Post',
      },
    ],
  },
  {
    type: 'toggler',
    label: 'Listings',
    value: 'businessesMenuOpen',
    fabIcon: 'mdi mdi-home-circle menu-icon',
    subNav: [
      {
        path: '/listings/list',
        toPath: getBusinessesPath.fullPath(),
        label: 'Businesses',
        model: 'Businesses',
      },
    ],
  },

  {
    type: 'toggler',
    label: 'Users',
    value: 'usersMenuOpen',
    fabIcon: 'mdi mdi-account-multiple menu-icon',
    subNav: [
      {
        path: '/user/list',
        toPath: getUsersPath.fullPath(),
        label: 'Users',
        model: 'User',
      },
      // {
      //   path: '/users/opinions',
      //   toPath: getOptionionsPath.fullPath(),
      //   label: 'Feedbacks',
      //   model: 'Opinion',
      // },
      // {
      //   path: '/users/role',
      //   toPath: getRolesPath.fullPath(),
      //   label: 'Roles',
      //   model: 'Role',
      // },
    ],
  },
  {
    label: 'Settings',
    value: 'settingsMenuOpen',
    fabIcon: 'mdi mdi-settings menu-icon',
    subNav: [
      {
        path: '/settings/languages',
        toPath: getLanguagePath.fullPath(),
        label: 'Languages',
        model: 'Language',
      },
      {
        path: '/settings/countries',
        toPath: getCountryPath.fullPath(),
        label: 'Countries',
        model: 'Country',
      },
      {
        path: '/settings/seo',
        toPath: getSeoPath.fullPath(),
        label: 'Seo',
        model: 'Metatag',
      },
      {
        path: '/settings/default',
        toPath: '/settings/default',
        label: 'Config',
        model: 'Setting',
      },
    ],
  },
  {
    type: 'toggler',
    label: 'Subscriptions',
    value: 'subscriptionMenuOpen',
    fabIcon: 'mdi mdi-credit-card menu-icon',
    subNav: [
      {
        path: '/subscriptions/plans',
        toPath: getPlansPath.fullPath(),
        label: 'Plans',
        model: 'Plan',
      },
      {
        path: '/subscriptions/payments',
        toPath: getPaymentsPath.fullPath(),
        label: 'Payments',
        model: 'Payment',
      },
    ],
  },
  {
    type: 'link',
    path: '/banners',
    toPath: getBannerPath.fullPath(),
    fabIcon: 'mdi mdi-image menu-icon',
    label: 'Banner Ads',
    model: 'Banner',
  },
  {
    type: 'link',
    path: '/mobile_banners',
    toPath: getMobileBannerPath.fullPath(),
    fabIcon: 'mdi mdi-image-outline menu-icon',
    label: 'Mobile Banner',
  },
  // {
  //   type: 'link',
  //   path: '/log',
  //   fabIcon: 'mdi mdi-image menu-icon',
  //   label: 'Server log',
  // },
  // {
  //   type: 'link',
  //   path: '/messages',
  //   fabIcon: 'mdi mdi-image menu-icon',
  //   label: 'Messages',
  // },
  // {
  //   type: 'link',
  //   path: '/notifications',
  //   fabIcon: 'mdi mdi-image menu-icon',
  //   label: 'Notifications',
  // },
];

function Sidebar() {
  const location = useLocation();

  const [state, setState] = React.useState({});

  const user = useSelector((state) => state.auth.currentUser);

  const toggleMenuState = (menuState) => {
    const newState = { ...state };
    if (newState[menuState]) {
      newState[menuState] = false;
    } else if (Object.keys(newState).length === 0) {
      newState[menuState] = true;
    } else {
      Object.keys(newState).forEach((i) => {
        newState[i] = false;
      });
      newState[menuState] = true;
    }
    setState(newState);
  };

  React.useEffect(() => {
    onRouteChanged();
    console.log('On Router changed');
  }, []);

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach((i) => {
      const newState = { ...state };
      newState[i] = false;
      setState(newState);
    });

    const dropdownPaths = [
      { path: '/categories', state: 'postMenuOpen' },
      { path: '/fields', state: 'postMenuOpen' },
      { path: '/ads', state: 'postMenuOpen' },
      { path: '/settings', state: 'settingsMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/subscription', state: 'subscriptionMenuOpen' },
      { path: '/businesses', state: 'businessesMenuOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        const newState = { ...state };
        newState[obj.state] = true;
        setState(newState);
      }
    });
  };

  const isAuthorizedToRead = (model) => {
    return true;
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  React.useEffect(() => {
    onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, []);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <div className="nav-link d-flex">
            {user.avatar && (
              <div className="profile-image">
                <img src={user.avatar} alt="profile" />
              </div>
            )}

            <div className="profile-name">
              <p className="name">{user.name}</p>
            </div>
          </div>
        </li>
        {navigators.map((navigator, index) => (
          <React.Fragment key={navigator.label + index}>
            {navigator.type === 'link' ? (
              isAuthorizedToRead(navigator.model) || !navigator.model ? (
                <li
                  className={
                    isPathActive(navigator.path)
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link
                    className="nav-link"
                    to={navigator.toPath || navigator.path}
                  >
                    <i className={navigator.fabIcon}></i>
                    <span className="menu-title">{navigator.label}</span>
                  </Link>
                </li>
              ) : (
                ''
              )
            ) : !navigator.subNav.some((nav) =>
                isAuthorizedToRead(nav.model)
              ) ? (
              ''
            ) : (
              <li
                className={
                  navigator.subNav.some((nav) =>
                    location.pathname.startsWith(nav.path)
                  )
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <div
                  className={
                    state[navigator.value]
                      ? 'nav-link menu-expanded'
                      : 'nav-link'
                  }
                  onClick={() => toggleMenuState(navigator.value)}
                  data-toggle="collapse"
                >
                  <i className={navigator.fabIcon}></i>
                  <span className="menu-title">{navigator.label}</span>
                  <i className="menu-arrow"></i>
                </div>

                <Collapse in={state[navigator.value]}>
                  <div>
                    <ul className="nav flex-column sub-menu">
                      {navigator.subNav.map((nav) => {
                        return (
                          <React.Fragment key={nav.path}>
                            {isAuthorizedToRead(nav.model) && (
                              <li className="nav-item" key={nav.label}>
                                <Link
                                  className={
                                    isPathActive(nav.path)
                                      ? 'nav-link active'
                                      : 'nav-link'
                                  }
                                  to={nav.toPath}
                                >
                                  {nav.label}
                                </Link>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </div>
                </Collapse>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;
