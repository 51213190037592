import Joi from 'joi'

const options = {
    errors: {
        wrap: {
            label: '',
        },
    },
    allowUnknown: true,
};

const schema = Joi.array().items(
    Joi.object({
        days: Joi.required().label('Days'),
        standardPrice: Joi.number().min(1).required().label("Standard Price").messages({
            "number.base": `Standard Cost is  required.`,
            "number.empty": `Standard Cost cannot be an empty field`,
            "any.required": `Standard Cost is  required.`,
        })
    })
).unique('days').message('Day is duplicate');

export const validatePost = (data) => {
    const { error } = schema.validate(data, options)
    if (!error) return
    const detail = error?.details[0]
    return { [detail.path[0]]: detail?.message }

}
