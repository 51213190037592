import { pick, compact } from 'lodash';
import { fileService } from '../../../../../services';

const scrollOptions = {
  behavior: 'smooth',
  block: 'end',
};
export const isFormValid = ({
  fields,
  inputValues,
  inputTitleRef,
  files,
  contactDetailRef,
  isPostingJob,
  media_type,
}) => {
  let returnValue = true;
  let errors = {};

  //Disable location validation
  if (!inputValues?.location?.formatted_address) {
    //errors['location'] = 'This field is required';
    //document.getElementById('contact').scrollIntoView(scrollOptions);
  }

  if (isPostingJob && !inputValues?.expiresAt) {
    errors['expiresAt'] = 'This field is required';
    const a = document
      .getElementById('datepicker')
      ?.scrollIntoView(scrollOptions);
  }

  //phone
  // if (contactDetailRef?.current?.getContactDetailObject().phone === '') {
  //   errors['phone'] = 'This field is required';
  //   document.getElementById('contact').scrollIntoView(scrollOptions);
  // }

  // if (contactDetailRef?.current?.getContactDetailObject().name === '') {
  //   errors['name'] = 'This field is required';
  //   document.getElementById('contact').scrollIntoView(scrollOptions);
  // }
  //file upload
  if (
    (media_type === 'image' && !files?.length) ||
    (media_type === 'document' && !files?.length)
  ) {
    errors['files'] = 'This field is required';
    document.getElementById('image-container').scrollIntoView(scrollOptions);
  }

  //description
  if (!inputValues?.description) {
    errors['description'] = 'This field is required';
    document.getElementById('Description').scrollIntoView(scrollOptions);
  }
  if (
    inputValues?.description &&
    inputValues?.description.replace(/(<([^>]+)>)/gi, '').replace('&nbsp;', '')
      .length < 10
  ) {
    errors['description'] = 'Min 20 char required';
    document.getElementById('Description').scrollIntoView(scrollOptions);
  }

  //fields
  fields
    .filter((f) => f.type === 'select_multiple')
    .forEach((field) => {
      if (!inputValues.selected[field._id] && field.mandatory) {
        errors[field._id] = 'This field is required';
        document.getElementById(field._id).scrollIntoView(scrollOptions);
      }
    });

  fields
    .filter((f) => f.type !== 'select_multiple')
    .reverse()
    .forEach((field) => {
      const fieldMatchingIndex = inputValues.fields.findIndex(
        (f) => f._id == field._id
      );
      const a =
        inputValues?.fields[fieldMatchingIndex]?.option?.rowExtras &&
        inputValues?.fields[fieldMatchingIndex]?.option?.rowExtras?.reduce(
          (_acc, curr) => {
            const extra = inputValues?.fields[
              fieldMatchingIndex
            ]?.option?.extras?.find((d) => d._id === curr._id);
            if (curr.is_mandatory && !extra) {
              errors[curr._id] = 'This field is required';
              document.getElementById(field._id).scrollIntoView(scrollOptions);
            }
          },
          []
        );

      if (
        inputValues.fields[fieldMatchingIndex] === undefined ||
        inputValues.fields[fieldMatchingIndex] === ''
      ) {
        if (field.mandatory) {
          errors[field._id] = 'This field is required';
          document.getElementById(field._id).scrollIntoView(scrollOptions);
        }
      }
    });
  //title validation
  if (
    inputTitleRef.current.value === undefined ||
    inputTitleRef.current.value === ''
  ) {
    errors['title'] = 'This field is required';
    inputTitleRef.current.scrollIntoView(scrollOptions);
  }
  if (inputTitleRef.current.value && inputTitleRef.current.value.length < 10) {
    errors['title'] = 'min 10 char required';
    inputTitleRef.current.scrollIntoView(scrollOptions);
  }

  if (Object.keys(errors).length) {
    returnValue = false;
  }

  return { isValid: returnValue, errors };
};

export const getSanitizedData = ({
  inputValues,
  contactDetailRef,
  pricingDetailRef,
  userData,
  salary,
  PostAdCtx,
  images,
  router,
  title,
}) => {
  const category = {
    parent: pick(PostAdCtx.category, ['_id', 'slug']),
    child: pick(PostAdCtx.childCategory, [
      '_id',
      'slug',
      'media_type',
      'template_type',
    ]),
  };
  const price = {
    currency: userData?.country?.currency_symbol || '$',
    amount: pricingDetailRef?.current?.getPricingDetailObject()?.price || 0,
    isNegotiable:
      !!pricingDetailRef?.current?.getPricingDetailObject()?.isNegotiable,
  };

  const user = {
    name: inputValues.user.name,
    email: inputValues.user.email,
    user_id: inputValues.user.user_id,
    phone: {
      number: contactDetailRef.current.getContactDetailObject().phone,
      calling_code: userData.country.calling_code,
    },
  };
  const fields = inputValues.fields;

  const description = inputValues.description;
  const country = inputValues.country;

  const location = {
    city_id: inputValues.city,
    address: inputValues.location,
  };

  const locale = router.locale;

  const data = {
    category,
    price,
    user,
    fields,
    country,
    title,
    description,
    location,
    locale,
    expiresAt: inputValues?.expiresAt,
    salary: {
      currency: userData?.country?.currency_symbol,
      ...salary,
    },
    images: compact(images.flat()),
  };

  let multipleFields = inputValues.selected;

  data.fields = data.fields.reduce(
    (acc, red) => [...acc, { ...red, option: red.option ? [red.option] : [] }],
    []
  );

  multipleFields = Object.keys(multipleFields).reduce((acc, red) => {
    acc.push({
      _id: red,
      option: multipleFields[red].map((d) => ({
        _id: d.value,
        extras: [],
      })),
    });
    return acc;
  }, []);
  data.fields = [...data.fields, ...multipleFields];

  return data;
};

export const uploadImage = async (PostAdCtx, files) => {
  try {
    const isImage = PostAdCtx.childCategory.media_type === 'image';

    const formData = new FormData();
    let images = [];
    if (files.length > 0) {
      images = files.map((file) => formData.append(`files`, file));
      const { data } = await fileService.uploadFileAndApplyWatermark(
        formData,
        isImage ? 'image' : 'file'
      );
      images.push(data?.data?.image);
      return [compact(images.flat()), null];
    }
    return [images, null];
  } catch (ex) {
    return [null, ex];
  }
};
