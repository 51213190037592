import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countryWithcities: [],
};

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    loadCountryWithCities: (state, { payload }) => {
      state.countryWithcities = payload;
    },
  },
});

export const { loadCountryWithCities } = countrySlice.actions;

export default countrySlice.reducer;
