import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Input from '../../common/input';
import AccordionBody from '../../common/accordionBody';
import Breadcrumb from '../../common/breadcrumb';
import { SubmitButton } from '../../common/button';
import { useMutation, useQueries } from 'react-query';
import { useForm } from 'react-hook-form';
import { Form, Alert, Accordion } from 'react-bootstrap';

import { countryService, languageService } from '../../../services';
import { getSanitizedData } from '../../../utils/sanitizer';
import Spinner from '../../common/spinner';
import { getCategoryPath, getCountryPath } from '../../../config/path';

const mapDataToForm = (data, code) => {
  return {
    [code]: {
      name: data[code]?.name,
      capital: data[code]?.capital,
    },
  };
};
export default function LanguageSpecific() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const langName = query.get('name');

  const { register, handleSubmit, reset } = useForm({});

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getCountryPath.fullPath());
  };
  const result = useQueries([
    {
      queryKey: ['language', params.languageId],
      queryFn: () => languageService.getItem(params.lang),
    },
    {
      queryKey: ['country', params.id],
      queryFn: () => countryService.getItem(params.id),
    },
  ]);

  const {
    d1: lang,
    d2: country,
    error: fetchingError,
    isFetching,
  } = getSanitizedData(result);
  //eslint-disable-next-line
  React.useEffect(
    () => country && reset(mapDataToForm(country, lang?.code)),
    [country, lang?.code, reset]
  );

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      countryService.update({
        _id: country._id,
        locale: lang?.code,
        language_code: lang?.code,
        ...country,
        ...formData,
      }),
    {
      onSuccess: () => {
        toast.success(`Successfully updated country`);
        getRoute();
      },
    }
  );

  const onSubmit = (formData) => mutate(formData);

  if (isFetching) return <Spinner />;

  return (
    <div>
      <Breadcrumb goBack={() => getRoute()} title={`Edit ${langName}`} />
      <div className="row ">
        {isError || fetchingError ? (
          <Alert variant="danger">
            {' '}
            {error?.response?.data?.message || fetchingError}{' '}
          </Alert>
        ) : null}
        <div className="card col-12">
          <div className="card-body col-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Accordion defaultActiveKey="1" className="w-100">
                  <AccordionBody label="Meta Data" eventKey="1">
                    <Input
                      label="Name"
                      col={12}
                      placeholder="Motors"
                      name={`${lang?.code}.name`}
                      register={register}
                    />
                    <Input
                      label="Capital"
                      col={12}
                      placeholder="Motors"
                      name={`${lang?.code}.capital`}
                      register={register}
                    />
                  </AccordionBody>
                </Accordion>
              </Form.Row>
              <SubmitButton isLoading={isSubmitting} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
