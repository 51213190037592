import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import customStyles from './customStyles';

const IsLatestItem = ({ value }) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const options = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  React.useEffect(() => {
    setValue('is_latest_item', options[1]);
  }, []);

  React.useEffect(() => {
    if (value) {
      setValue('is_latest_item', options[0]);
    } else {
      setValue('is_latest_item', options[1]);
    }
  }, [value]);

  return (
    <>
      <Grid item xs={12} lg={2} className="mt-3">
        <Form.Group controlId="is_latest_item" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Latest Item?</Form.Label>
          <Controller
            control={control}
            name={`is_latest_item`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Selec latest option`}
                options={options}
                styles={customStyles}
                inputref={{
                  ...register(`is_latest_item`),
                }}
              />
            )}
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default IsLatestItem;
