import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { authSlice } from './reducers/auth';
import { languageSlice } from './reducers/language';
import { countrySlice } from './reducers/country';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    language: languageSlice.reducer,
    country: countrySlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger()),
  ],
});

export default store;
