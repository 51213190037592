import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';

import { listingService } from '../../../../services';

function ListingDetails({ data, setUpdatedItem }) {
  const {
    mutate,
    error: err,
    isLoading,
  } = useMutation(
    (row) => {
      listingService.updateStatus({
        status: row.status,
        _id: row.id,
        isVerified: true,
      });
    },
    {
      onSuccess: async (data, context) => {
        setUpdatedItem(context);
      },
      onError: async (data, context) => {
        console.log(data);
      },
    }
  );

  const onApprove = (row) => {
    mutate({ ...row, status: 'active' });
  };

  const onReject = (row) => {
    mutate({ ...row, status: 'declined' });
  };

  return (
    <div>
      {data.logo && (
        <Row>
          <Col lg="12">
            <div className="text-center">
              <img src={data.logo} width="100" />
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg="12">
          <p className="font-weight-bold">Name:</p>
          <p>{data?.title}</p>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <p className="font-weight-bold">Email/Phone:</p>
          <p>
            {data?.user?.email} | {data?.user?.phone?.calling_code}{' '}
            {data?.user?.phone?.number}
          </p>
        </Col>
      </Row>
      {data?.website && (
        <Row>
          <Col lg="12">
            <p className="font-weight-bold">Website: </p>
            <p>
              <a href={data?.website} target="blank">
                {data?.website}
              </a>
            </p>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg="12">
          <p className="font-weight-bold">Address:</p>
          <p>{data?.address}</p>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <p className="font-weight-bold">Country/City:</p>
          <p>
            {data?.country?.name} | {data?.city?.slug}
          </p>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <p className="font-weight-bold">Industry Category/Sub:</p>
          <p>
            {data?.category?.parent?.slug} | {data?.category?.child?.slug}
          </p>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col lg="12">
          <p className="font-weight-bold">Overview:</p>
          <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
        </Col>
      </Row>

      <hr />
      <div className="bg-info p-3">
        <Row>
          {data.status !== 'active' && (
            <>
              <Col className="text-center">
                <Button
                  variant="primary"
                  onClick={() => onApprove({ id: data._id })}
                  disabled={isLoading}
                >
                  Approve
                </Button>
              </Col>
            </>
          )}
          <Col className="text-center">
            <Button
              variant="danger"
              onClick={() => onReject({ id: data._id })}
              disabled={isLoading}
            >
              Reject
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ListingDetails;
