import React, { useState, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { Card, Row, Col } from 'react-bootstrap';

import CurrencyField from '../Fields/CurrencyField.component';


const PricingDetail = forwardRef((props, ref) => {
  const [isNegotiable, setNegotiable] = useState(false);

  const { userData, initialValue, validationError } = props;

  const currencyRef = React.createRef();
  const negotiationRef = React.createRef();

  useImperativeHandle(ref, () => ({
    getPricingDetailObject() {
      return {
        price:
          currencyRef.current.attributes.getNamedItem('amount')?.value || '',
        isNegotiable: negotiationRef.current.value || '',
      };
    },
  }));

  return (
    <Card className="p-4 mt-3">
      <Row>
        <Col xs={8}>
          <h4 className="bg-light p-2 mb-3">Input Price</h4>
          <CurrencyField
            reff={currencyRef}
            userData={userData}
            validationError={validationError}
            initialValue={initialValue.amount ? initialValue.amount : ''}
          />
        </Col>
        <Col xs={4}>
          <h4 className="bg-light p-2 mb-3">Negotiable</h4>
          <label >
            <input
              type="checkbox"
              ref={negotiationRef}
              value={isNegotiable}
              onChange={(event) => setNegotiable(event.target.checked)}
            />
            <span></span>
          </label>
        </Col>
      </Row>
    </Card>
  );
});

PricingDetail.defaultProps = {};

export default PricingDetail;
