import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Input from '../../common/input';
import AccordionBody from '../../common/accordionBody';
import Breadcrumb from '../../common/breadcrumb';
import { SubmitButton } from '../../common/button';
import { useMutation, useQueries } from 'react-query';
import { useForm } from 'react-hook-form';
import { Form, Alert, Accordion } from 'react-bootstrap';

import { languageService, metatagService } from '../../../services';
import { getSanitizedData } from '../../../utils/sanitizer';
import Spinner from '../../common/spinner';
import { getSeoPath } from '../../../config/path';

const mapDataToForm = (data, code) => {
  return {
    [code]: {
      title: data[code]?.title,
      description: data[code]?.description,
      keywords: data[code]?.keywords,
      alt: data[code]?.alt,
    },
  };
};

export default function LanguageSpecific() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const langName = query.get('name');
  const isDefault = Boolean(!!query.get('default'));

  const { register, handleSubmit, reset } = useForm({});

  const result = useQueries([
    {
      queryKey: ['language', params.languageId],
      queryFn: () => languageService.getItem(params.lang),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['field', params.id],
      queryFn: () => metatagService.get(params.id),
      refetchOnWindowFocus: false,
    },
  ]);

  const {
    d1: lang,
    d2: meta,
    error: fetchingError,
    isFetching,
  } = getSanitizedData(result);
  //eslint-disable-next-line
  const getValue = !!isDefault ? meta?.default : meta;
  React.useEffect(
    () => meta && reset(mapDataToForm(getValue, lang?.code)),
    [meta, lang?.code, reset]
  );

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getSeoPath.fullPath());
  };

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      metatagService.update({
        _id: meta._id,
        locale: lang?.code,
        isDefault,
        ...formData,
      }),
    {
      onSuccess: () => {
        toast.success(`Successfully updated meta`);
        getRoute();
      },
    }
  );

  const onSubmit = (formData) => mutate(formData);

  if (isFetching) return <Spinner />;

  return (
    <div>
      <Breadcrumb goBack={() => getRoute()} title={`Edit ${langName}`} />
      <div className="row ">
        {isError || fetchingError ? (
          <Alert variant="danger">
            {' '}
            {error?.response?.data?.message || fetchingError}{' '}
          </Alert>
        ) : null}
        <div className="card col-12">
          <div className="card-body col-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Accordion defaultActiveKey="1" className="w-100">
                  <AccordionBody label="Meta Data" eventKey="1">
                    <Input
                      label="Title"
                      col={12}
                      placeholder="Motors"
                      name={`${lang?.code}.title`}
                      register={register}
                    />
                    <Input
                      label="Description"
                      col={12}
                      placeholder="Motors"
                      name={`${lang?.code}.description`}
                      register={register}
                    />
                    {/* <Input
                      label="Keywords"
                      col={12}
                      placeholder="motors, cars"
                      name={`${lang.code}.keywords`}
                      register={register}
                    /> */}
                    <Input
                      label="Alt"
                      col={12}
                      placeholder="motors, cars"
                      name={`${lang?.code}.alt`}
                      register={register}
                    />
                  </AccordionBody>
                </Accordion>
              </Form.Row>
              <SubmitButton isLoading={isSubmitting} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
