import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../../../common/requestError';

const AdminHint = ({ value }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('hint_admin', value);
  }, [value]);

  return (
    <>
      <Grid item xs={12} lg={4} className="mt-3">
        <Form.Group controlId="hint_admin" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Admin Hint *</Form.Label>
          <Form.Control
            type={'text'}
            label="hint_admin"
            {...register('hint_admin', {
              required: 'hint_admin is required',
            })}
            name="hint_admin"
          />
        </Form.Group>

        {errors?.hint_admin && (
          <RequestErrorMessage message={` Please enter the admin hint.`} />
        )}
      </Grid>
    </>
  );
};

export default AdminHint;
