import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { throttle } from 'lodash';

import customStyles from './customStyles';
import { useQuery } from 'react-query';
import { userService } from '../../../../services';

const User = ({ value }) => {
  const [query, setQuery] = React.useState('');
  const [currentUser, setCurrentUser] = React.useState([]);

  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { data: user, refetch } = useQuery(
    ['users', value],
    () => userService.suggestions(query),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const users = user?.data?.data || [];

  const fetch = React.useMemo(
    () =>
      throttle(() => {
        refetch();
      }, 500),
    []
  );

  const handleAutoCompleteFieldChange = (data) => {
    setQuery(data);
    fetch();
  };

  const userOptions =
    React.useMemo(() => {
      if (users.length > 0) {
        const options = [];
        users.forEach((user) => {
          const data = {};
          data.label = user.name;
          data.value = user.name;
          data.id = user._id;
          options.push(data);
        });
        return options;
      }
    }, [users]) || [];

  React.useEffect(() => {
    if (value) {
      const usr = {
        label: value?.email,
        value: value?.name,
        id: value?._id,
      };
      setValue('user', usr);
      setCurrentUser(usr);
    }
  }, [value]);

  return (
    <div className="col-sm-4">
      <Form.Label className="font-weight-bold">User *</Form.Label>
      <Controller
        control={control}
        name={`user`}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onChange={(data) => {
              onChange(data);
            }}
            placeholder={`Search a user`}
            options={userOptions}
            styles={customStyles}
            inputref={{
              ...register(`user`, {
                required: true,
              }),
            }}
            onInputChange={handleAutoCompleteFieldChange}
            defaultValue={currentUser}
          />
        )}
      />
    </div>
  );
};

export default User;
