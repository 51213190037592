import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const configuration = {
  height: 500,
  toolbar: ['bold', 'italic', 'bulletedList', 'undo', 'redo'],
};

function Description() {
  const [editorLoaded, setEditorLoaded] = useState(false);

  const editorRef = React.useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditorLoaded(true);
  }, []);

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const caption = `Detail description about the business`;

  if (editorLoaded) {
    return (
      <div id="description" className="bg-light p-3">
        <Form.Group className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Description *</Form.Label>

          <Controller
            control={control}
            name={`description`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CKEditor
                editor={ClassicEditor}
                config={configuration}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  const newValue =
                    data.length > 600 ? data.substr(0, 595) : data;
                  if (data) {
                    onChange(newValue);
                  } else {
                    onChange(null);
                  }
                }}
                value={value}
              />
            )}
          />
        </Form.Group>

        <Form.Text>{caption}</Form.Text>

        {errors?.description && (
          <RequestErrorMessage
            message={`Please enter your business description.`}
          />
        )}

        <Form.Group controlId="keywords" className="mt-4 mb-0">
          <Form.Label className="font-weight-bold">
            Tags Or Keywords (Comma Separated){' '}
          </Form.Label>

          <Form.Control
            style={{
              background: '#fff',
              minHeight: '40px',
              borderRadius: '4px',
            }}
            as="textarea"
            rows={2}
            label="keywords"
            placeholder="Enter tags or keywords comma separated..."
            {...register('keywords')}
            name="keywords"
          />
        </Form.Group>
      </div>
    );
  }
}

export default Description;
