// * eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { compact } from 'lodash';
import { getFlag } from '../../utils/countryFlag';
export default function CustomAutoComplete({
  name,
  value,
  setValue,
  options,
  ...rest
}) {
  const values = rest?.multiple
    ? compact(options.map((d) => value?.includes(d._id) && d))
    : options?.find((option) => option?._id === value);

  return (
    <Autocomplete
      {...rest}
      id="country-select-demo"
      options={
        rest?.multiple
          ? options?.filter(
              (option) =>
                !rest?.value?.map((d) => d?.name).includes(option?.name)
            )
          : options
      }
      size="small"
      autoHighlight
      value={values || {}}
      getOptionLabel={(option) => option?.name || ''}
      onChange={(event, newValue) => {
        setValue(
          name,
          rest?.multiple ? newValue.map((d) => d._id) : newValue?._id
        );
      }}
      renderOption={(option) => (
        <React.Fragment>
          <span>{getFlag(option?.code)}</span>
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={rest?.error}
          name={name}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}
