import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const Meta = ({ name, label }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Form.Group controlId={name} className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">{label}</Form.Label>
          <Form.Control
            type={'text'}
            label={label}
            {...register(name)}
            name={name}
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default Meta;
