import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import customStyles from './customStyles';

const Type = (props) => {
  const { parentCategory, parent_id } = props;
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const typeOptions = [
    { value: 'classified', label: 'Classified' },
    { value: 'real_state', label: 'Real State' },
    { value: 'posting_job', label: 'Posting Job' },
    { value: 'searching_job', label: 'Searching Job' },
    { value: 'vehicle', label: 'Vehicle' },
  ];

  let options = typeOptions;

  if (parent_id) {
    options = typeOptions.filter((o) => o.value === parentCategory?.type);
  }

  React.useEffect(() => {
    setValue('type', options[0]);
  }, []);

  return (
    <>
      <Grid item xs={12} lg={2} className="mt-3">
        <Form.Group controlId="type" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Type</Form.Label>
          <Controller
            control={control}
            name={`type`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Selec Type`}
                options={options}
                styles={customStyles}
                inputref={{
                  ...register(`type`),
                }}
              />
            )}
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default Type;
