import http from './httpService';
const settingEndpoint = '/files';

class FileService {
  fileUpload = (images) => http.post(`${settingEndpoint}/upload`, images);

  uploadFileAndApplyWatermark(data) {
    return http.post('/files/upload-watermark', data);
  }

  deleteFile = (images) => http.post(`${settingEndpoint}/delete`, images);
}

export default new FileService();
