import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';


const CurrencyField = (props) => {

  const [inputValues, setValues] = useState('');
  const [validation, setValidation] = useState('');
  const {
    reff,
    initialValue,
    validationError,
    userData,
    name = 'price',
  } = props;

  useEffect(() => {
    setValues(initialValue);
  }, []);

  useEffect(() => {
    setValidation(validationError);
  }, [validationError]);

  const handleOnChange = (value, key) => {
    if (validation && validation[key]) {
      setValidation('');
    }

    // const clonedValues = { ...inputValues };
    // clonedValues[key] = value;
    setValues(value);
  };
  return (
    <>
      <CurrencyInput
        id={name}
        name={name}
        value={inputValues}
        placeholder="price"
        amount={inputValues}
        className={`${
          validation && validation[name]
            ? 'form-control is-invalid'
            : 'form-control'
        }`}
        decimalsLimit={2}
        ref={reff}
        prefix={userData?.country?.currency_symbol || '$'}
        onValueChange={(value, name) => handleOnChange(value, name)}
      />
      {validation && validation[name] ? (
        <p className="invalid-feedback">{validation[name]}</p>
      ) : (
        ''
      )}
    </>
  );
};

CurrencyField.defaultProps = {};

export default CurrencyField;
