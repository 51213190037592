import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { useQuery, useMutation } from 'react-query';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import Table from '../common/table';
import { userService } from '../../services';
import Filter from './filter';
import qs from 'query-string';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { getFlag } from '../../utils/countryFlag';
import useStickyState from '../../hooks/useStickyState';

import CustomChip from '../common/chip';

const navigators = [
  { label: 'Employee', value: 'employee', icon: <SelectAllIcon /> },
  { label: 'User', value: 'user', icon: <SelectAllIcon /> },
];

function Users() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const [navigatorValue, setNavigatorValue] = useStickyState('user', 'userTab');

  const [query, setQuery] = useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  const {
    data,
    isError,
    isLoading: isFetching,
    error,
    refetch,
  } = useQuery(
    ['users'],
    () =>
      userService.paginate({ ...query, type: navigatorValue, parent_id: null }),
    { refetchOnWindowFocus: false, enabled: false }
  );
  React.useEffect(() => {
    if (query) refetch();
  }, [query, navigatorValue]);

  const users = data?.data?.data;

  const { mutate } = useMutation(
    (formData) => userService.delete(formData.original._id),
    {
      onSuccess: () => setQuery({ ...query, refetch: Date.now() }),
    }
  );

  const { mutate: mutateUser } = useMutation(
    (formData) => userService.update(formData._id, formData.data),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const onEdit = (row) => navigate('/users/edit/' + row._id);

  const onDelete = (row) => {
    if (window.confirm('Are you sure you wish to delete this item?'))
      mutate(row);
  };

  const handlePhoneVerification = (row) => {
    if (!row.phone?.length > 0)
      return toast.error('No phone associated with this user');
    mutateUser({
      _id: row._id,
      data: { 'phone.verifiedAt': row?.phone?.verifiedAt ? null : new Date() },
    });
  };

  const handleEmailVerification = (row) => {
    mutateUser({
      _id: row._id,
      data: { emailVerified: !row.emailVerified },
    });
  };

  // const isPhoneNumberVerified = async (user) => {
  //   if (user?.phone?.length > 0) {
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Table
        title="Users"
        filterable
        setNavigatorValue={setNavigatorValue}
        navigatorValue={navigatorValue}
        navigators={navigators}
        query={query}
        setQuery={setQuery}
        open={open}
        onOpen={() => setOpen((prev) => !prev)}
        data={users || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => navigate('/users/new')}
        columns={[
          {
            label: 'Date',
            path: 'createdAt',
            content: (row) => (
              <>
                <p>{moment(row.createdAt).format('ll')}</p>
                <p>{moment(row.createdAt).format('LT')}</p>
              </>
            ),
          },
          {
            label: 'Country',
            content: (row) => (
              <Tooltip title={row?.country?.name} placement="bottom">
                <p> {getFlag(row?.country?.code)} </p>
              </Tooltip>
            ),
          },
          {
            label: 'Name',
            path: 'name',
          },

          {
            label: 'Email',
            path: 'email',
          },

          {
            label: 'Status',
            content: (row) => <CustomChip row={row} alignLeft />,
          },
          {
            label: 'Verified Email ',
            path: 'status',
            content: (row) => (
              <>
                <Switch
                  defaultValue={!!row.emailVerified}
                  checked={row.emailVerified}
                  onChange={() => handleEmailVerification(row)}
                  name="checked"
                  color={row.emailVerified ? 'secondary' : 'warning'}
                />
              </>
            ),
          },

          {
            label: 'Verified Phone',
            path: 'status',
            content: (row) => (
              <>
                <p>{row?.phone?.length > 0 ? 'Vefied' : 'Not Verified'}</p>
              </>
            ),
          },

          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <>
                <button
                  className="btn btn-md btn-light mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="icon-pencil text-primary"></i>
                </button>
              </>
            ),
          },
        ]}
      >
        <Filter query={query} open={open} setQuery={setQuery} />
      </Table>
    </>
  );
}

export default Users;
