import React from 'react';
import { useLocation } from 'react-router-dom';

function Error404() {
  let location = useLocation();
  return (
    <div>
      <div className="d-flex align-items-center text-center error-page pt-5 pb-4 h-100">
        <div className="row flex-grow">
          <h4>
            No match for <code>{location.pathname}</code>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Error404;
