import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function DashboardMarketing() {
  const user = useSelector((state) => state.auth.currentUser);

  return (
    <div>
      <Helmet>
        <title>Dashboard - Adjeem</title>
      </Helmet>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-6 mb-4 mb-xl-0">
              <h3>Hi, {user.name}</h3>
            </div>
            <div className="col-sm-6">
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="border-right-dark pr-4 mb-3 mb-xl-0 d-xl-block d-none">
                  <h6 className="font-weight-medium text-dark mb-0">
                    {new Date().toLocaleString() + ''}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content tab-transparent-content pb-0">
            <div
              className="tab-pane fade show active"
              id="overview"
              role="tabpanel"
              aria-labelledby="overview-tab"
            >
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-between">
                        <h4 className="card-title">Sales</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardMarketing;
