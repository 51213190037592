import React from 'react';
import { Form } from 'react-bootstrap';

const TextField = ({
  validation,
  field,
  input,
  handleOnFieldTextChange,
  defaultValue,
}) => {
  return (
    <Form.Group className="mt-3" controlId="text" id={field._id}>
      <Form.Label>
        {field.name} {field.mandatory ? '*' : ''}{' '}
      </Form.Label>
      <Form.Control
        defaultValue={defaultValue}
        className={`${
          validation.errors && validation.errors[field._id] ? 'is-invalid' : ''
        }`}
        type="text"
        placeholder=""
        ref={input}
        onChange={(e) => handleOnFieldTextChange(field._id, e)}
        name={field.slug}
      />

      {validation.errors && validation.errors[field._id] && (
        <p className="invalid-feedback">{validation.errors[field._id]}</p>
      )}
    </Form.Group>
  );
};

export default TextField;
