import pick from 'lodash/pick';
export const sanitizePostData = (data, city) => {
  const obj = {
    title: data?.title,
    description: data?.description,
    category: data?.category,
    price: data?.price,
    user: data?.user,
    fields: data?.fields,
    city: pick(city, ['_id', 'slug']),
    country: data?.country,
    formatted_address: data?.location?.address?.formatted_address,
    images: data?.images,
    locale: data?.locale,
    thumbnail: data?.images[0],
    location: data?.location?.address?.latLng,
  };

  if (obj?.category?.parent?.detail) delete obj?.category?.parent?.detail;
  if (obj?.category?.child?.detail) delete obj?.category?.child?.detail;
  if (!obj.location) delete obj.location;

  return obj;
};

export const mapResToPost = (data, fields) => {
  let selected = {};
  const result = data?.fields?.reduce((acc, curr) => {
    const field = fields?.find((f) => f._id === curr._id);
    if (field?.type === 'select_multiple') {
      selected = {
        ...selected,
        [curr._id]: curr.option.map((o) => {
          const label = field?.options?.find(
            (option) => option._id === o._id
          )?.value;
          return {
            label,
            value: o._id,
          };
        }),
      };
    } else {
      acc.push({ ...curr, option: curr.option[0] || {} });
    }
    return acc;
  }, []);

  return {
    title: data?.title,
    description: data?.description,
    price: data?.price,
    city: data?.city?._id,
    country: data?.country,
    user: data?.user,
    category: data?.category,
    location: {
      latLng: {
        lat: data?.location?.coordinates ? data?.location?.coordinates[1] : 1,
        lng: data?.location?.coordinates ? data?.location?.coordinates[0] : 1,
      },
      formatted_address: data?.formatted_address,
    },
    fields: result,
    selected,
  };
};
