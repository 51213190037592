import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useMutation, useQueries } from 'react-query';
import BlockIcon from '@material-ui/icons/Block';
import Chip from '@material-ui/core/Chip';
import qs from 'query-string';

import { categoryService, fieldService } from '../../../../services';
import AddField from './Add';
import { getSanitizedData } from '../../../../utils/sanitizer';
import Table from '../../../common/table';

function CategoryFields() {
  const params = useParams();
  const location = useLocation();
  const [cat, setCat] = React.useState({ docs: [], totalDocs: 0 });

  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  const results = useQueries([
    {
      queryKey: ['categories', query],
      queryFn: () =>
        categoryService.fields({ _id: params.categoryId, ...query }),
      refetchOnWindowFocus: false,
      enabled: !!query,
    },
    {
      queryKey: 'fieldService',
      queryFn: () => fieldService.all(),
      refetchOnWindowFocus: false,
    },
  ]);

  const { mutate, error, isError } = useMutation(
    (row) => {
      const rowIds = row?.map((r) => r._id);
      categoryService.deleteMultipleFields(params.categoryId, {
        field_ids: rowIds,
      });
    },
    {
      onSuccess: () => {
        toast.success('Successfully deleted !');
        setTimeout(() => {
          setQuery({ ...query, at: Date.now() });
        }, 500);
      },
    }
  );

  const { mutate: disableMutation } = useMutation(
    (row) => {
      const rowIds = row?.map((r) => r._id);
      categoryService.disableMultipleFields(params.categoryId, {
        field_ids: rowIds,
      });
    },
    {
      onSuccess: () => {
        toast.success('Successfully disabled/enabled fields');
        setTimeout(() => {
          setQuery({ ...query, at: Date.now() });
        }, 500);
      },
    }
  );

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };

  const {
    d1: category,
    d2: fields,
    isFetching,
    error: err,
  } = getSanitizedData(results);

  React.useEffect(() => {
    setCat(category);
  }, [category]);

  const { mutate: updateIndex } = useMutation(
    (row) => categoryService.updateFieldIndex(params.categoryId, row),
    {
      onSuccess: (data) => {
        setCat({
          docs: data?.data?.data?.docs,
          totalDocs: data?.data?.data?.totalDocs,
        });
      },
    }
  );

  const onDragEnd = (data) => {
    const category = [...cat.docs];
    category.splice(data.destination, 0, category.splice(data.source, 1)[0]);
    setCat({ docs: category, totalDocs: cat.totalDocs });
    updateIndex(data);
  };
  const handleCustomButtonClick = (data) => {
    disableMutation(data);
  };

  const categoryName = qs.parse(location.search);
  return (
    <>
      {isError || err ? (
        <Alert variant="info"> {error?.response?.data?.message || err} </Alert>
      ) : null}
      <AddField
        field_id={1}
        onAdded={() => setQuery({ ...query, at: Date.now() })}
        category_id={params.categoryId}
        fields={fields?.filter(
          (f) => !cat?.docs?.map((d) => d._id).includes(f._id)
        )}
      />

      <Table
        title={`${categoryName.name} → Fields`}
        query={query}
        setQuery={setQuery}
        data={cat || {}}
        customButtonLabel="Disable"
        onCustomButtonClick={handleCustomButtonClick}
        multiple
        onToolbarAction={onDelete}
        isFetching={isFetching}
        addDisable={true}
        isDragDisabled={false}
        onDragEnd={onDragEnd}
        columns={[
          {
            label: 'Name',
            path: 'name',
            content: (row) => <p>{`${row?.name}  → ${row?.hint_admin}`}</p>,
          },
          {
            label: 'Disable Fields',
            name: 'is_disabled',
            content: (row) => (
              <>
                {row.is_disabled ? (
                  <Chip
                    color="secondary"
                    label="Disabled"
                    size="small"
                    icon={<BlockIcon />}
                  />
                ) : (
                  ''
                )}
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default CategoryFields;
