import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import pick from 'lodash/pick';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomAutoComplete from '../common/autocomplete';
import FilterForm from './filterForm';

import { buildQueryParam } from '../../utils/queryParams';
import { getQueryParam } from '../../utils/queryParams';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: '#6d6d6d',
    margin: '5px 10px 0px 0px',
    fortWeight: 700,
  },
  gridContainer: {
    margin: '10px',
    paddingBottom: '20px',
    width: 500,
  },
  grid: {
    marginTop: 10,
  },
  datePickerGrid: {
    //display: 'flex',
    display: 'none',
  },
  arrowIcon: {
    margin: '10px 12px 0px 12px',
  },
  applyBtn: {
    marginTop: 15,
    color: '#fff',
    background: '#0f414a !important',
    borderRadius: '5px',
    textTransform: 'none',
  },
  cancelBtn: {
    margin: '15px 5px 0px 0px',
    color: '#fff',
    background: '#44573c !important',
    borderRadius: '5px',
    textTransform: 'none',
  },
  btnContainer: {
    marginLeft: 'auto',
  },
}));
const statusOptions = [
  { name: 'Deleted', _id: 'deleted' },
  { name: 'Active', _id: 'active' },
  { name: 'Blocked', _id: 'blocked' },
  { name: 'Pending', _id: 'pending' },
  { name: 'Disabled', _id: 'disabled' },
];
const defaultStatus = ['active', 'pending'];
const defaultState = {
  country: null,
  status: defaultStatus,
};
export default function Filter({ query, setQuery }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const countriesWithCities = useSelector(
    (state) => state.country.countryWithcities
  );
  const [filter, setFilter] = React.useState(defaultState);

  const onSubmit = (event) => {
    event.preventDefault();

    const queryObj = {
      ...query,
      country: filter?.country,
      status: filter?.status || ['pending', 'active'],
    };
    setQuery(queryObj);
    navigate({
      path: '/ads',
      search: buildQueryParam(queryObj),
    });
  };

  const { country, status } = getQueryParam(search, [
    'limit',
    'page',
    'country',
    'status',
  ]);

  const obj = {
    country,
    status: !!status ? status?.split(',')?.map((s) => s) : defaultStatus,
  };
  // eslint-disable-next-line
  React.useEffect(() => setFilter({ ...filter, ...obj }), []);

  const handleClearFilter = () => {
    setFilter(defaultState);
    navigate({
      path: '/banners',
      search: `?limit=10&page=1&sorted:createdAt:desc`,
    });
  };
  const handleSetFilter = (name, value) => {
    setFilter({ ...filter, [name]: value });
  };

  const countries = countriesWithCities
    ? Object.values(countriesWithCities)?.map((country) =>
        pick(country, ['_id', 'name', 'code'])
      )
    : [];

  return (
    <FilterForm
      onSubmit={onSubmit}
      onSetFilter={handleSetFilter}
      onClearFilter={handleClearFilter}
      filter={filter}
    >
      <Grid xs={3} item className={classes.grid}>
        <Typography className={classes.subtitle}>Country </Typography>
      </Grid>
      <Grid xs={9} item className={classes.grid}>
        <CustomAutoComplete
          value={filter?.country}
          setValue={handleSetFilter}
          name="country"
          options={countries || []}
        />
      </Grid>

      <Grid xs={3} item className={classes.grid}>
        <Typography className={classes.subtitle}>Status </Typography>
      </Grid>
      <Grid xs={9} item className={classes.grid}>
        <CustomAutoComplete
          multiple
          name="status"
          value={filter?.status}
          setValue={handleSetFilter}
          options={statusOptions}
        />
      </Grid>
    </FilterForm>
  );
}
