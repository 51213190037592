import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

// import Form from '@material-ui/core/Form'

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { paymentService } from '../../../services';
const refundOptions = [
  { label: 'Select a reason', value: '' },
  { label: 'Duplicate', value: 'duplicate' },
  { value: 'fraudulent', label: 'Fraudulent' },
  { value: 'requested_by_customer', label: 'Requested by customer' },
];

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    display: 'block',
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

const ExtendedDialogBox = ({ row, open, setOpen, refundSuccess }) => {
  const classes = useStyles();

  const { register, handleSubmit, errors, watch } = useForm();
  // eslint-disable-next-line
  const { mutate, error, isError, isLoading } = useMutation(
    (formData) =>
      paymentService.create({
        ...formData,
        payment_intent: row.payment_intent,
        amount: row.amount,
      }),
    {
      onSuccess: () => {
        refundSuccess();
      },
    }
  );

  const onSubmit = (formData) => setOpen(); //mutate(formData)
  const reason = watch('reason');

  return (
    <Dialog onClose={setOpen} open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="simple-dialog-title">
          Refund 1 payment
          <br />
          <Typography variant="subtitle2">
            Refunds take 5-10 days to appear on a customer's statement.
          </Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl} size="small">
            <Select
              native
              error={errors?.reason}
              variant="outlined"
              name="reason"
              inputRef={register('reason', { required: true })}
            >
              {refundOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          {reason && (
            <Box>
              <Typography>And more detail....</Typography>
              <TextField
                fullWidth
                size="small"
                multiline
                variant="outlined"
                name="metadata.description"
                inputRef={register('metadata.description')}
              />
            </Box>
          )}
          {isError && (
            <Alert severity="error">{error?.response?.data?.message}</Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={setOpen}
            disabled={isLoading}
            color="secondary"
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            size="small"
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
ExtendedDialogBox.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
};
export default ExtendedDialogBox;
