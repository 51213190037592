
import React from 'react';
import Grid from '@material-ui/core/Grid'

function Upload({ onFileSelect, classes, image }) {
    const [preview, setPreview] = React.useState(null)

    const handleChange = (event) => {
        const img = event.target.files[0]
        onFileSelect(img)
        setPreview(URL.createObjectURL(img))
    }
    return (
        <Grid container>
            <Grid item xs={6}>
                <input type="file" onChange={handleChange} accept="image/*" />
            </Grid>
            {(preview || image) ? <Grid item xs={6}>
                <img src={preview || image} className={classes?.img} alt="" />
            </Grid> : ""}
        </Grid>
    );
}

export default Upload;