import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 999,
    marginLeft: '15px',
    filter: 'drop-shadow(-1px -1px 2px #8e939c)',
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-1.8em',
      height: '4em',
      width: '1em',
      '&::before': {
        borderWidth: '1.5em 1.9em 1.5em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1.5em 0 1.9em 1.5em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    marginTop: 5,
    width: '3em',
    height: '5em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  container: {
    width: '700px',
    padding: '10px',
  },

  header: {
    fontSize: '25px',
    color: '#87c6ed',
  },
  starIcon: {
    fill: '#8f8f22',
    marginRight: '4px',
    cursor: 'pointer',
  },
}));
