import cookie from 'js-cookie';
import http from './httpService';

const authEndpoint = '/admin';

class AuthService {
  login = (userInfo) => http.post(`${authEndpoint}/login`, userInfo);

  authenticate = (user, next) => {
    this.setCookie('token', user.token);
    this.setLocalStorage('user', {
      email: user.email,
      name: user.name,
      token: user?.token,
      roles: user?.roles,
      avatar: user.avatar,
      refreshToken: user.refreshToken,
    });
    next();
  };

  isAuth = () => {
    const cookieChecked = this.getCookie('token');
    if (cookieChecked) {
      if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
      } else {
        return false;
      }
    }
  };

  removeCookie = (key) => cookie.remove(key, { expires: 1 });

  getCookie = (key) => cookie.get(key);

  setLocalStorage = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value));

  removeLocalStorage = (key) => localStorage.removeItem(key);

  setCookie = (key, value) => cookie.set(key, value, { expires: 1 });

  logout = () => {
    localStorage.removeItem('user');
    cookie.remove('token');
  };

  register = (username, email, password) =>
    http.post(`${authEndpoint}/signup`, { username, email, password });

  getCurrentUser = () => JSON.parse(localStorage.getItem('user'));
}

export default new AuthService();
