import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { Alert, Dropdown } from 'react-bootstrap';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import Table from '../../common/table';
import CountryService from '../../../services/country.service';
import { getCityBasedOnCountry } from '../../../config/path';

function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const languages = useSelector((state) => state.language.languages);

  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  const { data, isLoading: isFetching } = useQuery(
    ['countries', query],
    () => CountryService.paginate(query),
    { refetchOnWindowFocus: false, enabled: !!query }
  );

  const { mutate, error, isError } = useMutation(
    (row) => CountryService.delete(row._id),
    {
      onSuccess: () => {
        toast.success('Successfully deleted !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const onDelete = async (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };
  const onEdit = (row) => navigate('/settings/countries/' + row._id);

  const countries = data?.data?.data;

  const onLanguageSpecificEdit = (row, language) => {
    navigate(
      `/settings/countries/${row._id}/${language._id}?name=${language.name}`
    );
  };

  return (
    <>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}

      <Table
        title="Countries"
        query={query}
        setQuery={setQuery}
        data={countries || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => navigate('/settings/countries/new')}
        columns={[
          {
            label: 'Name',
            path: 'name',
          },
          {
            label: 'Code',
            path: 'code',
          },
          {
            label: 'Order',
            path: 'order',
          },
          {
            label: 'Status',
            path: 'status',
          },
          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <>
                <div className="d-flex flex-wrap">
                  <Dropdown className="mr-1">
                    <Dropdown.Toggle
                      variant="btn btn-secondary"
                      id="dropdownMenuButton1"
                    >
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          navigate(
                            getCityBasedOnCountry(row._id, {
                              country: row.name,
                            }).fullPath()
                          )
                        }
                      >
                        Cities{' '}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="mr-1">
                    <Dropdown.Toggle className="ml-2" variant="btn btn-primary">
                      Edit
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {languages?.map((language) =>
                        language.code !== 'en' ? (
                          <Dropdown.Item
                            onClick={() =>
                              onLanguageSpecificEdit(row, language)
                            }
                            key={language._id}
                          >
                            Edit - {language.name}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              navigate(`/settings/countries/${row._id}`)
                            }
                            key={language._id}
                          >
                            Edit
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default Index;
