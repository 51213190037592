import React from 'react';
import { Card } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';

import GoogleMap from '../GoogleMap';
import SelectField from '../Fields/SelectField.component';

const LocationDetail = (props) => {
  const {
    userData,
    location,
    setLocation,
    validationError,
    handleOnFieldTextChange,
    inputValues,
    city,
  } = props;
  return (
    <div className="mt-4">
      <h4 className="mb-3">City</h4>

      {/* {userData.country.cities && userData.country.cities.length && (
        <SelectField
          name={'city'}
          fieldId={'city'}
          cityList={userData.country.cities}
          label="City"
          handleOnFieldTextChange={handleOnFieldTextChange}
          selectedId={inputValues?.city}
        />
      )} */}
      <TextField
        name={'name'}
        size="small"
        fullWidth
        variant="outlined"
        disabled
        label="City"
        value={city?.name}
      />

      <GoogleMap
        location={location}
        validation={validationError}
        setLocation={(data) => {
          setLocation((prev) => ({ ...prev, location: data }));
        }}
      />
    </div>
  );
};

LocationDetail.defaultProps = {};

export default LocationDetail;
