import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const PriceRange = ({ defaultValue }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="price_range mb-3 mt-4 bg-light p-3">
        <div className="row">
          <div className="col-md-4">
            <Form.Group controlId="price.range" className="mt-3 mb-0">
              <Form.Label className="font-weight-bold">Price Range</Form.Label>
              <Form.Control
                as="select"
                {...register('price.range')}
                style={{
                  background: '#fff',
                  minHeight: '40px',
                  borderRadius: '4px',
                }}
                name="price.range"
                label="price.range"
                defaultValue={defaultValue?.range}
              >
                <option value="not_to_say">Not to say</option>
                <option value="inexpensive"> $ - Inexpensive</option>
                <option value="moderate"> $$ - Moderate</option>
                <option value="pricey"> $$$ - Pricey</option>
                <option value="ultra_high"> $$$$ - Ultra High</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group controlId="price.from" className="mt-3 mb-0">
              <Form.Label>Price From</Form.Label>
              <Form.Control
                style={{
                  background: '#fff',
                  minHeight: '40px',
                  borderRadius: '4px',
                }}
                type={'text'}
                label="price.from"
                {...register('price.from')}
                name="price.from"
                defaultValue={defaultValue?.from}
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group controlId="price.to" className="mt-3 mb-0">
              <Form.Label>Price To</Form.Label>
              <Form.Control
                style={{
                  background: '#fff',
                  minHeight: '40px',
                  borderRadius: '4px',
                }}
                type={'text'}
                label="price.to"
                {...register('price.to')}
                name="price.to"
                defaultValue={defaultValue?.to}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceRange;
