import qs from 'query-string';
export const ads = {
  path: '/ads',
  param(page = 1, limit = 10, sorted = 'createdAt:desc', search = '') {
    return {
      pathname: this.path,
      search: `page=${page}&limit=${limit}&sorted=${sorted}&search=${search}`,
    };
  },
};

/**
 *
 * @param {string} path  route name
 * @returns  path and a
 */
export const buildGenericPath = (path, ...props) => ({
  path,
  param(page = 1, limit = 10, sorted = 'createdAt:desc', search = '') {
    return {
      pathname: this.path,
      search: qs.stringify({ page, limit, sorted, search, ...props[0] }),
    };
  },
  fullPath() {
    return '/' + this.param().pathname + '?' + this.param().search;
  },
});

//category
export const getCategoryPath = buildGenericPath('categories');
export const getSubCategoryPath = (parentCatId, props) =>
  buildGenericPath(`categories/${parentCatId}/subs`, props);

export const getFieldsBasedOnCategory = (categoryId, props) =>
  buildGenericPath(`categories/${categoryId}/fields`, props);

//fileds
export const getFieldPath = buildGenericPath('fields');

//country
export const getCountryPath = buildGenericPath('settings/countries');
//city

// export cin=
export const getCityBasedOnCountry = (countryId, props) =>
  buildGenericPath(`settings/cities/${countryId}`, props);

export const getOptionsBasedOnFields = (fieldId, props) =>
  buildGenericPath(`fields/${fieldId}/options`, props);

//language
export const getLanguagePath = buildGenericPath('settings/languages');

//seo
export const getSeoPath = buildGenericPath('settings/seo');

//settings
export const getSettingsPath = buildGenericPath('settings/default');

//plans
export const getPlansPath = buildGenericPath('subscriptions/plans');

//payments
export const getPaymentsPath = buildGenericPath('subscriptions/payments');

//banners
export const getBannerPath = buildGenericPath('banners');

//banners_mobile
export const getMobileBannerPath = buildGenericPath('mobile_banners');

//users/list
export const getUsersPath = buildGenericPath('users/list');

//Listings/businesses
export const getBusinessesPath = buildGenericPath('businesses/list');

//users/opinions
export const getOptionionsPath = buildGenericPath('users/opinions');

export const getLogPath = buildGenericPath('log');
//banners
// export const getBannerPath=buildGenericPath('banners')

//users/role
export const getRolesPath = buildGenericPath('users/role');
