import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Alert, Card } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';

import { SubmitButton } from '../../common/button';
import Breadcrumb from '../../common/breadcrumb';
import { countryService } from '../../../services';
import { fileService } from '../../../services';
import Spinner from '../../common/spinner';
import { getCountryPath } from '../../../config/path';
import customStyles from './customStyles';

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'deleted', label: 'Deleted' },
];

const verificationOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const useStyles = makeStyles((theme) => ({
  img: {
    height: 60,
    width: 60,
    marginTop: -20,
    borderRadius: '50%',
  },
  imagContainer: {
    paddingLeft: '40px',
  },
}));

export default function Country() {
  const navigate = useNavigate();
  const params = useParams();
  const [files, setFiles] = React.useState(null);

  const classes = useStyles();
  const [preview, setPreview] = React.useState(null);

  const handleChange = (file) => {
    setPreview(URL.createObjectURL(file));
    setFiles(file);
  };

  const { register, handleSubmit, setValue, control, reset, errors } = useForm({
    defaultValues: {
      verification_required: verificationOptions[0],
      status: statusOptions[0],
      default_language_id: '602a0648e217e700190ee53f',
    },
  });

  const { data, isLoading } = useQuery(
    ['country'],
    () => countryService.getItem(params.id),
    { refetchOnWindowFocus: false }
  );

  const country = data?.data?.data;

  React.useEffect(() => {
    if (country) {
      reset({
        ...country,
        verification_required: country?.verification_required
          ? verificationOptions[0]
          : verificationOptions[1],
      });
      const result = statusOptions.find((obj) => {
        return obj.value === country.status;
      });
      setValue('status', result);
      setPreview(country?.image);
    }
  }, [country, reset]);

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getCountryPath.fullPath());
  };

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      params.id === 'new'
        ? countryService.create({ ...country, ...formData })
        : countryService.update({ ...country, ...formData, _id: params.id }),
    {
      onSuccess: () => {
        toast.success(
          `Successfully ${
            params.id === 'new' ? 'added new' : 'updated'
          } countries`
        );
        getRoute();
      },
    }
  );

  const onSubmit = async (formData) => {
    const data = {
      ...formData,
      verification_required:
        formData.verification_required.value === 'yes' ? true : false,
      status: formData?.status?.value,
    };

    let formic = new FormData();
    if (files) {
      formic.append(`files`, files);
      const { data: res } = await fileService.fileUpload(formic);
      const image = res?.data?.image;
      mutate({ ...data, image });
    } else {
      mutate(data);
    }
  };

  React.useEffect(() => {
    reset({});
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <Breadcrumb
        goBack={() => getRoute()}
        title={params.id !== 'new' ? 'Edit' : 'Create'}
      />
      <Card className="p-5">
        {isError && (
          <Alert variant="danger"> {error?.response?.data?.message} </Alert>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-2">
              <Form.Group controlId="name" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Name *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="name"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  name="name"
                  placeholder="UAE"
                />
              </Form.Group>
            </div>

            <div className="col-sm-1">
              <Form.Group controlId="currency_code" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">
                  Currency Code *
                </Form.Label>
                <Form.Control
                  type={'text'}
                  label="currency_code"
                  {...register('currency_code', {
                    required: 'Currency code is required',
                  })}
                  name="currency_code"
                  placeholder="AED"
                />
              </Form.Group>
            </div>

            <div className="col-sm-2">
              <Form.Group controlId="currency_symbol" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">
                  Currency Symbol *
                </Form.Label>
                <Form.Control
                  type={'text'}
                  label="currency_symbol"
                  {...register('currency_symbol', {
                    required: 'Currency symbol is required',
                  })}
                  name="currency_symbol"
                  placeholder="$ or AED"
                />
              </Form.Group>
            </div>

            <div className="col-sm-1">
              <Form.Group controlId="code" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Code *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="code"
                  {...register('code', {
                    required: 'Code is required',
                  })}
                  name="code"
                  placeholder="uae"
                />
              </Form.Group>
            </div>

            <div className="col-sm-2">
              <Form.Group controlId="calling_code" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">
                  Calling code *
                </Form.Label>
                <Form.Control
                  type={'text'}
                  label="calling_code"
                  {...register('calling_code', {
                    required: 'Calling code is required',
                  })}
                  name="calling_code"
                  placeholder="977"
                />
              </Form.Group>
            </div>

            <div className="col-sm-1">
              <Form.Group controlId="order" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Order *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="order"
                  {...register('order', {
                    required: 'Order is required',
                  })}
                  name="order"
                  placeholder="1"
                />
              </Form.Group>
            </div>

            <div className="col-sm-1">
              <Form.Group controlId="flag" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">
                  Flag Name *
                </Form.Label>
                <Form.Control
                  type={'text'}
                  label="flag"
                  {...register('flag', {
                    required: 'Flag name is required',
                  })}
                  name="flag"
                  placeholder="uae"
                />
              </Form.Group>
            </div>

            <div className="col-sm-2">
              <Form.Group controlId="status" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Status</Form.Label>
                <Controller
                  control={control}
                  name={`status`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      placeholder={`Selec status`}
                      options={statusOptions}
                      styles={customStyles}
                      inputref={{
                        ...register(`status`),
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>

            <div className="col-sm-2">
              <Form.Group
                controlId="verification_required"
                className="mt-3 mb-0"
              >
                <Form.Label className="font-weight-bold">
                  Verification Required?
                </Form.Label>
                <Controller
                  control={control}
                  name={`verification_required`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      placeholder={`Selec option`}
                      options={verificationOptions}
                      styles={customStyles}
                      inputref={{
                        ...register(`verification_required`),
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-4">
              <Form.Group controlId="image" className="mb-3">
                <Form.Label className="font-weight-bold">Image</Form.Label>
                <Controller
                  name="image"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control
                      type="file"
                      onChange={(data) => {
                        onChange(data.target?.files[0]);
                        handleChange(data.target?.files[0]);
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>
            <div className="col-sm-4 mt-3">
              {preview && <img src={preview} className={classes?.img} />}
            </div>
          </div>

          <SubmitButton isLoading={isSubmitting} />
        </Form>
      </Card>
    </div>
  );
}
