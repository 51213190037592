import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import styles from '../edit.module.scss';
import RequestErrorMessage from '../../partials/requestError';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 40,
  }),
  group: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menu: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    paddingLeft: 20,
    backgroundColor: state.isSelected ? '#e1f2fb' : 'white',
    color: state.isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)',
    cursor: 'pointer',
  }),
};

const handleHeaderClick = (id) => {
  const node = document.querySelector(`#${id}`).parentElement
    .nextElementSibling;

  const classes = node.classList;

  if (classes.contains('collapsed_cat')) {
    node.classList.remove('collapsed_cat');
    document.getElementById(id).classList.add('ad_show_subcategories');
    document.getElementById(id).classList.remove('ad_hide_subcategories');
  } else {
    node.classList.add('collapsed_cat');
    document.getElementById(id).classList.remove('ad_show_subcategories');
    document.getElementById(id).classList.add('ad_hide_subcategories');
  }
};

function SelectCategory(props) {
  const { categories, currentItem } = props;

  const [currentValue, setCurrentValue] = useState(null);
  const [currentCategory, setCurrentCategory] = React.useState(null);

  const CustomGroupHeading = (props) => {
    useEffect(() => {
      if (!currentValue) {
        handleHeaderClick(props.id);
      }
    }, []);
    return (
      <div
        className={styles.group_heading_wrapper}
        onClick={() => {
          handleHeaderClick(props.id);
        }}
      >
        <label id={props.id} className="d-block p-2 ad_show_subcategories">
          <span>
            {props.data.icon && (
              <img
                src={props.data.icon}
                alt={props.data.label}
                className="mr-2"
              />
            )}

            {props.data.label}
          </span>
          <i className="icon-right float-right">&rarr;</i>
          <i className="icon-down float-right">&darr;</i>
        </label>
      </div>
    );
  };

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const group = [];

  if (categories && categories?.length > 0) {
    categories.forEach((category) => {
      const data = {};
      data.label = category.name;
      data.id = category._id;
      data.slug = category.slug;
      data.icon = category?.images?.icon;

      const childs = category?.children;

      const options = [];

      if (childs?.length > 0) {
        childs.forEach((child) => {
          const childData = {
            parent_id: category._id,
            parent_name: category.name,
            parent_slug: category.slug,
            label: child.name,
            name: child.name,
            value: child._id,
            slug: child.slug,
            id: child._id,
            media_type: child?.media_type,
            template_type: child?.type,
          };
          options.push(childData);
        });
      }

      data.options = options;

      group.push(data);

      if (
        currentItem?.category?.parent?._id == category._id &&
        !currentCategory
      ) {
        setCurrentCategory({
          ...data.options[0],
          label:
            data?.options[0]?.parent_name + ' » ' + data?.options[0]?.label,
        });
      }
    });
  }

  if (!currentCategory) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" variant="secondary" />
      </div>
    );
  }

  return (
    <div>
      <Form.Group className="mb-0 mt-4">
        <Form.Label className="font-weight-bold">
          Business Industry *
        </Form.Label>
        <Controller
          control={control}
          name={`category`}
          defaultValue={currentCategory}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder={
                <h2
                  className="m-0 p-2"
                  style={{
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#b8b8b8',
                  }}
                >
                  Select business industry *
                </h2>
              }
              onChange={(data) => {
                const inputData = {
                  ...data,
                  label: data.parent_name + ' » ' + data.label,
                };
                onChange(inputData);
                setCurrentValue(inputData);
              }}
              inputref={{
                ...register(`category`, {
                  required: true,
                }),
              }}
              // menuIsOpen={true}
              options={group}
              components={{
                GroupHeading: CustomGroupHeading,
              }}
              styles={customStyles}
              value={value}
            />
          )}
        />
      </Form.Group>

      {errors.category && errors['category'] && (
        <RequestErrorMessage message={`Please select your industry`} />
      )}
    </div>
  );
}

export default SelectCategory;
