import http from './httpService';
const settingEndpoint = '/admin/settings';

class SettingService {
  create = (data) => http.post(`${settingEndpoint}/create`, data);

  get = () => http.get(`${settingEndpoint}/all`);

  update = (data) => http.put(`${settingEndpoint}/update/${data._id}`, data);

  delete = (data) => http.delete(`${settingEndpoint}/delete/${data._id}`);

  getFieldsWithModel = () => http.get(`${settingEndpoint}/fields-with-model`);
}

export default new SettingService();
