export const bannerType = [
    { id: 'homepage_carousel_banner', name: "HomePage Carousel Banner" },
    { id: 'homepage_top_banner', name: "HomePage Top Banner" },
    { id: 'homepage_bottom_banner', name: "Homepage Bottom Banner" },
    { id: 'category_top_banner', name: "Category Page Top Banner" },
    { id: 'category_middle_banner', name: "Category Page Middle Banner" },
    { id: 'category_bottom_banner', name: "Category Bottom banner" },
    { id: 'post_page_top_banner', name: "Post Page Top Banner" },
    { id: 'post_page_bottom_banner', name: "Post Page Bottom Banner" },
]
export const categoryBanner = ['category_top_banner', 'category_bottom_banner', 'category_middle_banner']