import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';
import queryString from 'query-string';
import Breadcrumb from '../../../common/breadcrumb';
import MuiAccordion from '../../../common/muiAccordion';
import { categoryService, fileService } from '../../../../services';
import Name from './fields/nameField';
import MenuText from './fields/menuTextField';
import Order from './fields/orderField';
import Type from './fields/typeField';
import Status from './fields/statusField';
import IsMenuItem from './fields/isMenuItemField';
import IsPopularItem from './fields/isPopularItemField';
import IsFeaturedItem from './fields/isFeaturedItemField';
import IsLatestItem from './fields/isLatestItemField';
import IsIndustry from './fields/isIndustryField';
import MediaType from './fields/mediaTypeField';
import Description from './fields/descriptionField';
import Meta from './fields/metaField';
import Upload from './fields/upload';
import { useMutation, useQueries } from 'react-query';
import { Spinner } from 'react-bootstrap';
import SubType from './fields/subtypeField';
import TextareaField from './fields/textareaField';

function EditCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const parent_id = query.get('parent_id');
  const parentName = query.get('parent_name')?.replace('%20', ' ');
  const categoryName = query.get('name')?.replace('%20', ' ');
  const catName = query.get('cat_name')?.replace('%20', ' ');
  const lang = query.get('lang');

  let [{ data: category, isLoading, error }] = useQueries([
    {
      queryKey: ['category', params.id],
      queryFn: () => categoryService.getItem(params.id),
    },
  ]);

  const currentItem = category?.data?.data;

  let [{ data: parentCategory, isLoading: ploading, error: pError }] =
    useQueries([
      {
        queryKey: ['category', parent_id],
        queryFn: () => categoryService.getItem(parent_id),
        enabled: !!parent_id,
      },
    ]);

  parentCategory = parentCategory?.data?.data;
  const type = parentCategory?.type;

  const onSubmit = async (formData) => {
    if (formData) {
      const [icon, thumbnail] = await Promise.all([
        uploadImage(formData.images.icon),
        uploadImage(formData.images.thumbnail),
      ]);
      mutate({
        ...formData,
        images: {
          icon: icon,
          thumbnail: thumbnail,
        },
      });
    } else {
      mutate(formData);
    }
  };

  const uploadImage = async (files) => {
    if (files) {
      try {
        setLoading(true);
        let form = new FormData();
        form.append(`files`, files);
        const { data } = await fileService.fileUpload(form);
        const img = data?.data?.image;
        form.delete(`files`);
        setLoading(false);
        return img;
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const update = async (formData) => {
    const data = TransformCategoryData({ formData, id: params?.id });

    await categoryService.update(data);
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => update(formData), {
    onSuccess: () => {
      toast.success(`Successfully updated category`);
      navigate(
        `/categories/${
          queryString.parse(location.search)?.parent_id
        }/subs?limit=10&name=${parentCategory.name}&page=1`
      );
    },
  });

  const getTitle = () => {
    if (parentName) {
      return `${parentName} → Edit: ${currentItem?.name}`;
    } else {
      return `Edit: ${currentItem?.name}`;
    }
  };

  if (!currentItem) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb
        goBack={() =>
          navigate(
            `/categories/${parentCategory._id}/subs?name=${parentCategory.name}`
          )
        }
        title={getTitle()}
      />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name value={currentItem?.name} />
            <MenuText value={currentItem?.menu_text} />
            <Order value={currentItem?.order} />

            <Type
              value={currentItem?.type}
              parentCategory={parentCategory}
              parent_id={parent_id}
            />
            {parent_id && type === 'real_state' && (
              <SubType value={currentItem?.sub_type} />
            )}

            <IsMenuItem value={currentItem?.is_menu_item} />
            <IsPopularItem value={currentItem?.is_popular_item} />
            <IsFeaturedItem value={currentItem?.is_featured} />
            <IsLatestItem value={currentItem?.is_latest_item} />
            <IsIndustry value={currentItem?.is_industry_category} />
            <MediaType value={currentItem?.media_type} />
            <Description value={currentItem?.description} />
            <TextareaField
              name="short_description"
              label="Short Description"
              value={currentItem?.short_description}
            />
            <Status value={currentItem?.status} />
          </MuiAccordion>
          <MuiAccordion title="Default Meta (For {country} only)">
            <Meta
              name="meta.default.title"
              label="Title"
              value={currentItem?.meta?.default?.title}
            />
            <Meta
              name="meta.default.description"
              value={currentItem?.meta?.default?.description}
              label="Description"
            />
            <Meta
              name="meta.default.keywords"
              value={currentItem?.meta?.default?.keywords}
              label="Keywords"
            />
          </MuiAccordion>

          <MuiAccordion title="Meta  (For {country} & {City} )">
            <Meta
              name="meta.title"
              label="Title"
              value={currentItem?.meta?.title}
            />
            <Meta
              name="meta.description"
              label="Description"
              value={currentItem?.meta?.description}
            />
            <Meta
              name="meta.keywords"
              label="Keywords"
              value={currentItem?.meta?.keywords}
            />
          </MuiAccordion>

          <MuiAccordion title="Media">
            <Upload
              name="images.icon"
              label="Icon Image"
              value={currentItem?.images?.icon}
              existingName="existing_icon"
            />
            <Upload
              name="images.thumbnail"
              label="Thumbnail Image"
              value={currentItem?.images?.thumbnail}
              existingName="existing_thumbnail"
            />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid || loading}
            >
              Update
              {(isSubmitting || loading) && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default EditCategory;

export const TransformCategoryData = ({ formData, id }) => {
  const responseData = {
    ...formData,
    _id: id,
    type: formData.type.value,
    media_type: formData.media_type.value,
    images: {
      icon: formData?.images?.icon
        ? formData?.images?.icon
        : formData?.existing_icon,
      thumbnail: formData?.images?.thumbnail
        ? formData?.images?.thumbnail
        : formData?.existing_thumbnail,
    },

    is_menu_item: formData.is_menu_item.value,
    is_popular_item: formData.is_popular_item.value,
    is_featured: formData.is_featured.value,
    is_latest_item: formData.is_latest_item.value,
    is_industry_category: formData.is_industry_category.value,
    status: formData.status.value,
  };
  return responseData;
};
