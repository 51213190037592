import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterForm from '../../common/filterForm';
import { buildQueryParam, getQueryParam } from '../../../utils/queryParams';

const Filter = ({ query, setQuery }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [filter, setFilter] = React.useState({ form: null, to: null });

  const handleSetFilter = (name, value) => {
    setFilter({ ...filter, [name]: value });
  };
  const { from, to } = getQueryParam(search, ['from', 'to']);

  const handleClearFilter = () => {
    setFilter({ form: null, to: null });
    navigate({
      path: '/subscriptions/payments',
      search: `?limit=10&page=1&sorted:createdAt:desc`,
    });
  };
  // eslint-disable-next-line
  React.useEffect(() => setFilter({ to, from }), []);

  const onSubmit = (event) => {
    event.preventDefault();
    const queryObj = {
      ...query,
      from: filter?.from || null,
      to: filter?.to || null,
    };
    setQuery(queryObj);
    navigate({
      path: '/ads',
      search: buildQueryParam(queryObj),
    });
  };
  return (
    <div>
      <FilterForm
        onSubmit={onSubmit}
        onSetFilter={handleSetFilter}
        onClearFilter={handleClearFilter}
        filter={filter}
      ></FilterForm>
    </div>
  );
};

export default Filter;
