import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';

import styles from '../add.module.scss';

const SendEmail = (props) => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
  ];

  return (
    <>
      <Form.Group controlId="title" className="mt-4 mb-0">
        <Form.Label className="font-weight-bold">
          Email to business owner
        </Form.Label>

        <Controller
          control={control}
          name={`send_email`}
          render={({ field: { onChange } }) => (
            <Form.Check
              className={`${styles.send_email_checkbox}`}
              type={`switch`}
              label={`Send email?`}
              id="send-email"
              onChange={(data) => {
                onChange(data);
              }}
              {...register('send_email')}
            />
          )}
        />
      </Form.Group>
    </>
  );
};

export default SendEmail;
