import React from 'react';
import { Dropdown } from 'react-bootstrap';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function Dashboard() {
  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const settings4 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const user = useSelector((state) => state.auth.currentUser);

  return (
    <div>
      <Helmet>
        <title>Dashboard - Adjeem</title>
      </Helmet>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-6 mb-4 mb-xl-0">
              <h3>Hi, {user.name} 🙏 | 😊</h3>
            </div>
            <div className="col-sm-6">
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="border-right-dark pr-4 mb-3 mb-xl-0 d-xl-block d-none">
                  <h6 className="font-weight-medium text-dark mb-0">
                    {new Date().toLocaleString() + ''}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="page-header-tab mt-xl-4">
            <div className="col-12 pl-0 pr-0">
              <div className="row ">
                <div className="col-12 col-sm-6 mb-xs-4  pt-2 pb-2 mb-xl-0">
                  <ul className="nav nav-tabs tab-transparent" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="overview-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="overview"
                        aria-selected="true"
                      >
                        Overview
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="users-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="users"
                        aria-selected="false"
                      >
                        Users
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="returns-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="returns"
                        aria-selected="false"
                      >
                        Returns
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="more-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="more"
                        aria-selected="false"
                      >
                        More
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-content tab-transparent-content pb-0">
            <div
              className="tab-pane fade show active"
              id="overview"
              role="tabpanel"
              aria-labelledby="overview-tab"
            >
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-between">
                        <h4 className="card-title">Sales</h4>
                        <Dropdown alignRight>
                          <Dropdown.Toggle className="icon-dropdown">
                            <i className="mdi mdi-dots-vertical card-menu-btn"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="preview-list navbar-dropdown">
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Action</p>
                            </Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Another action</p>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Something else here</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Slider
                        {...settings1}
                        className="dashboard-widget-carousel"
                      >
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-success">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-success">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-success">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-between">
                        <h4 className="card-title">Purchases</h4>
                        <Dropdown alignRight>
                          <Dropdown.Toggle className="icon-dropdown">
                            <i className="mdi mdi-dots-vertical card-menu-btn"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="preview-list navbar-dropdown">
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Action</p>
                            </Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Another action</p>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Something else here</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Slider
                        {...settings2}
                        className="dashboard-widget-carousel"
                      >
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 37632</h2>
                            <h3 className="text-danger">-2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-danger">-2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-danger">-2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-between">
                        <h4 className="card-title">Returns</h4>
                        <Dropdown alignRight>
                          <Dropdown.Toggle className="icon-dropdown">
                            <i className="mdi mdi-dots-vertical card-menu-btn"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="preview-list navbar-dropdown">
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Action</p>
                            </Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Another action</p>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Something else here</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Slider
                        {...settings3}
                        className="dashboard-widget-carousel"
                      >
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 47632</h2>
                            <h3 className="text-success">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-success">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-success">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-between">
                        <h4 className="card-title">Marketing</h4>
                        <Dropdown alignRight>
                          <Dropdown.Toggle className="icon-dropdown">
                            <i className="mdi mdi-dots-vertical card-menu-btn"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="preview-list navbar-dropdown">
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Action</p>
                            </Dropdown.Item>
                            <div className="dropdown-divider"></div>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Another action</p>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="dropdown-item preview-item"
                              onClick={(evt) => evt.preventDefault()}
                            >
                              <p>Something else here</p>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Slider
                        {...settings4}
                        className="dashboard-widget-carousel"
                      >
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 57632</h2>
                            <h3 className="text-danger">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-danger">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                        <div>
                          <div className="d-flex flex-wrap align-items-baseline">
                            <h2 className="mr-3">$ 27632</h2>
                            <h3 className="text-danger">+2.3%</h3>
                          </div>
                          <div className="mb-3">
                            <p className="text-muted font-weight-bold text-small">
                              North Ludwig{' '}
                              <span className=" font-weight-normal">
                                ($2643M last month)
                              </span>
                            </p>
                          </div>
                          <button className="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i className="mdi mdi-calendar mr-1"></i>
                            <span className="text-left">Oct</span>
                          </button>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="users"
              role="tabpanel"
              aria-labelledby="users-tab"
            >
              Tab Item
            </div>
            <div
              className="tab-pane fade"
              id="returns-1"
              role="tabpanel"
              aria-labelledby="returns-tab"
            >
              Tab Item
            </div>
            <div
              className="tab-pane fade"
              id="more"
              role="tabpanel"
              aria-labelledby="more-tab"
            >
              Tab Item
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
