import React from 'react';
import { Form, Modal, Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Select from '../../../common/select';
import Input from '../../../common/input';

const CreatePlanPrice = ({ show, onHide, countries, addPrice, data }) => {
  const { handleSubmit, register, reset, errors } = useForm();

  React.useEffect(() => {
    if (countries?.length > 0) reset({ country_id: countries[0]?._id });
  }, [data, countries, reset]);

  React.useEffect(() => reset(data), [data, reset]);

  const onSubmit = (formData) => addPrice(formData);
  // console.log("countries are", countries)
  return (
    <div>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {countries?.length === 0 ? (
            'No countries available to add Price'
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card.Body className="pl-1 pr-2">
                <Form.Row>
                  <Select
                    required
                    col={12}
                    errors={errors}
                    label="Country"
                    name="country_id"
                    options={countries}
                    register={register}
                  />
                  <Input
                    col={12}
                    type="number"
                    step="any"
                    required
                    errors={errors}
                    label="Price($)"
                    name="price"
                    placeholder="100"
                    register={register}
                  />
                  <Input
                    col={12}
                    type="number"
                    step="any"
                    required
                    errors={errors}
                    label="Exchange Rate(USD to selected country currency)"
                    name="rate"
                    placeholder="2.5 | USD to AED"
                    register={register}
                  />
                </Form.Row>
              </Card.Body>
              <Button type="submit">Submit</Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreatePlanPrice;
