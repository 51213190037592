import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQueries, useQuery } from 'react-query';
import { Alert, Card, Form, Spinner } from 'react-bootstrap';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { compact } from 'lodash';

import Name from './fields/nameField';
import Link from './fields/linkField';
import Breadcrumb from '../../common/breadcrumb';
import Location from './fields/locationField';
import User from './fields/userField';
import Category from './fields/categoryField';
import { MuiButtonGroup } from '../../common/mui';
import { useStyles } from '../styles';
import {
  bannerService,
  fileService,
  planService,
  promotionService,
} from '../../../services';
import CustomizedSelect from '../../common/muiSelect';
import Upload from '../upload';
import { categoryBanner } from '../../../utils/banner';
import { getBannerPath } from '../../../config/path';

const state = {
  title: '',
  link: '',
  category: {
    parent: '',
    subcategory: '',
  },
  position: [],
  location: {
    city: '',
    country: '',
  },
  user_id: '',
};

const validate = (ads, checked) => {
  const err = [];
  //eslint-disable-next-line
  const re = ads?.position?.map((plan) => {
    if (!plan?.files && !plan?.image) err.push(plan.plan);
  });
  if (checked.length === 0) err.push('position');
  return err.length > 0 ? err : [];
};

function EditBanner() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [paymentMode, setPaymentMode] = React.useState('offline');
  const [ads, setAds] = React.useState(state);
  const [errors, setErrors] = React.useState([]);
  const [checked, setChecked] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);

  const params = useParams();

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  let [{ data: resBanner, isFetching: isBannerFetching }] = useQueries([
    {
      queryKey: ['banner', params.id],
      queryFn: () => bannerService.getBannerById(params.id),
      refetchOnWindowFocus: false,
    },
  ]);

  const banner = resBanner?.data?.data;

  React.useEffect(() => {
    setPaymentMode(banner?.payment_mode);
  }, [banner]);

  const { data: p, isFetching } = useQuery(
    ['bannerPlans'],
    planService.getBannerPlans,
    {
      refetchOnWindowFocus: false,
    }
  );

  const plans = p?.data?.data || [];

  const onDaySelect = (val, _id) => {
    let currState = { ...ads };

    const index = ads.position.map((p) => p.plan).indexOf(_id);

    if (index === -1) {
      currState.position.push({ plan: _id, day: val });
    } else {
      currState.position[index].day = val;
      currState.position[index].plan = _id;
    }
    setAds(currState);
  };

  const handleFileSelect = (file, plan) => {
    if (file) {
      let img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (
          img.width >= plan.imageResolution.width &&
          img.height >= plan.imageResolution.height
        ) {
          const adv = { ...ads };
          const index = adv.position.map((p) => p.plan).indexOf(plan._id);
          adv.position[index].files = file;
          setAds(adv);

          if (errors.length > 0) {
            const err = validate(adv, checked, isCategoryRequired);
            setErrors(err);
          }
        } else setErrors([...errors, plan._id]);
      };
      if (errors.length > 0) {
        const err = validate(ads, checked, isCategoryRequired);
        setErrors(err);
      }
    }
  };

  const totalCost = () => {
    const totalPrice = ads.position?.reduce((acc, red) => {
      const selectedPlan = plans?.find((p) => p?._id === red?.plan);
      const cost = selectedPlan?.durations.find((d) => d._id === red.day);
      const price = cost?.localePrice?.code
        ? cost?.localePrice?.price
        : cost?.standardPrice?.price;
      return acc + price;
    }, 0);
    if (totalPrice === 0) return { currency: '', price: 0 };
    const code = plans[0]?.durations[0]?.localePrice?.code;
    const standardCode = plans[0]?.durations[0]?.standardPrice?.code;

    return code
      ? { currency: code, price: totalPrice }
      : { currency: standardCode, price: totalPrice };
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newPlans = [...ads.position];
    if (currentIndex === -1) {
      newChecked.push(value);
      newPlans.push({
        plan: value._id,
        name: value.banner_type,
        day: value?.durations[0]?._id,
      });
    } else {
      newChecked.splice(currentIndex, 1);
      const index = newPlans.map((d) => d?.plan).indexOf(value?.plan);
      newPlans.splice(index, 1);
    }
    setChecked(newChecked);
    setAds({ ...ads, position: newPlans });

    if (errors.length > 0) {
      const err = validate(
        { ...ads, position: newPlans },
        newChecked,
        isCategoryRequired
      );
      setErrors(err);
    }
  };

  const isCategoryRequired = checked?.some((c) =>
    categoryBanner.includes(c.banner_type)
  )
    ? 1
    : 0;

  const {
    mutate,
    isError: isBannerError,
    isLoading: isBannerLoading,
    error: bannerError,
    isSuccess,
  } = useMutation((formData) => bannerService.update(formData), {
    onSuccess: (data) => {
      navigate(getBannerPath.fullPath());
    },
  });

  React.useEffect(() => {
    if (banner) {
      const result = banner.position.reduce(
        (acc, red) => {
          const plan = plans.find((p) => red.plan.plan_id === p._id);
          if (plan) acc['checked'] = [...acc['checked'], plan];
          acc['position'] = [
            ...acc['position'],
            {
              plan: red.plan?.plan_id,
              image: red.image,
              day: plan?.durations?.find((d) => red.plan.days === d.days)?._id,
            },
          ];
          return acc;
        },
        { position: [], checked: [] }
      );
      setAds({
        ...banner,
        position: result.position,
      });

      if (result?.checked?.length > 0) {
        setChecked(result.checked);
      }
    }
  }, [banner, plans]);

  const onSubmit = async (formData) => {
    if (isSuccess) return;
    const err = validate(ads, checked);

    if (err.length > 0) {
      return setErrors(err);
    }

    setSubmitting(true);

    const images = await Promise.all(
      ads.position.map(async (p) => {
        let formic = new FormData();

        if (!formic.has('files') && p?.files) {
          formic.append(`files`, p.files);
          const { data } = await fileService.fileUpload(formic);
          formic.delete('files');
          return { img: data?.data?.image || p?.image, plan: p.plan };
        } else return { img: p?.image, plan: p.plan };
      })
    );

    const selectedPlan = plans.reduce((acc, red) => {
      const pl = ads.position.map((plan, idx) => {
        if (plan.plan === red._id) {
          const dur = red.durations.find((d) => d._id === plan.day);
          const index = compact(images)
            .map((i) => i?.plan)
            .indexOf(plan?.plan);
          return {
            image: images[index].img,
            name: red.name,
            plan: {
              plan_id: red._id,
              days: dur.days,
              price: {
                amount: dur?.localePrice?.code
                  ? dur?.localePrice?.price
                  : dur?.standardPrice?.price,
                currency: dur?.localePrice?.code
                  ? dur?.localePrice?.code
                  : dur?.standardPrice?.code,
              },
            },
          };
        }
      });
      return [...acc, ...compact(pl)];
    }, []);

    const sanitizedData = {
      position: selectedPlan,
      title: formData.name,
      link: formData.link,
      user_id: formData?.user?.id,

      location: {
        city: {
          _id: formData?.location?.city?.id,
        },
        country: {
          _id: formData?.location?.country?.id,
          code: formData?.location?.country?.code,
        },
      },
      payment_mode: paymentMode,
      _id: params.id,
    };

    if (isCategoryRequired) {
      sanitizedData.category = {
        parent: {
          _id: formData?.category?.parent?.id,
          slug: formData?.category?.parent?.slug,
        },
        child: {
          _id: formData?.category?.subcategory?.id,
          slug: formData?.category?.subcategory?.slug,
        },
      };
    }

    mutate(sanitizedData);
    setSubmitting(false);
  };

  const getErrorMsg = (e) => e?.response?.data?.message;
  const hasError = (key) => errors.indexOf(key) !== -1;
  const isError = isBannerError;
  const error = getErrorMsg(bannerError);
  const isLoading = isBannerLoading || submitting;

  if (isFetching || isBannerFetching) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb goBack={() => navigate('/banners')} title={`Edit Banner`} />

      {isError && <Alert severity="error">{error}</Alert>}

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card className="p-3">
          <div className="row">
            <div className="col-sm-6">
              <Name value={banner?.title} />
            </div>
            <div className="col-sm-6">
              <Link value={banner?.link} />
            </div>
          </div>

          <div className="row mt-4">
            <Location value={banner?.location} />
            <User value={banner?.user_id} />
          </div>

          <div className="row mt-4">
            <MuiButtonGroup
              label="Payment Mode"
              setPaymentMode={setPaymentMode}
              classes={classes}
              paymentMode={paymentMode}
              option1="offline"
              option2="online"
            />
          </div>

          <div className="row mt-4">
            <div className="col-sm-12">
              <Form.Label className="font-weight-bold">Select Plan</Form.Label>
              <List dense>
                {plans?.map((plan) => {
                  const options =
                    plan?.durations?.reduce((acc = [], red) => {
                      acc.push({ value: red._id, label: red.days });
                      return acc;
                    }, []) || [];

                  const defaultPlan = ads?.position.find(
                    (p) => p.plan === plan._id
                  );
                  const defaultDate = defaultPlan
                    ? defaultPlan.day
                    : plan.durations[0]._id;
                  const index = plan.durations
                    .map((d) => d._id)
                    .indexOf(defaultPlan?.day);

                  const price =
                    index !== -1
                      ? plan.durations[index]?.localePrice?.code
                        ? `${plan.durations[index]?.localePrice?.code} ${plan.durations[index]?.localePrice?.price}`
                        : `${plan.durations[index]?.standardPrice?.code} ${plan.durations[index]?.standardPrice?.price}`
                      : plan?.durations[0]?.localePrice?.code
                      ? `${plan?.durations[0]?.localePrice?.code} ${plan?.durations[0]?.localePrice?.price}`
                      : `${plan?.durations[0]?.standardPrice?.code} ${plan?.durations[0]?.standardPrice?.price}`;
                  const isChecked = checked
                    .map((c) => c._id)
                    .includes(plan._id);
                  return (
                    <>
                      <ListItem key={plan._id + index}>
                        <Grid container>
                          <Grid item xs={10}>
                            <ListItemIcon>
                              <Checkbox
                                disabled={
                                  plan?.durations?.length === 0 ||
                                  !plan?.durations
                                }
                                edge="end"
                                onChange={handleToggle(plan)}
                                checked={isChecked}
                              />
                            </ListItemIcon>

                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.title}
                              color="textPrimary"
                            >
                              {plan?.name}
                            </Typography>

                            <Typography
                              variant="subtitle2"
                              className={classes.description}
                            >
                              {plan.description}
                            </Typography>
                          </Grid>
                          <Grid xs={2} item>
                            <CustomizedSelect
                              options={options}
                              value={defaultDate}
                              disabled={!isChecked}
                              onValueChange={(val) =>
                                onDaySelect(val, plan?._id)
                              }
                              name="days"
                              extraLabel="days"
                            />
                            {` — ${price}`}
                          </Grid>

                          {isChecked ? (
                            <>
                              <Typography className={classes.label}>
                                Upload Image
                              </Typography>
                              <Upload
                                file={defaultPlan?.files}
                                image={defaultPlan?.image}
                                onFileSelect={(f) => handleFileSelect(f, plan)}
                                classes={classes}
                              />
                              {hasError(`${plan._id}`) && (
                                <span className="text-danger">
                                  Resolution should be greater than{' '}
                                  {plan.imageResolution.width}px length and{' '}
                                  {plan.imageResolution.height}px height
                                </span>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </ListItem>
                      <Divider className={classes.divider} />
                    </>
                  );
                })}

                <Typography variant="h5" className={classes.totalPrice}>
                  {plans?.length > 0 &&
                    `Total - ${totalCost().currency} ${totalCost().price}`}
                </Typography>
                {hasError('position') && (
                  <span className="text-danger">
                    {' '}
                    Please select at-least one position
                  </span>
                )}

                {!!isCategoryRequired && (
                  <>
                    <div className="row mt-4">
                      <Category checked={checked} value={banner?.category} />
                    </div>
                  </>
                )}
              </List>
            </div>
          </div>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isLoading || !isFormValid}
            >
              Submit
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </Card>
      </form>
    </FormProvider>
  );
}

export default EditBanner;
