import React, { useState } from 'react';
import { Alert, Dropdown, Form, Modal, Button, Spinner } from 'react-bootstrap';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { languageService, optionService } from '../../../../../services';
import AddExtra from './Add';
import EditExtra from './Edit';
import AddData from './AddData';

function Extras() {
  const params = useParams();
  const location = useLocation();

  const [message, setMessage] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [query, setQuery] = React.useState(null);
  const [extras, setExtras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading_data, setLoadingData] = useState(false);
  const [updateExtraModel, setUpdateExtraModel] = useState(false);
  const [showDataModel, setShowDataModel] = useState(false);
  const [addDataModel, setAddDataModel] = useState(false);
  const [editingExtra, setEditingExtra] = useState({
    id: '',
    name: '',
  });
  const [currentLanguage, setCurrentLanguage] = useState([]);
  const [currentExtra, setCurrentExtra] = useState([]);
  const [currentValues, setCurrentValues] = useState([]);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  React.useEffect(() => {
    fetchLanguages();
    fetchData();
  }, []);

  const fetchLanguages = () => {
    languageService
      .all({ en: false })
      .then((response) => {
        setLanguages(response.data.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchData = () => {
    setLoading(true);
    const option_id = params.optionId;
    const field_id = params.fieldId;

    optionService
      .paginateExtras(field_id, option_id)
      .then((response) => {
        const data = response.data.data;
        setLoading(false);
        setExtras(data);
      })
      .catch((error) => {
        console.error('fsdfsd');
        setLoading(false);
      });
  };

  const onShowDataModel = (data) => {
    if (data.values.length) {
      setShowDataModel(true);
      setCurrentValues(data.values);
      setMessage('');
      setCurrentExtra(data);
    } else {
      setMessage('Data not available, Please add new data');
    }
  };

  const onExtraAdded = () => {
    window.location.reload();
  };

  const onExtraUpdated = (data) => {
    window.location.reload();
  };

  const onDeleteExtra = (data) => {
    const option_id = params.optionId;
    const field_id = params.fieldId;
    const extra_id = data._id;

    const item = {
      field_id,
      option_id,
      extra_id,
    };

    if (window.confirm('Are you sure?')) {
      setLoading(true);

      optionService
        .deleteExtra(item)
        .then((response) => {
          const data = response.data.data;
          setMessage(data.message);
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          console.log('Error: ' + error);
          setLoading(false);
        });
    }
  };

  const onEditExtraModel = (data, language) => {
    setUpdateExtraModel(true);
    setCurrentExtra(data);
    setCurrentLanguage(language);
  };

  const onAddDataModel = (data) => {
    setAddDataModel(true);
    setCurrentExtra(data);
  };

  const onDataAdded = () => {
    window.location.reload();
  };

  const onDeleteExtraData = (data) => {
    const option_id = params.optionId;
    const field_id = params.fieldId;
    const extra_id = currentExtra._id;
    const value_id = data._id;

    const item = {
      field_id,
      option_id,
      extra_id,
      value_id,
    };

    if (window.confirm('Are you sure?')) {
      setLoading(true);
      optionService
        .deleteExtraData(item)
        .then((response) => {
          const data = response.data.data;
          setMessage(data.message);
          setLoading(false);
          window.location.reload();
        })
        .catch((error) => {
          console.log('Error: ' + error);
          setLoading(false);
        });
    }
  };

  const onUpdateExtraData = (data) => {
    const option_id = params.optionId;
    const field_id = params.fieldId;
    const extra_id = currentExtra._id;
    const value_id = data._id;

    const item = {
      field_id,
      option_id,
      extra_id,
      value_id,
    };

    setLoading(true);

    optionService
      .updateExtraData({ ...item, ...this.state.editingExtra })
      .then((response) => {
        const data = response.data.data;

        setMessage(data.message);
        setEditingExtra({
          _id: '',
          name: '',
        });
        setLoading(false);

        window.location.reload();
      })
      .catch((error) => {
        console.log('Error: ' + error);
        setLoading(false);
      });
  };

  const handleEditExtraData = (data) => {
    setEditingExtra({
      _id: data._id,
      name: data.name,
    });
  };

  const onCancelExtraDataEdit = () => {
    setEditingExtra({
      _id: '',
      name: '',
    });
  };

  const handleChangeExtraData = (event, id) => {
    setEditingExtra({
      _id: id,
      name: event.target.value,
    });
  };

  const handleFeatureExtra = (id, isFeature) => {
    //const cloned = { ...this.state };
    const index = extras.findIndex((i) => i._id === id);
    extras[index].is_disabled = !isFeature;
    //this.setState(cloned);
    optionService
      .updateExtra({
        name: extras[index].name,
        is_disabled: !isFeature,
        is_mandatory: !!extras[index]?.is_mandatory,
        option_id: params.optionId,
        field_id: params.fieldId,
        extra_id: id,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMandatroyExtra = (id, isMandatory) => {
    //const cloned = { ...this.state };
    const index = extras.findIndex((i) => i._id === id);
    extras[index].is_mandatory = !isMandatory;
    //this.setState(cloned);
    optionService
      .updateExtra({
        name: extras[index].name,
        is_mandatory: !isMandatory,
        is_disabled: !!extras[index]?.is_disabled,
        option_id: params.optionId,
        field_id: params.fieldId,
        extra_id: id,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">{query?.name} → Extra information</h3>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <AddExtra
                option_id={params?.optionId}
                field_id={params.fieldId}
                onAdded={() => onExtraAdded()}
              />
              {message ? <Alert variant="info"> {message} </Alert> : null}
              {loading_data ? (
                <p className="text-center m-3">Loading...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <tbody>
                      {extras.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                              {item.values.length ? (
                                <button
                                  className="badge badge-info mr-2"
                                  onClick={() => onShowDataModel(item)}
                                >
                                  Show
                                </button>
                              ) : (
                                ''
                              )}
                              <button
                                className="badge badge-info mr-2"
                                onClick={() => onAddDataModel(item)}
                              >
                                Add data
                              </button>
                              <button
                                className="badge badge-danger"
                                onClick={() => onDeleteExtra(item)}
                                disabled={loading}
                              >
                                Delete{' '}
                                {loading ? (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    variant="info"
                                  />
                                ) : null}
                              </button>
                            </td>
                            <td className="p-0 mt-0">
                              <FormControlLabel
                                value="top"
                                control={
                                  <Switch
                                    color="secondary"
                                    checked={item?.is_disabled}
                                    onChange={() =>
                                      handleFeatureExtra(
                                        item._id,
                                        item?.is_disabled
                                      )
                                    }
                                  />
                                }
                                label="Disabled"
                                labelPlacement="top"
                              />
                            </td>
                            <td className="p-0 mt-0">
                              <FormControlLabel
                                value="top"
                                control={
                                  <Switch
                                    color="secondary"
                                    checked={item?.is_mandatory}
                                    onChange={() =>
                                      handleMandatroyExtra(
                                        item._id,
                                        item?.is_mandatory
                                      )
                                    }
                                  />
                                }
                                label="Mandatory"
                                labelPlacement="top"
                              />
                            </td>
                            <td>
                              <Dropdown className="mr-1">
                                <Dropdown.Toggle
                                  variant="btn btn-primary"
                                  id="dropdownMenuButton1"
                                >
                                  Edit
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {languages
                                    ? languages.map((language) =>
                                        language.code !== 'en' ? (
                                          <Dropdown.Item
                                            onClick={() =>
                                              onEditExtraModel(item, language)
                                            }
                                            key={language._id}
                                          >
                                            {' '}
                                            Edit - {language.name}
                                          </Dropdown.Item>
                                        ) : (
                                          <Dropdown.Item
                                            onClick={() =>
                                              onEditExtraModel(item, language)
                                            }
                                            key={language._id}
                                          >
                                            {' '}
                                            Edit{' '}
                                          </Dropdown.Item>
                                        )
                                      )
                                    : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="showDataModel">
        <Modal show={showDataModel} onHide={() => setShowDataModel(false)}>
          <Modal.Header>
            <Modal.Title>Data</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  {currentValues.map((value, index) => {
                    const isDisable =
                      !editingExtra._id || editingExtra._id !== value._id;
                    return (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            className="p-0 pl-3"
                            name="value"
                            value={isDisable ? value.name : editingExtra.name}
                            disabled={isDisable}
                            onChange={(e) =>
                              handleChangeExtraData(e, value._id)
                            }
                          />
                        </td>
                        {!isDisable ? (
                          <td className="p-0">
                            <Button
                              className="badge badge-info m-3"
                              onClick={() => onUpdateExtraData(value)}
                              disabled={loading}
                            >
                              Update
                              {loading ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  variant="info"
                                />
                              ) : null}
                            </Button>

                            <Button
                              className="badge badge-danger"
                              onClick={() => onCancelExtraDataEdit()}
                              disabled={loading}
                            >
                              Cancel
                            </Button>
                          </td>
                        ) : (
                          <td className="p-0">
                            <Button
                              className="badge badge-info m-3"
                              onClick={() => handleEditExtraData(value)}
                              disabled={loading}
                            >
                              Edit
                            </Button>

                            <Button
                              className="badge badge-danger"
                              onClick={() => onDeleteExtraData(value)}
                              disabled={loading}
                            >
                              Delete{' '}
                              {loading ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  variant="info"
                                />
                              ) : null}
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            <Button variant="light m-2" onClick={() => setShowDataModel(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="addDataModel">
        <Modal show={addDataModel} onHide={() => setAddDataModel(false)}>
          <Modal.Header>
            <Modal.Title>Add Data</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AddData
              option_id={params.optionId}
              field_id={params.fieldId}
              extra_id={currentExtra._id}
              onAdded={onDataAdded}
            />
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            <Button variant="light m-2" onClick={() => setAddDataModel(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="updateExtraModel">
        <Modal
          show={updateExtraModel}
          onHide={() => setUpdateExtraModel(false)}
        >
          <Modal.Header>
            <Modal.Title>
              Edit {currentExtra.name} - {currentLanguage.code}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditExtra
              option_id={params.optionId}
              field_id={params.fieldId}
              extra={currentExtra}
              language={currentLanguage}
              onExtraUpdated={onExtraUpdated}
            />
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            <Button
              variant="light m-2"
              onClick={() => setUpdateExtraModel(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Extras;
