export const buildQueryParam = options => Object.keys(options).reduce((acc, red, index) => acc.concat(`${index === 0 ? '?' : '&'}${[red]}=${options[red] || ''}`), '')


export const getQueryParam = (search, fields = []) => {
    const query = new URLSearchParams(search);

    const queries = fields.reduce((acc, red) => {
        if (query.get(red)) { return { ...acc, [red]: query.get(red) } }
        return acc
    }, {})
    return queries

}