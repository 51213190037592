import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';

import { ReactComponent as StarIcon } from '../../../../assets/images/star.svg';
import { useStyles } from './styles';

function PromotionDetail({ rows }) {
  const anchorRef = useRef(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  const open = Boolean(anchorEl);
  return (
    <div>
      <StarIcon
        ref={anchorRef}
        className={classes.starIcon}
        onClick={handlePopoverOpen}
        onMouseOver={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popper
        id={rows._id}
        open={open}
        anchorEl={anchorRef.current}
        placement="right-start"
        disablePortal={true}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          applyStyle: {
            gpuAcceleration: false,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Paper className={classes.container}>
          <Typography className={classes.header}>Plan Detail</Typography>
          <Divider />
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Days</TableCell>
                  <TableCell align="center">Activated at</TableCell>
                  <TableCell align="center">Expiration Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.promotions?.map((row) => {
                  const isActivePlan = moment(row.activatedAt).isAfter(
                    moment().format('ll')
                  );
                  return (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>

                      <TableCell align="center">
                        {row.dirhamPrice.currency} {row.dirhamPrice.amount}
                      </TableCell>
                      <TableCell align="center">
                        {isActivePlan ? (
                          <Chip size="small" label="Active" clickable />
                        ) : (
                          <Chip
                            size="small"
                            label="Expired"
                            color="secondary"
                            clickable
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">{row.days}</TableCell>
                      <TableCell align="center">
                        <div>
                          <p>{moment(row.activatedAt).format('LT')}</p>
                          <p>{moment(row.activatedAt).format('ll')}</p>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {moment(
                          moment(row.activatedAt).add(row.days, 'days')
                        ).format('ll')}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Popper>
    </div>
  );
}
PromotionDetail.propTypes = {
  id: PropTypes.number,
  instructions: PropTypes.string.isRequired,
};

export default PromotionDetail;
