export const getSanitizedData = dataToBeSanitized => {

    const [{ data, isError, error, isLoading: loading1 }, { data: data2, isError: isError2, error: error2, isLoading: loading2 }] = dataToBeSanitized
    const d1 = data?.data?.data
    const d2 = data2?.data?.data
    const e1 = isError && error?.response?.data?.message
    const e2 = isError2 && error2?.response?.data?.message
    const isFetching = loading1 || loading2
    return { d1, d2, error: e1 ? e1 : e2 ? e2 : '', isFetching }
}

export const getQueryParams = (location, arg = []) => {
    const { search } = location()
    const params = new URLSearchParams(search);
    return arg.map(a => params.get(a))

}

