import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';

import Breadcrumb from '../../../common/breadcrumb';
import MuiAccordion from '../../../common/muiAccordion';
import { categoryService, languageService } from '../../../../services';
import Name from './fields/nameField';
import MenuText from './fields/menuTextField';
import Description from './fields/descriptionField';
import Meta from './fields/metaField';
import { useMutation, useQueries } from 'react-query';
import { Spinner } from 'react-bootstrap';
import { getSanitizedData } from '../../../../utils/sanitizer';

function EditLanguageSpecificCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const query = new URLSearchParams(location.search);

  const parent_id = query.get('parent_id');
  const catName = query.get('cat_name')?.replace('%20', ' ');

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const langName = query.get('lang');

  const result = useQueries([
    {
      queryKey: ['language', params.languageId],
      queryFn: () => languageService.getItem(params.language_id),
    },
    {
      queryKey: ['category', params.id],
      queryFn: () => categoryService.getItem(params.id),
    },
  ]);

  const {
    d1: language,
    d2: currentItem,
    error: fetchingError,
    isFetching,
  } = getSanitizedData(result);

  const onSubmit = async (formData) => {
    if (formData) {
      mutate(formData);
    }
  };

  const update = async (formData) => {
    const data = TransformCategoryData({ formData, id: params?.id, language });
    await categoryService.update(data);
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => update(formData), {
    onSuccess: () => {
      toast.success(`Successfully updated category`);
      // navigate('/categories');
      navigate(`/categories/${parent_id}/subs?limit=10&name=${catName}&page=1`);
    },
  });

  if (!currentItem) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb
        goBack={() => navigate('/categories')}
        title={`Edit: ${currentItem?.name} - ${langName}`}
      />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name value={currentItem?.[language?.code]?.name} />
            <MenuText value={currentItem?.[language?.code]?.menu_text} />
            <Description value={currentItem?.[language?.code]?.description} />
          </MuiAccordion>
          <MuiAccordion title="Default Meta (For {country} only)">
            <Meta
              name="meta.default.title"
              label="Title"
              value={currentItem?.[language?.code]?.meta?.default?.title}
            />
            <Meta
              name="meta.default.description"
              value={currentItem?.[language?.code]?.meta?.default?.description}
              label="Description"
            />
            <Meta
              name="meta.default.keywords"
              value={currentItem?.[language?.code]?.meta?.default?.keywords}
              label="Keywords"
            />
          </MuiAccordion>

          <MuiAccordion title="Meta  (For {country} & {City} )">
            <Meta
              name="meta.title"
              label="Title"
              value={currentItem?.[language?.code]?.meta?.title}
            />
            <Meta
              name="meta.description"
              label="Description"
              value={currentItem?.[language?.code]?.meta?.description}
            />
            <Meta
              name="meta.keywords"
              label="Keywords"
              value={currentItem?.[language?.code]?.meta?.keywords}
            />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid || loading}
            >
              Update
              {(isSubmitting || loading) && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default EditLanguageSpecificCategory;

export const TransformCategoryData = ({ formData, id, language }) => {
  const responseData = {
    [language?.code]: {
      ...formData,
    },
    language_code: language?.code,
    _id: id,
  };
  return responseData;
};
