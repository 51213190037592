import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

export default function AutoComplete({
  onChange,
  options,
  multiple = false,
  selected,
  label,
  fullWidth = false,
}) {
  const value = options?.find((d) => d._id === selected);
  return (
    <Grid item xs={6}>
      <Autocomplete
        value={value}
        multiple={multiple}
        options={options}
        getOptionLabel={(option) => option.name}
        onChange={(event, data) => {
          onChange(data, event);
        }}
        renderInput={(params) => (
          <TextField label={label} {...params} variant="outlined" />
        )}
      />
    </Grid>
  );
}
