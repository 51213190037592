import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Alert, InputGroup, Col, Button, Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { optionService } from '../../../../../services';

function Add(props) {
  const { mutate, isLoading, isError, error } = useMutation(
    (formData) =>
      optionService.addExtra({
        name: formData.name,
        order: 0,
        option_id: props.option_id,
        field_id: props.field_id,
      }),
    {
      onSuccess: () => {
        props.onAdded();
      },
    }
  );
  const { register, errors, reset, handleSubmit } = useForm();
  const onSubmit = (formData) => {
    mutate(formData);
    reset({});
  };
  return (
    <div>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group
          as={Col}
          md="12"
          controlId="validationCustom01"
          className="p-0"
        >
          <InputGroup>
            <Form.Control
              type={'text'}
              label="name"
              {...register('name', {
                required: 'Name is required',
              })}
              name="name"
            />
            <InputGroup.Append>
              <Button variant="secondary" type="submit" disabled={isLoading}>
                Add
                {isLoading && (
                  <Spinner animation="border" size="sm" variant="info" />
                )}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Add;
