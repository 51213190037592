import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useForm } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const Website = ({ value }) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  React.useEffect(() => {
    setValue('website', value);
  }, [value]);

  return (
    <>
      <Form.Group controlId="website" className="mt-3 mb-0">
        <Form.Label className="font-weight-bold">Business Website</Form.Label>
        <Form.Control
          type={'text'}
          label="website"
          placeholder="https://www.adjeem.com"
          {...register('website')}
          name="website"
        />
      </Form.Group>

      {errors?.website && (
        <RequestErrorMessage message={` Please enter the compnay website.`} />
      )}
    </>
  );
};

export default Website;
