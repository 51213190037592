import React from 'react';
import { Form, Col } from 'react-bootstrap';

const Input = ({
  label,
  register,
  name,
  required = false,
  col,
  noMargin = false,
  errors,
  type = 'text',
  ...rest
}) => {
  if (errors) {
  }
  return (
    <Form.Group
      as={Col}
      className={`p-0 ml-2 mr-2 ${noMargin ? 'mb-0' : ''} col-md-${col} `}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...rest}
        name={name}
        {...register(name, { required })}
        // ref={register(name, { required })}
        type={type}
        isInvalid={errors && errors[name]}
      />
    </Form.Group>
  );
};
export default Input;
