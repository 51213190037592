import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  img: {
    height: 60,
    width: 60,
    marginTop: -20,
    borderRadius: '50%',
  },
  imagContainer: {
    paddingLeft: '40px',
  },
}));

function Upload({ name, label }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const [preview, setPreview] = React.useState(null);

  const handleChange = (file) => {
    setPreview(URL.createObjectURL(file));
  };

  return (
    <Grid container className="mb-3">
      <Grid item xs={6}>
        <Form.Group controlId={name} className="mb-3">
          <Form.Label className="font-weight-bold">{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.Control
                type="file"
                onChange={(data) => {
                  onChange(data.target?.files[0]);
                  handleChange(data.target?.files[0]);
                }}
              />
            )}
          />
        </Form.Group>
      </Grid>
      <Grid item xs={6}>
        {preview && <img src={preview} className={classes?.img} />}
      </Grid>
    </Grid>
  );
}

export default Upload;
