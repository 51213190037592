import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import { Alert, Dropdown } from 'react-bootstrap';
import qs from 'query-string';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useSelector } from 'react-redux';

import Table from '../../../common/table';
import { countryService } from '../../../../services';

function Index() {
  const navigate = useNavigate();
  const languages = useSelector((state) => state.language.languages);
  const params = useParams();
  const location = useLocation();
  const country = qs.parse(location.search)?.country;

  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  const onEdit = (row) =>
    navigate(
      `/settings/cities/${row.country_id}/${row._id}?country=${country}`
    );

  const {
    data,
    error,
    isError,
    isLoading: isFetching,
  } = useQuery(
    ['countries', query],
    () => countryService.cities({ ...query, country_id: params.id }),
    {
      refetchOnWindowFocus: false,
      enabled: !!query,
    }
  );

  const {
    mutate,
    error: deleteError,
    isError: isErr,
    isSuccess: isSuc,
  } = useMutation((row) => countryService.deleteCity(row));

  React.useEffect(() => {
    if (isSuc) {
      toast.success('Successfully deleted !');
      setQuery({ ...query, at: Date.now() });
    }
    //eslint-disable-next-line
  }, [isSuc]);

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };

  const onLanguageSpecificEdit = (row, language) => {
    navigate(
      `/settings/cities/${params.id}/${row._id}/${language._id}?name=${language.name}&country=${country}`
    );
  };

  const cities = data?.data?.data;

  return (
    <>
      {isError || isErr ? (
        <Alert variant="info">
          {error?.response?.data?.message ||
            deleteError?.response?.data?.message}
        </Alert>
      ) : null}
      <Table
        title={` ${country} → Cities`}
        query={query}
        setQuery={setQuery}
        data={cities || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() =>
          navigate(`/settings/cities/${params.id}/new?country=${country}`)
        }
        columns={[
          {
            label: 'Name',
            path: 'name',
          },
          {
            label: 'Order',
            path: 'order',
          },
          {
            label: 'Status',
            path: 'status',
          },
          {
            label: 'Bound',
            content: (row) => (
              <>
                <ButtonGroup
                  color="secondary"
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button>N {row?.bounds?.north?.toFixed(2)}</Button>
                  <Button>E {row?.bounds?.east?.toFixed(2)}</Button>
                  <Button>S {row?.bounds?.south?.toFixed(2)}</Button>
                  <Button>W {row?.bounds?.west?.toFixed(2)}</Button>
                </ButtonGroup>
              </>
            ),
          },

          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <>
                <div className="d-flex flex-wrap justify-content-center">
                  <Dropdown className="mr-1">
                    <Dropdown.Toggle
                      // className="ml-2"
                      variant="btn btn-primary"
                    >
                      Edit
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {languages?.map((language) =>
                        language.code !== 'en' ? (
                          <Dropdown.Item
                            onClick={() =>
                              onLanguageSpecificEdit(row, language)
                            }
                            key={language._id}
                          >
                            Edit - {language.name}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={() => onEdit(row)}
                            key={language._id}
                          >
                            Edit
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default Index;
