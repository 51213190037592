import http from './httpService';

const fieldEndpoint = '/admin/fields';
class OptionService {
  create = (data) =>
    http.post(`${fieldEndpoint}/${data.field_id}/options/create`, data);

  paginate = (options) => {
    const { page, limit, field_id } = options;
    let extraQuery = '';

    extraQuery += `&sort=${options.sorted}`;
    extraQuery += options.search ? `&search=${options.search}` : '';
    extraQuery += '&field_id=' + field_id;
    return http.get(
      `${fieldEndpoint}/${field_id}/options/paginate?page=${page}&limit=${
        limit + extraQuery
      }`
    );
  };

  getItem = (id, field_id) =>
    http.get(`${fieldEndpoint}/${field_id}/get/${id}`);

  update = (data) =>
    http.put(
      `${fieldEndpoint}/${data.field_id}/options/update/${data._id}`,
      data
    );

  delete = (id, field_id) =>
    http.delete(`${fieldEndpoint}/${field_id}/options/delete/${id}`);

  addExtra = (data) =>
    http.post(
      `${fieldEndpoint}/${data.field_id}/options/${data.option_id}/extras/create`,
      {
        name: data.name,
        order: data.order,
      }
    );

  updateExtra = (data) =>
    http.put(
      `${fieldEndpoint}/${data.field_id}/options/${data.option_id}/extras/update/${data.extra_id}`,
      {
        name: data.name,
        is_disabled: !!data?.is_disabled,
        is_mandatory: !!data?.is_mandatory,
        language_code: data.language_code,
      }
    );

  deleteExtra = (data) =>
    http.delete(
      `${fieldEndpoint}/${data.field_id}/options/${data.option_id}/extras/${data.extra_id}`
    );

  paginateExtras = (field_id, option_id) =>
    http.get(
      `${fieldEndpoint}/${field_id}/options/${option_id}/extras/paginate`
    );

  addExtraData = (data) =>
    http.post(
      `${fieldEndpoint}/${data.field_id}/options/${data.option_id}/extras/${data.extra_id}/create`,
      {
        name: data.name,
        order: data.order,
      }
    );

  deleteExtraData = (data) =>
    http.delete(
      `${fieldEndpoint}/${data.field_id}/options/${data.option_id}/extras/${data.extra_id}/values/${data.value_id}`
    );

  updateExtraData = (data) =>
    http.put(
      `${fieldEndpoint}/${data.field_id}/options/${data.option_id}/extras/${data.extra_id}/values/${data.value_id}`,
      data
    );
}

export default new OptionService();
