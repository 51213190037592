import React from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';

import Breadcrumb from '../common/breadcrumb';
import { getUsersPath } from '../../config/path';
import { userService } from '../../services';
import RequestErrorMessage from '../common/requestError';

function CreateUser() {
  const navigate = useNavigate();
  const params = useParams();

  const [error, setError] = React.useState(null);

  const countriesWithCities = useSelector(
    (state) => state.country.countryWithcities
  );

  const countries = countriesWithCities
    ? Object.values(countriesWithCities)?.map((country) =>
        pick(country, ['_id', 'name', 'code', 'calling_code'])
      )
    : [];

  const deliveryOptions = [
    { id: 'yes_price_included', name: 'Yes Price Included' },
    { id: 'yes_with_addition_fee', name: 'Yes with additional fee' },
    { id: 'no', name: 'No' },
  ];

  const status = [
    { id: 'pending', name: 'Pending' },
    { id: 'active', name: 'Active' },
    { id: 'blocked', name: 'Blocked' },
    { id: 'deleted', name: 'Deleted' },
  ];

  const roles = [
    { id: false, name: false },
    { id: 'admin', name: 'Admin' },
    { id: 'manager', name: 'Manager' },
    { id: 'marketing', name: 'Marketing' },
  ];

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getUsersPath.fullPath());
  };

  const defaultValues = {
    name: null,
    email: null,
    password: null,
    address: { country: null, street: null, postcode: null },
    phone: { number: null, verifiedAt: null, calling_code: null },
    picture: {},
    avatar: null,
    disable_chat: false,
    disable_feedback: false,
    notificationOptions: {
      deals_and_recommendation: true,
      ads_info: true,
      message: true,
      feedback: true,
    },
    sex: null,

    role: false,
    status: 'active',

    company: {
      name: null,
      address: null,
      about: null,
      deliveryOptions: 'no',
      deliveryFee: null,
      show_address_on_ads: true,
      logo: {
        banner: null,
        logo: null,
      },

      socials: {
        facebook: {
          id: null,
        },
        google: {
          id: null,
        },
      },
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    userService
      .create({
        ...data,
        sex: data?.sex ? data?.sex : 'other',
      })
      .then((response) => {
        toast.success(
          `Successfully ${params.id === 'new' ? 'added new' : 'updated'} user`
        );
        getRoute();
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        console.error(error);
      });
  };

  return (
    <div>
      <Breadcrumb goBack={() => getRoute()} title={'Create'} />

      {error && <Alert variant="info"> {error} </Alert>}

      <Card className="p-5">
        <div className="row">
          <div className="col-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  name="name"
                />

                {errors?.name && (
                  <RequestErrorMessage
                    message={` Please enter the user name.`}
                  />
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  {...register('email', {
                    required: 'Email is required',
                  })}
                  name="email"
                />

                {errors?.email && (
                  <RequestErrorMessage
                    message={` Please enter the user email.`}
                  />
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  {...register('address.country')}
                  name="address.country"
                >
                  {countries.map((country) => (
                    <option key={country._id} value={country._id}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone"
                  {...register('phone.number')}
                  name="phone.number"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 ml-3"
                controlId="send_email_verification"
              >
                <Form.Check
                  type="checkbox"
                  name="send_email_verification"
                  label="Send Verification Email"
                  {...register('send_email_verification')}
                />
              </Form.Group>

              <Form.Group className="mb-3 ml-3" controlId="send_credential">
                <Form.Check
                  type="checkbox"
                  name="send_credential"
                  label="Send Credential"
                  {...register('send_credential')}
                />
              </Form.Group>

              <h4 className="mt-3">Company Information</h4>
              <hr />
              <Form.Group className="mb-3" controlId="company_name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  {...register('company.name')}
                  name="company.name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="company_address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address"
                  name="company.address"
                  {...register('company.address')}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="company_about">
                <Form.Label>About</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter about"
                  {...register('company.about')}
                  name="company.about"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Delivery Options</Form.Label>
                <Form.Control
                  as="select"
                  {...register('company.deliveryOptions')}
                  name="company.deliveryOptions"
                >
                  {deliveryOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="company_delivery_fee">
                <Form.Label>Delivery Fee</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="$120"
                  {...register('company.deliveryFee')}
                  name="company.deliveryFee"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control as="select" {...register('roles')} name="roles">
                  {roles.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Text id="passwordHelpBlock" muted>
                  * Please be careful while selecting the role
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" {...register('status')} name="status">
                  {status.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CreateUser;
