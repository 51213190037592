import React, { Component } from 'react';
import { Form, Spinner, Col, Button, Alert } from 'react-bootstrap';

import { optionService } from '../../../../../services';

class EditExtra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      errors: {},
      message: null,
      loading: false,

      name: '',
      language_code: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.onExtraUpdated = this.onExtraUpdated.bind(this);
  }

  componentDidMount() {
    if (this.props.language && this.props.language.code !== 'en') {
      this.setState({
        name: this.props.extra[this.props.language.code]
          ? this.props.extra[this.props.language.code].name
          : '',
        language_code: this.props.language.code,
      });
    } else {
      this.setState({
        name: this.props.extra.name,
        language_code: this.props.language.code,
      });
    }
  }

  inputChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onExtraUpdated = (data) => {
    this.props.onExtraUpdated(data);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    this.setState({ loading: true });

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true, loading: false });
    } else {
      optionService
        .updateExtra({
          name: this.state.name,
          option_id: this.props.option_id,
          field_id: this.props.field_id,
          extra_id: this.props.extra._id,
          language_code: this.state.language_code,
        })
        .then(({ data }) => {
          this.setState({ message: data.message, loading: false, extra: '' });
          this.onExtraUpdated(data.data);
        })
        .catch((error) => {
          this.setState({ message: 'Error! Please try again', loading: false });
        });
    }
  };

  render() {
    return (
      <div className='mb-3'>
        {this.state.message ? (
          <Alert variant='info'> {this.state.message} </Alert>
        ) : null}

        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
        >
          <Form.Group as={Col}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              required
              type='text'
              name='name'
              value={this.state.name || ''}
              onChange={this.inputChangeHandler}
              placeholder='Like: model name...'
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Button
              variant='secondary'
              type='submit'
              disabled={this.state.loading}
            >
              Update{' '}
              {this.state.loading ? (
                <Spinner animation='border' size='sm' variant='info' />
              ) : null}
            </Button>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default EditExtra;
