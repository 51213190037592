import React from 'react';
import { GoogleMap, LoadScript, Rectangle } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '350px',
};

const center = {
  lat: 22.3758661,
  lng: 46.1901457,
};

function MyComponent({ bounds, setValue }) {
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function onLoad(rectangle) {
    setMap(rectangle);
  }, []);

  delete bounds._id;

  const handleBoundsChange = () => {
    const bounds = map?.getBounds();

    if (bounds) {
      var NE = bounds.getNorthEast();
      var SW = bounds.getSouthWest();

      setValue({
        north: NE.lat(),
        east: NE.lng(),
        south: SW.lat(),
        west: SW.lng(),
      });
    }
  };
  return (
    <LoadScript googleMapsApiKey="AIzaSyCx6eW65u1yMA7BNKKVsvNUOplcziZwFQM">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4}>
        <>
          <Rectangle
            bounds={bounds}
            editable
            onLoad={onLoad}
            onBoundsChanged={handleBoundsChange}
          />
        </>
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(MyComponent);
