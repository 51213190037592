import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import Breadcrumb from '../../../common/breadcrumb';
import { SubmitButton } from '../../../common/button';
import { useMutation, useQueries } from 'react-query';
import { useForm } from 'react-hook-form';
import { Form, Alert } from 'react-bootstrap';

import { countryService, languageService } from '../../../../services';
import { getSanitizedData } from '../../../../utils/sanitizer';
import Spinner from '../../../common/spinner';
import { getCityBasedOnCountry } from '../../../../config/path';
import { MuiInput } from '../../../common/mui';

const mapDataToForm = (data, code) => {
  return {
    [code]: {
      name: data[code]?.name,
    },
  };
};
export default function LanguageSpecific() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const langName = query.get('name');
  const country = query.get('country');

  const { register, handleSubmit, reset, control, errors } = useForm({});

  const result = useQueries([
    {
      queryKey: ['language', params.lang],
      queryFn: () => languageService.getItem(params.lang),
    },
    {
      queryKey: ['city', params.city_id],
      queryFn: () => countryService.getCity(params.id, params.city_id),
    },
  ]);

  const {
    d1: lang,
    d2: city,
    error: fetchingError,
    isFetching,
  } = getSanitizedData(result);
  //eslint-disable-next-line
  React.useEffect(
    () => city && reset(mapDataToForm(city, lang?.code)),
    [city, lang?.code, reset]
  );

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(
          getCityBasedOnCountry(params.id, {
            country,
            name: langName,
          }).fullPath()
        );
  };

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      countryService.updateCity({
        city_id: city._id,
        country_id: params.id,
        locale: lang?.code,
        ...formData,
      }),
    {
      onSuccess: () => {
        toast.success(`Successfully updated city`);
        getRoute();
      },
    }
  );

  const onSubmit = (formData) => mutate(formData);

  if (isFetching) return <Spinner />;
  const args = { errors, register, control };

  return (
    <div>
      <Breadcrumb goBack={() => getRoute()} title={`Edit ${langName}`} />
      <div className="row ">
        {isError || fetchingError ? (
          <Alert variant="danger">
            {error?.response?.data?.message || fetchingError}
          </Alert>
        ) : null}
        <div className="card col-12">
          <div className="card-body col-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                controlId={`${lang?.code}.name`}
                className="mt-0 mb-0"
              >
                <Form.Label className="font-weight-bold">Name *</Form.Label>
                <Form.Control
                  type={'text'}
                  label={`${lang?.code}.name`}
                  {...register(`${lang?.code}.name`, {
                    required: 'Name is required',
                  })}
                  name={`${lang?.code}.name`}
                  placeholder="UAE"
                />
              </Form.Group>

              {/* <MuiInput label="Name" name={`${lang?.code}.name`} {...args} /> */}

              <div className="mt-4 ml-2">
                <SubmitButton isLoading={isSubmitting} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
