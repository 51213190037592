import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import * as arrayMove from 'array-move';
import { useFormContext } from 'react-hook-form';

import styles from '../add.module.scss';

const SortableItem = SortableElement(({ file, handleClick }) => (
  <div className={styles.thumb} key={file?.name}>
    <div className={styles.thumbInner}>
      <img src={file?.preview || file?.image} className={styles.img} />

      <label className={styles.remove_icon} onClick={handleClick}>
        &times;
      </label>
    </div>
  </div>
));

const SortableList = SortableContainer(({ files, handleClick }) => (
  <div>
    {files.map((file, index) => (
      <SortableItem
        file={file}
        key={`item-${file?.path}-${index}`}
        index={index}
        handleClick={() => handleClick(index)}
      />
    ))}
  </div>
));

function LogoUploader() {
  const { register, unregister, setValue } = useFormContext();

  const [showWarning, setWarning] = useState(false);
  const [error, setError] = useState('');
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    setValue('logo', logo);
  }, [logo]);

  useEffect(() => {
    register('logo');
    return () => {
      unregister('logo');
    };
  }, [register, unregister, 'logo']);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpeg,.png,.jpg',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setError(`Error: ${err.message}`);
          }

          if (err.code === 'file-invalid-type') {
            setError(`Error: ${err.message}`);
          }
        });
      });

      acceptedFiles.reduce((acc = [], file) => {
        let img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = function () {
          if (img.width > 40 && img.height > 40) {
            const image = Object.assign(file, {
              preview: URL.createObjectURL(file),
            });

            setLogo((prev) => [...prev, image].slice(0, 8));
            setWarning(false);
          } else {
            if (!showWarning) setWarning(true);
          }
        };
        return acc;
      }, []);
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setLogo(arrayMove(logo, oldIndex, newIndex));
  };

  const handleRemove = (index) => {
    let newFiles = [...logo];

    newFiles = newFiles.filter((_file, idx) => idx !== index);
    setLogo(newFiles);
  };

  return (
    <div>
      <label className="mt-3 mb-3">Upload Business Logo</label>
      <aside className={styles.thumbsContainer}>
        {logo.length > 0 && (
          <SortableList
            files={logo}
            onSortEnd={onSortEnd}
            axis={'xy'}
            handleClick={handleRemove}
            distance={1}
          />
        )}
      </aside>

      {logo.length !== 1 &&
        (logo.length > 0 && logo?.length <= 1 ? (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={styles.file_uploader_input}
            align="center"
          >
            <input {...getInputProps()} className={styles.dropzone_input} />
            <span className={styles.image_remaining}>
              {8 - logo.length + ' ' + 'images remaining'}{' '}
            </span>
          </div>
        ) : (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={styles.file_uploader_input}
            align="center"
          >
            <input {...getInputProps()} className={styles.dropzone_input} />
            <button
              className={`btn btn-dark ${styles.upload_button}`}
              type="button"
            >
              Upload
            </button>
            <br />
          </div>
        ))}
    </div>
  );
}
export default LogoUploader;
