import React from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap'

function CustomInputAppend({ value, remove, onChange, ...rest }) {
    return (
        <InputGroup className="mb-2 ml-0" >
            <Form.Control
                {...rest}
                md={12}
                type="number"
                placeholder="1 = 1 Days"
                name="days"
                value={value}
                onChange={(event) => onChange(event.target.value)}
            />
            <InputGroup.Append>
                <Button variant="danger" onClick={remove}><i className="fa fa-times"></i></Button>
            </InputGroup.Append>
        </InputGroup>
    );
}

export default CustomInputAppend;