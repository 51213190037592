import { buildQueryParam } from '../utils/queryParams';
import http from './httpService';
const listingEndpoint = '/admin/listings';

class ListingService {
  create = (data) => http.post(`${listingEndpoint}/create`, data);

  getItem = (id) => http.get(`${listingEndpoint}/get/${id}`);

  update = (data) => http.put(`${listingEndpoint}/update/${data._id}`, data);

  getListingById = async (id) => {
    const { data } = await http.get(
      `${listingEndpoint}/get-listing-detail-by-id/${id}`
    );
    return data?.data;
  };

  updateStatus = async (data) => {
    const update = await http.put(
      `${listingEndpoint}/update-status/${data._id}`,
      {
        status: data.status,
        action_by: 'admin',
      }
    );

    return { data: update?.data?.data };
  };

  delete = (id) => http.delete(`${listingEndpoint}/delete${id}`);

  getListingDetail = async (slug) => {
    const { data } = await http.get(
      `${listingEndpoint}/get-listing-detail/${slug}`
    );
    return data?.data;
  };

  paginate = (options) => {
    return http.get(
      `${listingEndpoint + '/paginate' + buildQueryParam(options)}`
    );
  };

  async updateListing(data) {
    return http.put(`${listingEndpoint}/update/${data._id}`, data);
  }

  async uploadImages(data, listing_id) {
    return await http.post(
      listingEndpoint + `/upload-images/${listing_id}`,
      data
    );
  }

  async uploadLogo(data, listing_id) {
    return await http.post(
      listingEndpoint + `/upload-logo/${listing_id}`,
      data
    );
  }
}

export default new ListingService();
