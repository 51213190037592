import React from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useQuery } from 'react-query';
import qs from 'query-string';

import { paymentService } from '../../../services';
import Table from '../../common/table';
import CustomChip from '../../common/chip';
import Filter from './filter';
import Refund from './refund';

function Payments() {
  const { search } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [query, setQuery] = React.useState(null);
  const [showRefund, setShowRefund] = React.useState(false);

  React.useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  const {
    data,
    isLoading: isFetching,
    isError,
    error,
  } = useQuery(['payments', query], () => paymentService.getPayments(query), {
    enable: !!query,
    refetchOnWindowFocus: false,
  });

  const onRefund = (row) => {
    if (typeof row !== 'object') return;
    setShowRefund((prev) => !prev);
    setSelected(row);
  };

  const handleRefundSuccess = () => {
    setOpen((prev) => !prev);
    setSelected(null);
  };

  return (
    <>
      {isError && (
        <Alert severity="error">{error?.response?.data?.message}</Alert>
      )}
      <Refund
        open={showRefund}
        setOpen={() => setShowRefund((prev) => !prev)}
        row={selected}
        refundSuccess={() => handleRefundSuccess()}
      />
      <Table
        title="Payment"
        query={query}
        setQuery={setQuery}
        data={data?.data?.data || {}}
        onToolbarAction={onRefund}
        isFetching={isFetching}
        custom
        filterable
        onOpen={() => setOpen((prev) => !prev)}
        open={open}
        columns={[
          {
            label: 'Amount',
            path: 'amount',
            content: (row) => {
              return (
                <>
                  {row?.amount} {row?.currency?.toUpperCase()}
                  <CustomChip row={{ ...row, status: row.status }} />
                </>
              );
            },
          },
          {
            label: 'Payment Details',
            path: 'payment_info',
            content: (row) => {
              return (
                <>
                  {row?.dpo_card_payment_detail
                    ? Object.entries(row?.dpo_card_payment_detail).map(
                        ([key, value]) => (
                          <p>
                            {key}: {value}
                          </p>
                        )
                      )
                    : ''}
                  {row?.paypal_payment_detail
                    ? JSON.stringify(row?.paypal_payment_detail)
                    : ''}
                </>
              );
            },
          },
          // {
          //   label: 'Payer Email',
          //   path: 'payment_info.payer.email',
          // },
          // {
          //   label: 'Phone',
          //   path: 'payment_info.payer.phone',
          // },
          // {
          //   label: 'Provider',
          //   path: 'payment_info.provider',
          // },
          {
            label: 'Created At',
            path: 'createdAt',
          },
        ]}
      >
        <Filter query={query} open={open} setQuery={setQuery} />
      </Table>
    </>
  );
}

export default Payments;
