import React from 'react';
import { Badge } from 'react-bootstrap';

export default function Checkbox({
  field,
  validation,
  handleOnFieldSelectChange,
  inputValues,
  slug,
}) {
  return (
    <>
      <label className="mb-2">
        {field.name} {field.mandatory ? '*' : ''}{' '}
      </label>
      <div>
        <div
          className={`${
            validation.errors && validation.errors[field._id]
              ? 'is-invalid'
              : ''
          }`}
        >
          {field.options.map((option, index) => (
            <Badge
              className={`ghost ptr mr-2 mb-2 ${
                option._id === inputValues[slug] ? 'active' : ''
              }`}
              key={index}
              onClick={(e) =>
                handleOnFieldSelectChange(field._id, option._id, slug)
              }
              value={inputValues[slug] || ''}
            >
              {option.value}
            </Badge>
          ))}
        </div>
        {validation.errors && validation.errors[field._id] && (
          <p className="invalid-feedback">{validation.errors[field._id]}</p>
        )}
      </div>
    </>
  );
}
