import React from 'react';
import { Badge, Form } from 'react-bootstrap';

export default function Radio({
  field,
  validation,
  handleOnFieldSelectChange,
  currentValue,
  slug,
}) {
  return (
    <>
      <Form.Group>
        <label className="mb-2">
          {field.name} {field.mandatory ? '*' : ''}
        </label>
        <div>
          <div
            className={`${
              validation.errors && validation.errors[field._id]
                ? 'is-invalid'
                : ''
            }`}
          >
            {field.options.map((option, index) => {
              return (
                <Badge
                  className={`ghost ptr mr-2 mb-2 ${
                    option._id === currentValue ? 'active' : ''
                  }`}
                  key={index}
                  onClick={(e) =>
                    handleOnFieldSelectChange(field._id, option._id, slug)
                  }
                >
                  {option.value}
                </Badge>
              );
            })}
          </div>

          {validation.errors && validation.errors[field._id] && (
            <p className="invalid-feedback">{validation.errors[field._id]}</p>
          )}
        </div>
      </Form.Group>
    </>
  );
}
