import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Dropdown } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import qs from 'query-string';
import { useSelector } from 'react-redux';

import { fieldService } from '../../../services';
import { toast } from 'react-hot-toast';
import Table from '../../common/table';
import { getOptionsBasedOnFields } from '../../../config/path';

function Field() {
  const navigate = useNavigate();

  const location = useLocation();

  const languages = useSelector((state) => state.language.languages);

  const [query, setQuery] = React.useState(null);

  React.useEffect(() => {
    setQuery(qs.parse(location.search));
  }, [location.search]);

  const { data, isLoading } = useQuery(
    ['categories', query],
    () => fieldService.paginate(query),
    { refetchOnWindowFocus: false, enabled: !!query }
  );

  const fields = data?.data?.data;

  const { mutate, error, isError } = useMutation(
    (row) => fieldService.delete(row._id),
    {
      onSuccess: () => {
        toast.success('Successfully deleted !');
        setQuery({ ...query, at: Date.now() });
      },
    }
  );

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };

  const onEdit = (row, language) =>
    language.code === 'en'
      ? navigate('/fields/edit/' + row._id)
      : navigate(
          `/fields/edit/${row._id}/language/${language._id}?lang=${language?.name}`
        );

  const onOptions = (row) =>
    navigate(getOptionsBasedOnFields(row._id, { name: row.name }).fullPath());

  return (
    <>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Table
        title="Fields"
        query={query}
        setQuery={setQuery}
        data={fields || {}}
        onToolbarAction={onDelete}
        isFetching={isLoading}
        onAddNew={() => navigate('/fields/add')}
        columns={[
          { label: 'Name', path: 'name' },
          { label: 'Type', path: 'type' },
          { label: 'Hint', path: 'hint_admin' },
          {
            label: 'Action',
            path: 'action',
            content: (row) => (
              <div className="d-flex flex-wrap">
                <Dropdown className="mr-1">
                  <Dropdown.Toggle
                    variant="btn btn-primary"
                    id="dropdownMenuButton1"
                  >
                    Edit
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languages
                      ? languages.map((language) =>
                          language.code !== 'en' ? (
                            <Dropdown.Item
                              onClick={() => onEdit(row, language)}
                              key={language._id}
                            >
                              Edit - {language.name}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => onEdit(row, language)}
                              key={language._id}
                            >
                              Edit{' '}
                            </Dropdown.Item>
                          )
                        )
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
                {row.type !== 'text' ? (
                  <button
                    className="btn btn-default btn-light"
                    onClick={() => onOptions(row)}
                  >
                    Options
                  </button>
                ) : (
                  ''
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default Field;
