import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-hot-toast';

import Breadcrumb from '../../../common/breadcrumb';
import { getBusinessesPath } from '../../../../config/path';
import Title from './fields/titleField';
import RequestErrorMessage from '../partials/requestError';
import Location from './fields/locationField';
import Website from './fields/websiteField';
import SelectCategory from './fields/selectCategoryField';
import {
  categoryService,
  fileService,
  listingService,
} from '../../../../services';
import Description from './fields/descriptionField';
import PriceRange from './fields/priceRangeField';
import BusinessHours from './fields/businessHoursField';
import Social from './fields/socialField';
import Faq from './fields/faqField';
import Video from './fields/videoField';
import ImageUploader from './fields/imageUploaderField';
import LogoUploader from './fields/logoUploaderField';
import Product from './fields/productField';
import Email from './fields/emailField';
import Status from './fields/statusField';
import ValidationErrors from '../partials/validationErrors';
import SendEmail from './fields/sendEmailField';

function Add() {
  const navigate = useNavigate();

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getBusinessesPath.fullPath());
  };

  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState('primary');
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [isFormValid, setIsFormValid] = React.useState(false);

  const { data: categories, isFetched: isCategoriesFetched } = useQuery(
    ['categories'],
    categoryService.getAllIndustries,
    {
      refetchOnWindowFocus: false,
    }
  );

  let cats = {};

  if (isCategoriesFetched) {
    const nest = (items, id = null, link = 'parent_id') =>
      items
        .filter((item) => item[link] === id)
        .map((item) => ({ ...item, children: nest(items, item._id) }));

    cats = nest(categories?.data?.data);
  }

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      listingService.create(
        TransformListingData({
          formData,
        })
      ),
    {
      //Upload files
      onSuccess: async ({ data }, formData) => {
        const listing = data?.data;

        await uploadMedia(formData, listing._id);

        toast.success(`Successfully added new business`);
        getRoute();
      },
    }
  );

  const methods = useForm({ mode: 'onChange' });

  const formState = methods.formState;

  const onSubmit = async (formData) => {
    mutate({
      ...formData,
    });
  };

  const uploadMedia = async (formData, listing_id) => {
    const files = formData?.files;
    const logo = formData?.logo;

    if (files && files.length > 0) {
      const fd = new FormData();
      for (let i = 0; i < files.length; i++) {
        fd.append('files', files[i]);
      }
      await listingService.uploadImages(fd, listing_id);
    }

    if (logo && logo.length > 0) {
      const lg = new FormData();
      lg.append('logo', logo[0]);
      await listingService.uploadLogo(lg, listing_id);
    }
  };

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  return (
    <FormProvider {...methods}>
      <Breadcrumb goBack={() => getRoute()} title={'Add Business'} />

      {message && validationErrors.length === 0 && (
        <RequestErrorMessage message={message} />
      )}

      {validationErrors.length > 0 && (
        <ValidationErrors errors={validationErrors} />
      )}

      {isError && error.response.status === 400 && (
        <ValidationErrors errors={error?.response?.data?.message} />
      )}

      <Card className="p-5">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Tabs
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="primary" title="PRIMARY">
              <Title />

              <Location />

              <Website />

              {isCategoriesFetched && <SelectCategory categories={cats} />}

              <Email />

              <Status />

              <SendEmail />
            </Tab>
            <Tab eventKey="about" title="ABOUT BUSINESS">
              <Description />
              <PriceRange />
              <Social />
              <Faq />

              <BusinessHours />
            </Tab>
            <Tab eventKey="media" title="MEDIA">
              <Video />
              <LogoUploader />
              <ImageUploader />
            </Tab>

            <Tab eventKey="products" title="PRODUCTS">
              <Product />
            </Tab>
          </Tabs>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid}
            >
              Submit
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </Card>
    </FormProvider>
  );
}

export default Add;

export const TransformListingData = ({ formData }) => {
  const responseData = {
    title: formData.title,
    description: formData.description,
    keywords: formData.keywords,
    category: {
      parent: {
        _id: formData.category.parent_id,
        slug: formData.category.parent_slug,
      },
      child: {
        _id: formData.category.id,
        slug: formData.category.slug,
      },
    },
    city: {
      _id: formData.location.city.id,
      slug: formData.location.city.slug,
    },
    country: {
      _id: formData.location.country.id,
      code: formData.location.country.code,
      name: formData.location.country.value,
    },

    address: formData.location.address,
    location: {
      lat: formData.location.lat,
      lng: formData.location.lng,
    },
    website: formData.website,
    email: formData.email,
    phone: {
      number: formData.phone,
      calling_code: formData.location.country.calling_code,
    },
    video: formData.video,
    socials: formData.social,
    status: formData.status,
    send_email: formData.send_email,
  };

  if (
    formData.products.length > 0 &&
    formData.products[0].name &&
    formData.products[0].description
  ) {
    const products = [];

    formData.products.forEach((item) => {
      if (item.name && item.description) {
        products.push({
          name: item.name,
          description: item.description,
          price: item.price,
        });
      }
    });

    responseData.products = products;
  }

  if (
    formData.faqs.length > 0 &&
    formData.faqs[0].title &&
    formData.faqs[0].description
  ) {
    const faqs = [];

    formData.faqs.forEach((item) => {
      if (item.title && item.description) {
        faqs.push({
          title: item.title,
          detail: item.description,
        });
      }
    });

    responseData.faqs = faqs;
  }

  if (formData.business_hours.length > 0) {
    const days = formData.business_hours.map((item) => {
      return {
        day: item.day,
        opening: item.opening,
        closing: item.closing,
        is_24_hours_open: item.is_24_hours_open,
      };
    });

    responseData.business_hours = { days };
  }

  if (formData.price) {
    const price = formData.price;

    if (price.from && price.to) {
      const range = {
        range: price.range,
        from: price.from,
        to: price.to,
        currency: formData.location.country.currency_code,
      };
      responseData.price_range = range;
    }
  }

  return responseData;
};
