import http from './httpService';

const fieldEndpoint = '/admin/fields';
class FieldService {
  create = (data) =>
    !data?._id
      ? http.post(`${fieldEndpoint}/create`, data)
      : http.put(`${fieldEndpoint}/update/${data._id}`, data);

  async paginate({
    page = 1,
    limit = 10,
    sorted = 'createdAt:desc',
    search = '',
  }) {
    let extraQuery = '';

    extraQuery += `&sort=${sorted}`;
    extraQuery += search ? `&search=${search}` : '';
    return http.get(
      `${fieldEndpoint}/paginate?page=${page}&limit=${limit + extraQuery}`
    );
  }

  getItem = (id) => http.get(`${fieldEndpoint}/get/${id}`);

  update = (data) => http.put(`${fieldEndpoint}/update/${data._id}`, data);

  delete = (id) => http.delete(`${fieldEndpoint}/delete/${id}`);

  all = () => http.get(`${fieldEndpoint}/all`);
}

export default new FieldService();
