import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const Email = ({ value }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // if (!value) {
  //   return <></>;
  // }

  return (
    <>
      <Form.Group controlId="email" className="mt-4 mb-0">
        <Form.Label className="font-weight-bold">Email</Form.Label>
        <Form.Control
          style={{ background: '#fff', minHeight: '40px', borderRadius: '4px' }}
          type={'email'}
          label="email"
          placeholder="info@adjeem.com"
          {...register('email')}
          name="email"
          defaultValue={value}
        />
      </Form.Group>

      {errors?.email && (
        <RequestErrorMessage message={` Please enter your email address.`} />
      )}
    </>
  );
};

export default Email;
