import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const Video = ({ defaultValue }) => {
  const { register } = useFormContext();

  return (
    <>
      <Form.Group controlId="video" className="mt-3 mb-0">
        <Form.Label className="font-weight-bold">Business Video</Form.Label>
        <Form.Control
          style={{ background: '#fff', minHeight: '40px', borderRadius: '4px' }}
          type={'text'}
          label="video"
          placeholder="ex: https://youtu.be/CUSxJKNP2e0"
          {...register('video')}
          name="video"
          defaultValue={defaultValue}
        />
      </Form.Group>
    </>
  );
};

export default Video;
