import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';

import Breadcrumb from '../../common/breadcrumb';
import { SubmitButton } from '../../common/button';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import CustomAutoComplete from '../../common/muiAutocomplete';
import Uploader from '../../common/uploader';
import { Alert, Form } from 'react-bootstrap';

import Spinner from '../../common/spinner';
import { metatagService, fileService } from '../../../services';
import customStyles from './customStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      paddingRight: theme.spacing(2),
      // width: '25ch',
    },
    padding: 10,
  },
  uploadWrapper: {
    display: 'flex',
    margin: '5px 0px 20px 5px',
  },
  img: {
    height: 90,
  },
}));

const options = [
  { value: 'homepage', label: 'Homepage' },
  { value: 'contactUs', label: 'Contact Us' },
  { value: 'login', label: 'Login' },
  { value: 'registration', label: 'Registration' },
  { value: 'sitemap', label: 'Site Map' },
];
export default function Field() {
  const classes = useStyles();
  const [isFileUploading, setIsFleUploading] = React.useState(false);
  const [files, setFiles] = React.useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const { register, handleSubmit, reset, errors, control, setValue, watch } =
    useForm({ defaultValues: { page: null } });

  const {
    data: d1,
    error: err,
    isLoading: isMetatagLoading,
  } = useQuery('field', () => metatagService.get(params.id), {
    enabled: params.id !== 'new',
  });

  const field = d1?.data?.data;

  React.useEffect(() => {
    if (field) {
      reset({ ...field, page: options.find((o) => o.value === field.page) });
    }
  }, [field, reset]);

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      params.id === 'new'
        ? metatagService.create(formData)
        : metatagService.update({ ...formData, _id: params.id }),
    {
      onSuccess: () => {
        toast.success(
          `Successfully ${
            params.id === 'new' ? 'added new' : 'updated'
          } metatag`
        );
        navigate('/settings/seo');
      },
    }
  );

  const onSubmit = async (formData) => {
    if (files) {
      setIsFleUploading(true);
      const formic = new FormData();
      formic.append('files', files);
      const { data } = await fileService.fileUpload(formic);
      formic.delete('files');
      mutate({
        image: data?.data?.image,
        ...formData,
        page: formData.page.value,
      });
      setIsFleUploading(false);
    } else {
      mutate({ ...formData, page: formData.page.value, image: field?.image });
    }
  };

  if (isMetatagLoading) return <Spinner />;

  const page = watch('page');
  const existingImage = watch('image');

  return (
    <div>
      <Breadcrumb
        route="/settings/seo"
        title={params.id === 'new' ? 'Create' : 'Edit'}
      />
      <div className="row ">
        {isError ||
          (err && (
            <Alert variant="danger">
              {error?.response?.data?.message || err}
            </Alert>
          ))}
        <div className="card col-12">
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row mt-3">
              <div className="col-sm-2">
                <Form.Group controlId="page" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">Page * </Form.Label>
                  <Controller
                    control={control}
                    name={`page`}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        onChange={(data) => {
                          onChange(data);
                        }}
                        placeholder={`Selec option`}
                        options={options}
                        styles={customStyles}
                        inputref={{
                          ...register(`page`, { required: true }),
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </div>

              <div className="col-sm-4">
                <Form.Group controlId="title" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">
                    Meta Title *
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="title"
                    {...register('title', {
                      required: true,
                    })}
                    name="title"
                    placeholder="meta title"
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6">
                <Form.Group controlId="description" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">
                    Meta Description *
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="description"
                    {...register('description', {
                      required: true,
                    })}
                    name="description"
                    placeholder="meta description"
                  />
                </Form.Group>
              </div>

              <div className="col-sm-6 mt-3">
                <Form.Group controlId="keywords" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">
                    Meta Keywords *
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="keywords"
                    {...register('keywords', {
                      required: true,
                    })}
                    name="keywords"
                    placeholder="meta keywords"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-6">
                <Form.Group controlId="keywords" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">Image</Form.Label>
                  <Uploader
                    onFileSelect={(file) => setFiles(file)}
                    classes={classes}
                    image={existingImage}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group controlId="alt" className="mt-0 mb-0">
                  <Form.Label className="font-weight-bold">
                    Image Alt *
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="alt"
                    {...register('alt', {
                      required: true,
                    })}
                    name="alt"
                    placeholder="alt"
                  />
                </Form.Group>
              </div>
            </div>

            {page?.value === 'homepage' && (
              <>
                <div className="row mt-4 mb-4">
                  <div className="col-sm-6">
                    <Form.Group controlId="default.title" className="mt-0 mb-0">
                      <Form.Label className="font-weight-bold">
                        Default Meta Title *
                      </Form.Label>
                      <Form.Control
                        type={'text'}
                        label="default.title"
                        {...register('default.title', {
                          required: true,
                        })}
                        name="default.title"
                        placeholder="meta title"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group
                      controlId="default.description"
                      className="mt-0 mb-0"
                    >
                      <Form.Label className="font-weight-bold">
                        Default Meta Description *
                      </Form.Label>
                      <Form.Control
                        type={'text'}
                        label="default.description"
                        {...register('default.description', {
                          required: true,
                        })}
                        name="default.description"
                        placeholder="meta description"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <Form.Group
                      controlId="default.keywords"
                      className="mt-0 mb-0"
                    >
                      <Form.Label className="font-weight-bold">
                        Default Meta keywords *
                      </Form.Label>
                      <Form.Control
                        type={'text'}
                        label="default.keywords"
                        {...register('default.keywords', {
                          required: true,
                        })}
                        name="default.keywords"
                        placeholder="meta keywords"
                      />
                    </Form.Group>
                  </div>
                </div>
                {/* <Typography>Default Meta Title</Typography>
                <TextField
                  error={errors?.title}
                  placeholder="meta title"
                  fullWidth
                  size="small"
                  multiline
                  name="default.title"
                  variant="outlined"
                  {...register('default.title', {
                    required: true,
                  })}
                /> */}
                {/* <Typography> Default Meta Description</Typography>
                <TextField
                  error={errors?.title}
                  placeholder="Meta descriptions"
                  fullWidth
                  size="small"
                  multiline
                  name="default.description"
                  variant="outlined"
                  {...register('default.description', {
                    required: true,
                  })}
                /> */}
                {/* <Typography>Default Meta keywords</Typography>
                <TextField
                  error={errors?.title}
                  placeholder="meta keywords"
                  fullWidth
                  size="small"
                  multiline
                  name="default.keywords"
                  variant="outlined"
                  {...register('default.keywords', {
                    required: true,
                  })}
                /> */}
              </>
            )}
            <SubmitButton isLoading={isSubmitting || isFileUploading} />
          </form>
        </div>
      </div>
    </div>
  );
}
