import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useFormContext } from 'react-hook-form';

import styles from '../edit.module.scss';

const SortableItem = SortableElement(({ file, handleClick }) => (
  <div className={styles.thumb} key={file?.name}>
    <div className={styles.thumbInner}>
      <img src={file?.preview || file?.image} className={styles.img} />

      <label className={styles.remove_icon} onClick={handleClick}>
        &times;
      </label>
    </div>
  </div>
));

const SortableList = SortableContainer(({ files, handleClick }) => (
  <div>
    {files.map((file, index) => (
      <SortableItem
        file={file}
        key={`item-${file?.path}-${index}`}
        index={index}
        handleClick={() => handleClick(index)}
      />
    ))}
  </div>
));

function ImageUploader({ currentItems }) {
  const { register, unregister, setValue } = useFormContext();

  const [showWarning, setWarning] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const [currentMedia, setCurrentMedia] = useState(null);

  useEffect(() => {
    setValue('files', files);
  }, [files]);

  useEffect(() => {
    register('files');
    return () => {
      unregister('files');
    };
  }, [register, unregister, 'files']);

  useEffect(() => {
    if (currentItems && Object.keys(currentItems) !== 0) {
      setCurrentMedia(currentItems);
    }
  }, []);

  useEffect(() => {
    const images = currentMedia?.images?.medium;

    if (currentMedia && Object.keys(images) !== 0) {
      const imgs = images.map((i) => ({
        file: null,
        image: i,
        media: currentMedia,
      }));
      setFiles(imgs);
    }
  }, [currentMedia]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpeg,.png,.jpg',
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setError(`Error: ${err.message}`);
          }

          if (err.code === 'file-invalid-type') {
            setError(`Error: ${err.message}`);
          }
        });
      });

      acceptedFiles.reduce((acc = [], file) => {
        let img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = function () {
          if (img.width > 100 && img.height > 100) {
            const image = Object.assign(file, {
              preview: URL.createObjectURL(file),
            });

            setFiles((prev) => [...prev, image].slice(0, 8));
            setWarning(false);
          } else {
            if (!showWarning) setWarning(true);
          }
        };
        return acc;
      }, []);
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setFiles(arrayMoveImmutable(files, oldIndex, newIndex));
  };

  const handleRemove = (index) => {
    let newFiles = [...files];

    newFiles = newFiles.filter((_file, idx) => idx !== index);
    setFiles(newFiles);

    const isExist = isImageExist(files, index);

    if (isExist) {
      removeFromCurrentMedia(index, isExist);
    }
  };

  const isImageExist = (files, index) => {
    const currentImg = files[index]?.image;

    if (currentImg) return currentImg;

    return false;
  };

  const removeFromCurrentMedia = (index, matchedImage) => {
    const currentMediumImages = currentMedia?.images?.medium;
    const currentLargeImages = currentMedia?.images?.large;
    const currentThumbnail = currentMedia?.thumbnail;

    let thumbnail = currentThumbnail;

    const matchedMediumImages = currentMediumImages.filter(
      (item) => item !== matchedImage
    );

    const matchedLargeImages = currentLargeImages.filter(
      (item) => item !== currentMedia.images.large[index]
    );

    if (currentThumbnail.medium === matchedImage) {
      thumbnail = null;
    }

    const mediaData = {
      images: {
        medium: matchedMediumImages,
        large: matchedLargeImages,
      },
      thumbnail: thumbnail,
    };

    setCurrentMedia(mediaData);
  };

  return (
    <div>
      <label className="mt-4 mb-3">Upload Images</label>
      <aside className={styles.thumbsContainer}>
        {files.length > 0 && (
          <SortableList
            files={files}
            onSortEnd={onSortEnd}
            axis={'xy'}
            handleClick={handleRemove}
            distance={1}
          />
        )}
      </aside>

      {files.length !== 8 &&
        (files.length > 0 && files?.length < 8 ? (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={styles.file_uploader_input}
            align="center"
          >
            <input {...getInputProps()} className={styles.dropzone_input} />
            <span className={styles.image_remaining}>
              {8 - files.length + ' ' + 'images remaining'} |{' '}
              <a role="button" className="btn btn-dark">
                Upload More
              </a>
            </span>
          </div>
        ) : (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={styles.file_uploader_input}
            align="center"
          >
            <input {...getInputProps()} className={styles.dropzone_input} />
            <button
              className={`btn btn-dark ${styles.upload_button}`}
              type="button"
            >
              Upload Files
            </button>
            <br />
          </div>
        ))}

      {error && files.length === 0 && (
        <p className={styles.invalid_input}>
          Please upload minimum of one (1) photo to attract buyers.
        </p>
      )}

      <p className="text-danger mt-2">
        {showWarning &&
          files.length < 8 &&
          'Some images are too small to upload'}
      </p>
    </div>
  );
}
export default ImageUploader;
