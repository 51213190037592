import React from 'react';
import { Form } from 'react-bootstrap';

export default function Textarea({
  field,
  validation,
  input,
  handleOnFieldTextChange,
  ...rest
}) {
  return (
    <>
      <Form.Group>
        <Form.Label>
          {field.name} {field.mandatory ? '*' : ''}
        </Form.Label>
        <Form.Control
          {...rest}
          className={`${
            validation.errors && validation.errors[field._id]
              ? 'is-invalid'
              : ''
          }`}
          as="textarea"
          rows={3}
          ref={input}
          onChange={(e) => handleOnFieldTextChange(field._id, e)}
        />
        {validation.errors && validation.errors[field._id] ? (
          <p className="invalid-feedback">{validation.errors[field._id]}</p>
        ) : (
          ''
        )}
      </Form.Group>
    </>
  );
}
