import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReorderIcon from '@material-ui/icons/Reorder';

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    isDragDisabled = true,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!isDragDisabled && (
          <TableCell align="left" style={{ width: 20 }}>
            <ReorderIcon disabled />
          </TableCell>
        )}
        <TableCell padding="checkbox" className="ml-4">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            disabled
          />
        </TableCell>
        {columns.map((headCell, idx) => (
          <TableCell
            className={classes.headerCell}
            // key={!!headCell?.path ? headCell?.path : idx}
            key={idx}
            align="left"
            sortDirection={orderBy === headCell.path ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.path}
              direction={orderBy === headCell.path ? order : 'asc'}
              onClick={
                !headCell.sortDisabled && createSortHandler(headCell.path)
              }
            >
              {headCell.label}
              {orderBy === headCell.path ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
