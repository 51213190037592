import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import RequestErrorMessage from '../../partials/requestError';
import Phone from './phoneField';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
  group: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menu: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 5,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e1f2fb' : 'white',
    color: state.isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)',
  }),
};

function Location({ currentItem }) {
  const [currentCountry, setCurrentCountry] = React.useState(null);
  const [currentCity, setCurrentCity] = React.useState(null);

  const countriesWithCities = useSelector(
    (state) => state.country.countryWithcities
  );

  const countries =
    React.useMemo(() => {
      if (countriesWithCities) return Object.values(countriesWithCities);
    }, [countriesWithCities]) || [];

  const {
    watch,
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const countriesOptions = [];

  if (countries && countries.length > 0) {
    countries.forEach((country) => {
      const data = {};
      data.label = country.name;
      data.value = country.name;
      data.id = country._id;
      data.code = country.code;
      data.calling_code = country.calling_code;
      data.currency_code = country.currency_code;
      data.cities = country.cities;
      countriesOptions.push(data);

      if (currentItem?.country?._id == country._id && !currentCountry) {
        setCurrentCountry(data);
      }
    });
  }

  let selectedCountry = watch('location.country');

  const isCountrySelected =
    selectedCountry && Object.values(selectedCountry).length > 0;

  const citiesOptions = [];

  if (selectedCountry && selectedCountry.cities.length > 0) {
    selectedCountry.cities.forEach((city) => {
      const data = {};
      data.label = city.name;
      data.value = city.name;
      data.id = city._id;
      data.slug = city.slug;
      citiesOptions.push(data);
      if (currentItem?.city?._id == city._id && !currentCity) {
        setCurrentCity(data);
      }
    });
  }

  React.useEffect(() => {
    if (currentCountry) {
      setValue('location.country', currentCountry);
    }
  }, [currentCountry]);

  if (!isCountrySelected) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" variant="secondary" />
      </div>
    );
  }

  return (
    <>
      <Form.Group className="mb-0 mt-4">
        <Form.Label className="font-weight-bold">
          Business located country *
        </Form.Label>

        <Controller
          control={control}
          name={`location.country`}
          defaultValue={currentCountry}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              placeholder={`Select country`}
              options={countriesOptions}
              styles={customStyles}
              inputref={{
                ...register(`location.country`, {
                  required: true,
                }),
              }}
            />
          )}
        />

        {errors.location && errors?.location['country'] && (
          <RequestErrorMessage message={`Please select country`} />
        )}
      </Form.Group>

      {isCountrySelected && (
        <>
          <Form.Group className="mb-0 mt-4">
            <Form.Label className="font-weight-bold">
              Business registered city *
            </Form.Label>
            <Controller
              control={control}
              name={`location.city`}
              defaultValue={currentCity}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(data) => {
                    onChange(data);
                  }}
                  placeholder={`Select city`}
                  options={citiesOptions}
                  styles={customStyles}
                  inputref={{
                    ...register(`location.city`, {
                      required: true,
                    }),
                  }}
                />
              )}
            />
            {errors.location && errors?.location['city'] && (
              <RequestErrorMessage message={`Please select city`} />
            )}
          </Form.Group>

          <Phone
            calling_code={selectedCountry.calling_code}
            currentItem={currentItem?.user}
          />

          <Form.Group className="mb-0 mt-4">
            <Form.Label className="font-weight-bold">
              Business Address *
            </Form.Label>
            <Form.Control
              type={'text'}
              label="location.address"
              placeholder="Ajman Free Zone"
              {...register('location.address', {
                required: 'Business address is required',
              })}
              name="location.address"
              defaultValue={currentItem?.address}
            />
          </Form.Group>

          {errors.location && errors?.location['address'] && (
            <RequestErrorMessage message={`Please enter business address`} />
          )}

          <div className="ml-2">
            <div className="row">
              <div className="col-xs-6">
                <Form.Group className="mb-0 mt-4">
                  <Form.Label className="font-weight-bold">
                    Latitude{' '}
                    <a
                      href="https://www.latlong.net/convert-address-to-lat-long.html"
                      target="blank"
                    >
                      Generate?
                    </a>
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="location.lat"
                    placeholder="25.204849"
                    {...register('location.lat')}
                    name="location.lat"
                    defaultValue={currentItem?.location?.coordinates[0]}
                  />
                </Form.Group>
              </div>
              <div className="col-xs-6">
                <Form.Group className="mb-0 mt-4">
                  <Form.Label className="font-weight-bold">
                    Longitude
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="location.lng"
                    placeholder="55.270782"
                    {...register('location.lng')}
                    name="location.lng"
                    defaultValue={currentItem?.location?.coordinates[1]}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Location;
