import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../../common/requestError';

const Link = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Group controlId="link" className="mt-0 mb-0">
        <Form.Label className="font-weight-bold">Link *</Form.Label>
        <Form.Control
          type={'text'}
          label="link"
          {...register('link', {
            required: true,
          })}
          name="link"
        />
      </Form.Group>

      {errors?.link && (
        <RequestErrorMessage message={` Please enter the banner link.`} />
      )}
    </>
  );
};

export default Link;
