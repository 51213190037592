import React from 'react';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 46,
    minHeight: 46,
  }),
  group: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menu: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 5,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e1f2fb' : 'white',
    color: state.isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)',
  }),
};

export default customStyles;
