import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  languages: [],
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    loadLanguage: (state, { payload }) => {
      state.languages = payload;
    },
  },
});

export const { loadLanguage } = languageSlice.actions;

export default languageSlice.reducer;
