import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import planService from '../../../services/plan.service';

import Table from '../../common/table';

const Plans = () => {
  const navigate = useNavigate();
  const languages = useSelector((state) => state.language.languages);
  const [query, setQuery] = React.useState({
    filtered: [],
    page: 1,
    limit: 10,
    sorted: 'createdAt:desc',
  });

  const { data, isLoading } = useQuery(
    ['plan', query],
    () => planService.paginate(query),
    { refetchOnWindowFocus: false }
  );
  const plans = data?.data?.data;

  const { mutate, error } = useMutation((row) => planService.delete(row._id), {
    onSuccess: () => {
      toast.success('Successfully deleted the item');
      setQuery({ ...query, at: Date.now() });
    },
  });

  const onEdit = (row, language) =>
    language.code === 'en'
      ? navigate('/subscriptions/plans/edit/' + row?._id)
      : navigate('/subscriptions/plans/edit/' + row?._id + '/' + language._id);

  const onDelete = (row) => {
    if (window.confirm('Are you sure you want to delete?')) mutate(row);
  };

  return (
    <>
      {error ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Table
        title="Plan"
        query={query}
        setQuery={setQuery}
        data={plans || {}}
        onToolbarAction={onDelete}
        isFetching={isLoading}
        onAddNew={() => navigate('/subscriptions/plans/new')}
        columns={[
          { label: 'Name', path: 'name' },
          { label: 'Description', path: 'description' },
          { label: 'Type', path: 'type' },
          { label: 'Order', path: 'order' },
          {
            label: 'Actions',
            sortDisabled: true,
            content: (row) => {
              return (
                <div className="d-flex flex-wrap ">
                  <>
                    <Dropdown className="mr-1">
                      <Dropdown.Toggle
                        variant="btn btn-primary"
                        id="dropdownMenuButton1"
                      >
                        Edit
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {languages
                          ? languages.map((language) =>
                              language.code !== 'en' ? (
                                <Dropdown.Item
                                  onClick={() => onEdit(row, language)}
                                  key={language._id}
                                >
                                  Edit - {language.name}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => onEdit(row, language)}
                                  key={language._id}
                                >
                                  Edit
                                </Dropdown.Item>
                              )
                            )
                          : null}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="mr-1">
                      <Dropdown.Toggle
                        variant="btn btn-secondary"
                        id="dropdownMenuButton1"
                      >
                        Actions
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {row?.durations?.map((duration, idx) => (
                          <Dropdown.Item
                            key={duration?._id || idx}
                            onClick={() => {
                              if (row?.durations?.length < 1) {
                                toast.error(
                                  'Please add durations before adding prices'
                                );
                                return true;
                              }

                              navigate(
                                '/subscriptions/plans/price/' +
                                  row?._id +
                                  '?name=' +
                                  row?.name +
                                  '&duration=' +
                                  duration?._id
                              );
                            }}
                          >{`Plan Price (${duration?.days} Days)`}</Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default Plans;
