import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const Website = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Group controlId="title" className="mt-3 mb-0">
        <Form.Label className="font-weight-bold">Business Website</Form.Label>
        <Form.Control
          type={'text'}
          label="website"
          placeholder="https://www.adjeem.com"
          {...register('website')}
        />
      </Form.Group>

      {errors?.website && (
        <RequestErrorMessage message={` Please enter the compnay website.`} />
      )}
    </>
  );
};

export default Website;
