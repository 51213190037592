import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';
import { Form, Spinner } from 'react-bootstrap';
import { categoryService, postService } from '../../../services';
import Breadcrumb from '../../common/breadcrumb';
import MuiAccordion from '../../common/muiAccordion';
import Name from '../categories/edit/fields/nameField';
import Description from '../categories/edit/fields/descriptionField';
import CategoryField from './Fields/categoryField';
import { useMutation } from 'react-query';

function QuickEdit() {
  const navigate = useNavigate();
  const params = useParams();

  const [currentItem, setCurrentItem] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    const fetchItem = async () => {
      return await postService.getPostById(params?.id);
    };

    fetchItem()
      .then((response) => {
        setCurrentItem(response);
      })
      .catch(console.error);

    const fetchCategories = async () => {
      return await categoryService.getAllCategories();
    };

    fetchCategories()
      .then(({ data }) => {
        setCategories(data?.data);
      })
      .catch(console.error);
  }, []);

  //Form Action
  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  const onSubmit = async (formData) => {
    mutate(formData);
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => update(formData), {
    onSuccess: () => {
      toast.success(`Successfully updated`);
      navigate('/ads?page=1&limit=10&sorted=createdAt:desc');
    },
  });

  const update = async (formData) => {
    const data = {
      category: {
        parent: {
          _id: formData.parent.value,
          slug: formData.parent.slug,
        },
        child: {
          _id: formData.child.value,
          slug: formData.child.slug,
        },
      },
      title: formData?.name,
      description: formData?.description,
    };

    console.log('Form Data', data);

    await postService.quickUpdate(data, params?.id);
  };

  if (!currentItem || !categories) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Breadcrumb
        goBack={() => navigate(`/ads`)}
        title={`Edit - ` + currentItem?.title}
      />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name value={currentItem?.title} />
            <Description value={currentItem?.description} />
            <CategoryField
              categories={categories}
              currentCategory={currentItem?.category}
            />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting}
            >
              Update
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>

          {/* <MuiAccordion title="General" open={true}>
            <Name value={currentItem?.name} />
            <MenuText value={currentItem?.menu_text} />
            <Order value={currentItem?.order} />

            <Type
              value={currentItem?.type}
              parentCategory={parentCategory}
              parent_id={parent_id}
            />
            {parent_id && type === 'real_state' && (
              <SubType value={currentItem?.sub_type} />
            )}

            <IsMenuItem value={currentItem?.is_menu_item} />
            <IsPopularItem value={currentItem?.is_popular_item} />
            <IsFeaturedItem value={currentItem?.is_featured} />
            <IsLatestItem value={currentItem?.is_latest_item} />
            <IsIndustry value={currentItem?.is_industry_category} />
            <MediaType value={currentItem?.media_type} />
            <Description value={currentItem?.description} />
            <TextareaField
              name="short_description"
              label="Short Description"
              value={currentItem?.short_description}
            />
            <Status value={currentItem?.status} />
          </MuiAccordion>
          <MuiAccordion title="Default Meta (For {country} only)">
            <Meta
              name="meta.default.title"
              label="Title"
              value={currentItem?.meta?.default?.title}
            />
            <Meta
              name="meta.default.description"
              value={currentItem?.meta?.default?.description}
              label="Description"
            />
            <Meta
              name="meta.default.keywords"
              value={currentItem?.meta?.default?.keywords}
              label="Keywords"
            />
          </MuiAccordion>

          <MuiAccordion title="Meta  (For {country} & {City} )">
            <Meta
              name="meta.title"
              label="Title"
              value={currentItem?.meta?.title}
            />
            <Meta
              name="meta.description"
              label="Description"
              value={currentItem?.meta?.description}
            />
            <Meta
              name="meta.keywords"
              label="Keywords"
              value={currentItem?.meta?.keywords}
            />
          </MuiAccordion>

          <MuiAccordion title="Media">
            <Upload
              name="images.icon"
              label="Icon Image"
              value={currentItem?.images?.icon}
              existingName="existing_icon"
            />
            <Upload
              name="images.thumbnail"
              label="Thumbnail Image"
              value={currentItem?.images?.thumbnail}
              existingName="existing_thumbnail"
            />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid || loading}
            >
              Update
              {(isSubmitting || loading) && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div> */}
        </form>
      </Grid>
    </FormProvider>
  );
}

export default QuickEdit;
