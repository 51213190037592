import React from 'react';
// import GooglePlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-google-places-autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import RequestErrorMessage from '../../partials/requestError';
import Phone from './phoneField';

const customStyles = {
  control: (base) => ({
    ...base,
    height: 40,
    minHeight: 40,
  }),
  group: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menu: (base) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 5,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e1f2fb' : 'white',
    color: state.isSelected ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)',
  }),
};

function Location(props) {
  const countriesWithCities = useSelector(
    (state) => state.country.countryWithcities
  );

  const countries =
    React.useMemo(() => {
      if (countriesWithCities) return Object.values(countriesWithCities);
    }, [countriesWithCities]) || [];

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const countriesOptions = [];

  if (countries && countries.length > 0) {
    countries.forEach((country) => {
      const data = {};
      data.label = country.name;
      data.value = country.name;
      data.id = country._id;
      data.code = country.code;
      data.calling_code = country.calling_code;
      data.currency_code = country.currency_code;
      data.cities = country.cities;
      countriesOptions.push(data);
    });
  }

  const selectedCountry = watch('location.country');

  const isCountrySelected =
    selectedCountry && Object.values(selectedCountry).length > 0;

  const citiesOptions = [];

  if (selectedCountry && selectedCountry.cities.length > 0) {
    selectedCountry.cities.forEach((city) => {
      const data = {};
      data.label = city.name;
      data.value = city.name;
      data.id = city._id;
      data.slug = city.slug;
      citiesOptions.push(data);
    });
  }

  return (
    <>
      <Form.Group className="mb-0 mt-4">
        <Form.Label className="font-weight-bold">
          Business located country *
        </Form.Label>
        <Controller
          control={control}
          name={`location.country`}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(data) => {
                onChange(data);
              }}
              placeholder={`Select country`}
              options={countriesOptions}
              styles={customStyles}
              inputref={{
                ...register(`location.country`, {
                  required: true,
                }),
              }}
            />
          )}
        />

        {errors.location && errors?.location['country'] && (
          <RequestErrorMessage message={`Please select country`} />
        )}
      </Form.Group>

      {isCountrySelected && (
        <>
          <Form.Group className="mb-0 mt-4">
            <Form.Label className="font-weight-bold">
              Business registered city *
            </Form.Label>
            <Controller
              control={control}
              name={`location.city`}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(data) => {
                    onChange(data);
                  }}
                  placeholder={`Select city`}
                  options={citiesOptions}
                  styles={customStyles}
                  inputref={{
                    ...register(`location.city`, {
                      required: true,
                    }),
                  }}
                />
              )}
            />
            {errors.location && errors?.location['city'] && (
              <RequestErrorMessage message={`Please select city`} />
            )}
          </Form.Group>

          <Phone calling_code={selectedCountry.calling_code} />

          <Form.Group className="mb-0 mt-4">
            <Form.Label className="font-weight-bold">
              Business Address *
            </Form.Label>
            <Form.Control
              type={'text'}
              label="location.address"
              placeholder="Ajman Free Zone"
              {...register('location.address', {
                required: 'Business address is required',
              })}
              name="location.address"
            />
          </Form.Group>

          {/* <Form.Group className="mb-0 mt-4">
            <Form.Label className="font-weight-bold">
              Business Address *
            </Form.Label>
            <Controller
              control={control}
              name={`location.address`}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <GooglePlacesAutocomplete
                  selectProps={{
                    placeholder: 'Search address',
                    onChange: (data) => {
                      const { description } = data.value;

                      const locationDetail = {
                        full_address: description,
                      };

                      geocodeByAddress(description)
                        .then((results) => getLatLng(results[0]))
                        .then(({ lat, lng }) => {
                          locationDetail.lat = lat;
                          locationDetail.lng = lng;
                        })
                        .catch((error) => {
                          console.log(error);
                        });

                      onChange(locationDetail);
                    },
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: [selectedCountry.code || 'ae'],
                    },
                    types: ['establishment'],
                  }}
                  apiKey="AIzaSyDPJSXD9RDnrk73OTW-mKbbaI3Rda7mInc"
                  onPlaceSelected={(place) => {
                    console.log('Lat', place.geometry.location.lat());
                    console.log('Lng', place.geometry.location.lng());
                  }}
                />
              )}
            />
          </Form.Group> */}

          <div className="ml-2">
            <div className="row">
              <div className="col-xs-6">
                <Form.Group className="mb-0 mt-4">
                  <Form.Label className="font-weight-bold">
                    Latitude{' '}
                    <a
                      href="https://www.latlong.net/convert-address-to-lat-long.html"
                      target="blank"
                    >
                      Generate?
                    </a>
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="location.lat"
                    placeholder="25.204849"
                    {...register('location.lat')}
                    name="location.lat"
                  />
                </Form.Group>
              </div>
              <div className="col-xs-6">
                <Form.Group className="mb-0 mt-4">
                  <Form.Label className="font-weight-bold">
                    Longitude
                  </Form.Label>
                  <Form.Control
                    type={'text'}
                    label="location.lng"
                    placeholder="55.270782"
                    {...register('location.lng')}
                    name="location.lng"
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          {errors.location && errors?.location['address'] && (
            <RequestErrorMessage message={`Please enter business address`} />
          )}
        </>
      )}
    </>
  );
}

export default Location;
