import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import Breadcrumb from '../../../common/breadcrumb';
import MuiAccordion from '../../../common/muiAccordion';
import { fieldService, fileService } from '../../../../services';
import Name from './fields/nameField';
import Type from './fields/typeField';
import IsMandatory from './fields/isMandatoryField';
import IsSearchable from './fields/isSearchableField';
import FeaturedStyle from './fields/featuredStyleField';
import Hint from './fields/hintField';
import AdminHint from './fields/adminHintField';
import Upload from './fields/upload';
import SubType from './fields/subTypeField';

function AddField() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const lang = query.get('lang');

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  const watchType = methods.watch('type');

  const isSubTypeActive =
    watchType?.value === 'select' ||
    watchType?.value === 'select_multiple' ||
    watchType?.value === 'radio';

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const onSubmit = async (formData) => {
    setLoading(true);
    if (formData?.image) {
      const [image] = await Promise.all([uploadImage(formData.image)]);
      mutate({
        ...formData,
        image: image,
      });
    } else {
      mutate(formData);
    }
  };

  const create = async (formData) => {
    const data = TransformFieldData({ formData });
    await fieldService.create(data);
  };

  const uploadImage = async (files) => {
    if (files) {
      let form = new FormData();
      form.append(`files`, files);
      const { data } = await fileService.fileUpload(form);
      const img = data?.data?.image;
      form.delete(`files`);
      return img;
    }
  };

  const {
    mutate,
    isLoading: isSubmitting,
    error: mutationError,
    isError,
  } = useMutation((formData) => create(formData), {
    onSuccess: () => {
      setLoading(false);
      toast.success(`Successfully  added new category`);
      navigate('/fields');
    },
  });

  return (
    <FormProvider {...methods}>
      <Breadcrumb goBack={() => navigate('/fields')} title={`Create Field`} />

      <Grid item xs={12}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MuiAccordion title="General" open={true}>
            <Name />
            <Type />
            <IsMandatory />
            <IsSearchable />
            <FeaturedStyle />
            <Hint />
            <AdminHint />
            {isSubTypeActive && <SubType />}
            <Upload name="image" label={`Icon Image`} />
          </MuiAccordion>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid || loading}
            >
              Submit
              {isSubmitting ||
                (loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ))}
            </button>
          </div>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default AddField;

export const TransformFieldData = ({ formData }) => {
  const responseData = {
    ...formData,
    featured: formData.featured.value,
    is_searchable: formData.is_searchable.value,
    mandatory: formData.mandatory.value,
    type: formData.type.value,
    sub_type: formData?.sub_type?.value,
  };
  return responseData;
};
