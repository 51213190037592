import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';

import CurrencyField from '../Fields/CurrencyField.component';

const options = ['exact_rate', 'minimum', 'maximum', 'range'];
const pay_option = ['per_day', 'per_week', 'per_month', 'per_year'];

const SalaryRange = forwardRef((props, ref) => {
  const [type, setType] = useState(options[0]);
  const [payOption, setPayOption] = useState(pay_option[0]);

  const { errors, initialValue, userData } = props;
  const fromPriceRef = React.createRef();
  const toPriceRef = React.createRef();

  React.useEffect(() => {
    setType(initialValue?.type || options[0]);
    setPayOption(initialValue?.per || pay_option[0]);
  }, [initialValue]);

  useImperativeHandle(ref, () => ({
    getSalaryRangeObject() {
      const from =
        fromPriceRef.current.attributes.getNamedItem('amount')?.value || null;
      const to =
        toPriceRef?.current?.attributes?.getNamedItem('amount')?.value || null;
      return {
        type,
        from:
          type === 'minimum' || type == 'range' || type === 'exact_rate'
            ? Number(from)
            : null,
        to:
          type === 'maximum'
            ? Number(from)
            : type == 'range'
            ? Number(to)
            : null,

        per: payOption,
      };
    },
  }));
  return (
    <Card className="p-4 mt-3">
      <h4 className="bg-light p-2 mb-3">Salary Range</h4>
      <Form.Group>
        <Form.Control
          as="select"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Row>
        <Col>
          <CurrencyField
            name="from"
            reff={fromPriceRef}
            userData={userData}
            validationError={errors}
            initialValue={initialValue?.from ? initialValue?.from : ''}
          />
        </Col>

        {type === 'range' && (
          <Col>
            <Row>
              <Col md={2} className="p-0 mt-2">
                to
              </Col>
              <Col md={10} className="p-0">
                <CurrencyField
                  name="to"
                  reff={toPriceRef}
                  userData={userData}
                  validationError={errors}
                  initialValue={initialValue.to ? initialValue.to : ''}
                />
              </Col>
            </Row>
          </Col>
        )}
        <Col>
          <Form.Control
            as="select"
            value={payOption}
            onChange={(e) => {
              setPayOption(e.target.value);
            }}
          >
            {pay_option.map((option) => (
              <option key={option} value={option}>
                option
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Card>
  );
});

SalaryRange.defaultProps = {};

export default SalaryRange;
