import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const navigators = [
  {
    type: 'link',
    path: '/dashboard',
    fabIcon: 'mdi mdi-shield-check menu-icon',
    label: 'Dashboard',
  },

  {
    type: 'link',
    path: '/items',
    fabIcon: 'mdi mdi-format-list-bulleted-square menu-icon',
    label: 'Items',
    model: 'Items',
  },
  {
    type: 'link',
    path: '/payments',
    fabIcon: 'mdi mdi-credit-card menu-icon',
    label: 'Payments',
    model: 'Payments',
  },
];

function MarketingSidebar() {
  const location = useLocation();

  const [state, setState] = React.useState({});

  const user = useSelector((state) => state.auth.currentUser);

  const toggleMenuState = (menuState) => {
    const newState = { ...state };
    if (newState[menuState]) {
      newState[menuState] = false;
    } else if (Object.keys(newState).length === 0) {
      newState[menuState] = true;
    } else {
      Object.keys(newState).forEach((i) => {
        newState[i] = false;
      });
      newState[menuState] = true;
    }
    setState(newState);
  };

  React.useEffect(() => {
    onRouteChanged();
    console.log('On Router changed');
  }, []);

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach((i) => {
      const newState = { ...state };
      newState[i] = false;
      setState(newState);
    });

    const dropdownPaths = [];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        const newState = { ...state };
        newState[obj.state] = true;
        setState(newState);
      }
    });
  };

  const isAuthorizedToRead = (model) => {
    return true;
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  React.useEffect(() => {
    onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, []);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {navigators.map((navigator, index) => (
          <React.Fragment key={navigator.label + index}>
            {navigator.type === 'link' ? (
              isAuthorizedToRead(navigator.model) || !navigator.model ? (
                <li
                  className={
                    isPathActive(navigator.path)
                      ? 'nav-item active'
                      : 'nav-item'
                  }
                >
                  <Link
                    className="nav-link"
                    to={navigator.toPath || navigator.path}
                  >
                    <i className={navigator.fabIcon}></i>
                    <span className="menu-title">{navigator.label}</span>
                  </Link>
                </li>
              ) : (
                ''
              )
            ) : !navigator.subNav.some((nav) =>
                isAuthorizedToRead(nav.model)
              ) ? (
              ''
            ) : (
              <li
                className={
                  navigator.subNav.some((nav) =>
                    location.pathname.startsWith(nav.path)
                  )
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <div
                  className={
                    state[navigator.value]
                      ? 'nav-link menu-expanded'
                      : 'nav-link'
                  }
                  onClick={() => toggleMenuState(navigator.value)}
                  data-toggle="collapse"
                >
                  <i className={navigator.fabIcon}></i>
                  <span className="menu-title">{navigator.label}</span>
                  <i className="menu-arrow"></i>
                </div>

                <Collapse in={state[navigator.value]}>
                  <div>
                    <ul className="nav flex-column sub-menu">
                      {navigator.subNav.map((nav) => {
                        return (
                          <React.Fragment key={nav.path}>
                            {isAuthorizedToRead(nav.model) && (
                              <li className="nav-item" key={nav.label}>
                                <Link
                                  className={
                                    isPathActive(nav.path)
                                      ? 'nav-link active'
                                      : 'nav-link'
                                  }
                                  to={nav.toPath}
                                >
                                  {nav.label}
                                </Link>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </div>
                </Collapse>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
}

export default MarketingSidebar;
