import React from 'react';
import { Form } from 'react-bootstrap';

const SelectField = props => {
  const { label, fieldId, handleOnFieldTextChange, name, cityList, selectedId=null } = props;
  return (
    <>
      <Form.Group controlId={fieldId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          value={selectedId}
          as="select"
          name={name}
          onChange={e => {
            handleOnFieldTextChange(e.target.value, fieldId);
          }}
          custom
        >
          <option></option>

          {cityList.map((city, index) => {
            return (
              <option value={city._id} key={index}>
                {city.name}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    </>
  );
};

SelectField.defaultProps = {
  value: '',
  label: 'Input Field Label',
  handleOnFieldTextChange: () => null
};

export default SelectField;
