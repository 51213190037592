import http from './httpService';

const countryEndpoint = '/admin/countries';
class CountryService {
  create = (data) => http.post(`${countryEndpoint}/create`, data);

  async paginate(options) {
    let extraQuery = '';
    extraQuery += `&sort=${options.sorted}`;
    extraQuery += options.search ? `&search=${options.search}` : '';
    return http.get(
      `${countryEndpoint}/paginate?page=${options.page}&limit=${
        options.limit + extraQuery
      }`
    );
  }

  getItem = (id) =>
    id !== 'new' ? http.get(`${countryEndpoint}/get/${id}`) : null;

  update = (data) => http.put(`${countryEndpoint}/update/${data._id}`, data);

  delete = (id) => http.delete(`${countryEndpoint}/delete/${id}`);

  all = () => http.get(`${countryEndpoint}/all`);

  createCity = (data) =>
    data?.city_id === 'new'
      ? http.post(`${countryEndpoint}/${data.country_id}/cities/create`, data)
      : http.put(
          `${countryEndpoint}/${data.country_id}/cities/${data.city_id}/update`,
          data
        );

  cities = (options) => {
    if (!options?.country_id) return;
    let extraQuery = '';
    extraQuery += `&sort=${options.sorted}`;
    extraQuery += options.search ? `&search=${options.search}` : '';
    return http.get(
      `${countryEndpoint}/${options.country_id}/cities?page=${
        options.page
      }&limit=${options.limit + extraQuery}`
    );
  };

  getCountriesWithCity = () => http.get(`${countryEndpoint}/cities`);

  getCity = (countryId, cityId) => {
    if (cityId === 'new') return;
    else return http.get(`${countryEndpoint}/${countryId}/cities/${cityId}`);
  };

  updateCity = (data) =>
    http.put(
      `${countryEndpoint}/${data.country_id}/cities/${data.city_id}/update`,
      data
    );

  deleteCity = (data) =>
    http.delete(
      `${countryEndpoint}/${data.country_id}/cities/${data._id}/delete`
    );
}

export default new CountryService();
