import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Alert } from 'react-bootstrap';
import { useMutation } from 'react-query';
import Button from '@material-ui/core/Button';

import { optionService } from '../../../../services';
import CustomModal from '../../../common/modal';
import RequestErrorMessage from '../../../common/requestError';

function Add({ show, option, onHide, field_id, onAdded }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { value: '' },
  });

  React.useEffect(
    () => reset({ value: option.value, order: option.order }),
    [option, reset]
  );

  const { mutate, isError, error, isLoading } = useMutation(
    (formData) =>
      optionService.update({
        value: formData.value,
        order: Number(formData.order),
        field_id,
        _id: option._id,
        require_input_field: option.require_input_field,
        language_code: option.language,
      }),
    {
      onSuccess: () => {
        reset();
        onAdded();
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <CustomModal
      show={show}
      onHide={onHide}
      headerTitle="Edit Options"
      btnLabel="Cancel"
    >
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="value" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Value *</Form.Label>
          <Form.Control
            type={'text'}
            label="value"
            {...register('value', {
              required: 'Value is required',
            })}
            name="value"
          />
        </Form.Group>

        {errors?.value && (
          <RequestErrorMessage message={` Please enter the value.`} />
        )}

        <Form.Group controlId="order" className="mt-2 mb-0">
          <Form.Label className="font-weight-bold">Order * </Form.Label>
          <Form.Control
            type={'number'}
            label="order"
            {...register('order', {
              required: 'order is required',
            })}
            name="order"
          />
        </Form.Group>

        {errors?.order && (
          <RequestErrorMessage message={` Please enter the order.`} />
        )}

        <Button
          type="submit"
          disabled={isLoading}
          color="secondary"
          variant="contained"
          className="mt-3 ml-2"
        >
          Update
        </Button>
      </Form>
    </CustomModal>
  );
}

export default Add;
