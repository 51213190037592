import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import RequestErrorMessage from '../../partials/requestError';

const Title = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Group controlId="title" className="mt-0 mb-0">
        <Form.Label className="font-weight-bold">Business Name *</Form.Label>
        <Form.Control
          type={'text'}
          label="title"
          placeholder="Adjeem.com FZE LLC"
          {...register('title', {
            required: 'Title is required',
          })}
          name="title"
        />
      </Form.Group>

      {errors?.title && (
        <RequestErrorMessage message={` Please enter the compnay name.`} />
      )}
    </>
  );
};

export default Title;
