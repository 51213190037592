import React from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ inputValues, setValues, validation }) => {
  return (
    <>
      <Form.Group className="mt-3">
        <Form.Label>Post expire in{'*'}</Form.Label>
        <DatePicker
          minDate={new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)}
          selected={inputValues?.expiresAt}
          onChange={(date) => setValues({ ...inputValues, expiresAt: date })}
          className="red-border"
        />
        {validation && validation['expiresAt'] ? (
          <p className="text-danger mt-1" style={{ fontSize: '11px' }}>
            {validation['expiresAt']}
          </p>
        ) : (
          ''
        )}
      </Form.Group>
    </>
  );
};

export default CustomDatePicker;
