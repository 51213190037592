import React, { forwardRef, useImperativeHandle } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const ContactDetail = forwardRef((props, ref) => {
  const { validation, currentUser, inputValues } = props;

  const nameRef = React.createRef();
  const emailRef = React.createRef();
  const phoneRef = React.createRef();

  useImperativeHandle(ref, () => ({
    getContactDetailObject() {
      return {
        phone: phoneRef.current.value || '',
        name: nameRef.current.value,
        email: emailRef.current.value,
      };
    },
  }));

  return (
    <div className="mt-4">
      <h4 className="mb-3">Contact</h4>
      <Grid item>
        <TextField
          name={'name'}
          size="small"
          variant="outlined"
          ref={nameRef}
          isMandatory={true}
          label="Name"
          disabled
          className="mr-1 mt-3"
          validationError={validation.errors}
          value={inputValues?.user?.name ? inputValues.user.name : ''}
          isReadOnly={(currentUser && currentUser.name) || false}
        />

        <TextField
          disabled={true}
          ref={phoneRef}
          label="Phone Number"
          variant="outlined"
          size="small"
          className="mr-1 mt-3"
          value={
            inputValues.user && inputValues.user.phone
              ? inputValues?.user?.phone?.calling_code +
                '-' +
                inputValues?.user?.phone?.number
              : ''
          }
        />

        <TextField
          name={'email'}
          ref={emailRef}
          label="Email"
          variant="outlined"
          size="small"
          className="mr-1 mt-3"
          disabled
          validationError={validation.errors}
          value={
            inputValues.user && inputValues.user.email
              ? inputValues.user.email
              : ''
          }
          isReadOnly={(currentUser && currentUser.email) || false}
        />
      </Grid>
    </div>
  );
});

ContactDetail.defaultProps = {};

export default ContactDetail;
