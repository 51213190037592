import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import qs from 'query-string';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Card from '@material-ui/core/Card';
import Select from 'react-select';

import GoogleMap from './map';

import { SubmitButton } from '../../../common/button';
import Breadcrumb from '../../../common/breadcrumb';
import { countryService } from '../../../../services';
import Spinner from '../../../common/spinner';
import { getCityBasedOnCountry } from '../../../../config/path';

import customStyles from '../customStyles';

const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'deleted', label: 'Deleted' },
];

const defaultValues = {
  status: statusOptions[0],
  order: null,
  bounds: {
    north: 16.685,
    south: 29.671,
    east: 54.234,
    west: 34.251,
  },
};

function CreateCity() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const countryName = qs.parse(location.search)?.country;
  const [currentBound, setCurrentBound] = React.useState(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const { data, isLoading } = useQuery(
    'country',
    () => countryService.getCity(params.id, params.city_id),
    { enabled: !!params?.id && !!params.city_id }
  );

  const city = data?.data?.data;

  React.useEffect(() => {
    if (city) {
      reset({ ...city, bounds: city?.bounds || defaultValues.bounds });
      const result = statusOptions.find((obj) => {
        return obj.value === city.status;
      });
      setValue('status', result);
    }
  }, [city, reset]);

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(
          getCityBasedOnCountry(params.id, { country: countryName }).fullPath()
        );
  };

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      countryService.createCity({
        ...city,
        ...formData,
        order: Number(formData?.order),
        country_id: params?.id,
        city_id: params?.city_id,
      }),
    {
      onSuccess: () => {
        toast.success(
          `Successfully ${params.id === 'new' ? 'added new' : 'updated'} city`
        );
        getRoute();
      },
    }
  );

  const onSubmit = async (formData) => {
    mutate({
      ...formData,
      status: formData?.status?.value,
      bounds: currentBound,
    });
  };

  const handleSetValue = (data) => {
    setCurrentBound(data);
  };

  const bounds = watch('bounds');

  if (isLoading) return <Spinner />;

  return (
    <div>
      <Breadcrumb goBack={() => getRoute()} title={countryName} />
      <Card className="p-5">
        {isError ? (
          <Alert variant="danger"> {error?.response?.data?.message} </Alert>
        ) : null}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-2">
              <Form.Group controlId="name" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Name *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="name"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  name="name"
                  placeholder="UAE"
                />
              </Form.Group>
            </div>

            <div className="col-sm-1">
              <Form.Group controlId="order" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Order *</Form.Label>
                <Form.Control
                  type={'text'}
                  label="order"
                  {...register('order', {
                    required: 'Order is required',
                  })}
                  name="order"
                  placeholder="1"
                />
              </Form.Group>
            </div>

            <div className="col-sm-2">
              <Form.Group controlId="status" className="mt-0 mb-0">
                <Form.Label className="font-weight-bold">Status</Form.Label>
                <Controller
                  control={control}
                  name={`status`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(data) => {
                        onChange(data);
                      }}
                      placeholder={`Selec status`}
                      options={statusOptions}
                      styles={customStyles}
                      inputref={{
                        ...register(`status`),
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-sm-12">
              <GoogleMap bounds={bounds} setValue={handleSetValue} />
            </div>
          </div>

          <SubmitButton isLoading={isSubmitting} />
        </Form>
      </Card>
    </div>
  );
}

export default CreateCity;
