import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import styles from '../edit.module.scss';

function Social({ currentItems }) {
  const { fields, append, remove } = useFieldArray({
    name: 'social',
  });

  const [media, setMedia] = useState([
    {
      name: 'Facebook',
      value: 'facebook',
    },
    {
      name: 'Instagram',
      value: 'instagram',
    },
    {
      name: 'Youtube',
      value: 'youtube',
    },
    {
      name: 'LinkedIn',
      value: 'linkedin',
    },
    {
      name: 'Twitter',
      value: 'twitter',
    },
  ]);

  const [inputs, setInputs] = useState({
    name: 'Facebook',
    value: 'facebook',
    link: '',
  });

  React.useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      currentItems.forEach((item) => {
        append([
          {
            link: item?.link,
            name: item?.name,
            value: item?.name,
          },
        ]);
      });
    }
  }, [currentItems]);

  const AddMedia = (index) => {
    if (!inputs.link) {
      alert(`Please enter the ${inputs.name} URL`);
      return;
    }
    const updateMedia = [inputs];

    append(updateMedia);
    setMedia(media.filter((item) => item.value !== inputs.value));
    setInputs({
      ...inputs,
      link: '',
    });
  };

  useEffect(() => {
    if (media[0]) {
      setInputs({
        ...inputs,
        name: media[0].name,
        value: media[0].value,
      });
    }
  }, [fields]);

  return (
    <>
      <div className="bg-light p-3 mt-4">
        <div className="row mt-4">
          <div className="form-group clearfix margin-bottom-0 col-md-12">
            <div className="form-group clearfix">
              <Form.Label className="font-weight-bold">Social Media</Form.Label>
              <div className={styles.day_hours}>
                <div className="hours-display">
                  {fields.map((field, index) => {
                    return (
                      <div key={index}>
                        <Form.Control type={'hidden'} value={fields[index]} />
                        <MediaComponent
                          item={field}
                          index={index}
                          onRemove={(index) => {
                            const currentField = fields[index];

                            setMedia((oldValue) => [
                              ...oldValue,
                              {
                                name: currentField.name,
                                value: currentField.value,
                              },
                            ]);
                            remove(index);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>

                {media[0] && (
                  <div className="medias">
                    <div className="row">
                      <div className="col-md-3">
                        <Form.Group controlId="social[]" className="mt-3 mb-0">
                          <label>Select</label>
                          <Form.Control
                            as="select"
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            onChange={(e) => {
                              setInputs({
                                ...inputs,
                                name: e.target.selectedOptions[0].dataset.name,
                                value: e.target.value,
                              });
                            }}
                          >
                            {media.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.value}
                                  data-name={item.name}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group controlId="social[]" className="mt-3 mb-0">
                          <label>URL</label>
                          <Form.Control
                            style={{
                              background: '#fff',
                              minHeight: '40px',
                              borderRadius: '4px',
                            }}
                            type={'text'}
                            value={inputs.link}
                            placeholder="https://www.facebook.com/Adjeemllc"
                            onChange={(e) => {
                              setInputs({
                                ...inputs,
                                link: e.target.value,
                              });
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <a
                          className={`btn btn-dark text-white  float-right ${styles.add_social_media_btn}`}
                          role="button"
                          onClick={AddMedia}
                        >
                          <span style={{ fontSize: 22 }}>&#43;</span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Social;

function MediaComponent({ index, onRemove, item }) {
  return (
    <div
      className="links"
      style={{
        borderBottom: '2px solid #fafafa',
        paddingTop: 10,
        paddingBottom: 10,
        position: 'relative',
      }}
    >
      <span className="weekday font-weight-bold text-capitalize">
        {item.name}
      </span>
      <span className="fullday ml-2 mr-2"></span>
      <a className="start ml-2 mr-2" href={item.link} target="blank">
        {item.link}
      </a>
      <a
        className="link-primary"
        role="button"
        style={{
          position: 'absolute',
          right: 15,
          top: 10,
        }}
        onClick={() => onRemove(index)}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </a>
    </div>
  );
}
