import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Card, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-hot-toast';

import Breadcrumb from '../../../common/breadcrumb';
import { getBusinessesPath } from '../../../../config/path';
import RequestErrorMessage from '../partials/requestError';
import { categoryService, listingService } from '../../../../services';

import Title from './fields/titleField';
import Location from './fields/locationField';
import Website from './fields/websiteField';
import Email from './fields/emailField';
import SelectCategory from './fields/selectCategoryField';
import Status from './fields/statusField';
import Description from './fields/descriptionField';
import PriceRange from './fields/priceRangeField';
import Social from './fields/socialField';
import Faq from './fields/faqField';
import BusinessHours from './fields/businessHoursField';
import Video from './fields/videoField';
import ImageUploader from './fields/imageUploaderField';
import LogoUploader from './fields/logoUploaderField';
import Product from './fields/productField';
import ValidationErrors from '../partials/validationErrors';

function EditListing(props) {
  const params = useParams();

  const navigate = useNavigate();

  const getRoute = () => {
    return navigate.action !== 'POP'
      ? navigate(-1)
      : navigate(getBusinessesPath.fullPath());
  };

  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [activeTab, setActiveTab] = React.useState('primary');
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [categories, setCategories] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    const fetchItem = async () => {
      return await listingService.getItem(params?.listing_id);
    };

    fetchItem()
      .then(({ data }) => {
        setCurrentItem(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    const fetchCategories = async () => {
      return await categoryService.getAllIndustries();
    };

    fetchCategories()
      .then(({ data }) => {
        console.log(data);
        const items = data.data;

        let cats = {};

        const nest = (items, id = null, link = 'parent_id') =>
          items
            .filter((item) => item[link] === id)
            .map((item) => ({ ...item, children: nest(items, item._id) }));

        cats = nest(items);

        setCategories(cats);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const {
    mutate,
    error,
    isLoading: isSubmitting,
    isError,
  } = useMutation(
    (formData) =>
      listingService.update(TransformListingData(formData, params?.listing_id)),
    {
      //Upload files
      onSuccess: async ({ data }, formData) => {
        const listing = data?.data;

        await uploadMedia(formData, listing._id);

        toast.success(`Successfully added new business`);
        getRoute();
      },
    }
  );

  const methods = useForm({ mode: 'onChange' });
  const formState = methods.formState;

  const onSubmit = async (formData) => {
    mutate({
      ...formData,
    });
  };

  const uploadMedia = async (formData, listing_id) => {
    const files = formData?.files;
    const logo = formData?.logo;

    if (files && files.length > 0) {
      const fd = new FormData();
      for (let i = 0; i < files.length; i++) {
        if (isImage(files[i])) {
          fd.append('files', files[i]);
        }
      }
      await listingService.uploadImages(fd, listing_id);
    }

    if (logo && logo.length > 0) {
      const lg = new FormData();
      if (isImage(logo[0])) {
        lg.append('logo', logo[0]);
        await listingService.uploadLogo(lg, listing_id);
      }
    }
  };

  React.useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  if (!currentItem || !categories || loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }
  return (
    <FormProvider {...methods}>
      <Breadcrumb
        goBack={() => getRoute()}
        title={`Edit: ${currentItem?.title}`}
      />

      {message && validationErrors.length === 0 && (
        <RequestErrorMessage message={message} />
      )}

      {validationErrors.length > 0 && (
        <ValidationErrors errors={validationErrors} />
      )}

      {isError && error.response.status === 400 && (
        <ValidationErrors errors={error?.response?.data?.message} />
      )}

      <Card className="p-5">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Tabs
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="primary" title="PRIMARY">
              <Title value={currentItem?.title} />
              <Location currentItem={currentItem} />

              <Email value={currentItem?.user?.email} />
              <Website value={currentItem?.website} />
              <SelectCategory
                currentItem={currentItem}
                categories={categories}
              />
              <Status value={currentItem?.status} />
            </Tab>
            <Tab eventKey="about" title="ABOUT BUSINESS">
              <Description currentItem={currentItem} />
              <PriceRange currentItem={currentItem} />
              <Social currentItems={currentItem?.socials} />
              <Faq currentItem={currentItem} />
              <BusinessHours currentItems={currentItem?.business_hours?.days} />
            </Tab>
            <Tab eventKey="media" title="MEDIA">
              <Video value={currentItem?.video} />
              <LogoUploader value={currentItem?.logo} />
              <ImageUploader currentItems={currentItem?.media} />
            </Tab>

            <Tab eventKey="products" title="PRODUCTS">
              <Product currentItems={currentItem?.products} />
            </Tab>
          </Tabs>

          <div className="action mt-4 mb-4">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              disabled={isSubmitting || !isFormValid}
            >
              Submit
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </Card>
    </FormProvider>
  );
}

export default EditListing;

export const TransformListingData = (formData, id) => {
  console.log('Before Transform', formData);
  const responseData = {
    _id: id,
    title: formData.title,
    description: formData.description,
    keywords: formData.keywords,
    category: {
      parent: {
        _id: formData.category.parent_id,
        slug: formData.category.parent_slug,
      },
      child: {
        _id: formData.category.id,
        slug: formData.category.slug,
      },
    },
    city: {
      _id: formData.location.city.id,
      slug: formData.location.city.slug,
    },
    country: {
      _id: formData.location.country.id,
      code: formData.location.country.code,
      name: formData.location.country.value,
    },

    address: formData.location.address,
    location: {
      lat: formData.location.lat,
      lng: formData.location.lng,
    },
    website: formData.website,
    email: formData.email,
    phone: {
      number: formData.phone,
      calling_code: formData.location.country.calling_code,
    },
    video: formData.video,
    socials: formData.social,
    status: formData?.status?.value,
  };

  if (
    formData.products.length > 0 &&
    formData.products[0].name &&
    formData.products[0].description
  ) {
    const products = [];

    formData.products.forEach((item) => {
      if (item.name && item.description) {
        products.push({
          name: item.name,
          description: item.description,
          price: item.price,
        });
      }
    });

    responseData.products = products;
  }

  if (
    formData.faqs.length > 0 &&
    formData.faqs[0].title &&
    formData.faqs[0].description
  ) {
    const faqs = [];

    formData.faqs.forEach((item) => {
      if (item.title && item.description) {
        faqs.push({
          title: item.title,
          detail: item.description,
        });
      }
    });

    responseData.faqs = faqs;
  }

  if (formData.business_hours.length > 0) {
    const days = formData.business_hours.map((item) => {
      return {
        day: item.day,
        opening: item.opening,
        closing: item.closing,
        is_24_hours_open: item.is_24_hours_open,
      };
    });

    responseData.business_hours = { days };
  }

  if (formData.price) {
    const price = formData.price;

    if (price.from && price.to) {
      const range = {
        range: price.range,
        from: price.from,
        to: price.to,
        currency: formData.location.country.currency_code,
      };
      responseData.price_range = range;
    }
  }

  if (formData.files && formData.files.length > 0) {
    formData.files.forEach((img) => {
      if (img.media) responseData.media = img?.media;
    });
  }

  if (formData.logo && formData.logo.length > 0) {
    const existingLogo = formData.logo;

    if (existingLogo[0].image)
      responseData.existing_logo = existingLogo[0].image;
  }

  return responseData;
};

export const isImage = (file) => {
  return !!file?.type?.match('image.*');
};
