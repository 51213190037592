import http from './httpService';
const langEndpoint = '/admin/languages';

class LanguageService {
  create = (data) => {
    if (data?._id === 'new') {
      delete data._id;
      http.post(`${langEndpoint}/create`, data);
    } else http.put(`${langEndpoint}/update/${data._id}`, data);
  };

  paginate = (options) => {
    const { page, limit } = options;
    let extraQuery = '';
    extraQuery += `&sort=${options.sorted}`;
    extraQuery += options.search ? `&search=${options.search}` : '';
    return http.get(
      `${langEndpoint}/paginate?page=${page}&limit=${limit + extraQuery}`
    );
  };

  getItem = (id) => http.get(`${langEndpoint}/get/${id}`);

  update = (data) => http.put(`${langEndpoint}/update/${data._id}`, data);

  delete = (id) => http.delete(`${langEndpoint}/delete/${id}`);

  all = () => http.get(`${langEndpoint}/all`);
}

export default new LanguageService();
