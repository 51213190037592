import { Grid } from '@material-ui/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import customStyles from './customStyles';

const SubType = (props) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const options = [
    { value: 'none', label: '--none--' },
    { value: 'brand', label: 'Brand' },
  ];

  React.useEffect(() => {
    setValue('sub_type', options[0]);
  }, []);

  return (
    <>
      <Grid item xs={12} lg={2} className="mt-3">
        <Form.Group controlId="sub_type" className="mt-0 mb-0">
          <Form.Label className="font-weight-bold">Sub Type</Form.Label>
          <Controller
            control={control}
            name={`sub_type`}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={(data) => {
                  onChange(data);
                }}
                placeholder={`Selec option`}
                options={options}
                styles={customStyles}
                inputref={{
                  ...register(`sub_type`),
                }}
              />
            )}
          />
        </Form.Group>
      </Grid>
    </>
  );
};

export default SubType;
