import React from 'react';
import Select from 'react-select';

const style = (error = '') => {
  return {
    control: (base) => ({
      ...base,
      background: '#e5e5e5',
      boxShadow: 'none',
      border: error ? '1px solid red' : 'none',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#e5e5e5',
        padding: '2px 0px 2px 15px',
        color: 'rgb(109, 109, 109)',
        fontSize: '13px',

        cursor: 'pointer',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover': {
          ...styles[':active'],
          backgroundColor: '#007bff',
          color: '#fff',
        },
      };
    },
    multiValue: (styles, { data }) => ({ ...styles, backgroundColor: 'white' }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      cursor: 'pointer',
    }),
  };
};
const MultipleSelect = ({ onChange, value, validation, field }) => {
  const options = field.options.map((o) => ({
    value: o._id,
    label: o.value,
  }));

  const handleChange = (data) => {
    let clonedValue = { ...data };

    if (data.length === 0) {
      delete clonedValue[field._id];
    } else {
      clonedValue = { ...value, [field._id]: data };
    }
    onChange(clonedValue, field._id);
  };

  const error = validation.errors && validation.errors[field._id];
  return (
    <Select
      isMulti
      onChange={handleChange}
      name="colors"
      options={options}
      placeholder=""
      value={value[field._id]}
      classNamePrefix="select"
      styles={style(error)}
    />
  );
};

export default MultipleSelect;
