import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useMutation, useQuery } from 'react-query';
import { Alert, Dropdown, Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import listingService from '../../../services/listingService';
import Table from '../../common/table';
import Filter from './filter';
import CustomChip from '../../common/chip';
import { getFlag } from '../../../utils/countryFlag';
import { ReactComponent as VerifiedIcon } from '../../../../assets/images/verified.svg';
import ListingDetails from './partials/listingDetails';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '0px',
    fontSize: 11,
    width: 250,
    height: 250,
    borderRadius: '50%',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 250,
    height: 250,
  },
  thumbnail: {
    borderRadius: '50%',
    cursor: 'pointer',
    width: 30,
    height: 30,
  },
  verifiedIcon: {
    height: '20px',
    width: '20PX',
  },
  promotedIcon: {
    height: '20px',
    width: '20PX',
    fill: '#f7bd0c',
    marginRight: '4px',
  },
  iconContainer: {
    display: 'flex',
  },
}));

function Businesses() {
  const { search } = useLocation();
  const classes = useStyles();
  const [query, setQuery] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedListing, setSelectedListing] = React.useState(null);
  const [showDetail, setShowDetail] = React.useState(false);
  const [updatedItem, setUpdatedItem] = React.useState(null);
  let navigate = useNavigate();

  const onDelete = (row) => {
    //if (window.confirm('Are you sure you want to delete?'))
    //mutate({ ...row, status: 'deleted' });
  };

  const onEdit = (row) => {
    navigate(`/businesses/edit/${row._id}`);
  };

  const OnAddNew = () => {
    navigate('/businesses/add');
  };

  const handleShowClick = (row) => {
    setShowDetail(true);
    setSelectedListing(row);
  };

  React.useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  const {
    data,
    isLoading: isFetching,
    error,
    isError,
  } = useQuery(
    [query, search, updatedItem],
    () => listingService.paginate(query),
    {
      refetchOnWindowFocus: true,
      enabled: !!query,
    }
  );

  return (
    <>
      {isError ? (
        <Alert variant="info"> {error?.response?.data?.message} </Alert>
      ) : null}
      <Table
        title="Businesses"
        filterable
        open={open}
        onOpen={() => setOpen((prev) => !prev)}
        query={query}
        setQuery={setQuery}
        data={data?.data?.data || {}}
        onToolbarAction={onDelete}
        isFetching={isFetching}
        onAddNew={() => OnAddNew()}
        columns={[
          {
            label: 'Date',
            path: 'createdAt',
            content: (row) => (
              <>
                <p>{moment(row.createdAt).format('ll')}</p>
                <p>{moment(row.createdAt).format('LT')}</p>
              </>
            ),
          },
          {
            label: 'Logo',
            content: (row) => (
              <div>
                {row?.logo || row?.media?.thumbnail?.medium ? (
                  <LightTooltip
                    placement="top"
                    title={
                      <React.Fragment>
                        <img
                          src={row?.logo || row?.media?.thumbnail?.medium}
                          className={classes.image}
                        />
                      </React.Fragment>
                    }
                  >
                    <img
                      src={row?.logo || row?.media?.thumbnail?.medium}
                      alt=""
                      className={classes.thumbnail}
                    />
                  </LightTooltip>
                ) : (
                  ''
                )}
              </div>
            ),
          },
          { label: 'Title', path: 'title' },
          { label: 'User', path: 'user.name' },
          {
            label: 'Status',
            content: (row) => <CustomChip row={row} alignLeft />,
          },

          {
            label: 'Country',
            content: (row) => (
              <Tooltip title={row?.country?.name} placement="bottom">
                <p> {getFlag(row?.country?.code)} </p>
              </Tooltip>
            ),
          },

          {
            label: '',
            content: (row) => (
              <div className={classes.iconContainer}>
                {row.isVerified && (
                  <Tooltip title="Verified Post" placement="bottom">
                    <VerifiedIcon className={classes.verifiedIcon} />
                  </Tooltip>
                )}

                <Tooltip
                  title="Edit Item"
                  placement="bottom"
                  className="ml-2 mr-2"
                >
                  <Button
                    variant="outline-secondary"
                    onClick={() => onEdit(row)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Tooltip>

                <Tooltip title="View Detail" placement="bottom">
                  <RemoveRedEyeIcon
                    className="ml-2"
                    onClick={() => handleShowClick(row)}
                    role="button"
                  />
                </Tooltip>
              </div>
            ),
          },

          // {
          //   label: 'Action',
          //   content: (row) => (
          //     <Grid container>
          //       <Grid item xs={8}>
          //         <div className="d-flex flex-wrap" align="left">
          //           <Dropdown className="mr-1">
          //             <Dropdown.Toggle
          //               variant="btn btn-primary"
          //               id="dropdownMenuButton1"
          //             >
          //               Action
          //             </Dropdown.Toggle>
          //             <Dropdown.Menu>
          //               {[
          //                 { label: 'Pending', status: 'pending' },
          //                 { label: 'Block', status: 'blocked' },
          //                 { label: 'Active', status: 'active' },
          //               ]
          //                 .filter((d) => d.status !== row.status)
          //                 .map((action) => {
          //                   return (
          //                     <Dropdown.Item
          //                       onClick={() => onEdit(row, action.status)}
          //                       key={action.status}
          //                     >
          //                       {action.label}
          //                     </Dropdown.Item>
          //                   );
          //                 })}
          //             </Dropdown.Menu>
          //           </Dropdown>
          //         </div>
          //       </Grid>
          //     </Grid>
          //   ),
          // },
        ]}
      >
        <Filter query={query} open={open} setQuery={setQuery} />
      </Table>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedListing?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListingDetails
            data={selectedListing}
            setUpdatedItem={setUpdatedItem}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Businesses;
