import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <div className="text-muted text-center text-sm-left">
          www.adjeem.com
        </div>
        <div className="mt-1 mt-sm-0 text-center"> <i className=" icon-heart text-danger"></i></div>
      </div>
    </footer>
  );

}

export default Footer;